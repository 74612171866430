var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "d-none d-lg-flex flex-column justify-content-center align-items-center h-100",
      attrs: { id: "vehicleContent" }
    },
    [
      _vm.vehicle.splashImage
        ? _c("b-img", {
            staticClass: "mb-3 vehicle-image",
            attrs: {
              src: _vm.vehicle.splashImage,
              alt: _vm.vehicleName,
              fluid: ""
            }
          })
        : _c("body-style-icon", { attrs: { id: _vm.vehicle.bodyStyle } }),
      _vm._v(" "),
      _c("span", [
        _vm._v(
          "New " + _vm._s(_vm.vehicle.year) + " " + _vm._s(_vm.vehicle.make)
        )
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "trim" }, [
        _vm._v(
          " " + _vm._s(_vm.vehicle.model) + " " + _vm._s(_vm.vehicle.trim) + " "
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }