<template>
    <div
        class="color-swatch d-flex flex-column text-center m-2 position-relative align-items-center"
        @click="swatchClick"
    >
        <div class="color-container">
            <div
                :id="color.name"
                :class="[
                    { selected: selected },
                    { 'g-border': isColorWhite },
                    { 'other-svg': isColorOther },
                    { 'copper-svg': isColorCopper },
                    { 'silver-svg': isColorSilver }
                ]"
                :style="{ backgroundColor: getColorCodeFor(color) }"
                class="color"
            />
            <i
                v-if="selected"
                class="ion-checkmark-round"
                :class="[
                    isColorWhite || isColorOther || isColorSilver
                        ? 'gray'
                        : 'white'
                ]"
                aria-hidden="true"
            />
        </div>
        <span class="color-title my-2">
            {{ color.name }}
        </span>
    </div>
</template>

<script>
import _ from "lodash";
export default {
    name: "ColorSwatch",
    props: {
        color: {
            type: Object,
            required: true
        },
        selected: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    computed: {
        isColorWhite() {
            return (
                this.color.name === "White" ||
                this.color.hex === "FEFEFE" ||
                this.color.hex === "FFFFFF"
            );
        },
        isColorOther() {
            return this.color.name === "Other" || _.isNil(this.color.hex);
        },
        isColorCopper() {
            return this.color.name === "Copper";
        },
        isColorSilver() {
            return this.color.name === "Silver";
        }
    },
    methods: {
        getColorCodeFor(color) {
            if (!_.isNil(color.hex)) {
                return "#" + color.hex;
            }

            return "white";
        },

        swatchClick() {
            this.$emit("click", this.color);
        }
    }
};
</script>

<style lang="scss">
.color-swatch {
    cursor: pointer;

    .color-container {
        height: 40px;
        width: 40px;
        box-sizing: border-box;
        border-radius: 50%;

        .other-svg {
            background-image: url("images/other_color_swatch.svg");
            background-size: contain;
        }

        .copper-svg {
            background-image: url("images/copper_swatch.svg");
            background-size: contain;
        }

        .silver-svg {
            background-color: transparent !important;
            background-image: url("images/silver_swatch.svg");
            background-repeat: repeat;
            background-size: 5px;
            border: 1px solid #d4d4d4;
        }

        .g-border {
            border: 1px solid $light-medium-gray;
        }

        .color {
            width: 100%;
            height: 100%;
            box-sizing: border-box;
            border-radius: 50%;

            &.selected {
                box-shadow: 0 0 0 4px #fff, 0 0 0 6px #007dc6;
            }
        }

        .gray {
            color: $muted-dark-gray;
        }

        .white {
            color: $white;
        }

        .ion-checkmark-round {
            position: relative;
            bottom: 35px;
            font-size: 1.5rem;
            z-index: 10;
        }
    }
    .color-title {
        font-size: px2rem(12);
        color: $dark-gray;
        max-width: 90px;
    }
}
</style>
