<template>
    <div id="session-reset-modal-wrapper">
        <cs-modal
            id="session-reset-modal"
            ref="srmodal"
            :hide-footer="hideFooter"
            size="sm"
            @hidden="onHidden"
        >
            <!--General title-->
            <div v-if="!timeExpiring" slot="modal-title">
                Welcome to CarSaver
            </div>
            <!--END: General title-->

            <!--Idle message-->
            <div v-if="showTimeExpiring" slot="modal-title">
                Need More Time?
            </div>
            <!--END: Idle message-->

            <!--Getting Started-->
            <b-container
                v-if="showGettingStarted"
                class=" d-flex justify-content-center"
            >
                <div
                    class="d-flex flex-row justify-content-center align-items-center"
                >
                    <button
                        :disabled="getStartedDisabled"
                        class="btn btn-primary modal-body-btn"
                        @click="getStarted"
                    >
                        Get Started
                    </button>
                    <button
                        v-if="showContinue"
                        :disabled="continueWithDisabled"
                        class="btn btn-primary modal-body-btn used-btn"
                        @click="continueWithUser"
                    >
                        Continue with {{ userData.firstName }}
                    </button>
                    <button
                        v-if="showImDone"
                        class="btn btn-primary modal-body-btn used-btn"
                        @click="imDone"
                    >
                        I'm Done
                    </button>
                </div>

                <div
                    v-show="showAmbassadorIdInput"
                    id="ambassador-id-form-wrapper"
                >
                    <div class="ambassador-id-form d-flex">
                        <input
                            id="ambassador-id-entry"
                            v-model="form.ambassadorId"
                            type="text"
                            maxlength="4"
                        />
                        <button
                            type="button"
                            class="VehicleCatalog-LinkButton"
                            @click="addAmbassadorId()"
                        >
                            <span class="ion-ios-plus add-ambassador-icon" />
                        </button>
                    </div>
                </div>
            </b-container>
            <!--END: Getting Started-->

            <!--Done Message-->
            <b-container
                v-if="showEndMessage"
                class="d-flex justify-content-center"
            >
                <p class="thank-you-message">
                    Thank you for visiting CarSaver. Have a nice day!
                </p>
            </b-container>
            <!--END: Done Message-->

            <!--Idle Message-->
            <b-container
                v-if="showTimeExpiring"
                class=" d-flex justify-content-center"
            >
                <div
                    class="d-flex flex-row justify-content-center align-items-center"
                >
                    <button
                        class="btn btn-primary modal-body-btn"
                        @click="onIdleContinue"
                    >
                        Yes
                    </button>
                    <button
                        class="btn btn-primary modal-body-btn used-btn"
                        @click="onIdleDiscontinue"
                    >
                        No
                    </button>
                </div>
            </b-container>
            <!--End: Idle Message-->

            <!--Footer-->
            <div
                v-if="!timeExpiring"
                slot="modal-footer"
                class="ambassador-id-wrapper"
            >
                <ul class="list-inline">
                    <li
                        v-for="(ambassadorId, key) in ambassadorIds"
                        :key="key"
                        class="list-inline-item ambassador-pins"
                    >
                        <b-link
                            :class="{ selected: isIdSelected(ambassadorId) }"
                            @click="chooseAmbassador(ambassadorId)"
                        >
                            <span>{{ ambassadorId }}</span>
                        </b-link>
                    </li>
                    <li class="list-inline-item">
                        <b-btn @click="showAmbassadorIdInput = true">
                            <span class="ion-ios-plus add-ambassador-icon" />
                        </b-btn>
                    </li>
                    <li class="list-inline-item back-to-kiosk">
                        <b-btn @click="backToKiosk()">
                            <span class="ion-ios-home back-to-kiosk-icon" />
                        </b-btn>
                    </li>
                    <li>
                        <span v-if="errorMsg" class="invalid-warning">
                            {{ errorMsg }}
                        </span>
                    </li>
                </ul>
            </div>

            <!--Idle Message Footer-->
            <div
                v-if="showTimeExpiring"
                slot="modal-footer"
                class="ambassador-id-wrapper  count-down"
            >
                <p>
                    Your session will expire in
                    <strong>{{ timerTimeRemaining }}</strong> seconds...
                </p>
            </div>
            <!--END: Idle Message-->
        </cs-modal>
    </div>
</template>
<script>
/* global Android */

import Api from "../../../../api";
import EventBus from "../../../../event-bus";
import CSModal from "../../../../components/CSModal";
import { get, commit } from "vuex-pathify";
import {
    trackEvent,
    trackGAEvent,
    trackPageView
} from "../../../../lib/GoogleTagManager";

export default {
    name: "SessionResetModal",

    components: {
        CsModal: CSModal
    },

    data() {
        return {
            form: {
                ambassadorId: null
            },
            errorMsg: null,
            isFormInvalid: false,
            showAmbassadorIdInput: false,
            getStartedDisabled: true,
            continueWithDisabled: true,

            showImDone: false,
            showContinue: false,

            submitting: false,
            checkingAmbassadorIdError: false,
            ambassadorIdInputVisible: false,
            selectedAmbassadorId: null,
            showEndMessage: false,
            hideFooter: false,
            timeExpiring: false,
            ambassadorIds: [],
            inSession: false,
            timerTimeRemaining: 30, // 30 seconds
            countDown: null
        };
    },

    computed: {
        showTimeExpiring: function() {
            return !this.showEndMessage && this.timeExpiring;
        },
        showGettingStarted() {
            return !this.showEndMessage && !this.timeExpiring;
        },
        userFirstName: get("user/user@firstName"),
        userData: get("user/user"),
        isLoggedIn: get("user/isSignedIn")
    },

    mounted: function() {
        this.init();
    },

    onIdle() {
        this.onIdle();
    },

    created() {
        EventBus.$on("idleVue_onIdle", () => {
            this.onIdle();
        });

        EventBus.$on("session-reset-modal-open", () => {
            this.showModal();
        });

        EventBus.$on("session-reset-modal.close", () => {
            this.hideModal();
        });
    },

    methods: {
        onIdle() {
            if (this.inSession) {
                this.timeExpiring = true;
                this.showModal();
                this.startTimer();
            }
        },

        getAmbassadorIds() {
            const storedAmbassadorIds = JSON.parse(
                localStorage.getItem("ambassadorIds")
            );

            return Array.isArray(storedAmbassadorIds)
                ? storedAmbassadorIds
                : [];
        },

        startTimer() {
            if (this.countDown == null) {
                this.countDown = setInterval(() => {
                    this.timerTimeRemaining = this.timerTimeRemaining - 1;

                    if (this.timerTimeRemaining === 0) {
                        this.stopTimer();
                        this.imDone();
                    }
                }, 1000);
            }
        },

        stopTimer() {
            clearInterval(this.countDown);
            this.countDown = null;
        },

        removeNonDigits(value) {
            if (typeof value === "string") {
                return value.replace(/\D/g, "");
            }

            return value;
        },

        getSessionData: function() {
            return {
                ambassadorId: this.removeNonDigits(
                    sessionStorage.getItem("ambassadorId")
                ),
                storeId: sessionStorage.getItem("walmartStoreId")
            };
        },

        isIdSelected(id) {
            return id === this.selectedAmbassadorId;
        },

        showModal: function() {
            trackPageView(this, "/session-reset");

            this.init();
            this.$refs.srmodal.show();
        },

        showGoodByeMessage: function() {
            this.hideFooter = true;
            this.showEndMessage = true;

            setTimeout(() => {
                this.hideModal();
            }, 2000);
        },

        hideModal: function() {
            this.$refs.srmodal.hide();
        },

        reset() {
            this.form.ambassadorId = null;
            this.submitting = false;
            this.ambassadorIdInputVisible = false;
            this.getStartedDisabled = true;
            this.continueWithDisabled = true;
            this.showImDone = false;
            this.selectedAmbassadorId = null;
            this.showAmbassadorIdInput = false;
            this.errorMsg = null;
            this.isFormInvalid = false;
            this.showEndMessage = false;
            this.hideFooter = false;
            this.timeExpiring = false;
            this.resetTimer();
            this.stopTimer();
        },

        onHidden() {
            this.reset();
        },

        chooseAmbassador(ambassadorId) {
            this.getStartedDisabled = false;
            this.continueWithDisabled = false;
            this.selectedAmbassadorId = ambassadorId;
        },

        getStarted() {
            trackGAEvent(this, {
                category: "session-reset",
                action: "get-started",
                label: "Get Started"
            });
            this.setSessionData();

            if (this.isLoggedIn) {
                this.logout()
                    .then(data => {
                        window.location.reload();
                    })
                    .catch(data => {
                        console.error("/logout", data);
                    });
            } else {
                this.hideModal();
            }
        },

        continueWithUser() {
            trackGAEvent(this, {
                category: "session-reset",
                action: "continue-with-user",
                label: "Continue"
            });

            this.setSessionData();
            this.hideModal();
        },

        setSessionData() {
            this.inSession = true;
            const session = this.getSessionData();

            trackEvent(this, "session-reset", {
                ambassadorId: this.selectedAmbassadorId,
                walmartId: session.storeId
            });

            sessionStorage.setItem(
                "ambassadorId",
                this.selectedAmbassadorId.toString()
            );
            commit("sessionReset/SET_AMBASSADOR_ID", this.selectedAmbassadorId);
        },

        logout() {
            return this.httpGet({ method: "GET", url: "/logout" });
        },

        httpGet(options) {
            return new Promise((resolve, reject) => {
                const xhr = new XMLHttpRequest();
                xhr.open(options.method || "GET", options.url);
                if (options.headers) {
                    Object.keys(options.headers).forEach(key => {
                        xhr.setRequestHeader(key, options.headers[key]);
                    });
                }
                xhr.onload = () => {
                    if (xhr.status >= 200 && xhr.status < 400) {
                        resolve(xhr.response);
                    } else {
                        reject(xhr.statusText);
                    }
                };
                xhr.onerror = () => reject(xhr.statusText);
                xhr.send(options.body);
            });
        },

        imDone() {
            const { ambassadorId, storeId } = this.getSessionData();
            const userId = this.userData ? this.userData.id : null;

            trackGAEvent(this, {
                category: "session-reset",
                action: "done",
                label: "Done"
            });

            if (this.isLoggedIn) {
                this.endSession(userId, storeId, ambassadorId)
                    .then(response => {
                        this.clearSession();
                    })
                    .catch(error => {
                        console.error("endSession error", error);
                    });
            } else {
                this.clearSession();
            }
        },

        clearSession() {
            sessionStorage.removeItem("ambassadorId");
            commit("sessionReset/SET_AMBASSADOR_ID", "");
            this.inSession = false;
            this.showGoodByeMessage();
            this.logout()
                .then(data => {
                    this.redirect("/");
                })
                .catch(data => {
                    console.error("/logout", data);
                });
        },

        endSession(userId, walmartId, ambassadorPin) {
            const data = {
                userId,
                walmartId,
                ambassadorPin
            };

            return Api.post("/session-reset/endsession", data);
        },

        backToKiosk() {
            trackGAEvent(this, {
                category: "session-reset",
                action: "back-to-kiosk",
                label: "Back to Kiosk"
            });

            var fallbackUrl = window.location.origin;

            if (
                typeof Android !== "undefined" &&
                typeof Android.returnToKiosk() === "function"
            ) {
                Android.returnToKiosk();
            } else {
                window.location = fallbackUrl;
            }
        },

        redirect(path) {
            window.location = path;
        },

        addAmbassadorId() {
            this.errorMsg = null;

            if (this.form.ambassadorId) {
                const storedAmbassadorIds =
                    JSON.parse(localStorage.getItem("ambassadorIds")) || [];

                if (
                    Array.isArray(storedAmbassadorIds) &&
                    storedAmbassadorIds.indexOf(this.form.ambassadorId) !== -1
                ) {
                    this.errorMsg = "Ambassador Id already added";
                    return;
                }

                Api.get(
                    `/session-reset/walmarts/ambassador/?userPin=${this.form.ambassadorId}`
                )
                    .then(response => {
                        if (this.ambassadorIds.length === 5) {
                            this.ambassadorIds.pop();
                        }

                        this.ambassadorIds.unshift(this.form.ambassadorId);
                        localStorage.setItem(
                            "ambassadorIds",
                            JSON.stringify(this.ambassadorIds)
                        );
                        this.form.ambassadorId = "";
                    })
                    .catch(error => {
                        console.error(error);
                        this.errorMsg = "Invalid ambassadorId";
                    });
            } else {
                this.errorMsg = "Please enter your 4 digit PIN";
                console.log("ambassadorId was empty or already present");
            }
        },

        onIdleContinue: function() {
            trackGAEvent(this, {
                category: "session-reset",
                action: "idle-continue",
                label: "Continue"
            });

            this.stopTimer();
            this.resetTimer();
            this.hideModal();
        },

        onIdleDiscontinue: function() {
            trackGAEvent(this, {
                category: "session-reset",
                action: "idle-discontinue",
                label: "I'm Done"
            });

            this.imDone();
        },

        resetTimer() {
            this.timerTimeRemaining = 30;
        },

        init() {
            const session = this.getSessionData();

            this.ambassadorIds = this.getAmbassadorIds();
            this.inSession = !!session.ambassadorId;

            this.showImDone = this.inSession;
            this.showContinue = this.isLoggedIn;
        }
    }
};
</script>
<style lang="scss">
#session-reset-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    button {
        white-space: normal;
        opacity: 0.8;

        &:focus {
            box-shadow: none !important;
            opacity: 1;
            outline: none;
        }

        &:disabled,
        &:disabled:focus,
        &:disabled:hover {
            cursor: cursor;
            opacity: 0.5;
        }
    }

    .invalid-warning {
        color: #a94442;
        font-size: 14px;
        line-height: 46px;
    }

    .modal-body {
        display: flex;
        max-height: 295px;
        padding: 0;
        margin: 0;
    }

    .modal-content {
        height: 420px;
    }

    .modal-dialog {
        min-width: 660px;
    }

    .modal-footer {
        color: #d5d5d5;
        font-weight: 500;
        font-size: 24px;

        .count-down {
            display: flex;
            justify-content: center;

            p {
                margin: 0;
                color: $dark-gray;
                font-size: 1rem;
            }
        }
    }

    .modal-body-btn {
        width: 125px;
        height: 125px;
        display: block;
        background-color: #007dc6;
        text-align: center;
        padding: 52px 10px;
        font-weight: 700;
        font-size: 14px;
        color: #fff;
        margin: 0 10px;
        line-height: 18px;
        opacity: 0.8;
        border: none;

        &:hover {
            opacity: 1;
        }
    }

    .modal-body-btn,
    .modal-input,
    .modal-btn,
    .ladda-button {
        border-radius: 2px !important;
    }

    #ambassador-id-form-wrapper {
        position: absolute;
        bottom: 25px;
        width: 150px;

        #ambassador-id-entry {
            background-color: #f9f9f9;
            border: 1px solid #d5d5d5;
            height: 30px;
            width: 125px;
            color: #888;
            font-size: 12px;
            font-weight: 700;
            line-height: 15px;
            padding: 0 10px;
        }

        button {
            color: #007dc6;
            font-size: 24px;
            font-weight: 500;
            line-height: 1;
            margin-left: 5px;
            border: none;
            background: none;
            padding: 0;
        }
    }

    .ambassador-id-wrapper {
        position: absolute;
        bottom: 0;
        left: 0;
        height: 65px;
        width: 100%;
        background-color: #eaeaea;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        display: flex;
        flex-direction: row;
        align-items: center;

        .btn-secondary {
            opacity: 0.8;

            &:focus {
                box-shadow: none !important;
                opacity: 1;
            }
        }

        > ul {
            padding: 0;
            list-style-type: none;
            display: flex;
            margin: 0 auto;
            justify-content: space-between;

            > li {
                display: flex;
                margin: 0 15px;

                &.ambassador-pins {
                    font-size: 12px;
                    color: #bfbfbf;
                    opacity: 0.8;
                    line-height: 45px;

                    a {
                        color: inherit;
                        opacity: inherit;

                        &:hover {
                            opacity: 1;
                            text-decoration: none;
                        }

                        &.selected {
                            color: #888;
                        }
                    }

                    &:hover {
                        opacity: 1;
                    }
                }

                .add-ambassador-icon,
                .back-to-kiosk-icon {
                    color: #d5d5d5;
                    font-weight: 500;
                    font-size: 24px;
                }

                > button {
                    color: #d5d5d5;
                    text-decoration: none;
                    border: none;
                    background: none;
                    box-shadow: none;
                }

                &.selected {
                    > button {
                        color: #888888;
                    }
                }
            }

            > li.back-to-kiosk {
                position: absolute;
                right: 0px;
            }
        }
    }
    .form-wrapper {
        .modal-label {
            font-size: 14px;
            height: 20px;
            color: #888;
            padding: 0;
        }

        .modal-input {
            width: 100%;
            height: 30px;
            -webkit-appearance: none;
            padding: 0 10px;
            background-color: #fff;
            font-size: 14px;
            line-height: 30px;
            color: #888;
            outline: none;
            box-shadow: none;
            border: 1px solid #ddd;
        }
    }

    .modal-btn,
    .ladda-button {
        height: 30px;
        width: 180px;
        line-height: 20px;
    }
    .disabled {
        cursor: not-allowed;
    }

    .thank-you-message {
        align-self: center;
        width: 325px;
        color: #007dc6;
        font-size: 24px;
        font-weight: 700;
        line-height: 30px;
        text-align: center;
        -ms-flex-direction: row;
        flex-direction: row;
    }
}
</style>
