export const CONSUMER_FRIENDLY_HEADER_ORDER = {
    packages: {
        label: "Packages",
        includedIds: [
            4 // Package
        ],
        order: 5
    },
    exterior: {
        label: "Exterior",
        includedIds: [
            10 // Exterior
        ],
        order: 10
    },
    interior: {
        label: "Interior",
        includedIds: [
            2 // Interior
        ],
        order: 15
    },
    entertainment: {
        label: "Entertainment",
        includedIds: [
            1 // Entertainment
        ],
        order: 20
    }
};
