import _ from "lodash";
import { make, Payload, commit as rootCommit, dispatch } from "vuex-pathify";
import URL from "url-parse";
import { queryToFilters } from "@/store/modules/listings/helpers";
import listingsUrlStorage from "@/lib/listingsUrlStorage";

window.addEventListener("popstate", () => {
    const url = new URL(window.location.href, true);
    rootCommit("vehicleSearch/UPDATE_FILTERS_FROM_QUERY", url.query);
});

const pushWindowHistory = (filters, pageable, gridViewActive) => {
    const url = new URL(window.location.href, true);
    const currentUrl = url
        .toString()
        .substr(url.toString().indexOf(url.pathname));
    const cleanFilters = _.omitBy(
        filters,
        value => _.isNil(value) || (_.isArray(value) && _.isEmpty(value))
    );
    const view = gridViewActive ? "grid" : "list";

    url.query = {
        ...cleanFilters,
        ...pageable,
        view
    };

    const newUrl = url.toString().substr(url.toString().indexOf(url.pathname));

    if (currentUrl !== newUrl) {
        window.history.pushState(
            {
                filters: cleanFilters,
                pageable: pageable,
                view
            },
            "Vehicle Search",
            newUrl
        );
    }
};

const getQueryValue = query => {
    const url = new URL(window.location, true);
    return url.query[query];
};

const setUrl = value => {
    let url = value;

    if (!value) {
        url = window.location.href;
    }
    listingsUrlStorage.set(url);
};

// initial state
const initialState = {
    facets: null,
    listViewActive: getQueryValue("view") !== "grid",
    gridViewActive: getQueryValue("view") === "grid",
    view: getQueryValue("view"),
    pageable: {
        sort: "",
        page: 1
    },
    pageMetadata: {
        size: 0,
        number: 0,
        totalElements: 0,
        totalPages: 0
    },
    pushHistory: false,
    // allow keeping track of any initial stockType (i.e. NEW, USED) when the page loads and filters are cleared
    initialStockType: [],
    // Since we modify the filters in the depth of the tree when a Payload, we need something that is also changed to allow
    // other components to listen for any changes on the filters.
    filterCommitCount: 0,
    // If user manually selects a distance, lock it and do not allow "auto step out"
    distanceLocked: false,
    filters: {
        groupBy: null,
        distance: -1,
        stockTypes: [],
        makes: [],
        models: [],
        trims: [],
        exteriorColors: [],
        interiorColors: [],
        drivetrains: [],
        transmissions: [],
        fuels: [],
        bodyStyles: [],
        cylinders: [],
        minYear: null,
        maxYear: null,
        minPrice: null,
        maxPrice: null,
        minPayment: null,
        maxPayment: null,
        minMiles: null,
        maxMiles: null,
        dealerId: null,
        passengerCapacities: [],
        certifiedDealer: null,
        homeDelivery: null,
        localDealer: null,
        supplierPricing: null,
        paymentType: "ALL",
        dealBadges: [],
        vehicleCategory: null
    },
    collapsed: {
        stockTypes: true,
        specialPrograms: true,
        makes: false,
        models: false,
        exteriorColors: true,
        interiorColors: true,
        drivetrains: true,
        transmissions: true,
        fuels: true,
        bodyStyles: true,
        cylinders: true,
        year: true,
        price: true,
        monthlyPayment: true,
        miles: true,
        passengerCapacities: true,
        sort: false,
        dealBadges: true,
        vehicleCategory: true
    },
    showMobileFilters: false,
    mobileFiltersFacets: {
        showStockType: null,
        showPaymentType: null,
        showMiles: null,
        showYears: null,
        showExteriorColor: null,
        showInteriorColor: null
    },
    mobileListingsView: {
        isModel: true,
        isNew: false
    }
};

const emptyState = {
    filters: {
        distance: -1,
        minPrice: null,
        maxPrice: null,
        minPayment: null,
        maxPayment: null,
        minYear: null,
        maxYear: null,
        minMiles: null,
        maxMiles: null,
        makes: [],
        models: [],
        trims: [],
        exteriorColors: [],
        interiorColors: [],
        drivetrains: [],
        transmissions: [],
        fuels: [],
        bodyStyles: [],
        cylinders: [],
        passengerCapacities: [],
        stockTypes: [],
        certifiedDealer: null,
        homeDelivery: null,
        localDealer: null,
        supplierPricing: null,
        dealBadges: [],
        paymentType: "ALL",
        vehicleCategory: null
    },
    pageable: {
        sort: "",
        page: 1
    }
};

const mutations = {
    ...make.mutations(initialState),

    UPDATE_FILTERS_FROM_QUERY: (state, query) => {
        // set state to url
        const newFilters = {};
        const queryFilters = queryToFilters(emptyState.filters, query);
        Object.assign(newFilters, emptyState.filters, queryFilters);

        console.log("UPDATE_FILTERS_FROM_QUERY");

        if (_.isNil(_.get(queryFilters, "stockTypes"))) {
            newFilters.stockTypes = state.filters.stockTypes;
        }

        state.filters = newFilters;

        const newPageable = {};
        const queryPageable = queryToFilters(emptyState.pageable, query);
        Object.assign(newPageable, emptyState.pageable, queryPageable);
        newPageable.page = parseInt(newPageable.page);

        state.pageable = newPageable;

        dispatch("used/loadListings");
    },

    SET_FILTERS: (state, value) => {
        const newDistance = _.get(value, "distance");
        const currentDistance = _.get(state, "filters.distance");
        if (newDistance !== currentDistance) {
            state.distanceLocked = true;
        }

        state.filters =
            value instanceof Payload
                ? _.set(state.filters, value.path, value.value)
                : value;

        state.filterCommitCount++;

        if (state.pushHistory) {
            pushWindowHistory(
                state.filters,
                state.pageable,
                state.gridViewActive
            );
        }

        setUrl();
    },

    INITIALIZE_FILTERS: (state, value) => {
        state.filters =
            value instanceof Payload
                ? _.set(state.filters, value.path, value.value)
                : value;

        if (state.pushHistory) {
            pushWindowHistory(
                state.filters,
                state.pageable,
                state.gridViewActive
            );
        }
    },

    SET_PAGEABLE: (state, payload) => {
        // if we have a Payload, do something with it
        if (payload instanceof Payload) {
            _.set(state.pageable, payload.path, payload.value);
        } else {
            state.pageable = payload;
        }

        if (state.pushHistory) {
            pushWindowHistory(
                state.filters,
                state.pageable,
                state.gridViewActive
            );
        }
    },

    SET_GRID_VIEW_ACTIVE: (state, payload) => {
        state.gridViewActive = payload;
        state.listViewActive = !payload;
        state.view = "grid";

        if (state.pushHistory) {
            pushWindowHistory(
                state.filters,
                state.pageable,
                state.gridViewActive
            );
        }

        setUrl();
    },

    SET_LIST_VIEW_ACTIVE: (state, payload) => {
        state.listViewActive = payload;
        state.gridViewActive = !payload;
        state.view = "list";

        if (state.pushHistory) {
            pushWindowHistory(
                state.filters,
                state.pageable,
                state.gridViewActive
            );
        }

        setUrl();
    },

    SET_TO_MODEL_LISTINGS: state => {
        state.mobileListingsView = {
            isModel: true,
            isNew: false
        };
    },

    SET_TO_NEW_LISTINGS: state => {
        state.mobileListingsView = {
            isModel: false,
            isNew: true
        };
    }
};

// actions
const actions = {
    filtersChanged({ commit, state }) {
        setUrl();
        commit("SET_FILTER_COMMIT_COUNT", state.filterCommitCount + 1);
    },

    clearAllFilters({ commit, state }) {
        const newPageable = {};
        Object.assign(newPageable, state.pageable, {
            page: 1
        });

        // because we are pushing multiple commits here we 'pause' the history pushing if it was enabled
        const pushHistory = state.pushHistory;
        if (pushHistory) {
            commit("SET_PUSH_HISTORY", false);
        }
        commit("SET_PAGEABLE", newPageable);

        const newFilters = {};
        Object.assign(newFilters, state.filters, emptyState.filters);

        // force setting the initial stock types
        newFilters.stockTypes = state.initialStockType;

        // re-enable push history if it was previously turned on
        if (pushHistory) {
            commit("SET_PUSH_HISTORY", true);
        }
        commit("SET_FILTERS", newFilters);
    },

    clearPayments({ commit, state }) {
        const newFilters = {};
        Object.assign(newFilters, state.filters, {
            minPayment: null,
            maxPayment: null,
            paymentType: "ALL"
        });
        commit("SET_FILTERS", newFilters);
    },

    clearMakes({ commit, state }) {
        const newFilters = {};
        Object.assign(newFilters, state.filters, {
            makes: [],
            models: [],
            trims: []
        });
        commit("SET_FILTERS", newFilters);
    },

    clearModels({ commit, state }) {
        const newFilters = {};
        Object.assign(newFilters, state.filters, {
            models: [],
            trims: []
        });
        commit("SET_FILTERS", newFilters);
    },

    clearPrice({ commit, state }) {
        const newFilters = {};
        Object.assign(newFilters, state.filters, {
            minPrice: null,
            maxPrice: null
        });
        commit("SET_FILTERS", newFilters);
    },

    clearVehicleCategory({ commit, state }) {
        const newFilters = {};
        Object.assign(newFilters, state.filters, {
            vehicleCategory: null
        });
        commit("SET_FILTERS", newFilters);
    },

    clearDealBadges({ commit, state }) {
        const newFilters = {};
        Object.assign(newFilters, state.filters, {
            dealBadges: null
        });
        commit("SET_FILTERS", newFilters);
    },

    clearCertifiedDealer({ commit, state }) {
        const newFilters = {};
        Object.assign(newFilters, state.filters, {
            certifiedDealer: null
        });
        commit("SET_FILTERS", newFilters);
    },

    clearPaymentType({ commit, state }) {
        const newFilters = {};
        Object.assign(newFilters, state.filters, {
            paymentType: "ALL"
        });
        commit("SET_FILTERS", newFilters);
    },

    clearHomeDelivery({ commit, state }) {
        const newFilters = {};
        Object.assign(newFilters, state.filters, {
            homeDelivery: null
        });
        commit("SET_FILTERS", newFilters);
    },

    clearLocalDealer({ commit, state }) {
        const newFilters = {};
        Object.assign(newFilters, state.filters, {
            localDealer: null
        });
        commit("SET_FILTERS", newFilters);
    },

    clearSupplierPricing({ commit, state }) {
        const newFilters = {};
        Object.assign(newFilters, state.filters, {
            supplierPricing: null
        });
        commit("SET_FILTERS", newFilters);
    },

    clearMonthlyPayment({ commit, state }) {
        const newFilters = {};
        Object.assign(newFilters, state.filters, {
            minPayment: null,
            maxPayment: null
        });
        commit("SET_FILTERS", newFilters);
    },

    clearYears({ commit, state }) {
        const newFilters = {};
        Object.assign(newFilters, state.filters, {
            minYear: null,
            maxYear: null
        });
        commit("SET_FILTERS", newFilters);
    },

    clearMiles({ commit, state }) {
        const newFilters = {};
        Object.assign(newFilters, state.filters, {
            minMiles: null,
            maxMiles: null
        });
        commit("SET_FILTERS", newFilters);
    },

    clearMake({ commit, state }, makeName) {
        const modelsToRemove = makeName
            ? _.map(_.filter(state.facets.models, { makeName }), "name")
            : [];

        const trimsToRemove =
            modelsToRemove.length > 0
                ? _.chain(state.facets.trims)
                      .filter(t => {
                          return _.includes(modelsToRemove, t.modelName);
                      })
                      .map("name")
                      .value()
                : [];

        const newTrims = _.filter(state.filters.trims, trim => {
            return !_.includes(trimsToRemove, trim);
        });
        const newModels = _.filter(state.filters.models, model => {
            return !_.includes(modelsToRemove, model);
        });
        const newMakes = _.pull(state.filters.makes, makeName);

        const newFilters = {};
        Object.assign(newFilters, state.filters, {
            models: newModels,
            makes: newMakes,
            trims: newTrims
        });
        commit("SET_FILTERS", newFilters);
    },

    clearModel({ commit, state }, modelName) {
        const trimsToRemove = modelName
            ? _.map(_.filter(state.facets.trims, { modelName }), "name")
            : [];

        const newTrims = _.filter(state.filters.trims, trim => {
            return !_.includes(trimsToRemove, trim);
        });
        const newModels = _.pull(state.filters.models, modelName);

        const newFilters = {};
        Object.assign(newFilters, state.filters, {
            models: newModels,
            trims: newTrims
        });
        commit("SET_FILTERS", newFilters);
    },

    setToModelListings({ commit }) {
        commit("SET_TO_MODEL_LISTINGS");
    },

    setToNewListings({ commit }) {
        commit("SET_TO_NEW_LISTINGS");
    },

    localDealerChange({ commit, state }, localDealerFilter) {
        const newFilters = {};
        Object.assign(newFilters, state.filters, {
            localDealer: localDealerFilter
        });
        commit("SET_FILTERS", newFilters);

        if (state.pageable.sort === "relevance") {
            commit("SET_PAGEABLE", new Payload("sort", "sort", "distance,asc"));
        }
    },

    ...make.actions(initialState)
};

const getters = {
    getFiltersAsUrl: state => (path, sortBy) => {
        const url = new URL(path, true);

        const filters = state.filters;
        const pageable = state.pageable;
        const cleanFilters = _.omitBy(
            filters,
            value => _.isNil(value) || (_.isArray(value) && _.isEmpty(value))
        );

        if (sortBy) {
            const newPageable = {
                sort: sortBy
            };

            url.query = {
                ...cleanFilters,
                ...newPageable,
                view: state.view
            };
        } else {
            url.query = {
                ...cleanFilters,
                sort: pageable.sort,
                view: state.view
            };
        }

        return url.toString();
    }
};

export default {
    namespaced: true,
    state: initialState,
    mutations,
    actions,
    getters
};
