import URL from "url-parse";
import _ from "lodash";
import api from "../../api";
import { make, dispatch } from "vuex-pathify";
import { queryToFilters } from "./listings/helpers";
import listingsUrlStorage from "@/lib/listingsUrlStorage";

// While waiting for location, we will show listings from this zip code.
const DEFAULT_ZIP_CODE = "37067";

// initial state
const initialState = {
    listings: {},
    isLoading: false,
    isError: false,
    initialLoad: true,
    selectedDealer: _.get(window, "_CS_SELECTED_DEALER", null)
};

const mutations = {
    ...make.mutations(initialState)
};

const resetToFirstPage = ({ commit, rootState }) => {
    const newPageable = {};
    const currentPageable = _.get(rootState, "vehicleSearch.pageable", null);

    Object.assign(newPageable, currentPageable, {
        page: 1
    });

    commit("vehicleSearch/SET_PAGEABLE", newPageable, { root: true });
};

const defaultSearchOpts = () => {
    return {
        // searches by default should start from the first page
        resetToFirstPage: true
    };
};
const resolveSearchOpts = opts => {
    const resolvedOpts = {};
    Object.assign(resolvedOpts, defaultSearchOpts(), opts);

    return resolvedOpts;
};

const updateListingsUrl = () => {
    const url = window.location.href;
    listingsUrlStorage.set(url);
};

const loadListings = ({ commit, state, rootState }, opts) => {
    if (state.isLoading) {
        return;
    }

    const _opts = resolveSearchOpts(opts);
    const currentPage = _.get(rootState, "vehicleSearch.pageable.page", 1);
    if (_opts.resetToFirstPage === true && currentPage !== 1) {
        resetToFirstPage({ commit, state, rootState });
        return; // exit as resetting the page will trigger another search which should skip this conditional block
    }

    const url = new URL(window.location.href, true);

    let userZipCode = _.get(
        rootState,
        "location.selectedLocation.zipCode",
        null
    );
    let queryParamZipCode = _.get(url, "query.zipcode", null);
    queryParamZipCode = queryParamZipCode === "" ? null : queryParamZipCode;

    commit("SET_IS_LOADING", true);

    if (state.initialLoad) {
        if (queryParamZipCode != null) {
            dispatch("location/updateZipCode", queryParamZipCode);
            userZipCode = queryParamZipCode;
        }

        if (_.isNil(userZipCode)) {
            userZipCode = DEFAULT_ZIP_CODE;
        }

        const currentPageable = _.get(
            rootState,
            "vehicleSearch.pageable",
            null
        );
        const currentFilters = _.get(rootState, "vehicleSearch.filters", null);

        // set state to url
        const newFilters = {};
        const queryFilters = queryToFilters(currentFilters, url.query);
        Object.assign(newFilters, currentFilters, queryFilters);

        // assign proper stock type to store
        if (url.pathname.indexOf("/shop/new") !== -1) {
            newFilters.stockTypes = ["NEW"];
        } else if (url.pathname.indexOf("/shop/used") !== -1) {
            newFilters.stockTypes = ["USED"];
        }

        newFilters.dealerId = _.get(window, "_CS_SELECTED_DEALER.id", null);

        const newPageable = {};
        const queryPageable = queryToFilters(currentPageable, url.query);
        Object.assign(newPageable, currentPageable, queryPageable);
        newPageable.page = parseInt(newPageable.page);

        commit("vehicleSearch/SET_INITIAL_STOCK_TYPE", newFilters.stockTypes, {
            root: true
        });
        commit("vehicleSearch/INITIALIZE_FILTERS", newFilters, { root: true });
        commit("vehicleSearch/SET_PAGEABLE", newPageable, { root: true });
        commit("SET_INITIAL_LOAD", false);
    }

    // always get the filters again because they may have been changed by the initial load
    const activeFilters = _.get(rootState, "vehicleSearch.filters", null);
    const activePageable = _.get(rootState, "vehicleSearch.pageable", null);
    const cleanFilters = _.omitBy(
        activeFilters,
        value => _.isNil(value) || (_.isArray(value) && _.isEmpty(value))
    );

    api.post(
        `/inventory/search?page=${activePageable.page}&sort=${activePageable.sort}`,
        {
            ...cleanFilters,
            zipCode: userZipCode
        }
    )
        .then(response => {
            commit("SET_LISTINGS", _.omit(response.data, ["facets"]));
            commit("vehicleSearch/SET_FACETS", response.data.facets, {
                root: true
            });
            commit(
                "vehicleSearch/SET_PAGE_METADATA",
                response.data.pageMetadata,
                { root: true }
            );
            commit("SET_IS_LOADING", false);
            commit("SET_IS_ERROR", false);
            commit("vehicleSearch/SET_PUSH_HISTORY", true, { root: true });

            updateListingsUrl();

            const totalVehicles = _.get(
                response.data,
                "pageMetadata.totalElements",
                0
            );
            const currentDistance = _.get(
                rootState,
                "vehicleSearch.filters.distance"
            );
            const distanceLocked =
                _.get(rootState, "vehicleSearch.distanceLocked", false) ||
                false;
            if (
                !distanceLocked &&
                totalVehicles === 0 &&
                currentDistance !== -1
            ) {
                // Expand search nationwide
                const newFilters = {};
                Object.assign(newFilters, activeFilters, {
                    distance: -1
                });
                commit("vehicleSearch/SET_FILTERS", newFilters, { root: true });
                return;
            }

            const totalPages = _.get(
                response.data,
                "pageMetadata.totalPages",
                1
            );
            if (totalPages !== 0 && activePageable.page > totalPages) {
                const newPageable = {};
                Object.assign(newPageable, activePageable, {
                    page: totalPages
                });

                commit("vehicleSearch/SET_PAGEABLE", newPageable, {
                    root: true
                });
            }
        })
        .catch(error => {
            console.error(error);
            commit("SET_IS_LOADING", false);
            commit("SET_IS_ERROR", true);
        });
};

// actions
const actions = {
    handleZipCodeChanged: loadListings,
    handleFiltersChanged: loadListings,
    handlePageChange({ commit, state, rootState }) {
        loadListings({ commit, state, rootState }, { resetToFirstPage: false });
    },
    handleLoadInitialListings({ commit, state, rootState }) {
        loadListings({ commit, state, rootState }, { resetToFirstPage: false });
    },
    loadListings: loadListings,

    ...make.actions(initialState)
};

export default {
    namespaced: true,
    state: initialState,
    mutations,
    actions
};
