<template>
    <div id="session-reset-button-wrapper">
        <b-link @click="clickHandler()">
            <div
                id="session-reset-button"
                class="d-flex justify-content-center align-items-center"
                :style="pinStyle"
            >
                <i
                    v-if="ambassadorId === null"
                    class="ion-android-sync"
                    aria-hidden="true"
                />
                <span v-else>
                    {{ ambassadorId }}
                </span>
            </div>
        </b-link>

        <session-reset-modal />
    </div>
</template>

<script>
import SessionResetModal from "../SessionResetModal";
import EventBus from "../../../../event-bus";

export default {
    name: "SessionResetButton",

    components: {
        SessionResetModal
    },

    props: {
        ambassadorId: {
            type: String,
            default: null
        }
    },

    computed: {
        pinStyle() {
            if (this.ambassadorId !== null) {
                return {
                    transform: "none",
                    lineHeight: "60px",
                    fontSize: "20px",
                    color: "gray"
                };
            }

            return {};
        }
    },

    created() {
        this.init();
    },

    methods: {
        init() {
            const host = window.location.host;
            const isLocalHost = host === "localhost:5000";
            const isAlpha = host === "alpha.carsaver.com";
            const isWalmart = /wm[^.]*(?=\.)/.test(host);

            if (isWalmart) {
                // If you're at a walmart domain
                const idWithWm = host.match(/wm[^.]*(?=\.)/)[0];
                window.walmartStoreId = idWithWm.split("wm")[1];
                sessionStorage.setItem("walmartStoreId", window.walmartStoreId);
            } else if (isLocalHost || isAlpha) {
                sessionStorage.setItem("walmartStoreId", "4161");
            }
        },

        openSessionResetModal: function() {
            EventBus.$emit("session-reset-modal-open");
        },

        clickHandler: function() {
            this.openSessionResetModal();
        }
    }
};
</script>

<style lang="scss" scoped>
#session-reset-button-wrapper {
    #session-reset-button {
        position: fixed;
        bottom: 10px;
        right: 20px;
        z-index: 9999;
        text-align: center;
        opacity: 0.8;

        border-radius: 100px;
        box-shadow: 0 0 2px #888;
        background-color: hsla(0, 0%, 85%, 0.4);
        height: 60px;
        width: 60px;
        transform: rotate(45deg) scaleX(-1);

        > i {
            color: #fff;
            font-size: 4em;
            line-height: 1.175;

            &:before {
                font-weight: 600;
            }
        }

        &:hover {
            > i {
                color: #ccc;
            }
            color: #ccc;
        }
    }
}
</style>
