<template>
    <b-modal
        id="whatToLookForModal"
        ref="modal"
        size="lg"
        title="What to look for..."
        body-class="d-flex flex-column justify-content-center align-items-center align-items-md-start"
        :hide-footer="true"
    >
        <header slot="modal-header-close">
            <i class="ion-ios-close-outline" aria-hidden="true">
                &nbsp;
            </i>
        </header>
        <b-container class="indicator-container">
            <b-row>
                <slot name="indicator" />
            </b-row>
        </b-container>
        <div class="close-box w-100 d-flex flex-column align-items-center">
            <b-button variant="orange" size="lg" class="mb-2" @click="close">
                Close Window
            </b-button>
            <slot name="checkbox" />
        </div>
    </b-modal>
</template>
<script>
import EventBus from "../../event-bus";

export default {
    name: "WhatToLookForModal",
    created() {
        const component = this;
        EventBus.$on("what-to-look-for-modal-open", () => {
            component.show();
        });
    },
    methods: {
        show() {
            this.$refs.modal.show();
        },
        close() {
            this.$refs.modal.hide();
        }
    }
};
</script>
<style lang="scss">
#whatToLookForModal {
    .indicator-container {
        height: 450px;
    }
    .close-box {
        .btn-orange {
            font-size: 14px;
            width: 220px;
        }
        label {
            font-style: italic;
            color: $medium-gray;
        }
    }
    @include media-breakpoint-down(sm) {
        .container {
            max-width: 375px;
        }
    }
    @include media-breakpoint-down(md) {
        .modal-dialog {
            max-width: 750px !important;
        }
    }

    @include media-breakpoint-up(md) {
        .indicator-container {
            height: unset;
        }
    }
}
</style>
