<script>
/** Ported over from react-svg https://github.com/tanem/react-svg **/
import Vue from "vue";
import { SVGInjector } from "@tanem/svg-injector";

export default {
    name: "SvgFallback",

    props: {
        evalScripts: {
            type: String,
            required: false,
            default: "never",
            validator: value => {
                // The value must match one of these strings
                return ["always", "once", "never"].indexOf(value) !== -1;
            }
        },
        renumerateIRIElements: {
            type: Boolean,
            required: false,
            default: true
        },
        src: {
            type: String,
            required: true
        },
        svgClassName: {
            type: String,
            required: false,
            default: null
        },
        svgStyle: {
            type: Object,
            required: false,
            default: () => ({})
        }
    },

    mounted() {
        this.renderSVG();
    },

    updated() {
        this.removeSVG();
        this.renderSVG();
    },

    beforeDestroy() {
        this.removeSVG();
    },

    methods: {
        removeSVG() {
            if (
                this.$refs.svgContainer instanceof Object &&
                this.$refs.svgContainer.firstChild instanceof Object
            ) {
                this.$refs.svgContainer.removeChild(
                    this.$refs.svgContainer.firstChild
                );
            }
        },

        renderSVG() {
            if (this.$refs.svgContainer instanceof Object) {
                const {
                    evalScripts,
                    renumerateIRIElements,
                    src,
                    svgClassName,
                    svgStyle
                } = this;

                const div = document.createElement("div");

                const html = new Vue({
                    data: () => ({
                        svgClassName,
                        src,
                        svgStyle
                    }),
                    template:
                        '<div><div :class="svgClassName" :data-src="src" :style="svgStyle" /></div>'
                }).$mount().$el;
                div.innerHTML = html.outerHTML;

                const wrapper = this.$refs.svgContainer.appendChild(
                    div.firstChild
                );

                SVGInjector(wrapper.firstChild, {
                    evalScripts,
                    renumerateIRIElements
                });
            }
        }
    },

    render: function(createElement) {
        const {
            evalScripts,
            renumerateIRIElements,
            src,
            svgClassName,
            svgStyle,
            ...rest
        } = this;

        return createElement("div", {
            ref: "svgContainer",
            ...rest
        });
    }
};
</script>
