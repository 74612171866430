var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showFooter
    ? _c(
        "div",
        {
          staticClass:
            "listings-footer d-flex flex-column justify-content-center d-flex d-sm-none"
        },
        [
          _c(
            "div",
            { staticClass: "btn-float-bottom" },
            [
              _c(
                "b-button",
                { attrs: { variant: "orange" }, on: { click: _vm.click } },
                [
                  _vm._v(
                    "\n            " + _vm._s(_vm.buttonText) + "\n        "
                  )
                ]
              )
            ],
            1
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }