<template>
    <div
        v-if="showFooter"
        class="listings-footer d-flex flex-column justify-content-center d-flex d-sm-none"
    >
        <div class="btn-float-bottom">
            <b-button variant="orange" @click="click">
                {{ buttonText }}
            </b-button>
        </div>
    </div>
</template>

<style lang="scss">
.listings-footer {
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.5);
    position: fixed;
    bottom: 0;
    background-color: rgba(249, 249, 249, 0.95);
    height: 55px;
    width: 100%;
    z-index: 1041;

    .btn-float-bottom {
        display: flex;
        justify-content: center;

        .btn-orange {
            width: 175px;
            height: 40px;
        }
    }
}
</style>

<script>
import { get } from "vuex-pathify";
import numeral from "numeral";

export default {
    name: "ModelListingsFooter",
    computed: {
        modalVisible: get("vehicleSearch/showMobileFilters"),
        totalMatches: get("vehicleSearch/pageMetadata@totalElements"),
        showFooter() {
            return this.modalVisible;
        },
        buttonText() {
            return `See ${numeral(this.totalMatches).format("0,0")} Vehicles`;
        }
    },
    methods: {
        click() {
            this.$emit("click");
        }
    }
};
</script>
