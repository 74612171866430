<template>
    <span v-cloak>
        <a class="learn-more" @click="show">
            Learn more.
        </a>

        <b-modal
            id="confidenceModal"
            ref="modal"
            tabindex="-1"
            role="dialog"
            aria-labelledby="confidenceModal"
            aria-hidden="true"
            size="lg"
        >
            <div slot="modal-header-close">
                <i
                    aria-hidden="true"
                    class="ion-ios-close-outline"
                    @click="close"
                >
                    &nbsp;
                </i>
            </div>

            <div slot="modal-title">
                Shop with Confidence
            </div>

            <b-container>
                <b-row>
                    <div class="col-12">
                        <span class="font-weight-bold">
                            CarSaver gives you control over your shopping
                            experience.
                        </span>
                    </div>
                </b-row>
            </b-container>

            <b-container class="my-3">
                <b-row class="mb-3">
                    <div class="col-12">
                        <div
                            class="gray-block"
                            style="padding: 30px 30px 35px 30px; background-color: #f9f9f9; color: #444; font-size: 14px; border-radius: 5px;"
                        >
                            <div class="d-flex flex-column mb-4">
                                <span class="font-weight-bold">
                                    Choose your dealerships.
                                </span>
                                <span
                                    >CarSaver will not give your contact
                                    information to any dealership without your
                                    permission.</span
                                >
                            </div>

                            <div class="d-flex flex-column mb-4">
                                <span class="font-weight-bold">
                                    Keep your personal number private
                                </span>
                                <span
                                    >CarSaver’s Certified Dealers are given
                                    temporary replacement phone numbers so that
                                    you can personalize the communications you
                                    receive from each dealership – Choose email
                                    and phone or email only.</span
                                >
                            </div>

                            <div class="d-flex flex-column">
                                <span class="font-weight-bold">
                                    Control which dealerships can call you.
                                </span>
                                <span
                                    >CarSaver gives you the ability to turn off
                                    phone calls for one of all CarSaver Certifed
                                    Dealers when you no longer wish to receive
                                    them.</span
                                >
                            </div>
                        </div>
                    </div>
                </b-row>
                <b-row>
                    <div class="col-12">
                        <div class="font-weight-bold">
                            For more information about CarSaver’s privacy
                            protection features, call
                            <span style="color: #007DCF;">
                                800-277-4800.
                            </span>
                        </div>
                    </div>
                </b-row>
            </b-container>

            <div
                slot="modal-footer"
                class="w-100 d-flex flex-column justify-content-center align-items-center"
            >
                <b-button
                    style="width: 220px; font-size: 14px;"
                    variant="orange"
                    size="lg"
                    @click="close"
                >
                    Close
                </b-button>
                <div
                    class="d-flex mt-2 align-items-center"
                    style="font-size: 11px; color: #007DCF"
                >
                    <a
                        href="/tos"
                        target="_blank"
                        class="mr-1"
                        rel="noopener noreferrer"
                    >
                        Terms & Conditions
                    </a>
                    <span class="my-1">
                        |
                    </span>
                    <a
                        href="/privacy"
                        target="_blank"
                        class="ml-1"
                        rel="noopener noreferrer"
                    >
                        Privacy Policy
                    </a>
                </div>
            </div>
        </b-modal>
    </span>
</template>
<script>
import EventBus from "../../event-bus";

export default {
    name: "ShopWithConfidenceModal",
    created() {
        const component = this;
        EventBus.$on("shop-with-confidence-modal.open", () => {
            component.show();
        });
    },

    methods: {
        show() {
            this.$refs.modal.show();
        },
        close() {
            this.$refs.modal.hide();
        }
    }
};
</script>

<style lang="scss">
#confidenceModal {
    .modal-header {
        .modal-title {
            padding-left: 30px;
        }
    }

    @media only screen and (max-width: 450px) {
        .modal-header {
            text-align: center !important;
            height: 115px !important;

            .modal-title {
                white-space: normal !important;
                padding-left: 30px;
            }
        }
    }
}
</style>
