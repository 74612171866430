import _ from "lodash";
import URL from "url-parse";

const shouldAutoLoadModal = {
    methods: {
        shouldAutoLoadModal(modalName, callback) {
            const url = new URL(window.location.href, true);
            const modal = url.query.modal;
            if (_.isNil(modal)) {
                return;
            }

            window.history.pushState({}, null, url.toString());

            if (modal === modalName) {
                callback();
                delete url.query.modal;
            }
        }
    }
};

export default shouldAutoLoadModal;
