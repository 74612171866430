<template>
    <div
        id="autoAdvisorContent"
        class="d-none d-lg-flex flex-column justify-content-center align-items-center"
    >
        <span class="title">
            With You Every Step of The Way.
        </span>
        <span class="mb-3">
            Buy. Finance. Insure. Save
        </span>
        <b-img
            src="../../MainNavigation/images/menu_personal_shopper.png"
            width="180"
            height="180"
            alt="Personal Shopper"
            class="mb-3"
        />
        <span>CarSaver Personal Shopper</span>
    </div>
</template>
<script>
export default {
    name: "AutoAdvisorContent"
};
</script>
<style lang="scss">
#autoAdvisorContent {
    .title {
        font-size: 16px;
        font-weight: bold;
    }
}
</style>
