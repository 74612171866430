var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "item d-flex justify-content-center",
      class: { selected: _vm.selected, completed: _vm.completed }
    },
    [
      _c("div", { staticClass: "d-flex flex-row align-items-center" }, [
        _c("div", { staticClass: "number text-center" }, [
          _vm.completed
            ? _c("i", {
                staticClass: "ion-checkmark",
                attrs: { "aria-hidden": "true" }
              })
            : _vm._e(),
          _vm._v("\n            " + _vm._s(_vm.number) + "\n        ")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "label ml-2" }, [
          _vm._v("\n            " + _vm._s(_vm.label) + "\n            "),
          _c("small", { staticClass: "d-block" }, [
            _vm._v(
              "\n                " + _vm._s(_vm.description) + "\n            "
            )
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }