var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "session-reset-modal-wrapper" } },
    [
      _c(
        "cs-modal",
        {
          ref: "srmodal",
          attrs: {
            id: "session-reset-modal",
            "hide-footer": _vm.hideFooter,
            size: "sm"
          },
          on: { hidden: _vm.onHidden }
        },
        [
          !_vm.timeExpiring
            ? _c(
                "div",
                { attrs: { slot: "modal-title" }, slot: "modal-title" },
                [_vm._v("\n            Welcome to CarSaver\n        ")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.showTimeExpiring
            ? _c(
                "div",
                { attrs: { slot: "modal-title" }, slot: "modal-title" },
                [_vm._v("\n            Need More Time?\n        ")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.showGettingStarted
            ? _c(
                "b-container",
                { staticClass: "d-flex justify-content-center" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex flex-row justify-content-center align-items-center"
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary modal-body-btn",
                          attrs: { disabled: _vm.getStartedDisabled },
                          on: { click: _vm.getStarted }
                        },
                        [
                          _vm._v(
                            "\n                    Get Started\n                "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _vm.showContinue
                        ? _c(
                            "button",
                            {
                              staticClass:
                                "btn btn-primary modal-body-btn used-btn",
                              attrs: { disabled: _vm.continueWithDisabled },
                              on: { click: _vm.continueWithUser }
                            },
                            [
                              _vm._v(
                                "\n                    Continue with " +
                                  _vm._s(_vm.userData.firstName) +
                                  "\n                "
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.showImDone
                        ? _c(
                            "button",
                            {
                              staticClass:
                                "btn btn-primary modal-body-btn used-btn",
                              on: { click: _vm.imDone }
                            },
                            [
                              _vm._v(
                                "\n                    I'm Done\n                "
                              )
                            ]
                          )
                        : _vm._e()
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.showAmbassadorIdInput,
                          expression: "showAmbassadorIdInput"
                        }
                      ],
                      attrs: { id: "ambassador-id-form-wrapper" }
                    },
                    [
                      _c("div", { staticClass: "ambassador-id-form d-flex" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.ambassadorId,
                              expression: "form.ambassadorId"
                            }
                          ],
                          attrs: {
                            id: "ambassador-id-entry",
                            type: "text",
                            maxlength: "4"
                          },
                          domProps: { value: _vm.form.ambassadorId },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.form,
                                "ambassadorId",
                                $event.target.value
                              )
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "VehicleCatalog-LinkButton",
                            attrs: { type: "button" },
                            on: {
                              click: function($event) {
                                return _vm.addAmbassadorId()
                              }
                            }
                          },
                          [
                            _c("span", {
                              staticClass: "ion-ios-plus add-ambassador-icon"
                            })
                          ]
                        )
                      ])
                    ]
                  )
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.showEndMessage
            ? _c(
                "b-container",
                { staticClass: "d-flex justify-content-center" },
                [
                  _c("p", { staticClass: "thank-you-message" }, [
                    _vm._v(
                      "\n                Thank you for visiting CarSaver. Have a nice day!\n            "
                    )
                  ])
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.showTimeExpiring
            ? _c(
                "b-container",
                { staticClass: "d-flex justify-content-center" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex flex-row justify-content-center align-items-center"
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary modal-body-btn",
                          on: { click: _vm.onIdleContinue }
                        },
                        [_vm._v("\n                    Yes\n                ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass:
                            "btn btn-primary modal-body-btn used-btn",
                          on: { click: _vm.onIdleDiscontinue }
                        },
                        [_vm._v("\n                    No\n                ")]
                      )
                    ]
                  )
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.timeExpiring
            ? _c(
                "div",
                {
                  staticClass: "ambassador-id-wrapper",
                  attrs: { slot: "modal-footer" },
                  slot: "modal-footer"
                },
                [
                  _c(
                    "ul",
                    { staticClass: "list-inline" },
                    [
                      _vm._l(_vm.ambassadorIds, function(ambassadorId, key) {
                        return _c(
                          "li",
                          {
                            key: key,
                            staticClass: "list-inline-item ambassador-pins"
                          },
                          [
                            _c(
                              "b-link",
                              {
                                class: {
                                  selected: _vm.isIdSelected(ambassadorId)
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.chooseAmbassador(ambassadorId)
                                  }
                                }
                              },
                              [_c("span", [_vm._v(_vm._s(ambassadorId))])]
                            )
                          ],
                          1
                        )
                      }),
                      _vm._v(" "),
                      _c(
                        "li",
                        { staticClass: "list-inline-item" },
                        [
                          _c(
                            "b-btn",
                            {
                              on: {
                                click: function($event) {
                                  _vm.showAmbassadorIdInput = true
                                }
                              }
                            },
                            [
                              _c("span", {
                                staticClass: "ion-ios-plus add-ambassador-icon"
                              })
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "li",
                        { staticClass: "list-inline-item back-to-kiosk" },
                        [
                          _c(
                            "b-btn",
                            {
                              on: {
                                click: function($event) {
                                  return _vm.backToKiosk()
                                }
                              }
                            },
                            [
                              _c("span", {
                                staticClass: "ion-ios-home back-to-kiosk-icon"
                              })
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("li", [
                        _vm.errorMsg
                          ? _c("span", { staticClass: "invalid-warning" }, [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(_vm.errorMsg) +
                                  "\n                    "
                              )
                            ])
                          : _vm._e()
                      ])
                    ],
                    2
                  )
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.showTimeExpiring
            ? _c(
                "div",
                {
                  staticClass: "ambassador-id-wrapper count-down",
                  attrs: { slot: "modal-footer" },
                  slot: "modal-footer"
                },
                [
                  _c("p", [
                    _vm._v(
                      "\n                Your session will expire in\n                "
                    ),
                    _c("strong", [_vm._v(_vm._s(_vm.timerTimeRemaining))]),
                    _vm._v(" seconds...\n            ")
                  ])
                ]
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }