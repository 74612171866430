var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showOverlay
    ? _c(
        "div",
        {
          staticClass: "d-flex flex-column justify-content-center text-center",
          attrs: { id: "location-loading-overlay" }
        },
        [
          _c(
            "b-container",
            [
              _c("h2", [_vm._v("Calculating your location...")]),
              _vm._v(" "),
              _vm.nudge
                ? _c("h5", [
                    _vm._v(
                      "\n            To show vehicles in your area, please allow the location request\n            or enter your zip code below:\n        "
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "b-form",
                {
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.saveZipCode.apply(null, arguments)
                    }
                  }
                },
                [
                  _vm.nudge
                    ? _c(
                        "b-input-group",
                        {
                          staticClass: "my-3 mx-auto zipcode-group",
                          attrs: { prepend: "Zip Code" }
                        },
                        [
                          _c("b-form-input", {
                            staticClass: "zipcode",
                            attrs: {
                              placeholder: "e.g. 33132",
                              maxlength: "5",
                              state: _vm.isValidZipCode
                            },
                            model: {
                              value: _vm.zipCode,
                              callback: function($$v) {
                                _vm.zipCode = $$v
                              },
                              expression: "zipCode"
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "b-input-group-append",
                            [
                              _c(
                                "b-button",
                                { attrs: { variant: "info", type: "submit" } },
                                [
                                  _vm._v(
                                    "\n                        Update\n                    "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _vm.locationLoadingError
                ? _c("p", { staticClass: "text-warning" }, [
                    _vm._v(
                      "\n            We were unable to automatically determine your location, please\n            enter your zip code above.\n        "
                    )
                  ])
                : _c("loading-spinner", {
                    attrs: { size: 50, color: "#FFFFFF" }
                  })
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }