<template>
    <div>
        <b-modal
            id="scheduleCall"
            v-model="open"
            size="lg"
            title="Schedule a Call"
            :hide-footer="true"
            @hidden="reset"
            @show="reset"
        >
            <header slot="modal-header-close">
                <i aria-hidden="true" class="ion-ios-close-outline">
                    &nbsp;
                </i>
            </header>
            <b-container fluid>
                <p class="sub-title">
                    {{ $t("component.wavpp.scheduleCall.subTitle") }}
                </p>
                <form>
                    <b-row>
                        <div class="col-12 col-lg-7 form-block">
                            <div class="gray-block">
                                <div class="d-flex flex-column">
                                    <div class="form-row">
                                        <div class="form-group col-12 col-md-6">
                                            <label class="date-label">
                                                <span>{{
                                                    $t(
                                                        "component.wavpp.scheduleCall.dateLabel"
                                                    )
                                                }}</span>
                                            </label>

                                            <div class="input-group">
                                                <vue-bootstrap-datetime-picker
                                                    id="datePicker"
                                                    v-model="form.date"
                                                    :config="config"
                                                    @dp-change="changeCallDate"
                                                />
                                                <div
                                                    class="input-group-prepend"
                                                >
                                                    <div
                                                        class="input-group-text"
                                                    >
                                                        <i
                                                            aria-hidden="true"
                                                            class="ion-calendar"
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <span
                                                class="has-error text-danger"
                                                v-text="form.errors.get('date')"
                                            >
                                                Error Text
                                            </span>
                                        </div>

                                        <div class="form-group col-12 col-md-6">
                                            <label class="time-label">
                                                <span>{{
                                                    $t(
                                                        "component.wavpp.scheduleCall.timeLabel"
                                                    )
                                                }}</span>
                                            </label>

                                            <div class="input-group">
                                                <select
                                                    id="timeSlots"
                                                    v-model="form.time"
                                                    class="form-control"
                                                >
                                                    <option disabled value="">
                                                        Select
                                                    </option>
                                                    <option
                                                        v-for="(timeSlot,
                                                        key) in timeSlots"
                                                        :key="key"
                                                        :value="timeSlot"
                                                    >
                                                        {{ timeSlot.text }}
                                                    </option>
                                                </select>

                                                <div
                                                    class="input-group-prepend"
                                                >
                                                    <div
                                                        class="input-group-text"
                                                    >
                                                        <i
                                                            aria-hidden="true"
                                                            class="ion-clock"
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <span
                                                class="has-error text-danger"
                                                v-text="form.errors.get('time')"
                                            >
                                                Error Text
                                            </span>
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label
                                            class="comment-box-label"
                                            for="message"
                                        >
                                            {{
                                                $t(
                                                    "component.wavpp.scheduleCall.commentLabel"
                                                )
                                            }}
                                        </label>

                                        <textarea
                                            id="message"
                                            v-model="form.message"
                                            rows="4"
                                            class="form-control"
                                        />

                                        <span
                                            class="has-error text-danger"
                                            v-text="form.errors.get('message')"
                                        >
                                            Error Text
                                        </span>
                                    </div>

                                    <span class="phone-label">
                                        {{
                                            $t(
                                                "component.wavpp.scheduleCall.phoneLabel"
                                            )
                                        }}
                                    </span>

                                    <div class="col-7 p-0">
                                        <the-mask
                                            id="phoneNumber"
                                            v-model="form.phoneNumber"
                                            class="form-control"
                                            :mask="'(###) ###-####'"
                                        />

                                        <span
                                            class="has-error text-danger"
                                            v-text="
                                                form.errors.get('phoneNumber')
                                            "
                                        >
                                            Error Text
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-12 col-lg-5 mt-3">
                            <!--Vehicle in context-->
                            <vehicle-content
                                v-if="vehicle"
                                :vehicle="vehicle"
                            />

                            <!--No Vehicle in context-->
                            <auto-advisor-content v-else />
                        </div>
                    </b-row>

                    <b-row>
                        <div class="col-12">
                            <div class="terms-row mt-0 mt-lg-3 mb-3">
                                <div class="terms-and-condition-block">
                                    <span class="terms font-weight-bold">
                                        {{
                                            $t(
                                                "component.wavpp.scheduleCall.termsBold"
                                            )
                                        }}
                                    </span>

                                    <span class="terms font-italic">
                                        {{
                                            $t(
                                                "component.wavpp.scheduleCall.terms"
                                            )
                                        }}
                                    </span>
                                </div>
                            </div>
                        </div>

                        <b-col class="d-flex justify-content-center">
                            <ladda-b-btn
                                :loading="submitting"
                                variant="orange"
                                @click="onSubmit"
                            >
                                {{ $t("component.wavpp.scheduleCall.go") }}
                            </ladda-b-btn>
                        </b-col>
                    </b-row>
                </form>
            </b-container>
        </b-modal>

        <call-me-thank-you-modal />
    </div>
</template>

<script>
import LaddaBBtn from "Components/LaddaBBtn/index";
import { dispatch, get, sync } from "vuex-pathify";
import Form from "@/api/form";
import { TheMask } from "vue-the-mask";
import _ from "lodash";
import VueBootstrapDatetimePicker from "vue-bootstrap-datetimepicker";
import AutoAdvisorContent from "Modules/Wavpp/ScheduleCall/AutoAdvisorContent";
import VehicleContent from "Modules/Wavpp/ScheduleCall/VehicleContent";
import moment from "moment-timezone";
import CallMeThankYouModal from "Modules/Wavpp/CallMeThankYou";
import "pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.min.css";

export default {
    name: "ScheduleCall",
    components: {
        CallMeThankYouModal,
        VehicleContent,
        AutoAdvisorContent,
        LaddaBBtn,
        TheMask,
        VueBootstrapDatetimePicker
    },
    data() {
        return {
            form: new Form({
                date: "",
                time: "",
                message: "",
                phoneNumber: null
            }),
            timeSlots: [],
            config: {
                format: "MM/DD/YYYY",
                useCurrent: false,
                minDate: new Date()
            },
            submitting: false,
            user: null
        };
    },
    computed: {
        open: sync("supplierPricing/scheduleCallModal"),
        loggedInUser: get("user/user"),
        vehicle: get("supplierPricing/vehicle"),
        selectedTimeZone: get("location/selectedLocation@timeZone"),
        hoursOfOperation: get("supplierPricing/hoursOfOperation")
    },
    methods: {
        reset() {
            this.form.reset();
            this.submitting = false;
            this.form.phoneNumber = _.get(
                this.loggedInUser,
                "phoneNumber",
                null
            );
            this.form.message =
                "I would like to speak with you about the Walmart Associate Vehicle Purchase Program.";
        },
        onSubmit() {
            this.submitting = true;

            const certificateId = _.get(
                this.$store,
                "state.vehicleDetails.certificate.id",
                null
            );

            dispatch("supplierPricing/addScheduledCallQueue", {
                phoneNumber: this.form.phoneNumber,
                message: this.form.message,
                certificateId,
                callTime: this.form.time.value
            });

            // Kiosk requires an explicit return of false - even with .prevent on the event.
            return false;
        },
        changeCallDate(changedDateEvent) {
            if (changedDateEvent.date) {
                const callDate = changedDateEvent.date;
                const timeSlots = [];

                const referenceIndex = [0, 1, 2, 3, 4, 5, 6];
                const dayOfWeekHours = this.hoursOfOperation[
                    referenceIndex[callDate.day()]
                ];

                const now = moment();
                const nowPlus30Minutes = now.add(1, "minute");
                const startTime = moment(dayOfWeekHours.startTime, "HH:mm");
                const endTime = moment(dayOfWeekHours.endTime, "HH:mm");
                const start = moment.tz(
                    callDate.format("L") + " " + startTime.format("LT"),
                    "L LT",
                    "US/Eastern"
                );
                const end = moment.tz(
                    callDate.format("L") + " " + endTime.format("LT"),
                    "L LT",
                    "US/Eastern"
                );

                const startUserTz = start.clone().tz(this.selectedTimeZone);
                const endUserTz = end.clone().tz(this.selectedTimeZone);

                const isToday = moment(now.toDate()).isSame(callDate, "day");
                const tzText =
                    this.selectedTimeZone !== "US/Eastern"
                        ? startUserTz.zoneAbbr()
                        : "";
                while (startUserTz.isBefore(endUserTz.add(1, "second"))) {
                    if (!(isToday && startUserTz.isBefore(nowPlus30Minutes))) {
                        const formattedTime = startUserTz
                            .format("hh:mm a")
                            .toUpperCase();

                        timeSlots.push({
                            text: `${formattedTime} ${tzText}`,
                            value: startUserTz.format()
                        });
                    }
                    startUserTz.add(30, "minute");
                }

                this.timeSlots = timeSlots;
            } else {
                this.timeSlots = [];
            }
        }
    }
};
</script>
<style lang="scss">
#scheduleCall {
    .sub-title {
        margin-bottom: 15px;
    }

    .form-block {
        @include media-breakpoint-up(lg) {
            border-right: 1px solid #dee2e6;
        }

        .gray-block {
            background-color: #f9f9f9;
            padding: 15px 20px;
            border-radius: 3px;
        }
    }

    label {
        color: $medium-gray;
        font-size: 14px;
        font-style: italic;
    }

    .phone-label {
        color: $medium-gray;
        font-size: 14px;
        margin-bottom: 5px;
        font-style: italic;
    }

    .terms-row {
        .terms-and-condition-block {
            line-height: 15px;

            .terms {
                font-size: px2rem(12);
                color: $dark-gray;
                margin-top: 5px;
            }
        }
    }

    .btn-orange {
        font-size: 14px;
        width: 220px;
        height: 40px;
        font-family: $helvetica-55-Roman;
    }

    .modal-body {
        padding-top: 0;
        padding-bottom: 25px;

        /* IE11 Fix */
        min-height: 1px;
    }
}
</style>
