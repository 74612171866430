<template>
    <div id="filters-container">
        <b-modal
            id="filtersModal"
            ref="modal"
            v-model="modalVisible"
            body-class="p-0"
            footer-class="rounded-0"
            :hide-header="hideHeader"
            hide-footer
            @shown="onModalShown"
            @hidden="onModalHidden"
        >
            <div slot="modal-title">
                Filter Your Search
            </div>
            <b-container fluid class="p-0 h-100">
                <div ref="title" class="fixed-top">
                    <div class="shop-title">
                        {{ shopTitle }}
                    </div>
                </div>
                <div
                    ref="mobilefacetwrapper"
                    class="d-flex flex-column h-100 w-100 mobile-facet-wrapper"
                >
                    <facets
                        :is-loading="isLoading"
                        :show-payment-type-facet="showPaymentTypeFacet"
                        :show-miles-facet="showMilesFacet"
                        :show-years-facet="showYearsFacet"
                        :show-stock-type-filter="showStockTypeFilter"
                        :show-exterior-color-facet="showExteriorColorFacet"
                        :show-interior-color-facet="showInteriorColorFacet"
                        :show-sort-facet="showSortFacet"
                    />
                </div>
            </b-container>
        </b-modal>
    </div>
</template>
<script>
import { get, sync } from "vuex-pathify";
import Facets from "Modules/UsedListings/components/Facets";
import DesktopFiltersMixin from "Modules/UsedListings/components/DesktopFilters/mixin";

export default {
    name: "FiltersModal",

    components: {
        Facets
    },

    mixins: [DesktopFiltersMixin],

    props: {
        isLoading: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    data() {
        return {
            hideHeader: true,
            headerNode: {}
        };
    },

    computed: {
        stockTypes: get("vehicleSearch/filters?stockTypes"),
        showStockType: sync("vehicleSearch/mobileFiltersFacets@showStockType"),
        showPaymentType: sync(
            "vehicleSearch/mobileFiltersFacets@showPaymentType"
        ),
        showMiles: sync("vehicleSearch/mobileFiltersFacets@showMiles"),
        showYears: sync("vehicleSearch/mobileFiltersFacets@showYears"),
        showExteriorColor: sync(
            "vehicleSearch/mobileFiltersFacets@showExteriorColor"
        ),
        showInteriorColor: sync(
            "vehicleSearch/mobileFiltersFacets@showInteriorColor"
        ),
        modalVisible: sync("vehicleSearch/showMobileFilters"),
        isTypeUsed: function() {
            const type = "USED";

            if (Array.isArray(this.stockTypes)) {
                return this.stockTypes.indexOf(type) !== -1;
            }

            return false;
        },
        shopTitle() {
            if (this.isTypeUsed) {
                return "Shop Used Cars";
            } else {
                return "Shop New Cars";
            }
        }
    },

    methods: {
        onModalShown() {
            setTimeout(() => window.scrollTo({ top: 0 }), 400);

            // to prevent background page from scrolling
            // and other behavior bugs associated
            document.body.style.position = "fixed";
            document.body.style.width = "100vw";
            document.body.style.top = `-${window.scrollY}px`;
        },
        onModalHidden() {
            // to prevent background page from scrolling
            // and other behavior bugs associated
            document.body.style.position = "";
            document.body.style.width = "";
            document.body.style.top = "";
            window.scrollTo(0, parseInt(scrollY || "0") * -1);
        },
        showModal() {
            this.$refs.modal.show();
        },
        hideModal() {
            this.$refs.modal.hide();
        }
    }
};
</script>
<style lang="scss">
//Positioning fix
#filtersModal___BV_modal_outer_ {
    position: absolute;
    top: 0;
}

#filtersModal {
    transition: padding-top 0.3s ease-out;

    .btn-float-bottom {
        position: fixed;
        width: 100%;
        bottom: 20px;
        display: flex;
        justify-content: center;

        .btn-orange {
            width: 175px;
            height: 40px;
        }
    }

    button {
        width: 90px;
    }

    .shop-title {
        font-family: $helvetica-55-Roman;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 35px;
        width: 100%;
        background-color: $light-gray;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
        font-size: px2rem(12);
        line-height: px2rem(14);
    }

    #facets {
        margin-top: 50px;
        margin-bottom: 70px;
        padding-left: 15px;
        padding-right: 15px;

        .facet,
        .selected-facets {
            .facet-header {
                .label {
                    font-size: px2rem(22) !important;
                }
            }

            label,
            .collapse-icon {
                font-size: px2rem(16) !important;
            }

            select {
                font-size: px2rem(14) !important;
            }

            .clear {
                font-size: px2rem(12) !important;
            }
        }

        .facet,
        .selected-facets {
            background-color: white;
            margin-top: 10px;
        }
    }

    .make-divider {
        background-color: $very-light-gray;
    }

    &.modal {
        top: inherit;

        .modal-content {
            background-color: $card-background;
        }

        .modal-footer {
            padding: 0;
        }
    }
    .mobile-facet-wrapper {
        position: absolute;
        overflow: scroll;
    }
}
</style>
