<template>
    <facet-template
        :show-clear-link="isFiltered"
        :facet-name="'cylinders'"
        @clear="clearSelectedEngineTypes"
    >
        <span slot="facetLabel">
            {{ $t("component.facets.engineType") }}
        </span>

        <div slot="facetBody">
            <div class="container">
                <div class="row">
                    <div class="col-12 p-0">
                        <b-form-group class="m-0">
                            <b-form-checkbox-group
                                v-model="selectedEngineTypes"
                                plain
                                stacked
                            >
                                <b-form-checkbox
                                    v-for="option in availableEngineTypeOptions"
                                    :key="option.value"
                                    :value="option.value"
                                    class="mb-2"
                                >
                                    {{ option.text }}
                                </b-form-checkbox>
                            </b-form-checkbox-group>
                        </b-form-group>
                    </div>
                </div>
            </div>
        </div>
    </facet-template>
</template>

<script>
import _ from "lodash";
import FacetTemplate from "./FacetTemplate";
import { get, sync } from "vuex-pathify";
import mixin from "./mixin";

export default {
    name: "EngineTypeFacet",
    components: {
        FacetTemplate
    },
    mixins: [mixin],
    computed: {
        availableEngineTypes: get("vehicleSearch/facets@cylinders"),
        selectedEngineTypes: sync("vehicleSearch/filters@cylinders"),
        availableEngineTypeOptions() {
            const options = _.map(this.availableEngineTypes, cylinder => {
                return {
                    text: `${cylinder.count} Cylinders`,
                    value: cylinder.count
                };
            });

            return options;
        },
        isFiltered() {
            return !_.isEmpty(this.selectedEngineTypes);
        }
    },
    methods: {
        clearSelectedEngineTypes() {
            if (_.size(this.selectedEngineTypes) > 0) {
                this.selectedEngineTypes = [];
            }
        }
    }
};
</script>
