var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "facet-template",
    {
      attrs: { "show-clear-link": _vm.isFiltered, "facet-name": "models" },
      on: { clear: _vm.clearSelectedFilters }
    },
    [
      _c("span", { attrs: { slot: "facetLabel" }, slot: "facetLabel" }, [
        _vm._v("\n        Trim\n        "),
        _c("small", { staticClass: "font-italic font-weight-normal" }, [
          _vm._v(
            "\n            (" +
              _vm._s(_vm.selectedFilters.length) +
              " selected)\n        "
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", { attrs: { slot: "facetBody" }, slot: "facetBody" }, [
        _c(
          "div",
          {
            ref: "modelListContainer",
            staticClass: "container item-list",
            class: [{ expanded: _vm.selectorExpanded }]
          },
          _vm._l(_vm.modelGroups, function(model) {
            return _c("div", { key: model.name, staticClass: "row" }, [
              _c(
                "div",
                {
                  staticClass:
                    "col-12 make-divider d-flex align-items-center pl-3"
                },
                [
                  _c("span", { staticClass: "make-name" }, [
                    _vm._v(
                      "\n                        " +
                        _vm._s(model.name) +
                        "\n                    "
                    )
                  ])
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-sm-12 p-0 mb-1" },
                _vm._l(model.trims, function(trim) {
                  return _c(
                    "ul",
                    { key: trim.id, staticClass: "model-for-makes my-2" },
                    [
                      _c("li", { staticClass: "model-name" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.selectedFilters,
                              expression: "selectedFilters"
                            }
                          ],
                          staticClass: "mb-0",
                          attrs: {
                            id: "model-selector-" + trim.name,
                            type: "checkbox"
                          },
                          domProps: {
                            value: trim.name,
                            checked: Array.isArray(_vm.selectedFilters)
                              ? _vm._i(_vm.selectedFilters, trim.name) > -1
                              : _vm.selectedFilters
                          },
                          on: {
                            change: function($event) {
                              var $$a = _vm.selectedFilters,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = trim.name,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    (_vm.selectedFilters = $$a.concat([$$v]))
                                } else {
                                  $$i > -1 &&
                                    (_vm.selectedFilters = $$a
                                      .slice(0, $$i)
                                      .concat($$a.slice($$i + 1)))
                                }
                              } else {
                                _vm.selectedFilters = $$c
                              }
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "label",
                          {
                            staticClass: "mb-0 hotspot",
                            staticStyle: { cursor: "pointer" },
                            attrs: { for: "model-selector-" + trim.name }
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(trim.name) +
                                "\n                            "
                            )
                          ]
                        )
                      ])
                    ]
                  )
                }),
                0
              )
            ])
          }),
          0
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-12 p-2 text-center" },
          [
            !_vm.selectorExpanded
              ? _c(
                  "b-link",
                  {
                    on: {
                      click: function($event) {
                        return _vm.toggleListContainer()
                      }
                    }
                  },
                  [_vm._v("\n                Show More\n            ")]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.selectorExpanded
              ? _c(
                  "b-link",
                  {
                    on: {
                      click: function($event) {
                        return _vm.toggleListContainer()
                      }
                    }
                  },
                  [_vm._v("\n                Show Less\n            ")]
                )
              : _vm._e()
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }