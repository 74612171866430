<template>
    <half-circle-spinner
        :animation-duration="1000"
        :size="size"
        color="#004C91"
    />
</template>

<script>
import { HalfCircleSpinner } from "epic-spinners";

export default {
    name: "LoadingSpinner",
    components: { HalfCircleSpinner },
    props: {
        size: {
            type: Number,
            default: 30
        }
    }
};
</script>
