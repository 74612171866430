import clientStorage from "./clientStorage";
import _ from "lodash";

const namespace = "listings";

const url2Text = {
    "shop/new": "Back To Search",
    "shop/used": "Back To Search",
    "shop/models": "Back To Search",
    "pick-your-flow": "New Search",
    default: "New Search"
};

const textHanlder = url => {
    const paths = Object.keys(url2Text);
    let regexp = null;
    let i = 0;
    let path = null;

    for (i = 0; i < paths.length; i++) {
        path = paths[i];
        regexp = RegExp(path);

        if (regexp.test(url)) {
            return url2Text[path];
        }
    }

    return url2Text.default;
};

const listingsUrlStorage = {
    set: (url, text = null) => {
        const value = {
            url: url,
            text: text
        };

        if (_.isNil(url)) {
            console.trace("Error: Invalid url", url);
            return false;
        }

        if (_.isNil(text)) {
            value.text = textHanlder(value.url);
        }

        clientStorage.setItem(namespace, value);

        return { namespace: value };
    },

    get: () => {
        const value = clientStorage.getItem(namespace);

        if (_.isNil(value)) {
            console.trace(
                "Error: No listings key found in localStorage ",
                value
            );
            return false;
        }

        return value;
    }
};

export { listingsUrlStorage as default };
