import VueScrollTo from "vue-scrollto";

const smoothScroll = {
    computed: {
        scrollToOptions: function() {
            return {
                easing: "ease-in"
            };
        }
    },

    methods: {
        scrollTo(el) {
            const domObject = document.querySelector(`#${el}`);

            if (domObject) {
                VueScrollTo.scrollTo("#" + el, 500, this.scrollToOptions);
            } else {
                console.log("Error no dom object found", el);
            }
        },
        getTopOffset(domElement) {
            const node = document.querySelector(domElement);
            let offset = 0 - this.scrollOffset;

            if (node) {
                offset = 0 - this.getOffset(node).top - node.clientHeight;
            }

            return offset;
        },

        getOffset(el) {
            let _x = 0;
            let _y = 0;

            while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
                _x += el.offsetLeft - el.scrollLeft;
                _y += el.offsetTop - el.scrollTop;
                el = el.offsetParent;
            }

            return { top: _y, left: _x };
        }
    }
};

export default smoothScroll;
