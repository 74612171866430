var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "facet-template",
    {
      attrs: { "show-clear-link": _vm.isFiltered, "facet-name": "year" },
      on: { clear: _vm.clearSelectedYears }
    },
    [
      _c("span", { attrs: { slot: "facetLabel" }, slot: "facetLabel" }, [
        _vm._v(
          "\n        " + _vm._s(_vm.$t("component.facets.year")) + "\n    "
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "d-flex justify-content-between",
          attrs: { slot: "facetBody" },
          slot: "facetBody"
        },
        [
          _c("div", { staticClass: "left-select" }, [
            _c("label", { attrs: { for: "minYear" } }, [
              _vm._v("\n                starting at\n            ")
            ]),
            _vm._v(" "),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.selectedMinYear,
                    expression: "selectedMinYear"
                  }
                ],
                staticClass: "form-control-sm custom-select w-100",
                attrs: { id: "minYear", name: "minYear" },
                on: {
                  change: function($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function(o) {
                        return o.selected
                      })
                      .map(function(o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.selectedMinYear = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  }
                }
              },
              [
                _c("option", { domProps: { value: null } }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.$t("component.facets.any")) +
                      "\n                "
                  )
                ]),
                _vm._v(" "),
                _vm._l(_vm.availableMinYears, function(year) {
                  return _c(
                    "option",
                    { key: year, domProps: { value: year } },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(year) +
                          "\n                "
                      )
                    ]
                  )
                })
              ],
              2
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "right-select" }, [
            _c("label", { attrs: { for: "maxYear" } }, [
              _vm._v("\n                up to\n            ")
            ]),
            _vm._v(" "),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.selectedMaxYear,
                    expression: "selectedMaxYear"
                  }
                ],
                staticClass: "form-control-sm custom-select w-100",
                attrs: { id: "maxYear", name: "maxYear" },
                on: {
                  change: function($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function(o) {
                        return o.selected
                      })
                      .map(function(o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.selectedMaxYear = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  }
                }
              },
              [
                _c("option", { domProps: { value: null } }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.$t("component.facets.any")) +
                      "\n                "
                  )
                ]),
                _vm._v(" "),
                _vm._l(_vm.availableMaxYears, function(year) {
                  return _c(
                    "option",
                    { key: year, domProps: { value: year } },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(year) +
                          "\n                "
                      )
                    ]
                  )
                })
              ],
              2
            )
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }