<template>
    <div class="full-height">
        <pick-price-drive :selected-index="0" />

        <b-container fluid class="full-height">
            <b-row>
                <b-col>
                    <b-breadcrumb :items="breadcrumbs" class="mb-sm-0" />
                </b-col>
            </b-row>
            <b-row class="d-none d-sm-flex">
                <b-col class="header">
                    <slot name="header" />
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <slot />
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
import PickPriceDrive from "Components/PickPriceDrive";

export default {
    components: { PickPriceDrive },

    props: {
        breadcrumbs: {
            type: Array,
            required: true
        }
    }
};
</script>

<style lang="scss" scoped>
.breadcrumb {
    padding: 0.75rem 0 0 0;
}
.header {
    padding: 0 0 15px;

    @include media-breakpoint-up(md) {
        padding: 0 0 25px;
    }

    h1 {
        font-size: px2rem(24);
        color: $dark-gray;
        line-height: 25px;
        margin: 0;

        @include media-breakpoint-up(md) {
            font-size: px2rem(28);
        }
    }

    h2 {
        margin: 0;
        color: $primary-blue;
        font-size: px2rem(14);

        a {
            color: $dark-gray;
            text-decoration: underline;
        }
    }

    @include media-breakpoint-up(md) {
        border-bottom: solid 1px $muted-dark-gray;
        margin-bottom: 10px;
    }
}

@include media-breakpoint-up(md) {
    .breadcrumb {
        padding: 0.75rem 0;
    }
}
</style>
