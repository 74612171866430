<template>
    <i aria-hidden="true" :class="getIconClassMap" />
</template>

<script>
export default {
    name: "BodyStyleIcon",
    props: {
        id: {
            type: String,
            required: true
        },
        selected: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    computed: {
        getIconClassMap() {
            let cssClassMap = {};

            if (this.selected) {
                cssClassMap = `icon-${this.id}`;
            } else {
                cssClassMap = `icon-${this.id}-outline`;
            }

            return cssClassMap;
        }
    }
};
</script>
