var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    {},
    [
      _c("a", { staticClass: "learn-more", on: { click: _vm.show } }, [
        _vm._v("\n        Learn more.\n    ")
      ]),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          ref: "modal",
          attrs: {
            id: "confidenceModal",
            tabindex: "-1",
            role: "dialog",
            "aria-labelledby": "confidenceModal",
            "aria-hidden": "true",
            size: "lg"
          }
        },
        [
          _c(
            "div",
            {
              attrs: { slot: "modal-header-close" },
              slot: "modal-header-close"
            },
            [
              _c(
                "i",
                {
                  staticClass: "ion-ios-close-outline",
                  attrs: { "aria-hidden": "true" },
                  on: { click: _vm.close }
                },
                [_vm._v("\n                 \n            ")]
              )
            ]
          ),
          _vm._v(" "),
          _c("div", { attrs: { slot: "modal-title" }, slot: "modal-title" }, [
            _vm._v("\n            Shop with Confidence\n        ")
          ]),
          _vm._v(" "),
          _c(
            "b-container",
            [
              _c("b-row", [
                _c("div", { staticClass: "col-12" }, [
                  _c("span", { staticClass: "font-weight-bold" }, [
                    _vm._v(
                      "\n                        CarSaver gives you control over your shopping\n                        experience.\n                    "
                    )
                  ])
                ])
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-container",
            { staticClass: "my-3" },
            [
              _c("b-row", { staticClass: "mb-3" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c(
                    "div",
                    {
                      staticClass: "gray-block",
                      staticStyle: {
                        padding: "30px 30px 35px 30px",
                        "background-color": "#f9f9f9",
                        color: "#444",
                        "font-size": "14px",
                        "border-radius": "5px"
                      }
                    },
                    [
                      _c("div", { staticClass: "d-flex flex-column mb-4" }, [
                        _c("span", { staticClass: "font-weight-bold" }, [
                          _vm._v(
                            "\n                                Choose your dealerships.\n                            "
                          )
                        ]),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(
                            "CarSaver will not give your contact\n                                information to any dealership without your\n                                permission."
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "d-flex flex-column mb-4" }, [
                        _c("span", { staticClass: "font-weight-bold" }, [
                          _vm._v(
                            "\n                                Keep your personal number private\n                            "
                          )
                        ]),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(
                            "CarSaver’s Certified Dealers are given\n                                temporary replacement phone numbers so that\n                                you can personalize the communications you\n                                receive from each dealership – Choose email\n                                and phone or email only."
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "d-flex flex-column" }, [
                        _c("span", { staticClass: "font-weight-bold" }, [
                          _vm._v(
                            "\n                                Control which dealerships can call you.\n                            "
                          )
                        ]),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(
                            "CarSaver gives you the ability to turn off\n                                phone calls for one of all CarSaver Certifed\n                                Dealers when you no longer wish to receive\n                                them."
                          )
                        ])
                      ])
                    ]
                  )
                ])
              ]),
              _vm._v(" "),
              _c("b-row", [
                _c("div", { staticClass: "col-12" }, [
                  _c("div", { staticClass: "font-weight-bold" }, [
                    _vm._v(
                      "\n                        For more information about CarSaver’s privacy\n                        protection features, call\n                        "
                    ),
                    _c("span", { staticStyle: { color: "#007DCF" } }, [
                      _vm._v(
                        "\n                            800-277-4800.\n                        "
                      )
                    ])
                  ])
                ])
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "w-100 d-flex flex-column justify-content-center align-items-center",
              attrs: { slot: "modal-footer" },
              slot: "modal-footer"
            },
            [
              _c(
                "b-button",
                {
                  staticStyle: { width: "220px", "font-size": "14px" },
                  attrs: { variant: "orange", size: "lg" },
                  on: { click: _vm.close }
                },
                [_vm._v("\n                Close\n            ")]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "d-flex mt-2 align-items-center",
                  staticStyle: { "font-size": "11px", color: "#007DCF" }
                },
                [
                  _c(
                    "a",
                    {
                      staticClass: "mr-1",
                      attrs: {
                        href: "/tos",
                        target: "_blank",
                        rel: "noopener noreferrer"
                      }
                    },
                    [
                      _vm._v(
                        "\n                    Terms & Conditions\n                "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("span", { staticClass: "my-1" }, [
                    _vm._v("\n                    |\n                ")
                  ]),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass: "ml-1",
                      attrs: {
                        href: "/privacy",
                        target: "_blank",
                        rel: "noopener noreferrer"
                      }
                    },
                    [
                      _vm._v(
                        "\n                    Privacy Policy\n                "
                      )
                    ]
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }