<template>
    <div class="sort-by d-inline-flex align-items-center">
        <span class="sort-text pr-2">Sort By:</span>
        <div class="dropdown">
            <b-link
                id="dropdownMenuButton"
                class="sort dropdown-toggle"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
            >
                {{ sortDisplay }}
            </b-link>
            <div
                class="dropdown-menu dropdown-menu-right px-2"
                aria-labelledby="dropdownMenuButton"
            >
                <b-link
                    v-for="option in sortOptions"
                    :key="option.value"
                    class="dropdown-item"
                    @click="selectSort(option)"
                >
                    <i
                        v-if="sortDisplay === option.text"
                        aria-hidden="true"
                        class="ion-checkmark position-absolute"
                    />
                    {{ option.text }}
                </b-link>
            </div>
        </div>
    </div>
</template>

<script>
import listingSort from "@/modules/UsedListings/mixins/listingSort";
import { call } from "vuex-pathify";

export default {
    name: "SortSelectorMobile",

    mixins: [listingSort],

    computed: {
        sortDisplay() {
            let displayText = ""; // default
            let sortOption = null;

            if (
                Array.isArray(this.sortOptions) &&
                this.sortOptions.length > 0
            ) {
                sortOption = this.sortOptions.find(
                    element => element.value === this.sort
                );

                if (sortOption && sortOption.text) {
                    displayText = sortOption.text;
                }
            }

            return displayText;
        }
    },

    methods: {
        filtersChanged: call("vehicleSearch/filtersChanged"),
        selectSort(option) {
            this.sort = option.value;
            this.filtersChanged();
        }
    }
};
</script>

<style lang="scss" scoped>
// TODO: investigate importing these globally without breaking other styles - NL 9.3.20
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/dropdown";

.sort-by {
    .sort-text {
        font-size: px2rem(12);
        line-height: px2rem(14);
        font-weight: bold;
        color: $primary-blue;
        font-family: $helvetica-55-Roman;
    }
    .dropdown {
        font-size: px2rem(12);
        line-height: px2rem(14);
        font-family: $helvetica-55-Roman;

        .ion-checkmark {
            left: 10px;
        }
    }
    .dropdown-toggle::after {
        vertical-align: middle;
    }
}
</style>
