<template>
    <b-modal id="callInQueueModal" v-model="open" size="md" :hide-footer="true">
        <div slot="modal-title">
            {{ $t("component.wavpp.callInQueue.title") }}
        </div>
        <header slot="modal-header-close">
            <i aria-hidden="true" class="ion-ios-close-outline">
                &nbsp;
            </i>
        </header>
        <b-container fluid>
            <form>
                <b-row>
                    <b-col>
                        <div class="d-flex flex-column align-items-center">
                            <span class="message text-center">
                                {{ $t("component.wavpp.callInQueue.message") }}
                            </span>
                            <p v-if="callTime" class="content-box p-3">
                                {{ callTime | moment("dddd[,] L [at] LT") }}
                            </p>
                            <b-button variant="orange" @click="hide">
                                {{
                                    $t(
                                        "component.wavpp.callInQueue.closeWindow"
                                    )
                                }}
                            </b-button>
                        </div>
                    </b-col>
                </b-row>
            </form>
        </b-container>
    </b-modal>
</template>

<script>
import { sync, get } from "vuex-pathify";

export default {
    name: "CallInQueueModal",
    computed: {
        open: sync("supplierPricing/callInQueueModal"),
        callTime: get("supplierPricing/callTime")
    },
    methods: {
        hide() {
            this.open = false;
        }
    }
};
</script>
<style lang="scss">
#callInQueueModal {
    .modal-header {
        height: 95px;
    }

    @include media-breakpoint-up(md) {
        .modal-title {
            padding-left: 140px !important;
        }
    }

    .modal-title {
        padding-left: 70px;
    }

    .message {
        font-family: $helvetica-45-light;
        font-size: 14px;
        color: $medium-gray;
        margin-bottom: 15px;
        max-width: 390px;
    }

    .btn-orange {
        font-size: 14px;
        width: 195px;
        margin-bottom: 30px;
        font-family: $helvetica-55-Roman;
    }

    .content-box {
        margin-bottom: 35px;
        background-color: $card-background;
        font-family: $helvetica-55-Roman;
        font-size: 24px;
        color: $dark-gray;
    }

    .modal-body {
        padding-top: 0;
        padding-bottom: 25px;
    }
}
</style>
