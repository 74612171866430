<template>
    <div>
        <next-steps-modal ref="nextStepsModal" />

        <c-s-modal
            ref="formModal"
            modal-class="schedule-test-drive-modal"
            size="lg"
            lazy
            @ok.prevent="onSubmit"
            @hidden="onHidden"
            @shown="onShow"
        >
            <div slot="modal-title">
                <span class="d-none d-sm-inline"
                    >{{ scheduleOrReschedule }} Test Drive</span
                >
                <span class="d-sm-none">Test Drive</span>
            </div>

            <appointment-loader v-if="isLoading" />

            <b-container v-else>
                <b-row v-if="hasError">
                    <b-col>
                        <h3>Uh oh!</h3>
                        <p>
                            We've hit a bump in the road. Please try again.
                        </p>
                    </b-col>
                </b-row>
                <b-row v-else>
                    <div class="col-md-8">
                        <span v-if="vehicle">
                            {{ modalQuestion }}
                            <strong
                                >{{ vehicle.make }} {{ vehicle.model }}</strong
                            >?
                        </span>
                        <form
                            id="test-drive-form"
                            class="horizontal-form mt-2"
                            @submit.prevent="onSubmit"
                            @keydown="form.errors.clear($event.target.name)"
                        >
                            <div class="inputs-wrapper">
                                <div class="form-row">
                                    <div class="form-group col-12 col-md-6">
                                        <strong>
                                            <label class="day-label">
                                                <span
                                                    >{{
                                                        $t(
                                                            "component.scheduleTestDrive.dayLabel"
                                                        )
                                                    }}
                                                </span>
                                            </label>
                                        </strong>
                                        <div class="input-group input-group-sm">
                                            <vue-bootstrap-datetime-picker
                                                id="datePicker"
                                                v-model="form.appointmentDate"
                                                :config="config"
                                                @dp-change="
                                                    changeAppointmentDate
                                                "
                                            />
                                            <div class="input-group-prepend">
                                                <div class="input-group-text">
                                                    <i
                                                        class="ion-calendar"
                                                        aria-hidden="true"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            v-if="
                                                form.errors.has(
                                                    'appointmentDate'
                                                )
                                            "
                                            class="help-block with-errors text-danger"
                                        >
                                            <p
                                                class="has-error text-danger"
                                                v-text="
                                                    form.errors.get(
                                                        'appointmentDate'
                                                    )
                                                "
                                            >
                                                Error Text
                                            </p>
                                        </div>
                                    </div>
                                    <div
                                        class="form-group input-group-sm col-12 col-md-6"
                                    >
                                        <strong>
                                            <label
                                                class="time-label"
                                                for="appointmentTimeSlots"
                                            >
                                                {{
                                                    $t(
                                                        "component.scheduleTestDrive.timeLabel"
                                                    )
                                                }}
                                                <small class="font-italic">
                                                    {{
                                                        $t(
                                                            "component.scheduleTestDrive.dayFirst"
                                                        )
                                                    }}
                                                </small>
                                            </label>
                                        </strong>
                                        <select
                                            id="appointmentTimeSlots"
                                            v-model="form.appointmentTime"
                                            class="form-control"
                                        >
                                            <option disabled value="">
                                                {{
                                                    $t(
                                                        "component.scheduleTestDrive.selectOption"
                                                    )
                                                }}
                                            </option>
                                            <option
                                                v-for="(timeSlot,
                                                key) in timeSlots"
                                                :key="key"
                                                :value="timeSlot.value"
                                            >
                                                {{ timeSlot.text }}
                                            </option>
                                        </select>
                                        <div
                                            v-if="
                                                form.errors.has(
                                                    'appointmentTime'
                                                )
                                            "
                                            class="help-block with-errors text-danger"
                                        >
                                            <p
                                                class="has-error text-danger"
                                                v-text="
                                                    form.errors.get(
                                                        'appointmentTime'
                                                    )
                                                "
                                            >
                                                Error Text
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label
                                        class="comment-box-label"
                                        for="question"
                                    >
                                        {{
                                            $t(
                                                "component.scheduleTestDrive.questionOrComment"
                                            )
                                        }}
                                    </label>
                                    <textarea
                                        id="question"
                                        v-model="form.question"
                                        rows="4"
                                        class="form-control"
                                    />
                                    <div
                                        v-if="form.errors.has('question')"
                                        class="help-block with-errors text-danger"
                                    >
                                        <p
                                            class="has-error text-danger"
                                            v-text="form.errors.get('question')"
                                        >
                                            Error Text
                                        </p>
                                    </div>
                                </div>
                                <div
                                    v-if="dealer"
                                    class="dealership-info d-flex flex-column d-sm-flex d-md-none d-lg-none d-xl-none mt-0 mb-3"
                                >
                                    <div class="certified-dealer">
                                        <span>{{
                                            $t(
                                                "component.scheduleTestDrive.certifiedDealer"
                                            )
                                        }}</span>
                                    </div>
                                    <div class="dealership-name">
                                        <span>{{ dealer.name }}</span>
                                    </div>
                                    <span class="distance">
                                        {{
                                            $t(
                                                "component.scheduleTestDrive.distance"
                                            )
                                        }}
                                        {{ distance | numeral("0.0") }}
                                        {{
                                            $t(
                                                "component.scheduleTestDrive.miles"
                                            )
                                        }}
                                    </span>
                                </div>
                                <input
                                    id="ambassadorPin"
                                    v-model="form.ambassadorPin"
                                    type="hidden"
                                    name="ambassadorPin"
                                />
                            </div>
                        </form>

                        <div
                            v-if="user"
                            class="d-none d-sm-none d-md-flex flex-column user-info"
                        >
                            <div class="mb-2">
                                <span>{{
                                    $t(
                                        "component.scheduleTestDrive.contactInfoCorrect"
                                    )
                                }}</span>
                            </div>
                            <edit-contact-info />
                        </div>
                    </div>
                    <div
                        class="col-md-4 d-none d-sm-none d-md-flex flex-column"
                    >
                        <div class="vehicle-image flex-column">
                            <b-img :src="imageUrl" :alt="vehicleName" fluid />

                            <div v-if="vehicle" class="vehicle-title-box py-2">
                                <h4>{{ vehicleName }}</h4>
                                <span>{{ vehicle.trim }}</span>
                            </div>
                        </div>
                        <div
                            v-if="dealer"
                            class="dealership-info d-flex flex-column h-100 justify-content-end"
                        >
                            <span class="certified-dealer">
                                {{
                                    $t(
                                        "component.scheduleTestDrive.certifiedDealer"
                                    )
                                }}
                            </span>
                            <span class="dealership-name">
                                {{ dealer.name }}
                            </span>
                            <span class="distance">
                                {{
                                    $t("component.scheduleTestDrive.distance")
                                }}
                                {{ distance | numeral("0.0") }}
                                {{ $t("component.scheduleTestDrive.miles") }}
                            </span>
                            <span class="address">
                                {{ dealer.address1 }}
                            </span>
                            <span class="address">
                                {{ dealer.city }} {{ dealer.state }}
                                {{ dealer.zipCode }}
                            </span>
                        </div>
                    </div>
                </b-row>
                <b-row>
                    <div class="col-12">
                        <div class="terms-row mt-0 mt-lg-3">
                            <div class="terms-and-condition-block">
                                <span class="terms font-weight-bold">
                                    {{
                                        $t(
                                            "component.scheduleTestDrive.termsBold"
                                        )
                                    }}
                                </span>

                                <span class="terms font-italic">
                                    {{
                                        $t("component.scheduleTestDrive.terms")
                                    }}
                                </span>
                            </div>
                        </div>
                    </div>
                </b-row>
            </b-container>
            <template slot="modal-footer">
                <ladda-b-btn
                    :loading="submitting"
                    :disabled="isFormInvalid"
                    variant="orange"
                    @click="onSubmit"
                >
                    {{ buttonTitle }}
                </ladda-b-btn>
            </template>
        </c-s-modal>
    </div>
</template>

<style lang="scss">
.schedule-test-drive-modal {
    color: $dark-gray;

    .modal-body {
        padding: 15px 20px;

        label {
            color: $dark-gray;
            font-size: 14px;
        }
        textarea {
            height: 60px;
            border-radius: 0;
        }
        .form-check-label {
            font-size: px2rem(14);
        }
        .user-info {
            bottom: 0;
            color: $dark-gray;

            .user-name {
                height: auto;
                max-width: 250px;

                > span {
                    font-size: px2rem(16);
                    font-weight: bold;
                    color: $medium-gray;
                    height: auto;
                    line-height: 20px;
                    display: block;
                }
            }
            .user-phone {
                height: auto;
                max-width: 250px;
                > span {
                    font-size: px2rem(16);
                    color: $dark-gray;
                    line-height: 20px;
                    height: auto;
                    display: block;
                }
            }
            .user-email {
                max-width: 250px;
                font-size: px2rem(16);
                color: $dark-gray;
                height: auto;
                line-height: 20px;
                display: block;
            }
        }

        .vehicle-image {
            max-height: 250px;

            .vehicle-title-box {
                text-align: center;
                color: $dark-gray;
                line-height: 10px;

                > h4 {
                    font-size: px2rem(16);
                    font-weight: bold;
                    margin-bottom: 0;
                }

                > span {
                    font-size: px2rem(14);
                    font-weight: normal;
                }
            }
        }

        .dealership-info {
            .certified-dealer {
                font-size: px2rem(12);
                color: $muted-dark-gray;
                line-height: 15px;
            }
            .dealership-name {
                font-size: px2rem(16);
                color: #4a4a4a;
                line-height: 20px;
                font-weight: bold;
                height: auto;
                display: block;
                max-width: 250px;
            }
            .distance,
            .address {
                color: $black;
                font-size: px2rem(12);
            }
        }
    }

    .terms-row {
        .terms-and-condition-block {
            line-height: 15px;
            .terms {
                font-size: px2rem(12);
                color: $dark-gray;
                margin-top: 5px;
            }
        }
    }

    .col-md-8 {
        border-right: 1px solid #d4d4d4;
    }

    .modal-footer {
        background-color: $light-gray;
        height: 65px;

        > .btn-orange {
            margin: 0;
            width: 200px;
            font-size: px2rem(16);
        }
    }

    @include media-breakpoint-down(sm) {
        .col-md-8 {
            border-right: none;
        }
    }
}
</style>

<script>
import _ from "lodash";
import { get, sync } from "vuex-pathify";
import LaddaBBtn from "Components/LaddaBBtn";
import VueBootstrapDatetimePicker from "vue-bootstrap-datetimepicker";
import NextStepsModal from "../NextStepsModal";
import CSModal from "Components/CSModal";
import EditContactInfo from "Components/EditContactInfo";
import Form from "@/api/form";
import {
    trackEvent,
    trackGAEvent,
    trackPageView
} from "@/lib/GoogleTagManager";
import moment from "moment-timezone";
import AppointmentLoader from "Components/ScheduleTestDrive/components/AppointmentLoader";
import EventBus from "@/event-bus";

export default {
    name: "ScheduleTestDrive",
    components: {
        AppointmentLoader,
        EditContactInfo,
        CSModal,
        NextStepsModal,
        LaddaBBtn,
        VueBootstrapDatetimePicker
    },

    data() {
        return {
            form: new Form({
                appointmentDate: "",
                appointmentTime: "",
                question: "",
                ambassadorPin: null
            }),
            submitting: false,
            timeSlots: [],
            options: {
                format: "MM/DD/YYYY",
                useCurrent: false,
                minDate: new Date(),
                daysOfWeekDisabled: []
            }
        };
    },

    computed: {
        vehicleName() {
            if (!_.isNil(this.vehicle)) {
                return `${this.vehicle.year} ${this.vehicle.make} ${this.vehicle.model}`;
            }

            return "";
        },
        isFormInvalid() {
            return (
                !this.form.appointmentDate ||
                !this.form.appointmentTime ||
                this.form.errors.any()
            );
        },
        isReschedule() {
            return this.appointmentId != null;
        },
        buttonTitle() {
            if (this.isReschedule) {
                return this.submitting ? "Rescheduling..." : "Reschedule";
            } else {
                return this.submitting ? "Scheduling..." : "Schedule";
            }
        },
        scheduleOrReschedule() {
            return this.isReschedule ? "Reschedule" : "Schedule";
        },
        modalQuestion() {
            if (this.isReschedule) {
                return "When would you like to reschedule your test drive of the ";
            } else {
                return "When would you like to drive your ";
            }
        },

        hasError() {
            return !this.isLoading && this.dealer === null;
        },

        ambassadorPin: get("sessionReset/ambassadorId"),
        vehicle: get("scheduleAppointment/vehicle"),
        dealer: get("scheduleAppointment/dealer"),
        user: get("scheduleAppointment/user"),
        distance: get("scheduleAppointment/distance"),
        imageUrl: get("scheduleAppointment/imageUrl"),
        isOpen: sync("scheduleAppointment/isOpen"),
        isLoading: get("scheduleAppointment/isLoading"),
        certificateId: get("scheduleAppointment/certificateId"),
        appointmentId: get("scheduleAppointment/appointmentId"),
        appointmentScheduled: sync(
            "vehicleDetails/certificate@appointmentScheduled"
        ),
        selectedTimeZone: get("location/selectedLocation@timeZone"),
        hoursOfOperations() {
            return _.get(this.dealer, "hoursOfOperations");
        },
        config: {
            get: function() {
                if (_.isNil(this.hoursOfOperations)) {
                    return this.options;
                }

                this.setClosedDays();

                return this.options;
            },
            set: function(configs = {}) {
                this.options = configs;
            }
        }
    },

    watch: {
        ambassadorPin(nextValue) {
            this.form.ambassadorPin = nextValue;
        },
        isOpen(newVal) {
            if (newVal === true) {
                this.$refs.formModal.show();
            }
        }
    },
    methods: {
        getClosedDays() {
            const closedDays = [];
            const dayIndex = [1, 2, 3, 4, 5, 6, 0];

            if (!_.isNil(this.hoursOfOperations)) {
                $.each(this.hoursOfOperations, function(i, dayOfWeekHours) {
                    const openHours = dayOfWeekHours.openHours || "closed";
                    const closedHours = dayOfWeekHours.closeHours || "closed";
                    if (
                        openHours.toLowerCase() === "closed" ||
                        closedHours.toLowerCase() === "closed"
                    ) {
                        closedDays.push(dayIndex[i]);
                    }
                });
            }

            return closedDays;
        },
        setClosedDays() {
            this.options.daysOfWeekDisabled = this.getClosedDays();
        },
        onHidden() {
            this.form.reset();
            this.config = {
                format: "MM/DD/YYYY",
                useCurrent: false,
                minDate: new Date(),
                daysOfWeekDisabled: []
            };
            this.submitting = false;
            this.timeSlots = [];
            this.isOpen = false;
        },
        onShow() {
            if (this.isReschedule) {
                trackPageView(
                    this,
                    `/dashboard/appointment/${this.appointmentId}/reschedule`
                );
            } else {
                trackPageView(this, "/joy-ride");
            }

            const self = this;
            const getClosedDays = function() {
                const closedDays = [];
                const dayIndex = [1, 2, 3, 4, 5, 6, 0];
                const hoursOfOperations = _.get(
                    self.dealer,
                    "hoursOfOperations"
                );

                if (!_.isNil(hoursOfOperations)) {
                    $.each(hoursOfOperations, function(i, dayOfWeekHours) {
                        const openHours = dayOfWeekHours.openHours || "closed";
                        const closedHours =
                            dayOfWeekHours.closeHours || "closed";
                        if (
                            openHours.toLowerCase() === "closed" ||
                            closedHours.toLowerCase() === "closed"
                        ) {
                            closedDays.push(dayIndex[i]);
                        }
                    });
                }

                return closedDays;
            };

            this.config.daysOfWeekDisabled = getClosedDays();
        },
        showFormModal() {
            if (this.user !== null) {
                this.addSessionReset2form();
                this.$refs.formModal.show();
            }
        },

        addSessionReset2form() {
            this.form.ambassadorPin = this.$store.state.sessionReset.ambassadorId;
        },

        onSubmit() {
            this.submitting = true;

            if (this.user !== null) {
                this.addSessionReset2form();
            }

            if (this.isReschedule) {
                trackEvent(this, "reschedule-appointment", {
                    dealerId: this.dealer.id,
                    certificateId: this.certificateId,
                    message: this.question,
                    styleId: this.vehicle.styleId,
                    inventoryId: _.get(this.vehicle, "id")
                });
                trackGAEvent(this, {
                    category: "Schedule Test Drive",
                    action: "Rescheduled",
                    label: "Reschedule"
                });
            } else {
                trackEvent(this, "schedule-appointment", {
                    dealerId: this.dealer.id,
                    certificateId: this.certificateId,
                    message: this.question,
                    styleId: this.vehicle.styleId,
                    inventoryId: _.get(this.vehicle, "id")
                });
                trackGAEvent(this, {
                    category: "Schedule Test Drive",
                    action: "Scheduled",
                    label: "Go"
                });
            }

            let postUrl;
            if (!_.isNil(this.certificateId)) {
                // Initial Scheduling of Appointment
                postUrl = `/garage/${this.certificateId}/schedule-test-drive`;
            } else {
                // Reschedule of Appointment
                postUrl = `/appointments/${this.appointmentId}/reschedule`;
            }

            this.form
                .submit(postUrl)
                .then(() => {
                    this.submitting = false;
                    if (!_.isNil(_.get(this.$store, "state.vehicleDetails"))) {
                        this.appointmentScheduled = true;
                    }
                    this.$refs.formModal.hide();
                    this.$refs.nextStepsModal.show();
                    EventBus.$emit("reschedule-appointment-finished");
                })
                .catch(error => {
                    this.submitting = false;
                    console.error(error);
                });

            // Kiosk requires an explicit return of false - even with .prevent on the event.
            return false;
        },

        changeAppointmentDate(changedDateEvent) {
            if (changedDateEvent.date) {
                const appointmentDate = changedDateEvent.date;
                const timeSlots = [];

                const referenceIndex = [6, 0, 1, 2, 3, 4, 5];
                const dayOfWeekHours = this.dealer.hoursOfOperations[
                    referenceIndex[appointmentDate.day()]
                ];
                const dealerTimeZone = _.get(
                    this.dealer,
                    "timeZone",
                    "US/Eastern"
                );

                const now = moment();
                const nowPlus30Minutes = now.add(30, "minute");
                const startTime = moment(dayOfWeekHours.openHours, "h:mm A");
                const endTime = moment(dayOfWeekHours.closeHours, "h:mm A");
                const start = moment.tz(
                    appointmentDate.format("L") + " " + startTime.format("LT"),
                    "L LT",
                    dealerTimeZone
                );
                const end = moment.tz(
                    appointmentDate.format("L") + " " + endTime.format("LT"),
                    "L LT",
                    dealerTimeZone
                );

                const startUserTz = start.clone().tz(this.selectedTimeZone);
                const endUserTz = end.clone().tz(this.selectedTimeZone);

                const isToday = moment(now.toDate()).isSame(
                    appointmentDate,
                    "day"
                );
                const tzText =
                    this.selectedTimeZone !== dealerTimeZone &&
                    typeof startUserTz.zoneAbbr === "function"
                        ? startUserTz.zoneAbbr()
                        : "";
                while (startUserTz.isBefore(endUserTz.add(1, "second"))) {
                    if (!(isToday && startUserTz.isBefore(nowPlus30Minutes))) {
                        const formattedTime = startUserTz
                            .format("hh:mm a")
                            .toUpperCase();

                        timeSlots.push({
                            text: `${formattedTime} ${tzText}`,
                            value: startUserTz.format()
                        });
                    }
                    startUserTz.add(30, "minute");
                }

                this.timeSlots = timeSlots;
            } else {
                this.timeSlots = [];
            }
        }
    }
};
</script>
