<template>
    <facet-template
        v-if="show"
        :show-clear-link="isFiltered"
        :facet-name="'miles'"
        @clear="clearSelectedMiles"
    >
        <span slot="facetLabel">
            {{ $t("component.facets.mileage") }}
        </span>
        <div slot="facetBody">
            <div class="container">
                <div class="row">
                    <div class="col-12 p-0 full-width-select">
                        <select
                            v-model="selectedMaximumMiles"
                            class="form-control-sm custom-select w-100"
                        >
                            <option :value="null">
                                {{ $t("component.facets.any") }}
                            </option>
                            <option
                                v-for="mileOption in mileOptions"
                                :key="mileOption"
                                :value="mileOption"
                            >
                                {{ mileOption | numeral("0,0") }}
                                {{ $t("component.facets.orLess") }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
    </facet-template>
</template>

<script>
import FacetTemplate from "./FacetTemplate";
import { get, sync } from "vuex-pathify";
import _ from "lodash";

export default {
    name: "MilesFacet",
    components: {
        FacetTemplate
    },
    props: {
        show: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        availableMilesRange: get("vehicleSearch/facets@milesRange"),
        selectedMaximumMiles: sync("vehicleSearch/filters@maxMiles"),
        mileOptions() {
            return this.generateMileOptions(
                this.availableMilesRange.minimum,
                this.availableMilesRange.maximum
            );
        },
        isFiltered() {
            return !_.isNil(this.selectedMaximumMiles);
        }
    },
    methods: {
        clearSelectedMiles() {
            if (_.isInteger(this.selectedMaximumMiles)) {
                this.selectedMaximumMiles = null;
            }
        },
        generateMileOptions(minimumMileage, maximumMileage) {
            const stepMilesBy = 10000;

            // start greater than zero because "Any" option will cover this
            const mileageStart =
                minimumMileage === 0 ? stepMilesBy : minimumMileage;
            const mileageEnd =
                maximumMileage <= 250000 ? maximumMileage : 250000;

            return this.rangeInclusive(mileageStart, mileageEnd, stepMilesBy);
        },
        // lodash ranges are not inclusive (upper bounds)
        rangeInclusive(start, end, step = 1) {
            return _.range(start, end + step, step);
        }
    }
};
</script>
