var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "facet-template",
    {
      attrs: { "show-clear-link": _vm.isFiltered },
      on: { clear: _vm.clearSelectedStockTypes }
    },
    [
      _c("span", { attrs: { slot: "facetLabel" }, slot: "facetLabel" }, [
        _vm._v(
          "\n        " + _vm._s(_vm.$t("component.facets.stockType")) + "\n    "
        )
      ]),
      _vm._v(" "),
      _c("div", { attrs: { slot: "facetBody" }, slot: "facetBody" }, [
        _c("div", { staticClass: "container" }, [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-12 p-0" },
              [
                _c(
                  "b-form-group",
                  { staticClass: "m-0" },
                  [
                    _c(
                      "b-form-checkbox-group",
                      {
                        attrs: { plain: "", stacked: "" },
                        model: {
                          value: _vm.selectedStockTypes,
                          callback: function($$v) {
                            _vm.selectedStockTypes = $$v
                          },
                          expression: "selectedStockTypes"
                        }
                      },
                      _vm._l(_vm.availableStockTypeOptions, function(option) {
                        return _c(
                          "b-form-checkbox",
                          {
                            key: option.value,
                            staticClass: "mb-2",
                            attrs: { value: option.value }
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(option.text) +
                                "\n                            "
                            )
                          ]
                        )
                      }),
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }