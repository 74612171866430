import axios from "axios";
import _ from "lodash";

const basePath = "/api/v2";

export default new (class ApiClient {
    static ignoredResponseCodes = [400];

    constructor() {
        this.httpClient = axios.create();

        this.httpClient.interceptors.response.use(
            response => response,
            error => {
                const bugsnag = _.get(window, "bugsnagClient");
                if (
                    bugsnag !== undefined &&
                    !_.includes(
                        ApiClient.ignoredResponseCodes,
                        _.get(error, "response.status")
                    )
                ) {
                    const ajaxResponse = _.pick(_.get(error, "response"), [
                        "data",
                        "status",
                        "statusText",
                        "headers"
                    ]);
                    const ajaxRequest = _.get(error, "response.config");

                    // If we can't detect the request or response, assume network error or cancelled request
                    if (ajaxResponse !== null || ajaxRequest !== null) {
                        bugsnag.notify(error, {
                            metaData: {
                                ajaxResponse,
                                ajaxRequest
                            }
                        });
                    }
                }

                return Promise.reject(error);
            }
        );
    }

    static parseOptions(options) {
        if (_.isObject(options)) {
            if (_.get(options, "ignore404", false)) {
                ApiClient.ignoredResponseCodes.push(404);
            }
        }
    }

    get(path, params, options = {}) {
        ApiClient.parseOptions(options);

        return this.httpClient.get(basePath + path, { params });
    }

    put(path, data, options = {}) {
        ApiClient.parseOptions(options);

        return this.httpClient.put(basePath + path, data);
    }

    post(path, data, options = {}) {
        ApiClient.parseOptions(options);

        return this.httpClient.post(basePath + path, data);
    }

    delete(path, data, options = {}) {
        ApiClient.parseOptions(options);

        return this.httpClient.delete(basePath + path, data);
    }
})();
