<template>
    <cs-modal id="requestModal" ref="modal" size="md" @hidden="onHidden">
        <div v-if="!thankYou" slot="modal-title">
            Request a Call Back
        </div>
        <div v-if="thankYou" slot="modal-title">
            Success
        </div>
        <b-container>
            <div v-if="!thankYou" class="d-flex justify-content-center">
                <form
                    id="request-call-back-form"
                    class="form-wrapper"
                    @submit.prevent="onSubmit"
                    @keydown="form.errors.clear($event.target.name)"
                >
                    <div class="form-row">
                        <div class="form-group col-md-6">
                            <label for="firstName" class="modal-label">
                                First Name*
                            </label>
                            <input
                                id="firstName"
                                v-model="form.firstName"
                                type="text"
                                class="form-control modal-input"
                            />
                            <div class="help-block with-errors text-danger">
                                <p
                                    class="has-error text-danger"
                                    v-text="form.errors.get('firstName')"
                                >
                                    Error Text
                                </p>
                            </div>
                        </div>
                        <div class="form-group col-md-6">
                            <label for="lastName" class="modal-label">
                                Last Name*
                            </label>
                            <input
                                id="lastName"
                                v-model="form.lastName"
                                type="text"
                                class="form-control modal-input"
                            />
                            <div class="help-block with-errors text-danger">
                                <p
                                    class="has-error text-danger"
                                    v-text="form.errors.get('lastName')"
                                >
                                    Error Text
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-12">
                            <label for="email" class="modal-label">
                                Email
                            </label>
                            <input
                                id="email"
                                v-model="form.email"
                                type="text"
                                class="form-control modal-input"
                            />
                            <div class="help-block with-errors text-danger">
                                <p
                                    class="has-error text-danger"
                                    v-text="form.errors.get('email')"
                                >
                                    Error Text
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-md-6">
                            <label for="phoneNumber" class="modal-label">
                                Phone*
                            </label>
                            <the-mask
                                id="phoneNumber"
                                v-model="form.phoneNumber"
                                mask="(###) ###-####"
                                class="form-control modal-input"
                            />
                            <div class="help-block with-errors text-danger">
                                <p
                                    class="has-error text-danger"
                                    v-text="form.errors.get('phoneNumber')"
                                >
                                    Error Text
                                </p>
                            </div>
                        </div>
                        <div class="form-group col-md-6">
                            <label for="zipCode" class="modal-label">
                                Zip Code*
                            </label>
                            <input
                                id="zipCode"
                                v-model="form.zipCode"
                                type="text"
                                class="form-control modal-input"
                            />
                            <div class="help-block with-errors text-danger">
                                <p
                                    class="has-error text-danger"
                                    v-text="form.errors.get('zipCode')"
                                >
                                    Error Text
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-12">
                            <label for="message" class="modal-label">
                                Personal Message
                            </label>
                            <input
                                id="message"
                                v-model="form.message"
                                type="text"
                                class="form-control modal-input"
                            />
                            <div class="help-block with-errors text-danger">
                                <p
                                    class="has-error text-danger"
                                    v-text="form.errors.get('message')"
                                >
                                    Error Text
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="disclaimer py-2">
                        <span>
                            By sending this request you agree to our
                            <a href="/tos">
                                Terms
                            </a>
                            and that you have read our
                            <a href="/privacy">
                                Privacy Policy.
                            </a>
                        </span>
                    </div>
                </form>
            </div>

            <div v-if="thankYou" id="thank-you-msg" class="text-center">
                <div class="d-flex flex-column">
                    <h3>Thank you!</h3>
                    <span
                        >Your request has been sent. You will be contacted
                        soon.</span
                    >
                </div>
            </div>
        </b-container>

        <div slot="modal-footer" class="d-flex justify-content-center w-100">
            <ladda-b-btn
                v-if="!thankYou"
                :loading="submitting"
                variant="orange"
                @click="onSubmit"
            >
                Send Request
            </ladda-b-btn>
            <button
                v-if="thankYou"
                type="button"
                class="btn btn-orange"
                @click="closeModal"
            >
                Close
            </button>
        </div>
    </cs-modal>
</template>

<style lang="scss" scoped>
#requestModal {
    .form-wrapper {
        .form-group {
            margin-bottom: 0;
        }
        .modal-label {
            font-size: 12px;
            font-weight: bold;
            height: 14px;
            color: #888;
            padding: 0;
        }

        .modal-input {
            width: 100%;
            height: 30px;
            -webkit-appearance: none;
            padding: 0 10px;
            background-color: #fff;
            font-size: 14px;
            line-height: 30px;
            color: #888;
            outline: none;
            box-shadow: none;
            border: 1px solid #ddd;
            border-radius: 2px !important;
        }
        .disclaimer {
            font-size: 12px;
            line-height: 14px;
            margin: 0;
        }
    }

    #thank-you-msg {
        h3 {
            color: $primary-blue;
            font-weight: bold;
        }
    }

    .btn-orange {
        width: 180px;
        height: 40px;
    }
}
</style>

<script>
import { get } from "vuex-pathify";
import CSModal from "Components/CSModal";
import LaddaBBtn from "Components/LaddaBBtn";
import api from "@/api";
import _ from "lodash";
import { TheMask } from "vue-the-mask";

class Errors {
    constructor() {
        this.errors = {};
    }

    get(field) {
        if (this.errors[field]) {
            return this.errors[field].message;
        }
    }

    has(field) {
        return Object.prototype.hasOwnProperty.call(this.errors, field);
    }

    any() {
        return Object.keys(this.errors).length > 0;
    }

    record(errors) {
        this.errors = _.mapKeys(errors, "field");
    }

    clear(field) {
        if (field) {
            delete this.errors[field];

            return;
        }

        this.errors = {};
    }
}

class Form {
    constructor(data) {
        this.originalData = data;

        for (const field in data) {
            this[field] = data[field];
        }

        this.errors = new Errors();
    }

    data() {
        const data = {};

        for (const property in this.originalData) {
            data[property] = this[property];
        }

        return data;
    }

    reset() {
        for (const field in this.originalData) {
            this[field] = "";
        }

        this.errors.clear();
    }

    submit(url) {
        return new Promise((resolve, reject) => {
            api.post(url, this.data())
                .then(response => {
                    this.onSuccess(response);

                    resolve(response);
                })
                .catch(error => {
                    this.onFail(error.response.data.errors);

                    reject(error.response.data.errors);
                });
        });
    }

    onSuccess() {
        this.reset();
    }

    onFail(errors) {
        this.errors.record(errors);
    }
}

export default {
    name: "RequestACallBack",

    components: {
        "ladda-b-btn": LaddaBBtn,
        "cs-modal": CSModal,
        TheMask
    },

    data() {
        return {
            form: new Form({
                firstName: null,
                lastName: null,
                email: null,
                phoneNumber: null,
                zipCode: null,
                message: null
            }),
            submitting: false,
            user: null,
            thankYou: false
        };
    },

    computed: {
        loggedInUser: get("user/user")
    },

    watch: {
        loggedInUser(value) {
            this.user = value;
            this.reset();
        }
    },

    beforeMount() {
        if (!_.isNil(this.loggedInUser)) {
            this.user = this.loggedInUser;
            this.reset();
        }
    },

    methods: {
        reset() {
            this.form.reset();
            this.form.firstName = _.get(this.user, "firstName", null);
            this.form.lastName = _.get(this.user, "lastName", null);
            this.form.email = _.get(this.user, "email", null);
            this.form.phoneNumber = _.get(this.user, "phone", null);
            this.form.zipCode = _.get(this.user, "zipCode", null);
        },
        onHidden() {
            this.reset();
            this.thankYou = false;
            this.submitting = false;
        },
        show() {
            this.$refs.modal.show();
        },
        closeModal() {
            this.$refs.modal.hide();
        },
        onSubmit() {
            this.submitting = true;
            const component = this;
            this.form
                .submit("/navigation/request-call-back")
                .then(() => {
                    this.submitting = false;
                    component.thankYou = true;
                })
                .catch(error => {
                    this.submitting = false;
                    console.log(error);
                });

            // Kiosk requires an explicit return of false - even with .prevent on the event.
            return false;
        }
    }
};
</script>
