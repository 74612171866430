import URL from "url-parse";
import { make } from "vuex-pathify";

const state = {
    walmartId: getWalmartId(),
    ambassadorId: getAmbassadorId()
};

/**
 * @desc Searches for URL param and return value
 * @param String Url param search
 * @return String
 */
function getParam(param) {
    const parsed = new URL(location.search, true);

    return parsed.query[param];
}

function removeNonDigits(value) {
    if (typeof value === "string") {
        return value.replace(/\D/g, "");
    }

    return value;
}

function getAmbassadorId() {
    return removeNonDigits(sessionStorage.getItem("ambassadorId"));
}

function getWalmartId() {
    const host = location.host;
    const storeRegex = RegExp("wm[0-9]*", "ig");
    const prefixRegex = RegExp("^[a-z]*", "ig");
    const paramSessionReset = getParam("session_reset");
    let walmartId = "";

    if (storeRegex.test(host)) {
        walmartId = location.host.split(".")[0].replace(prefixRegex, "");
    } else if (paramSessionReset) {
        walmartId = paramSessionReset.replace(prefixRegex, "");
    }

    return walmartId;
}

const mutations = {
    ...make.mutations(state),

    SET_AMBASSADOR_ID: (state, payload) => {
        state.ambassadorId = payload;
    }
};

export default {
    namespaced: true,
    state,
    mutations
};
