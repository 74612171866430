var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: { id: "callMeNowModal", size: "md", "hide-footer": true },
      on: { hidden: _vm.reset, show: _vm.reset },
      model: {
        value: _vm.open,
        callback: function($$v) {
          _vm.open = $$v
        },
        expression: "open"
      }
    },
    [
      _c("div", { attrs: { slot: "modal-title" }, slot: "modal-title" }, [
        _vm._v(
          "\n        " +
            _vm._s(_vm.$t("component.wavpp.callMeNowModal.title")) +
            "\n    "
        )
      ]),
      _vm._v(" "),
      _c(
        "header",
        { attrs: { slot: "modal-header-close" }, slot: "modal-header-close" },
        [
          _c(
            "i",
            {
              staticClass: "ion-ios-close-outline",
              attrs: { "aria-hidden": "true" }
            },
            [_vm._v("\n             \n        ")]
          )
        ]
      ),
      _vm._v(" "),
      _c("b-container", { attrs: { fluid: "" } }, [
        _c(
          "form",
          [
            _c(
              "b-row",
              [
                _c("b-col", [
                  _c(
                    "div",
                    { staticClass: "d-flex flex-column align-items-center" },
                    [
                      _c("span", { staticClass: "message text-center" }, [
                        _vm._v(
                          "\n                            " +
                            _vm._s(
                              _vm.$t("component.wavpp.callMeNowModal.message")
                            ) +
                            "\n                        "
                        )
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "phone-label text-center" }, [
                        _vm._v(
                          "\n                            " +
                            _vm._s(
                              _vm.$t(
                                "component.wavpp.callMeNowModal.pleaseCallAt"
                              )
                            ) +
                            "\n                        "
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "phone-input text-center" },
                        [
                          _c("the-mask", {
                            staticClass: "form-control",
                            attrs: {
                              id: "phoneNumber",
                              mask: "(###) ###-####"
                            },
                            model: {
                              value: _vm.form.phoneNumber,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "phoneNumber", $$v)
                              },
                              expression: "form.phoneNumber"
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass: "has-error text-danger",
                              domProps: {
                                textContent: _vm._s(
                                  _vm.form.errors.get("phoneNumber")
                                )
                              }
                            },
                            [
                              _vm._v(
                                "\n                                Error Text\n                            "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c(
                            "ladda-b-btn",
                            {
                              attrs: {
                                loading: _vm.submitting,
                                variant: "orange"
                              },
                              on: { click: _vm.onSubmit }
                            },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm.$t(
                                      "component.wavpp.callMeNowModal.callMe"
                                    )
                                  ) +
                                  "\n                            "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ]
                  )
                ])
              ],
              1
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }