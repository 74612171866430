<template>
    <facet-template
        :show-clear-link="isFiltered"
        :facet-name="'exteriorColors'"
        @clear="clearSelectedExteriorColors"
    >
        <span slot="facetLabel">
            {{ $t("component.facets.extColor") }}
        </span>

        <div slot="facetBody">
            <div class="container">
                <div class="row">
                    <div class="col-12 p-0 ml-2 mb-0 mt-3">
                        <div class="color-list d-flex flex-row flex-wrap">
                            <color-swatch
                                v-for="color in normalizedExteriorColors"
                                :key="color.name"
                                :color="color"
                                :selected="isExteriorColorSelected(color)"
                                @click.native="toggleExteriorColor(color)"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </facet-template>
</template>

<script>
import FacetTemplate from "./FacetTemplate";
import { sync } from "vuex-pathify";
import _ from "lodash";
import ColorSwatch from "../../../../../components/ColorSwatch";
import mixin from "./mixin";

export default {
    name: "ExteriorColorFacet",
    components: {
        ColorSwatch,
        FacetTemplate
    },
    mixins: [mixin],
    computed: {
        availableExteriorColors: sync("vehicleSearch/facets@exteriorColors"),
        selectedExteriorColors: sync("vehicleSearch/filters@exteriorColors"),
        normalizedExteriorColors() {
            var array = [];
            var colorArray = this.availableExteriorColors.slice();
            var indexPos = colorArray.findIndex(
                value => value.name === "Other"
            );

            if (indexPos > -1) {
                array = colorArray.splice(indexPos, 1);
                colorArray.push(array.pop());
            }

            return colorArray;
        },
        isFiltered() {
            return !_.isEmpty(this.selectedExteriorColors);
        }
    },
    methods: {
        clearSelectedExteriorColors() {
            if (_.size(this.selectedExteriorColors) > 0) {
                this.selectedExteriorColors = [];
            }
        },
        toggleExteriorColor(color) {
            const colorIsAlreadySelected = this.isExteriorColorSelected(color);

            if (colorIsAlreadySelected) {
                this.removeExteriorColorFromFilters(color);
            } else {
                this.addExteriorColorToFilters(color);
            }
        },
        addExteriorColorToFilters(color) {
            this.selectedExteriorColors = this.selectedExteriorColors.concat(
                color.name
            );
        },
        removeExteriorColorFromFilters(color) {
            this.selectedExteriorColors = this.selectedExteriorColors.filter(
                colorName => colorName !== color.name
            );
        },
        isExteriorColorSelected(color) {
            return _.includes(this.selectedExteriorColors, color.name);
        }
    }
};
</script>
