import { render, staticRenderFns } from "./GetStartedBtn.vue?vue&type=template&id=425591a3&scoped=true"
import script from "./GetStartedBtn.vue?vue&type=script&lang=js"
export * from "./GetStartedBtn.vue?vue&type=script&lang=js"
import style0 from "./GetStartedBtn.vue?vue&type=style&index=0&id=425591a3&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/.pnpm/vue-loader@15.11.1_babel-core@6.26.3_css-loader@4.3.0_lodash@4.17.21_prettier@1.19.1_vue-temp_lls4wxv5ipnxzldxaoogv2ybrq/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "425591a3",
  null
  
)

export default component.exports