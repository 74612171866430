var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "color-swatch d-flex flex-column text-center m-2 position-relative align-items-center",
      on: { click: _vm.swatchClick }
    },
    [
      _c("div", { staticClass: "color-container" }, [
        _c("div", {
          staticClass: "color",
          class: [
            { selected: _vm.selected },
            { "g-border": _vm.isColorWhite },
            { "other-svg": _vm.isColorOther },
            { "copper-svg": _vm.isColorCopper },
            { "silver-svg": _vm.isColorSilver }
          ],
          style: { backgroundColor: _vm.getColorCodeFor(_vm.color) },
          attrs: { id: _vm.color.name }
        }),
        _vm._v(" "),
        _vm.selected
          ? _c("i", {
              staticClass: "ion-checkmark-round",
              class: [
                _vm.isColorWhite || _vm.isColorOther || _vm.isColorSilver
                  ? "gray"
                  : "white"
              ],
              attrs: { "aria-hidden": "true" }
            })
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "color-title my-2" }, [
        _vm._v("\n        " + _vm._s(_vm.color.name) + "\n    ")
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }