var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "facet-template",
    {
      attrs: { "show-clear-link": _vm.isFiltered, "facet-name": "makes" },
      on: { clear: _vm.clearSelectedMakes }
    },
    [
      _c(
        "div",
        { attrs: { slot: "facetLabel" }, slot: "facetLabel" },
        [
          _c("b-link", { staticClass: "toggleable-label" }, [
            _vm._v(
              "\n            " +
                _vm._s(_vm.$t("component.facets.make")) +
                "\n            "
            ),
            _c("small", { staticClass: "font-italic font-weight-normal" }, [
              _vm._v(
                "\n                (" +
                  _vm._s(_vm.selectedMakes.length) +
                  " selected)\n            "
              )
            ])
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { attrs: { slot: "facetBody" }, slot: "facetBody" }, [
        _c("div", { staticClass: "container" }, [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { ref: "makeListContainer", staticClass: "col-12 p-0 make-list" },
              _vm._l(_vm.makes, function(make) {
                return _c(
                  "div",
                  {
                    key: make.id,
                    staticClass:
                      "d-flex justify-content-between align-items-center mb-2",
                    staticStyle: { cursor: "pointer" },
                    on: {
                      click: function($event) {
                        return _vm.toggleMakeSelection(make)
                      }
                    }
                  },
                  [
                    _c("make-icon", {
                      attrs: { make: make, selected: _vm.isMakeSelected(make) }
                    })
                  ],
                  1
                )
              }),
              0
            )
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }