<template>
    <facet-template
        :show-clear-link="isFiltered"
        :facet-name="'makes'"
        @clear="clearSelectedMakes"
    >
        <div slot="facetLabel">
            <b-link class="toggleable-label">
                {{ $t("component.facets.make") }}
                <small class="font-italic font-weight-normal">
                    ({{ selectedMakes.length }} selected)
                </small>
            </b-link>
        </div>

        <div slot="facetBody">
            <div class="container">
                <div class="row">
                    <div ref="makeListContainer" class="col-12 p-0 make-list">
                        <div
                            v-for="make in makes"
                            :key="make.id"
                            class="d-flex justify-content-between align-items-center mb-2"
                            style="cursor: pointer;"
                            @click="toggleMakeSelection(make)"
                        >
                            <make-icon
                                :make="make"
                                :selected="isMakeSelected(make)"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </facet-template>
</template>

<script>
import FacetTemplate from "../FacetTemplate";
import MakeIcon from "./MakeIcon";
import { get, sync, call } from "vuex-pathify";
import _ from "lodash";
import mixin from "../mixin";

export default {
    name: "MakeFacet",

    components: {
        MakeIcon,
        FacetTemplate
    },

    mixins: [mixin],

    computed: {
        availableMakes: get("vehicleSearch/facets@makes"),
        selectedMakes: sync("vehicleSearch/filters@makes"),
        collapsed: sync("vehicleSearch/collapsed@makes"),

        makes() {
            return _.orderBy(
                this.availableMakes,
                [make => make.name.toLowerCase()],
                ["asc"]
            );
        },
        isFiltered() {
            return !_.isEmpty(this.selectedMakes);
        }
    },

    methods: {
        clearMake: call("vehicleSearch/clearMake"),
        clearMakes: call("vehicleSearch/clearMakes"),
        clearSelectedMakes() {
            if (this.selectedMakes.length) {
                this.clearMakes();
            }
        },
        toggleMakeSelection(clickedMake) {
            // collection of selected makes is a list of make ids as String
            const makeAlreadySelected = this.isMakeSelected(clickedMake);

            if (!makeAlreadySelected) {
                this.addMakeToFilters(clickedMake.name);
            } else {
                this.removeMakeFromFilters(clickedMake.name);
            }
        },
        isMakeSelected(make) {
            return _.includes(this.selectedMakes, make.name);
        },
        addMakeToFilters(selectedMakeId) {
            this.selectedMakes = this.selectedMakes.concat(selectedMakeId);
        },
        removeMakeFromFilters(selectedMake) {
            this.clearMake(selectedMake);
        }
    }
};
</script>
<style lang="scss" scoped>
.make-list {
    max-height: 310px;
    overflow: scroll;
}
.toggleable-label {
    color: #4a4a4a;
    text-decoration: none;
}
</style>
