<template>
    <div
        id="vehicleContent"
        class="d-none d-lg-flex flex-column justify-content-center align-items-center h-100"
    >
        <b-img
            v-if="vehicle.splashImage"
            :src="vehicle.splashImage"
            :alt="vehicleName"
            fluid
            class="mb-3 vehicle-image"
        />
        <body-style-icon v-else :id="vehicle.bodyStyle" />

        <span>New {{ vehicle.year }} {{ vehicle.make }}</span>
        <span class="trim"> {{ vehicle.model }} {{ vehicle.trim }} </span>
    </div>
</template>
<script>
import BodyStyleIcon from "Modules/ShopBy/BodyStyle/components/BodyStyleIcon/index";
export default {
    name: "VehicleContent",
    components: { BodyStyleIcon },
    props: {
        vehicle: {
            required: true,
            type: Object
        }
    },

    computed: {
        vehicleName() {
            return `${this.vehicle.year} ${this.vehicle.make} ${this.vehicle.model} ${this.vehicle.trim}`;
        }
    }
};
</script>
<style lang="scss">
#vehicleContent {
    .vehicle-image {
        /* IE11 fix */
        min-height: 1px;
    }

    .trim {
        font-size: 16px;
        font-weight: bold;
    }
}
</style>
