import { trackGAEvent } from "@/lib/GoogleTagManager";
import moment from "moment";
import _ from "lodash";
import { purchaseTimelinesEnum } from "@/enum/purchaseTimelinesEnum";

export default {
    name: "purchaseTimelineMixin",
    data() {
        return {
            startDate: null,
            endDate: null,
            purchaseTimeline: purchaseTimelinesEnum
        };
    },
    methods: {
        setPurchaseTimeline(selectedTimeline) {
            console.log("setPurchaseTimeline: ", selectedTimeline);
            const timeline = this.generateTimeFrame(selectedTimeline);
            this.startDate = this.dateFormatter(timeline.start, "YYYY-MM-DD");
            this.endDate = this.dateFormatter(timeline.end, "YYYY-MM-DD");
            trackGAEvent(this, {
                category: "sign-up",
                action: "purchase-timeline",
                label: `${selectedTimeline}`
            });
            if (document.getElementById("purchaseTimeFrameForm")) {
                this.$nextTick().then(() => {
                    document.getElementById("purchaseTimeFrameForm").submit();
                });
            }
        },
        generateTimeFrame(selectedTimeline) {
            const timeline = {
                start: null,
                end: null
            };

            switch (selectedTimeline) {
                case purchaseTimelinesEnum.THIS_WEEK:
                    timeline.start = moment();
                    timeline.end = moment().add(7, "d");
                    break;
                case purchaseTimelinesEnum.THIS_MONTH:
                    timeline.start = moment();
                    timeline.end = moment().add(30, "d");
                    break;
                case purchaseTimelinesEnum.ONE_TO_THREE_MONTHS:
                    timeline.start = moment().add(30, "d");
                    timeline.end = moment().add(90, "d");
                    break;
                case purchaseTimelinesEnum.THREE_TO_SIX_MONTHS:
                    timeline.start = moment().add(90, "d");
                    timeline.end = moment().add(180, "d");
                    break;
                case purchaseTimelinesEnum.UNDECIDED:
                    timeline.start = moment().add(180, "d");
                    break;
                default:
                    timeline.start = null;
                    timeline.end = null;
            }

            return timeline;
        },
        dateFormatter(date, format) {
            if (_.isEmpty(date) || _.isEmpty(format)) {
                return null;
            }

            return moment(date).format(format);
        }
    }
};
