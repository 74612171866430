<template>
    <cs-modal id="forgotPasswordModal" ref="modal" size="md" @hidden="onHidden">
        <div slot="modal-title">
            Reset Password
        </div>
        <b-container>
            <div v-if="!thankYou" class="d-flex justify-content-center">
                <form
                    id="request-call-back-form"
                    class="form-wrapper col-12"
                    @submit.prevent="onSubmit"
                    @keydown="form.errors.clear($event.target.name)"
                >
                    <div class="form-row">
                        <div class="form-group col-12 mt-3">
                            <label for="email" class="modal-label">
                                Enter the
                                <strong>e-mail address</strong> associated with
                                your CarSaver account.
                            </label>
                            <input
                                id="email"
                                v-model="form.email"
                                type="text"
                                class="form-control modal-input"
                                placeholder="E-mail*"
                            />
                            <div class="help-block with-errors text-danger">
                                <p
                                    class="has-error text-danger"
                                    v-text="form.errors.get('email')"
                                >
                                    Error Text
                                </p>
                            </div>
                        </div>
                    </div>
                </form>
            </div>

            <div v-if="thankYou" id="thank-you-msg">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="d-flex flex-column p-2">
                                <h3 class="text-center">
                                    Check Your E-mail!
                                </h3>
                                <span class="my-2">
                                    You will receive an e-mail from us with
                                    instructions for resetting your password.
                                </span>
                                <span class="my-2">
                                    If you don’t receive this e-mail, please
                                    check your junk mail folder or contact your
                                    Personal Shopper at
                                    <a href="tel:8002174800">
                                        (800) 217-4800 </a
                                    >.
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </b-container>

        <div slot="modal-footer" class="d-flex justify-content-center w-100">
            <ladda-b-btn
                v-if="!thankYou"
                :loading="submitting"
                :disabled="isFormInvalid"
                variant="orange"
                @click="onSubmit"
            >
                Continue
            </ladda-b-btn>
            <button
                v-if="thankYou"
                type="button"
                class="btn btn-orange"
                @click="closeModal"
            >
                Close
            </button>
        </div>
    </cs-modal>
</template>

<style lang="scss" scoped>
#forgotPasswordModal {
    .form-wrapper {
        .modal-label {
            font-size: 14px;
            height: 20px;
            color: #888;
            padding: 0;
        }

        .modal-input {
            width: 100%;
            height: 30px;
            -webkit-appearance: none;
            padding: 0 10px;
            background-color: #fff;
            font-size: 14px;
            line-height: 30px;
            color: #888;
            outline: none;
            box-shadow: none;
            border: 1px solid #ddd;
            border-radius: 2px !important;
        }
    }

    #thank-you-msg {
        h3 {
            color: $primary-blue;
            font-weight: bold;
        }
    }

    button {
        width: 180px;
    }
    .disabled {
        cursor: not-allowed;
    }
}
</style>

<script>
import CSModal from "../../components/CSModal";
import LaddaBBtn from "../../components/LaddaBBtn/index";
import api from "../../api";
import _ from "lodash";
import { trackGAEvent, trackPageView } from "../../lib/GoogleTagManager";

class Errors {
    constructor() {
        this.errors = {};
    }

    get(field) {
        if (this.errors[field]) {
            return this.errors[field].defaultMessage;
        }
    }

    has(field) {
        return _.has(this.errors, field);
    }

    any() {
        return Object.keys(this.errors).length > 0;
    }

    record(errors) {
        this.errors = _.mapKeys(errors, "field");
    }

    clear(field) {
        if (field) {
            delete this.errors[field];

            return;
        }

        this.errors = {};
    }
}

class Form {
    constructor(data) {
        this.originalData = data;

        for (const field in data) {
            this[field] = _.trim(data[field]);
        }

        this.errors = new Errors();
    }

    data() {
        const data = {};

        for (const property in this.originalData) {
            data[property] = _.trim(this[property]);
        }

        return data;
    }

    reset() {
        for (const field in this.originalData) {
            this[field] = "";
        }

        this.errors.clear();
    }

    submit(url) {
        return new Promise((resolve, reject) => {
            api.post(url, this.data())
                .then(response => {
                    this.onSuccess(response);

                    resolve(response);
                })
                .catch(error => {
                    this.onFail(error.response.data.errors);

                    reject(error.response.data.errors);
                });
        });
    }

    onSuccess() {
        this.reset();
    }

    onFail(errors) {
        this.errors.record(errors);
    }
}

export default {
    name: "ForgotPassword",

    components: {
        "ladda-b-btn": LaddaBBtn,
        "cs-modal": CSModal
    },

    data() {
        return {
            form: new Form({
                email: null
            }),
            submitting: false,
            thankYou: false
        };
    },

    computed: {
        isFormInvalid() {
            return !this.form.email;
        }
    },

    methods: {
        reset() {
            this.form.reset();
        },
        onHidden() {
            this.reset();
            this.thankYou = false;
            this.submitting = false;
        },
        show() {
            trackPageView(this, "/auth/reset-password");

            this.$refs.modal.show();
        },
        closeModal() {
            this.$refs.modal.hide();
        },
        onSubmit() {
            this.submitting = true;
            const component = this;
            this.form
                .submit("/user/password-reset")
                .then(() => {
                    trackGAEvent(component, {
                        category: "reset-password",
                        action: "submit",
                        label: "Success"
                    });
                    this.submitting = false;
                    component.thankYou = true;
                })
                .catch(error => {
                    trackGAEvent(component, {
                        category: "reset-password",
                        action: "submit",
                        label: "Failure"
                    });
                    this.submitting = false;
                    console.log(error);
                });

            // Kiosk requires an explicit return of false - even with .prevent on the event.
            return false;
        }
    }
};
</script>
