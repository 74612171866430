var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "sticky-footer" }, [
    _c(
      "div",
      {
        staticClass: "position-fixed d-lg-none d-md-block w-100",
        attrs: { id: "floatingFooter", "data-aos": "fade-up" }
      },
      [
        _c(
          "div",
          {
            staticClass:
              "d-flex justify-content-center align-items-center w-100 footer-block"
          },
          [
            _c(
              "a",
              {
                staticClass: "btn btn-primary mx-3 btn-xlg",
                attrs: { href: _vm.url.new }
              },
              [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.$t("page.home.floatingFooter.new")) +
                    "\n            "
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "btn btn-dark-blue mr-3 btn-xlg",
                attrs: { href: _vm.url.used }
              },
              [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.$t("page.home.floatingFooter.used")) +
                    "\n            "
                )
              ]
            )
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }