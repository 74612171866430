import { dispatch } from "vuex-pathify";

export default {
    props: {
        showPaymentTypeFacet: {
            type: Boolean,
            default: true,
            required: false
        },
        showStockTypeFilter: {
            type: Boolean,
            default: false,
            required: false
        },
        showMilesFacet: {
            type: Boolean,
            default: true,
            required: false
        },
        showYearsFacet: {
            type: Boolean,
            default: true,
            required: false
        },
        showExteriorColorFacet: {
            type: Boolean,
            default: true,
            required: false
        },
        showInteriorColorFacet: {
            type: Boolean,
            default: true,
            required: false
        },
        showSortFacet: {
            type: Boolean,
            default: true,
            required: false
        },
        showStockTypeFacet: {
            type: Boolean,
            default: false,
            required: false
        },
        showDealBadgeFacet: {
            type: Boolean,
            default: true,
            required: false
        }
    },

    methods: {
        clearAllSelectedFilters() {
            dispatch("vehicleSearch/clearAllFilters");
        }
    }
};
