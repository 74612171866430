var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "cs-modal",
        {
          ref: "modal",
          staticClass: "modal-body",
          attrs: { id: "signInRequiredModal", "hide-footer": true }
        },
        [
          _c("div", { attrs: { slot: "modal-title" }, slot: "modal-title" }, [
            _vm._v("\n                Sign In\n            ")
          ]),
          _vm._v(" "),
          _c("sign-in-modal-form")
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }