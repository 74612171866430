var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "wrapper", staticClass: "wrapper" },
    [
      _c("mobile-listings-footer", { on: { click: _vm.toggleFiltersModal } }),
      _vm._v(" "),
      _c("filters-modal", {
        attrs: {
          "show-payment-type-facet": _vm.showPaymentType,
          "show-miles-facet": _vm.showMiles,
          "show-years-facet": _vm.showYears,
          "show-stock-type-facet": _vm.showStockType,
          "show-exterior-color-facet": _vm.showExteriorColor,
          "show-interior-color-facet": _vm.showInteriorColor
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }