<template>
    <div
        class="transmission-icon-container d-flex flex-column align-items-center text-center my-4 mx-2 position-relative"
    >
        <div class="icon-container">
            <div class="icon col-12">
                <div v-if="transmissionType === 'MANUAL'">
                    <b-img
                        src="./images/manual.svg"
                        fluid
                        alt="Responsive image"
                    />
                </div>
                <div v-if="transmissionType === 'AUTOMATIC'">
                    <b-img
                        src="./images/automatic.svg"
                        fluid
                        alt="Responsive image"
                    />
                </div>
            </div>
        </div>
        <span :class="[{ selected: selected }]" class="transmission-title">
            {{ transmissionType | titleCase }}
            <i v-if="selected" class="ion-checkmark" aria-hidden="true" />
        </span>
    </div>
</template>

<script>
import _ from "lodash";

export default {
    name: "TransmissionIcon",
    filters: {
        titleCase: function(value) {
            return _.startCase(_.toLower(value));
        }
    },
    props: {
        transmission: {
            type: Object,
            required: true
        },
        selected: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            transmissionType: this.transmission.name
        };
    },
    computed: {}
};
</script>

<style lang="scss">
.transmission-icon-container {
    cursor: pointer;
    .icon {
        width: 75px;
        height: 75px;
        box-sizing: border-box;
        padding: 0;

        svg {
            height: 100%;
        }
    }

    .transmission-title {
        color: $dark-gray;
        margin-top: 10px;
    }
    .selected {
        color: $primary-blue;
        font-weight: bold;
    }
    .ion-checkmark {
        color: $check-mark-active;
    }
}
</style>
