var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("half-circle-spinner", {
    attrs: { "animation-duration": 1000, size: _vm.size, color: "#004C91" }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }