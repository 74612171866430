var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "modal",
      attrs: {
        id: "whatToLookForModal",
        size: "lg",
        title: "What to look for...",
        "body-class":
          "d-flex flex-column justify-content-center align-items-center align-items-md-start",
        "hide-footer": true
      }
    },
    [
      _c(
        "header",
        { attrs: { slot: "modal-header-close" }, slot: "modal-header-close" },
        [
          _c(
            "i",
            {
              staticClass: "ion-ios-close-outline",
              attrs: { "aria-hidden": "true" }
            },
            [_vm._v("\n             \n        ")]
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "b-container",
        { staticClass: "indicator-container" },
        [_c("b-row", [_vm._t("indicator")], 2)],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "close-box w-100 d-flex flex-column align-items-center"
        },
        [
          _c(
            "b-button",
            {
              staticClass: "mb-2",
              attrs: { variant: "orange", size: "lg" },
              on: { click: _vm.close }
            },
            [_vm._v("\n            Close Window\n        ")]
          ),
          _vm._v(" "),
          _vm._t("checkbox")
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }