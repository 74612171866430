<template>
    <div v-if="shouldDisplay" class="container d-flex justify-content-center">
        <div
            class="next-steps-block"
            :class="
                financingAvailable
                    ? 'width-with-finance'
                    : 'width-without-finance'
            "
        >
            <h1 class="next-title text-center">
                {{ $t("component.nextStepsButtons.title") }}
            </h1>
            <div class="d-flex flex-wrap flex-sm-nowrap justify-content-around">
                <div class="mx-2">
                    <b-link
                        class="btn btn-outline-primary square-btn"
                        href="/pick-your-flow"
                    >
                        {{ $t("component.nextStepsButtons.shop") }}
                    </b-link>
                </div>
                <div v-if="financingAvailable" class="mx-2">
                    <b-button
                        variant="outline-primary square-btn"
                        @click="openFinancing"
                    >
                        {{ $t("component.nextStepsButtons.finance") }}
                    </b-button>
                </div>
                <div class="mx-2">
                    <b-link
                        class="btn btn-outline-primary square-btn"
                        @click="openInsurance"
                    >
                        {{ $t("component.nextStepsButtons.insurance") }}
                    </b-link>
                </div>
                <div class="mx-2">
                    <b-button
                        variant="outline-primary square-btn"
                        @click="gotoTradeIn"
                    >
                        {{ $t("component.nextStepsButtons.trade") }}
                    </b-button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import EventBus from "../../event-bus";
import { get } from "vuex-pathify";
import _ from "lodash";

export default {
    name: "NextStepsButtons",
    computed: {
        canFinance: get("vehicleDetails/vehicle@financingAvailable"),
        isFinancingAvailable: get("vehicleDetails/isFinancingAvailable"),
        certificate: get("vehicleDetails/certificate"),
        financingAvailable() {
            return this.canFinance && this.isFinancingAvailable;
        },
        certificateId() {
            return _.get(this.certificate, "id", null);
        },
        shouldDisplay() {
            return !_.isNil(_.get(this.$store, "state.vehicleDetails"));
        }
    },
    methods: {
        openFinancing() {
            EventBus.$emit("financing-modal-open");
        },
        openInsurance() {
            EventBus.$emit("insurance-modal-open");
        },
        gotoTradeIn() {
            if (this.certificateId !== null) {
                window.location = `/certificate/${this.certificateId}/tradein`;
            }
        }
    }
};
</script>
<style lang="scss" scoped>
.width-without-finance {
    max-width: 460px;
}
.width-with-finance {
    max-width: 430px;
}
.next-steps-block {
    background: $very-light-gray;
    padding: 15px;
    border-radius: 2px;

    @include media-breakpoint-up(sm) {
        .next-title {
            font-size: 24px !important;
        }
    }
    .next-title {
        font-size: 20px;
    }
    .square-btn {
        height: 76px !important;
        color: $primary-blue;
        text-align: center;
        font-size: 14px;
        line-height: 15px;
        margin: 5px 0;
        max-width: 135px;

        &:hover {
            color: #fff;
        }
    }
}
</style>
