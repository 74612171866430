import URL from "url-parse";
import _ from "lodash";

export default {
    props: {
        returnUrl: {
            type: String,
            default: null,
            required: false
        }
    },
    data() {
        return {
            url: new URL(window.location.href, true)
        };
    },
    computed: {
        modalToOpen() {
            return this.url.query.modal;
        },
        redirectUrl() {
            if (!_.isNil(this.returnUrl)) {
                return this.returnUrl;
            }

            if (!_.isNil(this.url.query.returnUrl)) {
                return this.url.query.returnUrl;
            }

            return "/";
        }
    }
};
