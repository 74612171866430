<template>
    <div>

        <cs-modal id="signInRequiredModal" ref="modal" :hide-footer=true class="modal-body">
            <div slot="modal-title">
                Sign In
            </div>
            <sign-in-modal-form></sign-in-modal-form>
<!--        SignUpModal story for VDP will go here   -->
        </cs-modal>
    </div>
</template>

<script>
import CSModal from "../CSModal";
import EventBus from "../../event-bus";
import signInMixin from "@/mixins/signInMixin";
import SignInForm from "Modules/Auth/SignIn/components/SignInForm";
import SocialSignin from "Modules/Auth/SignIn/components/SocialSignin";
import SignUpLink from "Modules/Auth/SignIn/components/SignUpLink";
import SignUpModalForm from "Modules/Auth/SignUp/components/SignUpModalForm";
import SignInModalForm from "Modules/Auth/SignIn/SignInModalForm";

export default {
    name: "SignInRequiredModal",

    components: {
        SignInModalForm,
        SignInForm,
        SocialSignin,
        SignUpLink,
        'cs-modal': CSModal
    },
    methods: {
        show() {
            this.$refs.modal.show();
        }
    }
};
</script>

