import _ from "lodash";

const moveModalToBody = {
    mounted() {
        const foundModal = _.find(this.$refs, ref => {
            const tag = _.get(ref, "$vnode.componentOptions.tag");
            return (
                tag === "c-s-modal" || tag === "cs-modal" || tag === "b-modal"
            );
        });

        if (foundModal) {
            document.body.appendChild(foundModal.$el);
        }
    }
};

export default moveModalToBody;
