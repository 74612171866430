<template>
    <b-button
        variant="orange"
        size="lg"
        class="get-started-btn"
        :disabled="loading"
        block
        @click="getStartedHandler"
    >
        See What Your Car Is Worth
        <zipcode-modal ref="zipcodeModal" />
    </b-button>
</template>

<script>
import { get } from "vuex-pathify";
import ZipcodeModal from "Modules/SellYourCar/LandingPage/components/ZipcodeModal";

export default {
    name: "GetStartedBtn",
    components: { ZipcodeModal },
    data() {
        return {
            loading: false,
            zipCode: "",
            isValidZipCode: null
        };
    },
    computed: {
        isStateSycWhitelisted: get("location/isStateSycWhitelisted"),
        isSellYourCarEnabled: get("location/isSellYourCarEnabled"),
        activeDma: get("location/activeDma"),
        activeState: get("location/activeState"),
        carOfferUrl: get("location/urls@carOffer")
    },
    methods: {
        getStartedHandler() {
            if (!this.isSellYourCarEnabled) {
                alert("Sorry but the Program is currently unavailable, please try again at a later time.")
            }
            else if (this.activeState && this.isStateSycWhitelisted) {
                window.location = this.carOfferUrl;
            } else {
                this.$refs.zipcodeModal.show();
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.get-started-btn {
    max-width: 268px;
}
</style>
