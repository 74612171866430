var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex flex-row justify-content-between contact-wrapper" },
    [
      _c("div", { staticClass: "d-flex flex-column mr-auto" }, [
        _c("span", { staticClass: "font-weight-bold" }, [
          _vm._v(
            "\n            " +
              _vm._s(_vm.user.firstName) +
              " " +
              _vm._s(_vm.user.lastName) +
              "\n        "
          )
        ]),
        _vm._v(" "),
        _c("span", [_vm._v(_vm._s(_vm._f("phone")(_vm.user.phoneNumber)))]),
        _vm._v(" "),
        _c("span", { staticClass: "mr-2" }, [
          _vm._v("\n            " + _vm._s(_vm.user.email) + "\n        ")
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "d-flex align-self-center" }, [
        _c(
          "div",
          { attrs: { id: "editContactInfo" } },
          [
            _c(
              "b-button",
              {
                attrs: {
                  id: "editInfoBtn",
                  tabindex: "0",
                  variant: "secondary",
                  size: "sm"
                }
              },
              [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.$t("component.editContactInfo.editBtn")) +
                    "\n            "
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "b-popover",
              {
                ref: "editInfoPopover",
                attrs: {
                  target: "editInfoBtn",
                  triggers: "focus click",
                  placement: "top"
                },
                on: { shown: _vm.popoverShown }
              },
              [
                _c("template", { slot: "title" }, [
                  _c(
                    "div",
                    {
                      staticClass: "d-flex",
                      attrs: { id: "update-contact-info-header" }
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "font-weight-bold py-2 mr-2" },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(
                                _vm.$t(
                                  "component.editContactInfo.updateContactInfo"
                                )
                              ) +
                              "\n                        "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass:
                            "d-flex justify-content-center align-items-center close-icon",
                          on: { click: _vm.closePopup }
                        },
                        [
                          _c(
                            "i",
                            {
                              staticClass: "ion-ios-close-outline",
                              attrs: { "aria-hidden": "true" }
                            },
                            [
                              _vm._v(
                                "\n                                 \n                            "
                              )
                            ]
                          )
                        ]
                      )
                    ]
                  )
                ]),
                _vm._v(" "),
                _c(
                  "b-form",
                  {
                    on: {
                      submit: function($event) {
                        $event.preventDefault()
                        return _vm.onSubmit.apply(null, arguments)
                      }
                    }
                  },
                  [
                    _c(
                      "div",
                      [
                        _c("b-form-input", {
                          staticClass: "mt-2 mb-2",
                          attrs: {
                            id: "phoneNumber",
                            state: !_vm.$v.form.phoneNumber.$invalid,
                            type: "text",
                            "aria-describedby": "phoneInputLiveFeedback",
                            placeholder: "Phone Number"
                          },
                          model: {
                            value: _vm.form.phoneNumber,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "phoneNumber", $$v)
                            },
                            expression: "form.phoneNumber"
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "b-form-invalid-feedback",
                          { attrs: { id: "phoneInputLiveFeedback" } },
                          [
                            _vm._v(
                              "\n                            Please enter a 10 digit phone number.\n                        "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _vm.errors && _vm.errors.has("phoneNumber")
                          ? _c(
                              "small",
                              { staticClass: "error-block text-danger" },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(_vm.errors.get("phoneNumber")) +
                                    "\n                        "
                                )
                              ]
                            )
                          : _vm._e()
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      [
                        _c("b-form-input", {
                          staticClass: "mt-2 mb-2",
                          attrs: {
                            id: "email",
                            state: !_vm.$v.form.email.$invalid,
                            type: "text",
                            "aria-describedby": "emailInputLiveFeedback",
                            placeholder: "E-mail"
                          },
                          model: {
                            value: _vm.form.email,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "email", $$v)
                            },
                            expression: "form.email"
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "b-form-invalid-feedback",
                          { attrs: { id: "emailInputLiveFeedback" } },
                          [
                            _vm._v(
                              "\n                            Please enter a valid e-mail format.\n                        "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _vm.errors && _vm.errors.has("email")
                          ? _c(
                              "small",
                              { staticClass: "error-block text-danger" },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(_vm.errors.get("email")) +
                                    "\n                        "
                                )
                              ]
                            )
                          : _vm._e()
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.hasServerError
                      ? _c("div", [
                          _c(
                            "span",
                            { staticClass: "error-block text-danger" },
                            [
                              _vm._v(
                                "\n                            Unexpected error please try again.\n                        "
                              )
                            ]
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex justify-content-center align-items-center mt-2"
                      },
                      [
                        _c(
                          "ladda-b-btn",
                          {
                            attrs: {
                              loading: _vm.submitting,
                              disabled: _vm.$v.form.$invalid,
                              variant: "primary",
                              type: "submit",
                              size: "sm"
                            }
                          },
                          [
                            _vm._v(
                              "\n                            " +
                                _vm._s(
                                  _vm.$t("component.editContactInfo.save")
                                ) +
                                "\n                        "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ]
                )
              ],
              2
            )
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }