import "element-closest-polyfill";
import smoothscroll from "smoothscroll-polyfill";
import "core-js/features/array/from";
import "core-js/features/string/includes";
import "core-js/features/object/entries";
import "core-js/modules/es.promise";
import "core-js/modules/es.array.iterator";
import chromeOnly from "./chromeOnly";

smoothscroll.polyfill();
chromeOnly();
