import api from "@/api";
import { make } from "vuex-pathify";

const state = {
    dealer: null,
    distance: null,
    isOpen: false,
    isLoading: false,
    imageUrl: null,
    user: null,
    vehicle: null,
    certificateId: null,
    questionType: null
};

const mutations = {
    ...make.mutations(state)
};

const actions = {
    openModal({ commit }, { certificateId, questionType }) {
        commit("SET_IS_LOADING", true);
        commit("SET_IS_OPEN", true);
        commit("SET_CERTIFICATE_ID", certificateId);
        if (questionType) {
            commit("SET_QUESTION_TYPE", questionType);
        } else {
            commit("SET_QUESTION_TYPE", "Vehicle Availability");
        }
        api.get(`/garage/${certificateId}/contact-dealer`)
            .then(response => {
                commit("SET_DEALER", response.data.dealer);
                commit("SET_DISTANCE", response.data.distance);
                commit("SET_IMAGE_URL", response.data.imageUrl);
                commit("SET_USER", response.data.user);
                commit("SET_VEHICLE", response.data.vehicle);
                commit("SET_IS_LOADING", false);
            })
            .catch(error => {
                console.error(error);
                commit("SET_IS_LOADING", false);
                commit("SET_IS_OPEN", false);
            });
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations
};
