var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "c-s-modal",
    {
      ref: "nextStepsModal",
      staticClass: "next-steps-modal",
      attrs: {
        id: "next-steps-modal",
        "hide-footer": true,
        "ok-only": "",
        "ok-title": "Close Window"
      }
    },
    [
      _c("div", { attrs: { slot: "modal-title" }, slot: "modal-title" }, [
        _c("span", [_vm._v(_vm._s(_vm.$t("component.nextStepsModal.title")))])
      ]),
      _vm._v(" "),
      _c(
        "b-container",
        [
          _c("b-row", [
            _c("div", { staticClass: "w-100 pl-3 my-3" }, [
              _c("span", { staticClass: "body-title" }, [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.$t("component.nextStepsModal.bodyTitle")) +
                    "\n                "
                )
              ])
            ])
          ]),
          _vm._v(" "),
          _c("b-row", [_c("next-steps-buttons")], 1)
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }