var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showShopComLinkBack
    ? _c("div", { staticClass: "shop-com-link-back-block" }, [_vm._m(0)])
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass:
          "container-fluid h-100 d-flex justify-content-between align-items-center"
      },
      [
        _c("div", { staticClass: "link-back-container" }, [
          _c(
            "a",
            {
              staticClass: "link-back",
              attrs: {
                href: "https://www.shop.com",
                target: "_blank",
                rel: "noopener noreferrer"
              }
            },
            [_vm._v("\n                < Back to SHOP.COM\n            ")]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "d-none d-lg-inline w-100 text-center" }, [
          _c("span", { attrs: { id: "link-back-message" } }, [
            _vm._v(
              "\n                You are no longer on SHOP.COM. SHOP.COM is a trusted partner\n                that makes auto buying services available to Carsaver\n                customers.\n            "
            )
          ])
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }