<template>
    <div id="filter-by" class="mt-1">
        <b-card no-body>
            <div
                class="filter-header d-flex justify-content-start align-items-center pl-3"
            >
                <h5 slot="header" class="mb-0">
                    {{ $t("component.facets.filter") }}
                </h5>
            </div>

            <b-card-body class="facets p-0">
                <facets
                    :is-loading="isLoading"
                    :show-payment-type-facet="showPaymentTypeFacet"
                    :show-miles-facet="showMilesFacet"
                    :show-years-facet="showYearsFacet"
                    :show-stock-type-facet="showStockTypeFacet"
                    :show-exterior-color-facet="showExteriorColorFacet"
                    :show-interior-color-facet="showInteriorColorFacet"
                    :show-deal-badge-facet="showDealBadgeFacet"
                    :is-dealer-inventory-search="isDealerInventorySearch"
                />
            </b-card-body>
        </b-card>
    </div>
</template>

<script>
import Facets from "../Facets/index";
import mixin from "./mixin";

export default {
    name: "DesktopFilters",

    components: {
        Facets
    },

    mixins: [mixin],

    props: {
        isLoading: {
            type: Boolean,
            required: false,
            default: false
        },
        isDealerInventorySearch: {
            type: Boolean,
            required: false,
            default: false
        }
    }
};
</script>

<style lang="scss">
#filter-by {
    .card {
        border: none;

        .filter-header {
            background-color: $card-background;
            border-radius: 2px 2px 0 0;
            color: #444;
            z-index: 1;
            min-height: 30px;
            margin-bottom: 5px;

            > h5 {
                font-size: px2rem(12);
                font-weight: bold;
            }
        }

        .card-body {
            li {
                border: none;
                padding: 0;
            }
        }
    }
}
</style>
