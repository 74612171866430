var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: { id: "callInQueueModal", size: "md", "hide-footer": true },
      model: {
        value: _vm.open,
        callback: function($$v) {
          _vm.open = $$v
        },
        expression: "open"
      }
    },
    [
      _c("div", { attrs: { slot: "modal-title" }, slot: "modal-title" }, [
        _vm._v(
          "\n        " +
            _vm._s(_vm.$t("component.wavpp.callInQueue.title")) +
            "\n    "
        )
      ]),
      _vm._v(" "),
      _c(
        "header",
        { attrs: { slot: "modal-header-close" }, slot: "modal-header-close" },
        [
          _c(
            "i",
            {
              staticClass: "ion-ios-close-outline",
              attrs: { "aria-hidden": "true" }
            },
            [_vm._v("\n             \n        ")]
          )
        ]
      ),
      _vm._v(" "),
      _c("b-container", { attrs: { fluid: "" } }, [
        _c(
          "form",
          [
            _c(
              "b-row",
              [
                _c("b-col", [
                  _c(
                    "div",
                    { staticClass: "d-flex flex-column align-items-center" },
                    [
                      _c("span", { staticClass: "message text-center" }, [
                        _vm._v(
                          "\n                            " +
                            _vm._s(
                              _vm.$t("component.wavpp.callInQueue.message")
                            ) +
                            "\n                        "
                        )
                      ]),
                      _vm._v(" "),
                      _vm.callTime
                        ? _c("p", { staticClass: "content-box p-3" }, [
                            _vm._v(
                              "\n                            " +
                                _vm._s(
                                  _vm._f("moment")(
                                    _vm.callTime,
                                    "dddd[,] L [at] LT"
                                  )
                                ) +
                                "\n                        "
                            )
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "b-button",
                        {
                          attrs: { variant: "orange" },
                          on: { click: _vm.hide }
                        },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(
                                _vm.$t(
                                  "component.wavpp.callInQueue.closeWindow"
                                )
                              ) +
                              "\n                        "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ])
              ],
              1
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }