<template>
    <layout :breadcrumbs="breadcrumbs">
        <template slot="header">
            <h1 class="text-center">Shop by {{ pageTitle }}</h1>
        </template>

        <b-row id="shop-by-price">
            <b-col cols="12" md="9">
                <h2 class="price-sub-title">
                    What is your price range?
                </h2>
                <!--Desktop Slider-->
                <div
                    class="row d-none d-md-flex justify-content-center align-items-center h-50"
                >
                    <div
                        class="col-12 col-md d-flex justify-content-center justify-content-sm-end"
                    >
                        <span>$0</span>
                    </div>

                    <vue-slider-component
                        ref="priceSlider"
                        v-model="priceRange"
                        class="col-12 col-md-8"
                        v-bind="desktopSliderOptions"
                        @callback="onDragEnd"
                    >
                        <template slot="tooltip" slot-scope="{ index, value }">
                            <div
                                class="diy-tooltip d-flex flex-column justify-content-center align-items-center"
                            >
                                <small v-if="index === 0" class="mb-1">
                                    starting at
                                </small>
                                <small v-if="index === 1" class="mb-1">
                                    up to
                                </small>
                                <div v-if="index === 0 || index === 1">
                                    <div v-if="index === 0" class="price">
                                        ${{ value }}k
                                    </div>
                                    <div v-if="index === 1" class="price">
                                        ${{ value }}k
                                    </div>
                                </div>

                                <div v-else class="merged-price">
                                    <span v-if="value[0] === value[1]">
                                        ${{ value[0] }}k
                                    </span>
                                    <span v-else>
                                        ${{ value[0] }}k - ${{ value[1] }}k
                                    </span>
                                </div>
                            </div>
                        </template>
                    </vue-slider-component>
                    <div
                        class="col-12 col-md d-flex justify-content-center justify-content-sm-start"
                    >
                        <span>$100k +</span>
                    </div>
                </div>
                <!--Mobile Slider-->
                <div
                    class="row d-flex d-md-none justify-content-center align-items-center"
                >
                    <div
                        class="col-12 col-md d-flex justify-content-center justify-content-sm-start m-2"
                    >
                        <span>$100k +</span>
                    </div>
                    <vue-slider-component
                        ref="priceSlider"
                        v-model="priceRange"
                        v-bind="mobileSliderOptions"
                        @callback="onDragEnd"
                    >
                        <template slot="tooltip" slot-scope="{ index, value }">
                            <div
                                class="diy-tooltip d-flex flex-column justify-content-center align-items-center"
                            >
                                <small v-if="index === 0" class="mb-1">
                                    starting at
                                </small>
                                <small v-if="index === 1" class="mb-1">
                                    up to
                                </small>
                                <div v-if="index === 0 || index === 1">
                                    <div v-if="index === 0" class="price">
                                        ${{ value }}k
                                    </div>
                                    <div v-if="index === 1" class="price">
                                        ${{ value }}k
                                    </div>
                                </div>

                                <div v-else class="merged-price">
                                    <span v-if="value[0] === value[1]">
                                        ${{ value[0] }}k
                                    </span>
                                    <span v-else>
                                        ${{ value[0] }}k - ${{ value[1] }}k
                                    </span>
                                </div>
                            </div>
                        </template>
                    </vue-slider-component>
                    <div
                        class="col-12 col-md d-flex justify-content-center justify-content-sm-end m-2"
                    >
                        <span>$0</span>
                    </div>
                </div>
            </b-col>

            <selected-filters :route-to="next" />
        </b-row>
    </layout>
</template>

<script>
import { dispatch, get } from "vuex-pathify";
import _ from "lodash";
import VueSliderComponent from "vue-slider-component";
import SelectedFilters from "../components/SelectedFilters";
import Layout from "../components/Layout";
import dot from "./images/slider-dot.png";

export default {
    components: { Layout, SelectedFilters, VueSliderComponent },

    data() {
        return {
            pageTitle: _.get(this.$route.meta, "pageTitle", "Price"),
            priceRange: [0, 100],
            breadcrumbs: this.$route.meta.breadcrumbs,
            desktopSliderOptions: {
                min: 0,
                max: 100,
                interval: 1,
                dotSize: 40,
                bgStyle: {
                    backgroundColor: "#E6E6E6"
                },
                sliderStyle: {
                    backgroundImage: `url(${dot})`,
                    "background-color": "transparent",
                    "background-size": "cover",
                    "box-shadow": "none",
                    "border-radius": "0"
                },
                tooltipMerge: true
            },
            mobileSliderOptions: {
                min: 0,
                max: 100,
                interval: 1,
                dotSize: 40,
                width: 6,
                height: 320,
                bgStyle: {
                    backgroundColor: "#E6E6E6"
                },
                sliderStyle: {
                    backgroundImage: `url(${dot})`,
                    "background-color": "transparent",
                    "background-size": "cover",
                    "box-shadow": "none",
                    "border-radius": "0"
                },
                tooltipMerge: true,
                direction: "vertical"
            }
        };
    },
    computed: {
        next() {
            return {
                href: _.get(this.$route.meta, "next.href"),
                to: _.get(this.$route.meta, "next.to"),
                modelsPage: _.get(this.$route.meta, "next.modelsPage", false)
            };
        },
        minPrice: get("shopBy/minPriceFilter"),
        maxPrice: get("shopBy/maxPriceFilter")
    },

    watch: {
        minPrice(newVal) {
            if (_.isNil(newVal)) {
                this.$refs.priceSlider.setValue([0, this.priceRange[1]]);
            } else if (newVal.value / 1000 !== this.priceRange[0]) {
                this.$refs.priceSlider.setValue([
                    newVal.value / 1000,
                    this.priceRange[1]
                ]);
            }
        },
        maxPrice(newVal) {
            if (_.isNil(newVal)) {
                this.$refs.priceSlider.setValue([this.priceRange[0], 100]);
            } else if (newVal.value / 1000 !== this.priceRange[1]) {
                this.$refs.priceSlider.setValue([
                    this.priceRange[0],
                    newVal.value / 1000
                ]);
            }
        }
    },

    created() {
        dispatch("shopBy/setSort", "price,asc");
    },

    methods: {
        onDragEnd() {
            const filters = [];

            if (this.priceRange[0] === 0) {
                dispatch("shopBy/removeFilter", "minPrice");
            }

            if (this.priceRange[1] === 100) {
                dispatch("shopBy/removeFilter", "maxPrice");
            }

            if (this.priceRange[0] > 0) {
                filters.push({
                    name: "minPrice",
                    value: this.priceRange[0] * 1000
                });
            }

            if (this.priceRange[1] < 100) {
                filters.push({
                    name: "maxPrice",
                    value: this.priceRange[1] * 1000
                });
            }

            if (filters.length > 0) {
                dispatch("shopBy/addFilters", filters);
            }
        }
    }
};
</script>
<style lang="scss" scoped>
#shop-by-price {
    .price-title {
        font-size: px2rem(28);
        color: $dark-gray;
        line-height: 25px;
        padding: 45px 0 25px 0;
    }

    .price-sub-title {
        font-size: px2rem(24);
        padding: 35px 0 35px 35px;
    }

    .price {
        font-size: px2rem(16);
        border: 1px solid $primary-blue;
        border-radius: 20px;
        background-color: $very-light-gray;
        padding: 3px 20px;
    }

    .price:before {
        content: "";
        position: absolute;
        bottom: -10px;
        left: 50%;
        width: 0;
        height: 0;
        border: 5px solid transparent;
        border-top-color: inherit;
        transform: translate(-50%);
    }

    .merged-price {
        font-size: px2rem(16);
        border: 1px solid $primary-blue;
        border-radius: 20px;
        background-color: $very-light-gray;
        min-width: 120px;
        min-height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .merged-price:before {
        content: "";
        position: absolute;
        bottom: -10px;
        left: 50%;
        width: 0;
        height: 0;
        border: 5px solid transparent;
        border-top-color: inherit;
        transform: translate(-50%);
    }

    @include media-breakpoint-down(sm) {
        .price:before {
            bottom: unset !important;
            left: unset !important;
            top: 30px !important;
            right: -9px !important;
            transform: rotate(-90deg) !important;
        }

        .price-sub-title {
            padding: 0;
        }

        .merged-price:before {
            bottom: unset !important;
            left: unset !important;
            top: 11px !important;
            right: -9px !important;
            transform: rotate(-90deg) !important;
        }
    }

    @include media-breakpoint-down(xs) {
        /* vendor override*/
        .selected-filters {
            .filter-box {
                min-height: 0;
            }
        }
    }
}
</style>
