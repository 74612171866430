var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "session-reset-button-wrapper" } },
    [
      _c(
        "b-link",
        {
          on: {
            click: function($event) {
              return _vm.clickHandler()
            }
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "d-flex justify-content-center align-items-center",
              style: _vm.pinStyle,
              attrs: { id: "session-reset-button" }
            },
            [
              _vm.ambassadorId === null
                ? _c("i", {
                    staticClass: "ion-android-sync",
                    attrs: { "aria-hidden": "true" }
                  })
                : _c("span", [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.ambassadorId) +
                        "\n            "
                    )
                  ])
            ]
          )
        ]
      ),
      _vm._v(" "),
      _c("session-reset-modal")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }