const dmas = {
    abileneSweetwater: 662,
    albanyGa: 525,
    atlanta: 524,
    augusta: 520,
    austin: 635,
    bakersfield: 800,
    beaumontPortArthur: 692,
    chicago: 602,
    charlestonSc: 519,
    charlotte: 517,
    columbusGa: 522,
    columbiaSc: 546,
    corpusChristi: 600,
    dallasFtWorth: 623,
    dc: 511,
    fresno: 866,
    ftMyers: 571,
    greensboroHighPointWinstonSalem: 518,
    greenvilleSpartanburgAshevilleAnderson: 567,
    gainesville: 592,
    harlingenWeslacoBrownsvilleMcallen: 636,
    houston: 618,
    jacksonville: 561,
    lasVegas: 839,
    losAngeles: 803,
    lubbock: 651,
    macon: 503,
    miami: 528,
    myrtleBeachFlorence: 570,
    orlando: 534,
    palmSprings: 804,
    panamaCity: 656,
    phoenix: 753,
    sanAntonio: 641,
    sanAngelo: 661,
    sanDiego: 825,
    santaBarbara: 855,
    savannah: 507,
    shreveport: 612,
    tallahassee: 530,
    tampa: 539,
    triCitiesTnVa: 531,
    tylerLongview: 709,
    victoria: 626,
    waco: 625,
    westPalmBeach: 548,
    yuma: 771
};

const sycDmaWhiteList = [
    dmas.abileneSweetwater,
    dmas.albanyGa,
    dmas.atlanta,
    dmas.augusta,
    dmas.austin,
    dmas.bakersfield,
    dmas.beaumontPortArthur,
    dmas.chicago,
    dmas.charlestonSc,
    dmas.charlotte,
    dmas.columbiaSc,
    dmas.columbusGa,
    dmas.corpusChristi,
    dmas.dallasFtWorth,
    dmas.dc,
    dmas.fresno,
    dmas.ftMyers,
    dmas.greensboroHighPointWinstonSalem,
    dmas.greenvilleSpartanburgAshevilleAnderson,
    dmas.gainesville,
    dmas.harlingenWeslacoBrownsvilleMcallen,
    dmas.houston,
    dmas.jacksonville,
    dmas.lasVegas,
    dmas.losAngeles,
    dmas.lubbock,
    dmas.macon,
    dmas.miami,
    dmas.myrtleBeachFlorence,
    dmas.orlando,
    dmas.palmSprings,
    dmas.panamaCity,
    dmas.phoenix,
    dmas.sanAntonio,
    dmas.sanAngelo,
    dmas.sanDiego,
    dmas.santaBarbara,
    dmas.savannah,
    dmas.shreveport,
    dmas.tallahassee,
    dmas.tampa,
    dmas.triCitiesTnVa,
    dmas.tylerLongview,
    dmas.victoria,
    dmas.waco,
    dmas.westPalmBeach,
    dmas.yuma
];

export { dmas, sycDmaWhiteList };
