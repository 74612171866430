var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "facet-template",
    {
      attrs: { "show-clear-link": _vm.isFiltered, "facet-name": "models" },
      on: { clear: _vm.clearSelectedModels }
    },
    [
      _c("span", { attrs: { slot: "facetLabel" }, slot: "facetLabel" }, [
        _vm._v("\n        Model\n        "),
        _c("small", { staticClass: "font-italic font-weight-normal" }, [
          _vm._v(
            "\n            (" +
              _vm._s(_vm.selectedModels.length) +
              " selected)\n        "
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", { attrs: { slot: "facetBody" }, slot: "facetBody" }, [
        _c(
          "div",
          {
            ref: "modelListContainer",
            staticClass: "container model-list",
            class: [{ expanded: _vm.modelSelectorExpanded }]
          },
          _vm._l(_vm.makeGroups, function(make) {
            return _c("div", { key: make.name, staticClass: "row" }, [
              _c(
                "div",
                {
                  staticClass:
                    "col-12 make-divider d-flex align-items-center pl-3"
                },
                [
                  _c("img", {
                    staticClass: "make-logo mr-1",
                    attrs: { src: _vm.getLogoForMake(make), alt: "logo" }
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "make-name" }, [
                    _vm._v(
                      "\n                        " +
                        _vm._s(make.name) +
                        "\n                    "
                    )
                  ])
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-sm-12 p-0 mb-1" },
                _vm._l(make.models, function(model) {
                  return _c(
                    "ul",
                    { key: model.id, staticClass: "model-for-makes my-2" },
                    [
                      _c("li", { staticClass: "model-name" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.selectedModels,
                              expression: "selectedModels"
                            }
                          ],
                          staticClass: "mb-0",
                          attrs: {
                            id: "model-selector-" + model.name,
                            type: "checkbox"
                          },
                          domProps: {
                            value: model.name,
                            checked: Array.isArray(_vm.selectedModels)
                              ? _vm._i(_vm.selectedModels, model.name) > -1
                              : _vm.selectedModels
                          },
                          on: {
                            input: _vm.modelChange,
                            change: function($event) {
                              var $$a = _vm.selectedModels,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = model.name,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    (_vm.selectedModels = $$a.concat([$$v]))
                                } else {
                                  $$i > -1 &&
                                    (_vm.selectedModels = $$a
                                      .slice(0, $$i)
                                      .concat($$a.slice($$i + 1)))
                                }
                              } else {
                                _vm.selectedModels = $$c
                              }
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "label",
                          {
                            staticClass: "mb-0 hotspot",
                            staticStyle: { cursor: "pointer" },
                            attrs: { for: "model-selector-" + model.name }
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(model.name) +
                                "\n                            "
                            )
                          ]
                        )
                      ])
                    ]
                  )
                }),
                0
              )
            ])
          }),
          0
        ),
        _vm._v(" "),
        _vm.revealShowMoreToggle
          ? _c(
              "div",
              { staticClass: "col-12 p-2 text-center" },
              [
                !_vm.modelSelectorExpanded
                  ? _c(
                      "b-link",
                      {
                        on: {
                          click: function($event) {
                            return _vm.toggleModelListContainer()
                          }
                        }
                      },
                      [_vm._v("\n                Show More\n            ")]
                    )
                  : _c(
                      "b-link",
                      {
                        on: {
                          click: function($event) {
                            return _vm.toggleModelListContainer()
                          }
                        }
                      },
                      [_vm._v("\n                Show Less\n            ")]
                    )
              ],
              1
            )
          : _vm._e()
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }