<template>
    <b-modal
        id="callMeNowModal"
        v-model="open"
        size="md"
        :hide-footer="true"
        @hidden="reset"
        @show="reset"
    >
        <div slot="modal-title">
            {{ $t("component.wavpp.callMeNowModal.title") }}
        </div>
        <header slot="modal-header-close">
            <i aria-hidden="true" class="ion-ios-close-outline">
                &nbsp;
            </i>
        </header>
        <b-container fluid>
            <form>
                <b-row>
                    <b-col>
                        <div class="d-flex flex-column align-items-center">
                            <span class="message text-center">
                                {{
                                    $t("component.wavpp.callMeNowModal.message")
                                }}
                            </span>
                            <span class="phone-label text-center">
                                {{
                                    $t(
                                        "component.wavpp.callMeNowModal.pleaseCallAt"
                                    )
                                }}
                            </span>

                            <div class="phone-input text-center">
                                <the-mask
                                    id="phoneNumber"
                                    v-model="form.phoneNumber"
                                    class="form-control"
                                    :mask="'(###) ###-####'"
                                />
                                <span
                                    class="has-error text-danger"
                                    v-text="form.errors.get('phoneNumber')"
                                >
                                    Error Text
                                </span>
                            </div>
                            <div>
                                <ladda-b-btn
                                    :loading="submitting"
                                    variant="orange"
                                    @click="onSubmit"
                                >
                                    {{
                                        $t(
                                            "component.wavpp.callMeNowModal.callMe"
                                        )
                                    }}
                                </ladda-b-btn>
                            </div>
                        </div>
                    </b-col>
                </b-row>
            </form>
        </b-container>
    </b-modal>
</template>

<script>
import LaddaBBtn from "Components/LaddaBBtn/index";
import { dispatch, get, sync } from "vuex-pathify";
import Form from "../../../api/form";
import { TheMask } from "vue-the-mask";
import _ from "lodash";

export default {
    name: "CallMeNowModal",
    components: { LaddaBBtn, TheMask },
    data() {
        return {
            form: new Form({
                phoneNumber: null
            }),
            submitting: false
        };
    },
    computed: {
        open: sync("supplierPricing/callMeNowModal"),
        loggedInUser: get("user/user")
    },
    methods: {
        reset() {
            this.submitting = false;
            this.form.reset();
            this.form.phoneNumber = _.get(
                this.loggedInUser,
                "phoneNumber",
                null
            );
        },
        onSubmit() {
            this.submitting = true;

            const certificateId = _.get(
                this.$store,
                "state.vehicleDetails.certificate.id",
                null
            );

            dispatch("supplierPricing/addCallNowQueue", {
                phoneNumber: this.form.phoneNumber,
                certificateId
            });

            // Kiosk requires an explicit return of false - even with .prevent on the event.
            return false;
        }
    }
};
</script>
<style lang="scss">
#callMeNowModal {
    .modal-header {
        height: 95px;
    }

    @include media-breakpoint-up(md) {
        .modal-title {
            padding-left: 140px !important;
        }
    }

    .modal-title {
        padding-left: 70px;
    }

    .message {
        font-family: $helvetica-45-light;
        font-size: 14px;
        color: $medium-gray;
        margin-bottom: 15px;
        max-width: 390px;
    }

    .phone-label {
        color: $medium-gray;
        font-size: 12px;
        margin-bottom: 5px;
    }

    .phone-input {
        margin-bottom: 25px;
    }

    .btn-orange {
        font-size: 14px;
        width: 195px;
        margin-bottom: 30px;
        font-family: $helvetica-55-Roman;
    }

    .modal-body {
        padding-top: 0;
        padding-bottom: 25px;
    }
}
</style>
