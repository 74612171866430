<template>
    <facet-template
        :show-clear-link="isFiltered"
        :facet-name="'price'"
        @clear="clearSelectedPrices"
    >
        <span slot="facetLabel">
            {{ $t("component.facets.price") }}
        </span>
        <div slot="facetBody" class="d-flex justify-content-between">
            <div class="left-select">
                <label for="minimumPrice">
                    starting at
                </label>
                <select
                    id="minimumPrice"
                    v-model="selectedMinPrice"
                    class="form-control-sm custom-select w-100"
                    name="minimumPrice"
                >
                    <option :value="null">
                        {{ $t("component.facets.any") }}
                    </option>
                    <option
                        v-for="price in availableMinPrices"
                        :key="price"
                        :value="price"
                    >
                        {{ price | numeral("$0,0") }}
                    </option>
                </select>
            </div>

            <div class="right-select">
                <label for="maximumPrice">
                    up to
                </label>
                <select
                    id="maximumPrice"
                    v-model="selectedMaxPrice"
                    class="form-control-sm custom-select w-100"
                    name="maximumPrice"
                >
                    <option :value="null">
                        {{ $t("component.facets.any") }}
                    </option>
                    <option
                        v-for="price in availableMaxPrices"
                        :key="price"
                        :value="price"
                    >
                        {{ price | numeral("$0,0") }}
                    </option>
                </select>
            </div>
        </div>
    </facet-template>
</template>

<script>
import FacetTemplate from "./FacetTemplate";
import { get, sync, dispatch } from "vuex-pathify";
import _ from "lodash";

export default {
    name: "PriceFacet",
    components: {
        FacetTemplate
    },
    props: {
        priceStep: {
            type: Number,
            default: 1000
        }
    },

    data() {
        return {
            maxPriceAllowed: 175000
        };
    },

    computed: {
        availablePriceRange: get("vehicleSearch/facets@priceRange"),
        selectedMinPrice: sync("vehicleSearch/filters@minPrice"),
        selectedMaxPrice: sync("vehicleSearch/filters@maxPrice"),
        availableMinPrices() {
            const start = _.get(this.availablePriceRange, "minimum", 0);
            let end = _.get(
                this.availablePriceRange,
                "maximum",
                this.maxPriceAllowed
            );

            end = end > this.maxPriceAllowed ? this.maxPriceAllowed : end;

            // a maximum price has been selected
            if (!_.isNil(this.selectedMaxPrice)) {
                end = _.toInteger(this.selectedMaxPrice) - this.priceStep;
            }

            return this.generateAvailablePrices(start, end);
        },
        availableMaxPrices() {
            let start = _.get(this.availablePriceRange, "minimum", 0);
            let end = _.get(
                this.availablePriceRange,
                "maximum",
                this.maxPriceAllowed
            );

            end = end > this.maxPriceAllowed ? this.maxPriceAllowed : end;

            // a minimum price has been selected
            if (!_.isNil(this.selectedMinPrice)) {
                start = _.toInteger(this.selectedMinPrice) + this.priceStep;
            }

            return this.generateAvailablePrices(start, end);
        },
        isFiltered() {
            return (
                !_.isNil(this.selectedMinPrice) ||
                !_.isNil(this.selectedMaxPrice)
            );
        }
    },
    watch: {
        availablePriceRange: function(newPriceRange) {
            if (
                !_.isNil(this.selectedMinPrice) &&
                newPriceRange.minimum > this.selectedMinPrice
            ) {
                this.selectedMinPrice = newPriceRange.minimum;
            }

            if (
                !_.isNil(this.selectedMaxPrice) &&
                newPriceRange.maximum < this.selectedMaxPrice
            ) {
                this.selectedMaxPrice = newPriceRange.maximum;
            }
        }
    },
    methods: {
        clearSelectedPrices() {
            dispatch("vehicleSearch/clearPrice");
        },
        generateAvailablePrices(start, end) {
            // 0 will be covered by "Any" option
            if (start === 0) {
                start = this.priceStep;
            }

            const availablePrices =
                start !== end ? _.range(start, end, this.priceStep) : [start];
            const maxPriceAdded = _.max(availablePrices);
            if (maxPriceAdded < end) {
                availablePrices.push(end);
            }
            return availablePrices;
        }
    }
};
</script>
<style lang="scss" scoped></style>
