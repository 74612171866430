var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "csmodal",
      attrs: {
        id: _vm.id,
        "modal-class": _vm.modalClass,
        size: _vm.size,
        "body-class": _vm.bodyClass,
        "footer-class": _vm.footerClass,
        lazy: _vm.lazy,
        "hide-footer": _vm.hideFooter,
        "hide-header": _vm.hideHeader,
        centered: _vm.centered
      },
      on: { hidden: _vm.onHidden, shown: _vm.onShown }
    },
    [
      _vm._t(
        "modal-title",
        function() {
          return [
            _vm._v("\n        You should be overriding this modal title.\n    ")
          ]
        },
        { slot: "modal-title" }
      ),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { slot: "modal-header-close" }, slot: "modal-header-close" },
        [
          _c("i", {
            staticClass: "ion-ios-close-outline",
            attrs: { "aria-hidden": "true" }
          })
        ]
      ),
      _vm._v(" "),
      _vm._t("default", function() {
        return [_vm._v("You should be overriding this modal body.")]
      }),
      _vm._v(" "),
      _vm._t(
        "modal-footer",
        function() {
          return [
            _vm._v(
              '\n        You should be overriding this modal footer, if you want to hide the\n        footer use the class: (hide-footer="true")\n    '
            )
          ]
        },
        { slot: "modal-footer" }
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }