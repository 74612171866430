<template>
    <facet-template
        v-if="notEmpty"
        :show-clear-link="isFiltered"
        :facet-name="'specialPrograms'"
        @clear="clearSelectedPrograms"
    >
        <span slot="facetLabel">
            {{ $t("component.facets.specialPrograms") }}
        </span>

        <div slot="facetBody">
            <div class="container">
                <div class="row">
                    <div class="col-12 p-0">
                        <b-form-group class="m-0 check-type">
                            <b-form-checkbox
                                v-if="certifiedDealerFacet"
                                v-model="certifiedDealerFilter"
                                :unchecked-value="null"
                                class="mb-2"
                            >
                                CarSaver Certified
                            </b-form-checkbox>
                            <b-form-checkbox
                                v-if="homeDeliveryFacet"
                                v-model="homeDeliveryFilter"
                                :unchecked-value="null"
                                class="mb-2"
                            >
                                Home Delivery
                            </b-form-checkbox>
                            <b-form-checkbox
                                v-if="localDealerFacet"
                                :checked="localDealerFilter"
                                :unchecked-value="null"
                                class="mb-2"
                                @change="localDealerChange"
                            >
                                Local Dealer
                            </b-form-checkbox>
                            <b-form-checkbox
                                v-if="supplierPricingFacet"
                                v-model="supplierPricingFilter"
                                :unchecked-value="null"
                                class="mb-2"
                            >
                                Vehicles with Supplier Pricing
                            </b-form-checkbox>
                        </b-form-group>
                    </div>
                </div>
            </div>
        </div>
    </facet-template>
</template>

<script>
import FacetTemplate from "./FacetTemplate";
import { get, sync, call } from "vuex-pathify";

export default {
    name: "SpecialProgramsFacet",
    components: {
        FacetTemplate
    },

    data() {
        return {
            localDealer: null
        };
    },

    computed: {
        sort: sync("vehicleSearch/pageable@sort"),
        certifiedDealerFacet: get("vehicleSearch/facets@certifiedDealer"),
        homeDeliveryFacet: get("vehicleSearch/facets@homeDelivery"),
        localDealerFacet: get("vehicleSearch/facets@localDealer"),
        supplierPricingFacet: get("vehicleSearch/facets@supplierPricing"),
        certifiedDealerFilter: sync("vehicleSearch/filters@certifiedDealer"),
        homeDeliveryFilter: sync("vehicleSearch/filters@homeDelivery"),
        localDealerFilter: get("vehicleSearch/filters@localDealer"),
        supplierPricingFilter: sync("vehicleSearch/filters@supplierPricing"),
        notEmpty() {
            return (
                this.certifiedDealerFacet === true ||
                this.homeDeliveryFacet === true ||
                this.localDealerFacet === true ||
                this.supplierPricingFacet === true
            );
        },

        isFiltered() {
            return (
                this.certifiedDealerFilter === true ||
                this.homeDeliveryFilter === true ||
                this.localDealerFilter === true ||
                this.supplierPricingFilter === true
            );
        }
    },

    mounted() {
        this.localDealer = this.localDealerFilter;
    },

    methods: {
        localDealerChange: call("vehicleSearch/localDealerChange"),
        clearLocalDealer: call("vehicleSearch/clearLocalDealer"),
        clearSelectedPrograms() {
            this.certifiedDealerFilter = null;
            this.homeDeliveryFilter = null;
            this.clearLocalDealer();
            this.supplierPricingFilter = null;
        }
    }
};
</script>
