import { make } from "vuex-pathify";
import api from "@/api";
import _ from "lodash";
import buildUrl from "build-url";

const findFilter = (name, selectedFilters) => {
    return _.chain(selectedFilters)
        .filter(filter => {
            return filter.name === name;
        })
        .head()
        .value();
};

const initialState = {
    loading: false,
    selectedFilters: [],
    selectedFiltersSort: null,
    styles: [],
    modelBaseStyles: [],
    makes: [],
    sortMakesBy: "asc",
    compareSelectedTrims: [],
    selectedYear: null,
    bodyStyles: [
        { id: "convertible", name: "Convertibles" },
        { id: "coupe", name: "Coupes" },
        { id: "hatchback", name: "Hatchbacks" },
        { id: "sedan", name: "Sedans" },
        { id: "suv", name: "SUVs" },
        { id: "truck", name: "Trucks" },
        { id: "van", name: "Vans" },
        { id: "wagon", name: "Wagons" }
    ],
    modelCountNationwide: 0
};

const getters = {
    ...make.getters(initialState),
    paymentTypeFilter: state => {
        return findFilter("paymentType", state.selectedFilters);
    },
    minPriceFilter: state => {
        return findFilter("minPrice", state.selectedFilters);
    },
    maxPriceFilter: state => {
        return findFilter("maxPrice", state.selectedFilters);
    },
    minPaymentFilter: state => {
        return findFilter("minPayment", state.selectedFilters);
    },
    maxPaymentFilter: state => {
        return findFilter("maxPayment", state.selectedFilters);
    },
    hasFilters: state => {
        return _.size(state.selectedFilters) > 0;
    },
    selectedMakeName: state => {
        const filter = findFilter("makes", state.selectedFilters);
        if (_.isNil(filter)) {
            return null;
        }

        return _.get(filter, "value");
    },
    selectedMake: (state, getters) => {
        const makeName = getters.selectedMakeName;

        return _.find(state.makes, ["name", makeName]);
    },
    selectedBodyStyles: state => {
        const filter = findFilter("bodyStyles", state.selectedFilters);

        if (_.isNil(filter)) {
            return [];
        }

        return _.get(filter, "value");
    },
    groupedOptions: state => styleId => {
        const style = _.find(state.styles, { id: styleId });
        if (_.isNil(style)) {
            return {};
        }

        return _.groupBy(style.standards, "header");
    },
    filterQuery: state => {
        const baseUrl = "/";
        const params = {
            queryParams: normSelectedFilters(
                state.selectedFilters,
                state.selectedFiltersSort
            )
        };

        return buildUrl(baseUrl, params);
    }
};

const mutations = {
    ...make.mutations(initialState)
};

const actions = {
    ...make.actions(initialState),

    getMakes({ commit, state }) {
        commit("SET_LOADING", true);
        return api
            .get("/vehicle-data/makes/latest")
            .then(response => {
                commit(
                    "SET_MAKES",
                    sortMakes(response.data, state.sortMakesBy)
                );
                commit("SET_LOADING", false);
            })
            .catch(error => {
                console.error("Error fetching makes", error);
                commit("SET_LOADING", false);
            });
    },

    getStylesByMakeName({ commit }, { makeName, year }) {
        commit("SET_LOADING", true);
        commit("SET_MODEL_BASE_STYLES", []);
        api.get("/styles/search/latestByMakeName", { makeName, year })
            .then(response => {
                commit("SET_MODEL_BASE_STYLES", response.data);
                commit("SET_LOADING", false);
            })
            .catch(error => {
                console.error(
                    `Error fetching styles by make name ${makeName}`,
                    error
                );
                commit("SET_LOADING", false);
            });
    },

    getStylesByModelId({ commit, rootState }, modelId) {
        commit("SET_LOADING", true);
        const zipCode = _.get(rootState, "location.selectedLocation.zipCode");
        api.get("/styles/search/byModelId", { modelId, zipCode })
            .then(response => {
                commit("SET_STYLES", response.data);

                commit("SET_LOADING", false);
            })
            .catch(error => {
                console.error(
                    `Error fetching styles by model id ${modelId}`,
                    error
                );
                commit("SET_LOADING", false);
            });
    },

    getModelCountNationwide({ commit, rootState }, { bodyStyle, modelId }) {
        const zipCode = _.get(rootState, "location.selectedLocation.zipCode");
        api.get("/styles/search/countByModelIdNationwide", {
            bodyStyle,
            modelId,
            zipCode
        })
            .then(response => {
                commit("SET_MODEL_COUNT_NATIONWIDE", response.data);
            })
            .catch(error => {
                console.error(error);
            });
    },

    setSort({ commit }, sort) {
        commit("SET_SELECTED_FILTERS_SORT", sort);
    },

    addFilters({ commit, state }, filters) {
        const newFilters = _.unionBy(filters, state.selectedFilters, "name");

        commit("SET_SELECTED_FILTERS", newFilters);
    },

    addFilter({ commit, state }, filter) {
        const newFilters = _.unionBy([filter], state.selectedFilters, "name");

        commit("SET_SELECTED_FILTERS", newFilters);
    },

    removeFilter({ commit, state }, filterName) {
        let newFilters = [];

        if (typeof filterName === "object") {
            newFilters = handleRemoveFilterObj(state, filterName);
        } else {
            newFilters = _.filter(state.selectedFilters, filter => {
                return filter.name !== filterName;
            });
        }

        commit("SET_SELECTED_FILTERS", newFilters);
    },

    removeFilters({ commit, state }, filters) {
        const newFilters = _.difference(state.selectedFilters, filters);

        commit("SET_SELECTED_FILTERS", newFilters);
    },

    clearAllFilters({ commit }) {
        commit("SET_SELECTED_FILTERS", []);
    }
};

const normSelectedFilters = (filters, sort) => {
    const normFilters = {};

    filters.forEach(function(filter) {
        normFilters[filter.name] = filter.value;
    });

    // Add sort
    if (!_.isNil(sort)) {
        normFilters.sort = sort;
    }

    return normFilters;
};

const handleRemoveFilterObj = (state, obj) => {
    const filterName = _.get(obj, "name");
    const newFilters = _.cloneDeep(state.selectedFilters);
    let index = null;
    const newBodyStyles = {
        name: "bodyStyles",
        value: []
    };

    // bodystyles
    if (filterName === newBodyStyles.name) {
        index = _.findIndex(newFilters, function(filter) {
            return filter.name === newBodyStyles.name;
        });

        if (index !== -1) {
            _.pull(newFilters[index].value, obj.value);

            if (newFilters[index].value.length === 0) {
                _.remove(newFilters, function(filter) {
                    return filter.name === newBodyStyles.name;
                });
            }
        }
    }

    return newFilters;
};

function sortMakes(makes, order) {
    const sortOrder = order || "asc";

    return _.orderBy(
        makes,
        [
            function(a) {
                return a.name.toLowerCase();
            }
        ],
        [sortOrder]
    );
}

export default {
    namespaced: true,
    state: initialState,
    mutations,
    actions,
    getters
};
