<template>
    <div v-if="facets" id="facets">
        <div v-if="isLoading" class="loader-blocker" />

        <distance-and-zip-selector v-if="!isDealerInventorySearch" />

        <selected-filters />

        <make-facet v-if="hasMakes" />

        <model-facet v-if="hasModels" />

        <trim-facet v-if="hasTrims" />

        <body-style-facet v-if="hasBodyStyles" />

        <year-facet v-if="showYearsFacet" />

        <price-facet />

        <miles-facet :show="showMilesFacet" />

        <exterior-color-facet
            v-if="showExteriorColorFacet && hasExteriorColors"
        />

        <interior-color-facet
            v-if="showInteriorColorFacet && hasInteriorColors"
        />

        <monthly-payment-facet
            :show-payment-type-facet="showPaymentTypeFacet"
        />

        <transmission-facet v-if="hasTransmissions" />

        <engine-type-facet v-if="hasEngineTypes" />

        <drive-type-facet v-if="hasDriveTypes" />

        <fuel-type-facet v-if="hasFuelTypes" />

        <passenger-capacity-facet v-if="hasPassengerCapacities" />

        <vehicle-category-facet />

        <special-programs-facet v-if="hasSpecialPrograms" />

        <deal-badge-facet v-if="showDealBadgeFacet && hasDealBadges" />

        <stock-type-facet v-if="showStockTypeFacet" />
    </div>
</template>

<script>
import { get } from "vuex-pathify";
import PriceFacet from "./components/PriceFacet";
import YearFacet from "./components/YearFacet";
import MonthlyPaymentFacet from "./components/MonthlyPaymentFacet";
import StockTypeFacet from "./components/StockTypeFacet";
import BodyStyleFacet from "./components/BodyStyleFacet";
import MakeFacet from "./components/MakeFacet";
import ModelFacet from "./components/ModelFacet";
import TrimFacet from "./components/TrimFacet";
import MilesFacet from "./components/MilesFacet";
import ExteriorColorFacet from "./components/ExteriorColorFacet";
import InteriorColorFacet from "./components/InteriorColorFacet";
import TransmissionFacet from "./components/TransmissionFacet";
import EngineTypeFacet from "./components/EngineTypeFacet";
import DriveTypeFacet from "./components/DriveTypeFacet";
import FuelTypeFacet from "./components/FuelTypeFacet";
import SelectedFilters from "../SelectedFilters/index";
import PassengerCapacityFacet from "./components/PassengerCapacityFacet";
import _ from "lodash";
import SpecialProgramsFacet from "Modules/UsedListings/components/Facets/components/SpecialProgramsFacet";
import DealBadgeFacet from "Modules/UsedListings/components/Facets/components/DealBadgeFacet";
import VehicleCategoryFacet from "Modules/UsedListings/components/Facets/components/VehicleCategoryFacet";
import DistanceAndZipSelector from "./components/DistanceAndZipSelector";

export default {
    name: "Facets",

    components: {
        DistanceAndZipSelector,
        VehicleCategoryFacet,
        DealBadgeFacet,
        PassengerCapacityFacet,
        SelectedFilters,
        PriceFacet,
        YearFacet,
        MonthlyPaymentFacet,
        StockTypeFacet,
        BodyStyleFacet,
        MakeFacet,
        ModelFacet,
        TrimFacet,
        MilesFacet,
        ExteriorColorFacet,
        InteriorColorFacet,
        TransmissionFacet,
        EngineTypeFacet,
        DriveTypeFacet,
        FuelTypeFacet,
        SpecialProgramsFacet
    },

    props: {
        isLoading: {
            type: Boolean,
            default: false,
            required: false
        },
        showStockTypeFacet: {
            type: Boolean,
            default: false,
            required: false
        },
        showMilesFacet: {
            type: Boolean,
            default: true,
            required: false
        },
        showPaymentTypeFacet: {
            type: Boolean,
            default: true,
            required: false
        },
        showYearsFacet: {
            type: Boolean,
            default: true,
            required: false
        },
        showExteriorColorFacet: {
            type: Boolean,
            default: true,
            required: false
        },
        showInteriorColorFacet: {
            type: Boolean,
            default: true,
            required: false
        },
        showSortFacet: {
            type: Boolean,
            default: false,
            required: false
        },
        showDealBadgeFacet: {
            type: Boolean,
            default: true,
            required: false
        },
        isDealerInventorySearch: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    computed: {
        facets: get("vehicleSearch/facets"),
        campaignType: get("campaign/type"),
        hasModels() {
            return !_.isEmpty(this.facets.models);
        },
        hasTrims() {
            return !_.isEmpty(this.facets.trims);
        },
        hasSpecialPrograms() {
            return (
                _.get(this.facets, "supplierPricing", false) ||
                _.get(this.facets, "certifiedDealer", false)
            );
        },
        hasDealBadges() {
            return !_.isEmpty(this.facets.dealBadges);
        },
        hasBodyStyles() {
            return !_.isEmpty(this.facets.bodyStyles);
        },
        hasMakes() {
            return !_.isEmpty(this.facets.makes);
        },
        hasExteriorColors() {
            return !_.isEmpty(this.facets.exteriorColors);
        },
        hasInteriorColors() {
            return !_.isEmpty(this.facets.interiorColors);
        },
        hasEngineTypes() {
            return !_.isEmpty(this.facets.cylinders);
        },
        hasDriveTypes() {
            return !_.isEmpty(this.facets.drivetrains);
        },
        hasFuelTypes() {
            return !_.isEmpty(this.facets.fuelTypes);
        },
        hasTransmissions() {
            return !_.isEmpty(this.facets.transmissions);
        },
        hasPassengerCapacities() {
            return !_.isEmpty(this.facets.passengerCapacities);
        }
    }
};
</script>

<style lang="scss" scoped>
#facets {
    position: relative;

    .loader-blocker {
        position: absolute;
        height: 100%;
        width: 100%;
        background-color: #fff;
        opacity: 0.75;
        filter: alpha(opacity=75);
        z-index: 49;
        left: 0;
        top: 0;
    }
}
</style>

<style lang="scss">
@import "./components/styles/styles.scss";

.facet {
    :focus {
        outline: none;
    }

    @include media-breakpoint-down(xs) {
        label.form-check-label {
            padding-left: 7px;
        }
    }
}
</style>
