var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "label",
    { staticClass: "sort-selector px-2 mb-0" },
    [
      _vm._v(
        "\n    " + _vm._s(_vm.$t("component.sortSelector.sortBy")) + ":\n    "
      ),
      _c(
        "b-form-select",
        {
          staticClass: "px-1",
          attrs: { id: "sort-select" },
          model: {
            value: _vm.sort,
            callback: function($$v) {
              _vm.sort = $$v
            },
            expression: "sort"
          }
        },
        _vm._l(_vm.sortOptions, function(option) {
          return _c(
            "option",
            { key: option.value, domProps: { value: option.value } },
            [_vm._v("\n            " + _vm._s(option.text) + "\n        ")]
          )
        }),
        0
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }