import _ from "lodash";

const campaignTypes = {
    walmart: [
        "walmart.carsaver.com",
        "walmart-services.carsaver.com",
        "walmart.beta.carsaver.com",
        "localhost-walmart:5000",
        "walmart-app.carsaver.com"
    ],
    wavpp: [
        "walmart-associate.carsaver.com",
        "walmart-employee.beta.carsaver.com",
        "localhost-wavpp:5000"
    ],
    shop: [
        "shop.carsaver.com",
        "shop.beta.carsaver.com",
        "localhost-shop:5000",
        "unfranchise.carsaver.com",
        "unfranchise.beta.carsaver.com",
        "localhost-unfranchise:5000"
    ],
    iheart: [
        "localhost-iheart:5000",
        "iheartmedia-employee.carsaver.com",
        "iheartmedia-employee.beta.carsaver.com"
    ]
};

const state = {
    phoneNumber: _.get(window, "_CAMPAIGN.phoneNumber", null),
    domain: _.get(window, "_CAMPAIGN.domain", null)
};

const getters = {
    phoneNumberFormatted: state => {
        if (!_.has(state, "phoneNumber") || _.isNil(state.phoneNumber)) {
            return "";
        }

        return `(${state.phoneNumber.substring(
            0,
            3
        )}) ${state.phoneNumber.substring(3, 6)}-${state.phoneNumber.substring(
            6,
            10
        )}`;
    },
    type: state => {
        return getCampaignType(state.domain);
    }
};

function arrayContainsString(arr, str) {
    const normalStr = str.toLowerCase().replace(/ /g, "");

    return arr.indexOf(normalStr) !== -1;
}

function getCampaignType(domain) {
    let isOfType;
    let typeDomains;

    if (!domain) {
        return null;
    }

    if (isWmStore(domain)) {
        return "kiosk";
    }

    for (const type in campaignTypes) {
        typeDomains = campaignTypes[type];
        isOfType = arrayContainsString(typeDomains, domain);

        if (isOfType === true) {
            return type;
        }
    }

    return null;
}

function isWmStore(domain) {
    if (_.isEmpty(domain)) {
        return false;
    }

    return !!domain.startsWith("wm");
}

export default {
    namespaced: true,
    state,
    getters
};
