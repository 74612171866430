export default {
    data() {
        return {
            collapsibles: {
                stockTypes: "stockTypes",
                makes: "makes",
                models: "models",
                exteriorColors: "exteriorColors",
                interiorColors: "interiorColors",
                drivetrains: "drivetrains",
                transmissions: "transmissions",
                fuels: "fuels",
                bodyStyles: "bodyStyles",
                cylinders: "cylinders",
                year: "year",
                price: "price",
                monthlyPayment: "monthlyPayment",
                miles: "miles",
                passengerCapacities: "passengerCapacities",
                sort: "sort",
                specialPrograms: "specialPrograms",
                dealBadges: "dealBadges",
                vehicleCategory: "vehicleCategory",
                distanceAndZip: "distanceAndZip"
            }
        };
    }
};
