<template>
    <facet-template
        :show-clear-link="isFiltered"
        :facet-name="'monthlyPayment'"
        @clear="clearSelectedPayments"
    >
        <span slot="facetLabel">
            {{ $t("component.facets.monthlyPayment") }}
        </span>

        <div slot="facetBody" class="container p-0">
            <div v-if="showPaymentTypeFacet" class="row">
                <div class="col-12 full-width-select">
                    <label for="paymentType">payment type</label>
                    <b-form-group class="m-0 check-type">
                        <b-form-select
                            id="paymentType"
                            v-model="paymentTypeFilter"
                            :options="paymentOptions"
                        />
                    </b-form-group>
                </div>
            </div>

            <div class="row pt-2">
                <div class="col-12">
                    <div class="d-flex justify-content-between">
                        <div class="left-select">
                            <label for="minPayment">
                                starting at
                            </label>
                            <select
                                id="minPayment"
                                v-model="selectedMinPayment"
                                class="form-control-sm custom-select w-100"
                                name="minPayment"
                            >
                                <option :value="null">
                                    {{ $t("component.facets.any") }}
                                </option>
                                <option
                                    v-for="payment in minPayments"
                                    :key="payment.value"
                                    :value="payment.value"
                                >
                                    {{ payment.text }}
                                </option>
                            </select>
                        </div>

                        <div class="right-select">
                            <label for="maxPayment">
                                up to
                            </label>
                            <select
                                id="maxPayment"
                                v-model="selectedMaxPayment"
                                class="form-control-sm custom-select w-100"
                                name="maxPayment"
                            >
                                <option :value="null">
                                    {{ $t("component.facets.any") }}
                                </option>
                                <option
                                    v-for="payment in maxPayments"
                                    :key="payment.value"
                                    :value="payment.value"
                                >
                                    {{ payment.text }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </facet-template>
</template>

<script>
import _ from "lodash";
import FacetTemplate from "./FacetTemplate";
import numeral from "numeral";
import { get, sync, dispatch } from "vuex-pathify";

export default {
    name: "MonthlyPaymentFacet",

    components: {
        FacetTemplate
    },

    props: {
        paymentsStep: {
            type: Number,
            default: 50
        },
        showPaymentTypeFacet: {
            type: Boolean,
            default: true,
            required: false
        }
    },

    data() {
        return {
            paymentOptions: [
                {
                    text: "All",
                    value: "ALL"
                },
                {
                    text: "Lease",
                    value: "LEASE"
                },
                {
                    text: "Finance",
                    value: "FINANCE"
                }
            ],
            maxMonthlyPaymentAllowed: 1500
        };
    },

    computed: {
        paymentTypeFilter: sync("vehicleSearch/filters@paymentType"),
        availablePaymentRange: get("vehicleSearch/facets@paymentRange"),
        selectedMinPayment: sync("vehicleSearch/filters@minPayment"),
        selectedMaxPayment: sync("vehicleSearch/filters@maxPayment"),
        minPayments() {
            const minPaymentAvailable = _.get(
                this.availablePaymentRange,
                "minimum",
                0
            );
            const maxPaymentAvailable = _.get(
                this.availablePaymentRange,
                "maximum",
                this.maxMonthlyPaymentAllowed
            );
            let maxPayment = _.isInteger(this.selectedMaxPayment)
                ? _.toInteger(this.selectedMaxPayment)
                : maxPaymentAvailable;

            maxPayment =
                maxPayment > this.maxMonthlyPaymentAllowed
                    ? this.maxMonthlyPaymentAllowed
                    : maxPayment;

            // 0 payment will be covered by the "Any" option
            const minPayment =
                _.toInteger(minPaymentAvailable) === 0
                    ? minPaymentAvailable + this.paymentsStep
                    : minPaymentAvailable;

            return _.map(
                _.range(minPayment, maxPayment, this.paymentsStep),
                price => {
                    return {
                        value: price,
                        text: numeral(price).format("$0,0") + "/mo"
                    };
                }
            );
        },
        maxPayments() {
            const minPaymentAvailable = _.get(
                this.availablePaymentRange,
                "minimum",
                0
            );
            let maxPaymentAvailable = _.get(
                this.availablePaymentRange,
                "maximum",
                this.maxMonthlyPaymentAllowed
            );
            let minPayment = minPaymentAvailable;

            maxPaymentAvailable =
                maxPaymentAvailable > this.maxMonthlyPaymentAllowed
                    ? this.maxMonthlyPaymentAllowed
                    : maxPaymentAvailable;

            // if a minPayment is selected, max payment must start with the next payment
            if (!_.isNil(this.selectedMinPayment)) {
                minPayment =
                    _.toInteger(this.selectedMinPayment) + this.paymentsStep;
            }

            // 0 payment will be covered by the "Any" option
            minPayment =
                minPayment === 0 ? minPayment + this.paymentsStep : minPayment;

            return _.map(
                this.rangeInclusive(
                    minPayment,
                    maxPaymentAvailable,
                    this.paymentsStep
                ),
                price => {
                    return {
                        value: price,
                        text: numeral(price).format("$0,0") + "/mo"
                    };
                }
            );
        },
        isFiltered() {
            return (
                !_.isNil(this.selectedMinPayment) ||
                !_.isNil(this.selectedMaxPayment) ||
                this.paymentTypeFilter !== "ALL"
            );
        }
    },

    watch: {
        availablePaymentRange: function(newPaymentRange) {
            if (
                !_.isNil(this.selectedMinPayment) &&
                newPaymentRange.minimum > this.selectedMinPayment
            ) {
                this.selectedMinPayment = newPaymentRange.minimum;
            }

            if (
                !_.isNil(this.selectedMaxPayment) &&
                newPaymentRange.maximum < this.selectedMaxPayment
            ) {
                this.selectedMaxPayment = newPaymentRange.maximum;
            }
        }
    },

    methods: {
        clearPaymentType() {
            this.paymentTypeFilter = "ALL";
        },
        clearSelectedPayments() {
            dispatch("vehicleSearch/clearPayments");
        },
        // lodash ranges are not inclusive (upper bounds)
        rangeInclusive(start, end, step = 1) {
            return _.range(start, end + step, step);
        }
    }
};
</script>
