<template>
    <b-modal id="supplierPricingModal" v-model="open" :hide-footer="true">
        <header slot="modal-header-close">
            <i aria-hidden="true" class="ion-ios-close-outline">
                &nbsp;
            </i>
        </header>
        <b-container fluid>
            <b-row>
                <b-col>
                    <div v-if="vehicle" class="d-flex flex-column text-center">
                        <h1 class="title">Supplier Pricing<sup>*</sup></h1>
                        <span class="sub-title">
                            Great News! {{ vehicle.make }} offers special
                            pricing to Walmart Associates!
                        </span>

                        <p class="content">
                            To get your exclusive supplier price on this
                            <span class="font-weight-bold"
                                >{{ vehicle.year }} {{ vehicle.make }}
                                {{ vehicle.model }} {{ vehicle.trim }}</span
                            >, please speak with one of our Walmart Associate
                            Vehicle Purchase Program agents.
                        </p>

                        <b-row>
                            <b-col :cols="isCallCenterOpen ? 12 : 6" sm="6">
                                <b-button
                                    :variant="
                                        isCallCenterOpen
                                            ? 'outline-primary'
                                            : 'orange'
                                    "
                                    size="lg"
                                    class="mb-2 mx-auto"
                                    @click="scheduleCall"
                                >
                                    Schedule a Call
                                </b-button>
                            </b-col>
                            <b-col v-if="isCallCenterOpen" cols="12" sm="6">
                                <b-button
                                    variant="orange"
                                    size="lg"
                                    class="mb-2 mx-auto"
                                    @click="callMeNow"
                                >
                                    Call Me Now
                                </b-button>
                            </b-col>
                        </b-row>

                        <div class="disclaimer">
                            * Only available at participating franchise
                            dealerships. Pricing and eligibility of specific
                            models is subject to change at any time without
                            notice.
                        </div>
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </b-modal>
</template>

<script>
import { get, sync } from "vuex-pathify";
import isCallCenterOpen from "Modules/Wavpp/mixins/ScheduleCallMixin";

export default {
    name: "SupplierPricingModal",
    mixins: [isCallCenterOpen],
    computed: {
        open: sync("supplierPricing/supplierPricingAvailableModal"),
        callMeNowModalOpen: sync("supplierPricing/callMeNowModal"),
        scheduleCallModalOpen: sync("supplierPricing/scheduleCallModal"),
        vehicle: get("supplierPricing/vehicle")
    },
    methods: {
        scheduleCall() {
            this.open = false;
            this.scheduleCallModalOpen = true;
        },
        callMeNow() {
            this.open = false;
            this.callMeNowModalOpen = true;
        }
    }
};
</script>
<style lang="scss">
#supplierPricingModal {
    .title {
        font-size: 36px;
        color: $primary-blue;
    }
    .sub-title {
        font-family: $helvetica-55-Roman;
        font-size: 14px;
        color: $dark-gray;
        margin-top: 5px;
        margin-bottom: 15px;
    }
    .disclaimer {
        font-size: 10px;
        font-family: $helvetica-46-light-italic;
        line-height: 15px;
    }
    .content {
        margin-bottom: 35px;
    }
    .btn-orange,
    .btn-outline-primary {
        font-size: 14px;
        width: 195px;
    }
    .modal-body {
        padding-top: 0;
        padding-bottom: 25px;
    }
}
</style>
