var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "transmission-icon-container d-flex flex-column align-items-center text-center my-4 mx-2 position-relative"
    },
    [
      _c("div", { staticClass: "icon-container" }, [
        _c("div", { staticClass: "icon col-12" }, [
          _vm.transmissionType === "MANUAL"
            ? _c(
                "div",
                [
                  _c("b-img", {
                    attrs: {
                      src: require("./images/manual.svg"),
                      fluid: "",
                      alt: "Responsive image"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.transmissionType === "AUTOMATIC"
            ? _c(
                "div",
                [
                  _c("b-img", {
                    attrs: {
                      src: require("./images/automatic.svg"),
                      fluid: "",
                      alt: "Responsive image"
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ])
      ]),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass: "transmission-title",
          class: [{ selected: _vm.selected }]
        },
        [
          _vm._v(
            "\n        " +
              _vm._s(_vm._f("titleCase")(_vm.transmissionType)) +
              "\n        "
          ),
          _vm.selected
            ? _c("i", {
                staticClass: "ion-checkmark",
                attrs: { "aria-hidden": "true" }
              })
            : _vm._e()
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }