<template>
    <layout :breadcrumbs="breadcrumbs">
        <template slot="header">
            <h1 class="text-center">Shop by {{ pageTitle }}</h1>
        </template>

        <b-row>
            <div class="col-12 col-md-9">
                <div id="content" class="d-flex flex-column flex-sm-row">
                    <div id="bodystyle-selection" class="d-flex flex-column">
                        <div id="title" class="d-flex">
                            <h2>What body style are you looking for?</h2>
                        </div>
                        <div id="bodystyle-body" class="d-flex">
                            <div id="body-styles" class="d-flex flex-wrap mb-3">
                                <div
                                    v-for="bodyStyle in validBodyStyles"
                                    :id="bodyStyle.id"
                                    :key="bodyStyle.id"
                                    class="text-center body-style"
                                >
                                    <!-- selected style is based on applied filters in vuex -->
                                    <div
                                        class="body-style-wrapper"
                                        @click="toggleBodyStyle(bodyStyle)"
                                    >
                                        <div class="body-style-icon">
                                            <body-style-icon
                                                :id="bodyStyle.id"
                                                :selected="
                                                    isBodyStyleSelected(
                                                        bodyStyle
                                                    )
                                                "
                                            />
                                        </div>
                                        <small class="text-uppercase">
                                            {{ bodyStyle.name }}
                                        </small>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <form id="multiselect-form">
                            <div class="form-check">
                                <input
                                    id="multi-pick"
                                    v-model="multiselect"
                                    type="checkbox"
                                    class="form-check-input"
                                />
                                <label
                                    id="multi-pick-text"
                                    for="multi-pick"
                                    class="form-check-label"
                                >
                                    make multiple selections
                                </label>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <selected-filters :route-to="next" />
        </b-row>
    </layout>
</template>
<script>
import { get, dispatch } from "vuex-pathify";
import _ from "lodash";
import BodyStyleIcon from "./components/BodyStyleIcon";
import SelectedFilters from "../components/SelectedFilters";
import Layout from "../components/Layout";

export default {
    components: {
        BodyStyleIcon,
        SelectedFilters,
        Layout
    },

    data() {
        return {
            routeMeta: this.$route.meta.to,
            breadcrumbs: this.$route.meta.breadcrumbs,
            pageTitle: _.get(this.$route.meta, "pageTitle", "Body Style"),
            multiselect: false
        };
    },

    computed: {
        next() {
            return {
                href: _.get(this.$route.meta, "next.href"),
                to: _.get(this.$route.meta, "next.to"),
                modelsPage: _.get(this.$route.meta, "next.modelsPage", false)
            };
        },
        filterQuery: get("shopBy/filterQuery"),
        modelsUrl: function() {
            return "/shop/models" + this.filterQuery;
        },
        availableBodyStyles: get("shopBy/bodyStyles"),
        selectedBodyStyles: get("shopBy/selectedBodyStyles"),
        appliedBodyStyleFilters: {
            get: function() {
                return {
                    name: "bodyStyles",
                    value: this.selectedBodyStyles
                };
            }
        },
        validBodyStyles() {
            return _.filter(this.availableBodyStyles, bodyStyle => {
                return bodyStyle != null;
            });
        }
    },

    watch: {
        multiselect: function(newValue, oldValue) {
            if (!newValue && oldValue) {
                this.clearBodyStyles();
            }
        }
    },

    methods: {
        clearBodyStyles() {
            dispatch("shopBy/removeFilter", "bodyStyles");
        },
        toggleBodyStyle(clickedBodyStyle) {
            const userIsAddingBodyStyleFilter =
                this.selectedBodyStyles.indexOf(clickedBodyStyle.id) === -1;

            if (userIsAddingBodyStyleFilter) {
                this.addBodyStyle(clickedBodyStyle.id);

                if (!this.multiselect) {
                    this.goToNext();
                }
            } else {
                this.removeBodyStyle(clickedBodyStyle.id);
            }
        },
        addBodyStyle(newStyle) {
            if (this.appliedBodyStyleFilters.value.indexOf(newStyle) !== -1) {
                return;
            }

            dispatch("shopBy/addFilter", {
                name: "bodyStyles",
                value: this.selectedBodyStyles.concat(newStyle)
            });
        },
        removeBodyStyle(style) {
            dispatch("shopBy/removeFilter", {
                name: "bodyStyles",
                value: style
            });
        },
        isBodyStyleSelected(bodyStyle) {
            return this.selectedBodyStyles.indexOf(bodyStyle.id) > -1;
        },
        goToNext() {
            if (this.next.modelsPage) {
                window.location = this.modelsUrl;
            } else if (this.next.href) {
                window.location = this.routeTo.href;
            } else if (this.next.to) {
                this.$router.push({ path: this.next.to });
            } else {
                console.error(
                    "Error in BodyStyle/index.vue ill form next object",
                    this.next
                );
            }
        }
    }
};
</script>
<style lang="scss" scoped>
* {
    color: #444;
}

h1,
h2 {
    font-weight: lighter;
}

h1 {
    font-size: 28px;
    line-height: 28px;
}

h2 {
    font-size: 24px;
    line-height: 30px;
}

small {
    font-size: px2rem(12);
}

#multiselect-form {
    line-height: px2rem(16);
}

#multi-pick-text {
    font-size: px2rem(16);
    font-style: italic;
}

#header {
    margin-bottom: px2rem(34);

    h1 {
        padding: 0 0 24px 0;
        border-bottom: 1px solid #888;
    }
}

#bodystyle-selection {
    padding-left: 45px;
    width: 100%;
    min-height: 350px;

    i {
        font-size: 30px;
        -webkit-text-stroke-width: thin;
    }

    #title {
        margin-bottom: 25px;
    }

    #body-styles {
        width: 100%;

        .body-style {
            width: 25%;
            margin-top: 25px;
            margin-bottom: 25px;
        }
    }
}

.breadcrumb {
    padding-left: 0px;

    .breadcrumb-item {
    }
}

.icon-sedan,
.icon-suv,
.icon-convertible,
.icon-truck,
.icon-van,
.icon-hatchback,
.icon-wagon,
.icon-coupe {
    color: $primary-blue !important;
}

@include media-breakpoint-down(xs) {
    .full-height {
        min-height: 100vh;
    }

    /* vendor override*/
    .selected-filters {
        .filter-box {
            min-height: 0;
        }
    }

    #bodystyle-selection {
        padding-left: 0;

        i {
            font-size: 25px;
            -webkit-text-stroke-width: thin;
        }

        #title {
            margin-bottom: 10px;
        }

        #body-styles {
            width: 100%;

            .body-style {
                width: 33%;
                margin-top: 15px;
                margin-bottom: 15px;
            }
        }
    }
}
</style>
