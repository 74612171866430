var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("dealer-sticky-footer", { attrs: { dealer: _vm.dealer } }, [
    _c(
      "div",
      {
        staticClass:
          "d-flex align-items-center justify-content-between button-wrapper",
        attrs: { slot: "cta" },
        slot: "cta"
      },
      [
        _c(
          "div",
          { staticClass: "mx-2" },
          [
            _c(
              "b-button",
              {
                staticClass: "footer-btn",
                attrs: {
                  href: `/dealer/${_vm.dealer.id}/directions`,
                  variant: "secondary",
                  target: "_blank",
                  block: ""
                }
              },
              [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.$t("component.dealerStickyFooter.directions")) +
                    "\n            "
                )
              ]
            )
          ],
          1
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }