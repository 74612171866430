<template>
    <facet-template
        :show-clear-link="isFiltered"
        :facet-name="'drivetrains'"
        @clear="clearSelectedDriveTrains"
    >
        <span slot="facetLabel">
            {{ $t("component.facets.driveType") }}
        </span>

        <div slot="facetBody">
            <div class="container">
                <div class="row">
                    <div class="col-12 p-0">
                        <b-form-group class="m-0">
                            <b-form-checkbox-group
                                v-model="selectedDriveTrains"
                                plain
                                stacked
                            >
                                <b-form-checkbox
                                    v-for="option in availableDriveTrainOptions"
                                    :key="option.value"
                                    :value="option.value"
                                    class="mb-2"
                                >
                                    {{ option.text }}
                                </b-form-checkbox>
                            </b-form-checkbox-group>
                        </b-form-group>
                    </div>
                </div>
            </div>
        </div>
    </facet-template>
</template>

<script>
import _ from "lodash";
import FacetTemplate from "./FacetTemplate";
import { get, sync } from "vuex-pathify";
import mixin from "./mixin";

export default {
    name: "DriveTypeFacet",
    components: {
        FacetTemplate
    },
    mixins: [mixin],
    computed: {
        availableDriveTrains: get("vehicleSearch/facets@drivetrains"),
        selectedDriveTrains: sync("vehicleSearch/filters@drivetrains"),
        availableDriveTrainOptions() {
            return this.availableDriveTrains
                .filter(driveTrain => driveTrain.id !== "UNKNOWN") // UNKNOWN is always appended server side
                .map(function(driveTrain) {
                    return {
                        text: driveTrain.name,
                        value: driveTrain.id
                    };
                });
        },
        isFiltered() {
            return !_.isEmpty(this.selectedDriveTrains);
        }
    },

    methods: {
        clearSelectedDriveTrains() {
            if (_.size(this.selectedDriveTrains) > 0) {
                this.selectedDriveTrains = [];
            }
        }
    }
};
</script>
