var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("iframe", {
    class: _vm.className,
    attrs: {
      title: "CsIframe",
      src: _vm.src,
      width: _vm.width,
      height: _vm.height,
      frameborder: "0"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }