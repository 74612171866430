<template>
    <div class="d-flex flex-row justify-content-between contact-wrapper">
        <div class="d-flex flex-column mr-auto">
            <span class="font-weight-bold">
                {{ user.firstName }} {{ user.lastName }}
            </span>
            <span>{{ user.phoneNumber | phone }}</span>
            <span class="mr-2">
                {{ user.email }}
            </span>
        </div>
        <div class="d-flex align-self-center">
            <div id="editContactInfo">
                <b-button
                    id="editInfoBtn"
                    tabindex="0"
                    variant="secondary"
                    size="sm"
                >
                    {{ $t("component.editContactInfo.editBtn") }}
                </b-button>
                <b-popover
                    ref="editInfoPopover"
                    target="editInfoBtn"
                    triggers="focus click"
                    placement="top"
                    @shown="popoverShown"
                >
                    <template slot="title">
                        <div id="update-contact-info-header" class="d-flex">
                            <span class="font-weight-bold py-2 mr-2">
                                {{
                                    $t(
                                        "component.editContactInfo.updateContactInfo"
                                    )
                                }}
                            </span>
                            <span
                                class="d-flex justify-content-center align-items-center close-icon"
                                @click="closePopup"
                            >
                                <i
                                    class="ion-ios-close-outline"
                                    aria-hidden="true"
                                >
                                    &nbsp;
                                </i>
                            </span>
                        </div>
                    </template>
                    <b-form @submit.prevent="onSubmit">
                        <div>
                            <b-form-input
                                id="phoneNumber"
                                v-model="form.phoneNumber"
                                :state="!$v.form.phoneNumber.$invalid"
                                class="mt-2 mb-2"
                                type="text"
                                aria-describedby="phoneInputLiveFeedback"
                                placeholder="Phone Number"
                            />
                            <b-form-invalid-feedback
                                id="phoneInputLiveFeedback"
                            >
                                Please enter a 10 digit phone number.
                            </b-form-invalid-feedback>
                            <small
                                v-if="errors && errors.has('phoneNumber')"
                                class="error-block text-danger"
                            >
                                {{ errors.get("phoneNumber") }}
                            </small>
                        </div>

                        <div>
                            <b-form-input
                                id="email"
                                v-model="form.email"
                                :state="!$v.form.email.$invalid"
                                class="mt-2 mb-2"
                                type="text"
                                aria-describedby="emailInputLiveFeedback"
                                placeholder="E-mail"
                            />
                            <b-form-invalid-feedback
                                id="emailInputLiveFeedback"
                            >
                                Please enter a valid e-mail format.
                            </b-form-invalid-feedback>
                            <small
                                v-if="errors && errors.has('email')"
                                class="error-block text-danger"
                            >
                                {{ errors.get("email") }}
                            </small>
                        </div>

                        <div v-if="hasServerError">
                            <span class="error-block text-danger">
                                Unexpected error please try again.
                            </span>
                        </div>

                        <div
                            class="d-flex justify-content-center align-items-center mt-2"
                        >
                            <ladda-b-btn
                                :loading="submitting"
                                :disabled="$v.form.$invalid"
                                variant="primary"
                                type="submit"
                                size="sm"
                            >
                                {{ $t("component.editContactInfo.save") }}
                            </ladda-b-btn>
                        </div>
                    </b-form>
                </b-popover>
            </div>
        </div>
    </div>
</template>
<style lang="scss" scoped>
.contact-wrapper {
    max-width: 330px;
}

#update-contact-info-header {
    color: $muted-dark-gray;

    .close-icon {
        font-size: px2rem(20);
        color: $muted-dark-gray;
        cursor: pointer;
    }
    .close-icon:hover {
        color: $primary-blue;
    }
}
#editContactInfo {
    .btn-secondary {
        color: #9b9b9b;
        height: 40px;
        width: 70px;
        font-family: $helvetica-65-medium;
    }
}
</style>

<script>
import { get, dispatch } from "vuex-pathify";
import { validationMixin } from "vuelidate";
import {
    required,
    email,
    minLength,
    maxLength,
    numeric
} from "vuelidate/lib/validators";
import LaddaBBtn from "../LaddaBBtn";
import _ from "lodash";

export default {
    name: "EditContactInfo",

    components: {
        LaddaBBtn
    },

    mixins: [validationMixin],

    data() {
        return {
            form: {
                email: "",
                phoneNumber: ""
            }
        };
    },
    validations: {
        form: {
            email: {
                required,
                email
            },
            phoneNumber: {
                required,
                numeric,
                minLength: minLength(10),
                maxLength: maxLength(10)
            }
        }
    },

    computed: {
        user: get("user/user"),
        editContactOpen: get("user/updateEmailPhone@editContactOpen"),
        errors: get("user/updateEmailPhone@errors"),
        submitting: get("user/updateEmailPhone@submitting"),
        hasServerError: get("user/updateEmailPhone@hasServerError")
    },

    watch: {
        editContactOpen(newValue, oldValue) {
            if (newValue === oldValue) {
                return;
            }
            if (newValue === false) {
                this.closePopup();
            }
        }
    },

    created() {
        this.reset();
    },

    methods: {
        popoverShown() {
            this.$gtm.trackEvent({
                event: "Event",
                category: "edit-user-details",
                action: "edit-popover",
                label: "edit"
            });
        },

        onSubmit() {
            this.$gtm.trackEvent({
                event: "Event",
                category: "edit-user-details",
                action: "edit-popover",
                label: "save"
            });

            dispatch("user/updateEmailPhone", {
                email: this.form.email,
                phoneNumber: this.form.phoneNumber,
                userId: this.user.id
            });

            // Kiosk requires an explicit return of false - even with .prevent on the event.
            return false;
        },
        closePopup() {
            console.log("closePopup()");
            this.$refs.editInfoPopover.$emit("close");
            this.reset();
            dispatch("user/resetProfileForm");
        },

        reset() {
            this.form.email = _.get(this.user, "email", "Please Enter Email");
            this.form.phoneNumber = _.get(
                this.user,
                "phoneNumber",
                "Please Enter Phone Number"
            );
        }
    }
};
</script>
