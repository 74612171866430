var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    { staticClass: "tooltip-disclaimer" },
    [
      _c(
        "b-link",
        { attrs: { id: _vm.$id("tooltip-disclaimer"), tabindex: "0" } },
        [
          _c("i", {
            staticClass: "icon ion-ios-information-outline",
            class: { "deal-card": _vm.isDealCard },
            attrs: { "aria-hidden": "true" }
          })
        ]
      ),
      _vm._v(" "),
      _c(
        "b-popover",
        {
          attrs: {
            target: _vm.$id("tooltip-disclaimer"),
            triggers: "click blur focus",
            show: _vm.show
          },
          on: {
            "update:show": function($event) {
              _vm.show = $event
            }
          }
        },
        [
          _c(
            "b-link",
            {
              staticClass: "float-right pl-1",
              on: {
                click: function($event) {
                  _vm.show = !_vm.show
                }
              }
            },
            [
              _c("i", {
                staticClass: "icon ion-ios-close-outline close-tooltip",
                attrs: { "aria-hidden": "true" }
              })
            ]
          ),
          _vm._v(" "),
          _c("span", { domProps: { innerHTML: _vm._s(_vm.title) } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }