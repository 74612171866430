var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-btn",
    {
      ref: "ladda",
      attrs: { disabled: _vm.isDisabled, variant: _vm.variant, size: _vm.size },
      on: { click: _vm.click }
    },
    [
      _vm.loading
        ? _c("span", {
            staticClass: "spinner-border spinner-border-sm mr-2",
            attrs: { role: "status", "aria-hidden": "true" }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.loading
        ? _c("span", [_vm._v(_vm._s(_vm.loadingText))])
        : _c("span", [_vm._t("default")], 2)
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }