var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("footer", { attrs: { id: "main-footer" } }, [
    _c("div", { staticClass: "container-fluid" }, [
      _c("div", { staticClass: "social" }, [
        _c("ul", [
          _c("li", [_vm._v(_vm._s(_vm.$t("component.csFooter.about")))]),
          _vm._v(" "),
          _vm.showHowItWorks
            ? _c("li", [
                _c("a", { attrs: { href: "/how-it-works" } }, [
                  _vm._v(
                    "\n                            " +
                      _vm._s(_vm.$t("component.csFooter.howItWorks")) +
                      "\n                        "
                  )
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("li", [
            _c("a", { attrs: { href: "/contact" } }, [
              _vm._v(
                "\n                            " +
                  _vm._s(_vm.$t("component.csFooter.contact")) +
                  "\n                        "
              )
            ])
          ]),
          _vm._v(" "),
          _c("li", [
            _c(
              "a",
              {
                attrs: {
                  target: "_blank",
                  rel: "noopener noreferrer",
                  href:
                    "https://recruiting.paylocity.com/recruiting/jobs/List/1384/CarSaver-Management-LLC"
                }
              },
              [
                _vm._v(
                  "\n                            " +
                    _vm._s(_vm.$t("component.csFooter.careers")) +
                    "\n                        "
                )
              ]
            )
          ])
        ]),
        _vm._v(" "),
        _vm.showBuy
          ? _c("ul", [
              _c("li", [_vm._v(_vm._s(_vm.$t("component.csFooter.services")))]),
              _vm._v(" "),
              _c("li", [
                _c("a", { attrs: { href: "/shop-by" } }, [
                  _vm._v(
                    "\n                            " +
                      _vm._s(_vm.$t("component.csFooter.shopNewCars")) +
                      "\n                        "
                  )
                ])
              ]),
              _vm._v(" "),
              _c("li", [
                _c("a", { attrs: { href: "/shop/used" } }, [
                  _vm._v(
                    "\n                            " +
                      _vm._s(_vm.$t("component.csFooter.shopUsedCars")) +
                      "\n                        "
                  )
                ])
              ]),
              _vm._v(" "),
              _c("li", [
                _c(
                  "a",
                  {
                    attrs: { href: "/shop-by/make" },
                    on: { click: _vm.updateSavedUrl }
                  },
                  [
                    _vm._v(
                      "\n                            " +
                        _vm._s(_vm.$t("component.csFooter.buildACar")) +
                        "\n                        "
                    )
                  ]
                )
              ]),
              _vm._v(" "),
              _vm.showFinance
                ? _c("li", [
                    _c("a", { attrs: { href: "/finance" } }, [
                      _vm._v(
                        "\n                            " +
                          _vm._s(_vm.$t("component.csFooter.finance")) +
                          "\n                        "
                      )
                    ])
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.showInsure
                ? _c("li", [
                    _c("a", { attrs: { href: "/insure" } }, [
                      _vm._v(
                        "\n                            " +
                          _vm._s(_vm.$t("component.csFooter.insure")) +
                          "\n                        "
                      )
                    ])
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.showProtect
                ? _c("li", [
                    _c("a", { attrs: { href: "/warranty" } }, [
                      _vm._v(
                        "\n                            " +
                          _vm._s(
                            _vm.$t("component.csFooter.lifetimeWarranty")
                          ) +
                          "\n                        "
                      )
                    ])
                  ])
                : _vm._e()
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("ul", [
          _c("li", [
            _vm._v(_vm._s(_vm.$t("component.csFooter.certifiedDealers")))
          ]),
          _vm._v(" "),
          _c("li", [
            _c("a", { attrs: { href: "/dealer-overview" } }, [
              _vm._v(
                "\n                            " +
                  _vm._s(_vm.$t("component.csFooter.dealerOverview")) +
                  "\n                        "
              )
            ])
          ]),
          _vm._v(" "),
          _vm.showPortal
            ? _c("li", [
                _c(
                  "a",
                  {
                    attrs: {
                      target: "_blank",
                      rel: "noopener noreferrer",
                      href: "https://portal.carsaver.com"
                    }
                  },
                  [
                    _vm._v(
                      "\n                            " +
                        _vm._s(_vm.$t("component.csFooter.dealerPortal")) +
                        "\n                        "
                    )
                  ]
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.showBecomeCertified
            ? _c("li", [
                _c(
                  "a",
                  {
                    attrs: {
                      target: "_blank",
                      rel: "noopener noreferrer",
                      href: "http://www.carsaverdealers.com"
                    }
                  },
                  [
                    _vm._v(
                      "\n                            " +
                        _vm._s(_vm.$t("component.csFooter.becomeCertified")) +
                        "\n                        "
                    )
                  ]
                )
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _vm.showProtect
          ? _c("ul", [
              _c("li", [
                _vm._v(_vm._s(_vm.$t("component.csFooter.lifetimeWarranty")))
              ]),
              _vm._v(" "),
              _c("li", [
                _c("a", { attrs: { href: "/warranty/faq" } }, [
                  _vm._v(
                    "\n                            " +
                      _vm._s(_vm.$t("component.csFooter.faq")) +
                      "\n                        "
                  )
                ])
              ]),
              _vm._v(" "),
              _c("li", [
                _c(
                  "a",
                  {
                    attrs: {
                      target: "_blank",
                      rel: "noopener noreferrer",
                      href: "http://carsaver.naenwan.com/naenwan/onlineclaims"
                    }
                  },
                  [
                    _vm._v(
                      "\n                            " +
                        _vm._s(_vm.$t("component.csFooter.fileClaim")) +
                        "\n                        "
                    )
                  ]
                )
              ]),
              _vm._v(" "),
              _c("li", [
                _c(
                  "a",
                  {
                    attrs: {
                      target: "_blank",
                      rel: "noopener noreferrer",
                      href: "http://carsaver.naenwan.com/naenwan/transfer"
                    }
                  },
                  [
                    _vm._v(
                      "\n                            " +
                        _vm._s(_vm.$t("component.csFooter.transfer")) +
                        "\n                        "
                    )
                  ]
                )
              ]),
              _vm._v(" "),
              _c("li", [
                _c(
                  "a",
                  {
                    attrs: {
                      target: "_blank",
                      rel: "noopener noreferrer",
                      href: "http://carsaver.naenwan.com/OnlineCancellations"
                    }
                  },
                  [
                    _vm._v(
                      "\n                            " +
                        _vm._s(_vm.$t("component.csFooter.cancellation")) +
                        "\n                        "
                    )
                  ]
                )
              ])
            ])
          : _vm._e(),
        _vm._v(" "),
        !_vm.isKiosk
          ? _c("ul", { staticClass: "icons" }, [
              _c("li", [_vm._v(_vm._s(_vm.$t("component.csFooter.follow")))]),
              _vm._v(" "),
              _vm._m(0),
              _vm._v(" "),
              _vm._m(1),
              _vm._v(" "),
              _vm._m(2),
              _vm._v(" "),
              _vm._m(3)
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _c("div", { staticClass: "policy" }, [
        _c(
          "ul",
          { staticClass: "copyright", class: { "tc-spanish": _vm.isSpanish } },
          [
            _c("li", { staticClass: "item" }, [
              _c("span", [
                _vm._v(
                  "© " +
                    _vm._s(_vm.currentYear) +
                    " CarSaver™. All\n                            rights reserved."
                )
              ])
            ]),
            _vm._v(" "),
            _vm._m(4),
            _vm._v(" "),
            _c("li", { staticClass: "item" }, [
              _c("span", [
                _vm._v(
                  "© 1986–" +
                    _vm._s(_vm.currentYear) +
                    " Chrome Data\n                            Solutions, LP"
                )
              ])
            ])
          ]
        ),
        _vm._v(" "),
        _c(
          "ul",
          { staticClass: "tc", class: { "tc-spanish": _vm.isSpanish } },
          [
            _c("li", { staticClass: "item" }, [
              _c(
                "a",
                {
                  attrs: {
                    target: "_blank",
                    href: "/tos",
                    rel: "noopener noreferrer"
                  }
                },
                [
                  _vm._v(
                    "\n                            " +
                      _vm._s(_vm.$t("component.csFooter.terms")) +
                      "\n                        "
                  )
                ]
              )
            ]),
            _vm._v(" "),
            _c("li", { staticClass: "item" }, [
              _c(
                "a",
                {
                  attrs: {
                    target: "_blank",
                    href: "/privacy",
                    rel: "noopener noreferrer"
                  }
                },
                [
                  _vm._v(
                    "\n                            " +
                      _vm._s(_vm.$t("component.csFooter.privacy")) +
                      "\n                        "
                  )
                ]
              )
            ]),
            _vm._v(" "),
            _c("li", { staticClass: "item" }, [
              _c(
                "a",
                {
                  attrs: {
                    target: "_blank",
                    href: "/privacy#do-not-sell-my-info",
                    rel: "noopener noreferrer"
                  }
                },
                [
                  _vm._v(
                    "\n                            " +
                      _vm._s(_vm.$t("component.csFooter.doNotSell")) +
                      "\n                        "
                  )
                ]
              )
            ])
          ]
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", [
      _c(
        "a",
        {
          attrs: {
            target: "_blank",
            rel: "noopener noreferrer",
            href: "https://www.facebook.com/CarSaverUSA/"
          }
        },
        [
          _c("i", {
            staticClass: "ion-social-facebook",
            attrs: { title: "Facebook", "aria-hidden": "true" }
          })
        ]
      )
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", [
      _c(
        "a",
        {
          attrs: {
            target: "_blank",
            rel: "noopener noreferrer",
            href: "https://twitter.com/CarSaverUSA"
          }
        },
        [
          _c("i", {
            staticClass: "ion-social-twitter",
            attrs: { title: "Twitter", "aria-hidden": "true" }
          })
        ]
      )
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", [
      _c(
        "a",
        {
          attrs: {
            target: "_blank",
            rel: "noopener noreferrer",
            href: "https://www.instagram.com/carsaverusa/"
          }
        },
        [
          _c("i", {
            staticClass: "ion-social-instagram-outline",
            attrs: { title: "Instagram", "aria-hidden": "true" }
          })
        ]
      )
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", [
      _c(
        "a",
        {
          attrs: {
            target: "_blank",
            rel: "noopener noreferrer",
            href: "https://www.youtube.com/channel/UC8qFagdCSfNtl6yWFwNa_vw"
          }
        },
        [
          _c("i", {
            staticClass: "ion-social-youtube",
            attrs: { title: "YouTube", "aria-hidden": "true" }
          })
        ]
      )
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", { staticClass: "item" }, [
      _c("span", [_vm._v("Vehicle photos © Evox Images")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }