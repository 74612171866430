var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-none d-md-block", attrs: { id: "pick-price-drive" } },
    [
      _c(
        "b-container",
        { staticClass: "d-flex justify-content-around", attrs: { fluid: "" } },
        [
          _c("item", {
            attrs: {
              selected: _vm.selectedIndex === 0,
              completed: _vm.selectedIndex > 0,
              number: 1,
              label: "Pick It",
              description: "Select Vehicle"
            }
          }),
          _vm._v(" "),
          _c("item", {
            attrs: {
              selected: _vm.selectedIndex === 1,
              completed: _vm.selectedIndex > 1,
              number: 2,
              label: "Price It",
              description: _vm.priceItDescription
            }
          }),
          _vm._v(" "),
          _c("item", {
            attrs: {
              selected: _vm.selectedIndex === 2,
              completed: _vm.selectedIndex > 2,
              number: 3,
              label: "Drive It",
              description: "Schedule Test Drive"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }