var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "d-flex flex-column align-items-center mb-3",
      attrs: { id: "sign-in-content" }
    },
    [
      _c(
        "b-form",
        { staticClass: "w-100", on: { submit: _vm.onSubmit } },
        [
          _c(
            "b-form-group",
            { attrs: { label: _vm.$t("component.signInForm.email") } },
            [
              _c("b-form-input", {
                attrs: {
                  id: "email",
                  type: "email",
                  disabled: _vm.submitting,
                  required: "",
                  placeholder: "Enter your email"
                },
                model: {
                  value: _vm.form.email,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.form,
                      "email",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "form.email"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-form-group",
            { attrs: { label: _vm.$t("component.signInForm.password") } },
            [
              _c("b-form-input", {
                attrs: {
                  id: "password",
                  type: "password",
                  disabled: _vm.submitting,
                  required: "",
                  placeholder: "Enter Password"
                },
                model: {
                  value: _vm.form.password,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.form,
                      "password",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "form.password"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _vm.error
            ? _c("p", { staticClass: "error-block text-center" }, [
                _vm._v(
                  "\n            The email address or password are not correct\n        "
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "p",
            { staticClass: "text-right forgot-password font-italic" },
            [
              _c(
                "b-link",
                {
                  on: {
                    click: function($event) {
                      return _vm.showForgotPasswordModal()
                    }
                  }
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.$t("component.signInForm.forgot")) +
                      "\n            "
                  )
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm.showSection
            ? _c(
                "p",
                { staticClass: "disclaimer text-center" },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$t("component.signInForm.disclaimer")) +
                      "\n            "
                  ),
                  _c("b-link", { attrs: { href: "/tos", target: "_blank" } }, [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.$t("component.signInForm.terms")) +
                        "\n            "
                    )
                  ]),
                  _vm._v("\n            and\n            "),
                  _c(
                    "b-link",
                    { attrs: { href: "/privacy", target: "_blank" } },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.$t("component.signInForm.privacy")) +
                          " "
                      )
                    ]
                  ),
                  _vm._v(".\n        ")
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "b-button",
            {
              staticClass: "sign-in-btn mx-auto",
              attrs: {
                type: "submit",
                variant: "orange",
                disabled: _vm.submitting
              }
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.$t("component.signInForm.signIn")) +
                  "\n        "
              )
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("forgot-password", { ref: "forgotPasswordModal" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }