import _ from "lodash";

// PREDICATES

export const selectedOrRequiredPredicate = option => {
    return (
        _.toUpper(_.get(option, "selectionState")) === "SELECTED" ||
        _.toUpper(_.get(option, "selectionState")) === "REQUIRED"
    );
};
