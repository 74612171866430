<template>
    <facet-template
        :show-clear-link="isFiltered"
        @clear="clearSelectedStockTypes"
    >
        <span slot="facetLabel">
            {{ $t("component.facets.stockType") }}
        </span>

        <div slot="facetBody">
            <div class="container">
                <div class="row">
                    <div class="col-12 p-0">
                        <b-form-group class="m-0">
                            <b-form-checkbox-group
                                v-model="selectedStockTypes"
                                plain
                                stacked
                            >
                                <b-form-checkbox
                                    v-for="option in availableStockTypeOptions"
                                    :key="option.value"
                                    :value="option.value"
                                    class="mb-2"
                                >
                                    {{ option.text }}
                                </b-form-checkbox>
                            </b-form-checkbox-group>
                        </b-form-group>
                    </div>
                </div>
            </div>
        </div>
    </facet-template>
</template>

<script>
import _ from "lodash";
import FacetTemplate from "./FacetTemplate";
import { get, sync } from "vuex-pathify";

export default {
    name: "StockTypeFacet",

    components: {
        FacetTemplate
    },

    computed: {
        availableStockTypes: get("vehicleSearch/facets@stockTypes"),
        selectedStockTypes: sync("vehicleSearch/filters@stockTypes"),
        availableStockTypeOptions() {
            return _.map(this.availableStockTypes, stockType => {
                return {
                    text: this.lowerCaseThenCapitalize(stockType.name),
                    value: stockType.name
                };
            });
        },
        isFiltered() {
            return !_.isEmpty(this.selectedStockTypes);
        }
    },

    methods: {
        clearSelectedStockTypes() {
            if (_.size(this.selectedStockTypes) > 0) {
                this.selectedStockTypes = [];
            }
        },

        lowerCaseThenCapitalize(value) {
            if (!value) return "";
            return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
        }
    }
};
</script>
