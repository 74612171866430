<template>
    <span class="tooltip-disclaimer">
        <b-link :id="$id('tooltip-disclaimer')" tabindex="0">
            <i
                aria-hidden="true"
                class="icon ion-ios-information-outline"
                :class="{ 'deal-card': isDealCard }"
            />
        </b-link>
        <b-popover
            :target="$id('tooltip-disclaimer')"
            triggers="click blur focus"
            :show.sync="show"
        >
            <b-link class="float-right pl-1" @click="show = !show">
                <i
                    aria-hidden="true"
                    class="icon ion-ios-close-outline close-tooltip"
                />
            </b-link>
            <!-- eslint-disable-next-line vue/no-v-html -->
            <span v-html="title" />
        </b-popover>
    </span>
</template>

<style lang="scss">
.tooltip-disclaimer {
    > a {
        outline: none;
    }
}
.close-tooltip {
    color: #212529;
    font-size: 14px;
}
.deal-card {
    color: #fff;
}
@media (max-width: 425px) {
    .deal-card {
        color: #212529;
    }
}
</style>

<script>
export default {
    name: "TooltipDisclaimer",

    props: {
        title: {
            required: true,
            type: String
        },
        isDealCard: {
            required: false,
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            show: false
        };
    }
};
</script>
