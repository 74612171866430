var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-none d-md-flex", attrs: { id: "dealerStickyFooter" } },
    [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            {
              staticClass:
                "d-flex flex-row justify-content-between content-block w-100"
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "d-flex flex-column justify-content-center align-items-start dealer-block"
                },
                [
                  _c("span", { staticClass: "dealer font-weight-bold" }, [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.dealer.name) +
                        "\n                    "
                    )
                  ]),
                  _vm._v(" "),
                  _vm.isDeliveryOnly
                    ? _c("span", { staticClass: "address" }, [
                        _vm._v(
                          "\n                        Nationwide shipping to"
                        ),
                        _c("br"),
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm.user.city) +
                            ", " +
                            _vm._s(_vm.user.stateCode) +
                            "\n                        " +
                            _vm._s(_vm.user.zipCode) +
                            "\n                    "
                        )
                      ])
                    : _c("span", { staticClass: "address" }, [
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm.dealer.address1) +
                            " "
                        ),
                        _c("br"),
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm.dealer.city) +
                            ", " +
                            _vm._s(_vm.dealer.state) +
                            "\n                        " +
                            _vm._s(_vm.dealer.zipCode) +
                            "\n                    "
                        )
                      ])
                ]
              ),
              _vm._v(" "),
              _vm._t("cta")
            ],
            2
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }