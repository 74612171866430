import listingsUrlStorage from "@/lib/listingsUrlStorage";

export default {
    methods: {
        storeCurrentUrl(url = null, text = null) {
            const value = {
                url: url,
                text: text
            };

            if (typeof value.url !== "string") {
                value.url = window.location.href;
            }

            listingsUrlStorage.set(value.url, value.text);
        }
    }
};
