import { get } from "vuex-pathify";

const navigationHelpers = {
    computed: {
        isSignedIn: get("user/isSignedIn"),
        campaignType: get("campaign/type"),
        isStateSycWhitelisted: get("location/isStateSycWhitelisted"),
        sellYourCarEnabled: get("location/isSellYourCarEnabled"),
        isWavpp() {
            return this.campaignType === "wavpp";
        },
        showBuy() {
            return !this.isWavpp || this.isSignedIn;
        },
        showSell() {
            return this.sellYourCarEnabled && this.isStateSycWhitelisted;
        },
        showFinance() {
            return !this.isWavpp;
        },
        showInsure() {
            return !this.isWavpp;
        },
        showProtect() {
            return !this.isWavpp;
        },
        showHowItWorks() {
            return !this.isWavpp;
        },
        showPortal() {
            return !this.isWavpp;
        },
        showBecomeCertified() {
            return !this.isWavpp;
        }
    }
};

export default navigationHelpers;
