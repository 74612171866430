var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mt-1", attrs: { id: "filter-by" } },
    [
      _c(
        "b-card",
        { attrs: { "no-body": "" } },
        [
          _c(
            "div",
            {
              staticClass:
                "filter-header d-flex justify-content-start align-items-center pl-3"
            },
            [
              _c(
                "h5",
                {
                  staticClass: "mb-0",
                  attrs: { slot: "header" },
                  slot: "header"
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.$t("component.facets.filter")) +
                      "\n            "
                  )
                ]
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "b-card-body",
            { staticClass: "facets p-0" },
            [
              _c("facets", {
                attrs: {
                  "is-loading": _vm.isLoading,
                  "show-payment-type-facet": _vm.showPaymentTypeFacet,
                  "show-miles-facet": _vm.showMilesFacet,
                  "show-years-facet": _vm.showYearsFacet,
                  "show-stock-type-facet": _vm.showStockTypeFacet,
                  "show-exterior-color-facet": _vm.showExteriorColorFacet,
                  "show-interior-color-facet": _vm.showInteriorColorFacet,
                  "show-deal-badge-facet": _vm.showDealBadgeFacet,
                  "is-dealer-inventory-search": _vm.isDealerInventorySearch
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }