<template>
    <b-container>
        <span
            class="sub-form-title">Sign up to your CarSaver Profile to access this feature.
        </span>
        <b-form>
            <b-row>
                <b-col>
                    <b-form-group
                        label="First Name"
                    >
                        <b-form-input
                            name="first-name"
                            v-model="$v.form.firstName.$model"
                            placeholder="Enter first name"
                            aria-describedby="'firstNameInputLiveFeedback'"
                            :state="validateState('firstName')"
                        ></b-form-input>
                        <b-form-invalid-feedback id="firstNameInputLiveFeedback">
                            {{ generateErrorMessage('firstName') }}
                        </b-form-invalid-feedback>
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-group
                        label="Last Name"
                    >
                        <b-form-input
                            name="last-name"
                            v-model="$v.form.lastName.$model"
                            placeholder="Enter last name"
                            aria-describedby="lastNameInputLiveFeedback"
                            :state="validateState('lastName')"
                        ></b-form-input>
                        <b-form-invalid-feedback id="lastNameInputLiveFeedback">
                            {{ generateErrorMessage('lastName') }}
                        </b-form-invalid-feedback>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-form-group
                        label="Email Address"
                    >
                        <b-form-input
                            name="email"
                            v-model="$v.form.email.$model"
                            placeholder="Enter email address"
                            aria-describedby="emailInputLiveFeedback"
                            :state="validateState('email')"
                        ></b-form-input>
                        <b-form-invalid-feedback id="emailInputLiveFeedback">
                            {{ generateErrorMessage('email') }}
                        </b-form-invalid-feedback>
                        <small
                            v-if="errors && errors.has('email')"
                            class="error-block text-danger"
                        >
                            {{ errors.get("email") }}
                        </small>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-form-group label="Time to Purchase">
                        <b-form-select
                            name="selected"
                            v-model="$v.form.selected.$model"
                            aria-describedby="selectedLiveFeedback"
                            :options="options"
                            :state="validateState('selected')"
                            @input="setPurchaseTimeline">
                            <template #first>
                                <b-form-select-option :value="null" disabled>-- Select Timeline --
                                </b-form-select-option>
                            </template>
                        </b-form-select>
                        <b-form-invalid-feedback id="selectedLiveFeedback">
                            {{ generateErrorMessage('selected') }}
                        </b-form-invalid-feedback>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-form-group
                        label="Phone Number"
                    >
                        <b-form-input
                            id="phone-number"
                            name="phone-number"
                            v-model="$v.form.phoneNumber.$model"
                            placeholder="Enter phone number"
                            aria-describedby="phoneNumberInputLiveFeedback"
                            :state="validateState('phoneNumber')"
                        ></b-form-input>
                        <b-form-invalid-feedback id="phoneNumberInputLiveFeedback">
                            {{ generateErrorMessage('phoneNumber') }}
                        </b-form-invalid-feedback>
                        <small
                            v-if="errors && errors.has('phoneNumber')"
                            class="error-block text-danger"
                        >
                            {{ errors.get("phoneNumber") }}
                        </small>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-form-group>
                        <b-row>
                            <b-col>
                                <label>Password</label>
                            </b-col>
                            <b-col style="text-align: end">
                                <b-link variant="link" @click="showPassword">
                                    {{ passwordButtonText }}
                                </b-link>
                            </b-col>
                        </b-row>
                        <b-form-input
                            v-model="$v.form.password.$model"
                            placeholder="Enter password"
                            :type="type"
                            aria-describedby="passwordInputLiveFeedback"
                            :state="validateState('password')"
                        ></b-form-input>
                        <b-form-invalid-feedback id="passwordInputLiveFeedback">
                            {{ generateErrorMessage('password') }}
                        </b-form-invalid-feedback>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-form-group
                    >
                        <b-form-checkbox
                            v-model="form.smsAllowed"
                        >It's okay to text me <em>(you can opt out at any time)</em>
                        </b-form-checkbox>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row class="mb-3">
                <b-col>
                    <b-button
                        variant="orange"
                        class="mx-auto submit-btn"
                        @click="createUser()"
                    >
                        Create My Profile
                    </b-button>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <span class="mx-auto">
                        <em>By Signing up you agree to CarSaver's&nbsp;<b-link href="/tos">terms of
                            use
                        </b-link>&nbsp;&&nbsp;<b-link
                            href="/privacy">privacy policy
                        </b-link>
                        </em>
                    </span>
                </b-col>
            </b-row>
        </b-form>
    </b-container>
</template>
<script>
import purchaseTimelineMixin from "@/mixins/purchaseTimelineMixin";
import {purchaseTimelinesEnum} from "@/enum/purchaseTimelinesEnum";
import {get, dispatch} from "vuex-pathify";
import {validationMixin} from "vuelidate";
import {
    required,
    email,
    minLength,
    maxLength,
    numeric
} from "vuelidate/lib/validators";

export default {
    name: 'SignUpModalForm',
    mixins: [purchaseTimelineMixin, validationMixin],
    data() {
        return {
            form: {
                firstName: '',
                lastName: '',
                email: '',
                phoneNumber: '',
                password: '',
                selected: null,
                smsAllowed: false
            },
            options: Object.values(purchaseTimelinesEnum),
            type: 'password',
            passwordButtonText: 'Show'
        }
    },
    computed: {
        errors: get("user/createUserErrors")
    },
    validations: {
        form: {
            firstName: {
                required,
                maxLength: maxLength(255)
            },
            lastName: {
                required,
                maxLength: maxLength(255)
            },
            email: {
                required,
                email,
                maxLength: maxLength(255)
            },
            selected: {
                required
            },
            phoneNumber: {
                required,
                numeric,
                minLength: minLength(10),
                maxLength: maxLength(10)
            },
            password: {
                required,
                minLength: minLength(6),
                maxLength: maxLength(18)
            }
        }
    },
    methods: {
        validateState(formProperty) {
            const {$dirty, $error} = this.$v.form[formProperty];
            if (this.errors && this.errors.has(formProperty)) {
                return $error
            }
            return $dirty ? !$error : null;
        },
        createUser() {
            console.log(this.form)
            this.$v.$touch()
            if (!this.$v.form.$anyError) {
                const userForm = {
                    firstName: this.form.firstName,
                    lastName: this.form.lastName,
                    email: this.form.email,
                    phoneNumber: this.form.phoneNumber,
                    password: this.form.password,
                    purchaseTimeFrameStartDate: this.startDate,
                    purchaseTimeFrameEndDate: this.endDate,
                    smsAllowed: this.form.smsAllowed
                }
                dispatch("user/createUser", userForm)
                    .catch(error => {
                        console.log('errors: ', error)
                    })

            }

        },
        generateErrorMessage(formProperty) {
            let errorMessage = ''
            if (this.$v.form[formProperty].required === false) {
                errorMessage = `This is a required field.`
            } else if (this.$v.form[formProperty].numeric === false) {
                errorMessage = 'Only numbers are allowed.'
            } else if (this.$v.form[formProperty].email === false) {
                errorMessage = 'Incorrect email format.'
            } else if (this.$v.form[formProperty].minLength === false) {
                errorMessage = 'Below minimum length.'
            } else if (this.$v.form[formProperty].maxLength === false) {
                errorMessage = 'Exceeded maximum length.'
            }
            return errorMessage
        },
        showPassword() {
            if (this.type === 'password') {
                this.type = 'text'
                this.passwordButtonText = 'Hide'
            } else {
                this.type = 'password'
                this.passwordButtonText = 'Show'
            }
        }
    }
}
</script>
<style>
.submit-btn {
    width: 100%;
}
</style>
