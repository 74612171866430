<template>
    <dealer-sticky-footer :dealer="dealer">
        <div
            slot="cta"
            class="d-flex align-items-center justify-content-between button-wrapper"
        >
            <div class="mx-2">
                <b-button
                    :href="`/dealer/${dealer.id}/directions`"
                    variant="secondary"
                    target="_blank"
                    class="footer-btn"
                    block
                >
                    {{ $t("component.dealerStickyFooter.directions") }}
                </b-button>
            </div>
        </div>
    </dealer-sticky-footer>
</template>

<script>
import { get } from "vuex-pathify";
import DealerStickyFooter from "Components/DealerStickyFooter";

export default {
    name: "ListingsDealerStickyFooter",
    components: {
        DealerStickyFooter
    },
    computed: {
        dealer: get("used/selectedDealer")
    }
};
</script>

<style lang="scss" scoped>
.button-wrapper {
    .footer-btn {
        width: 125px;
        height: 30px;
    }
}
</style>
