<template>
    <facet-template
        :show-clear-link="isFiltered"
        :facet-name="'transmissions'"
        @clear="clearSelectedTransmissions"
    >
        <span slot="facetLabel">
            {{ $t("component.facets.transmission") }}
        </span>

        <div slot="facetBody">
            <div class="container">
                <div class="row">
                    <div class="col-12 p-0 mb-0">
                        <div
                            class="d-flex flex-row justify-content-around flex-wrap"
                        >
                            <div
                                v-for="transmission in filterableTransmissions"
                                :key="transmission.id"
                            >
                                <transmission-icon
                                    :transmission="transmission"
                                    :selected="
                                        isTransmissionSelected(transmission)
                                    "
                                    @click.native="
                                        toggleTransmission(transmission)
                                    "
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </facet-template>
</template>

<script>
import FacetTemplate from "./FacetTemplate";
import TransmissionIcon from "../../TransmissionIcon/index";
import { get, sync } from "vuex-pathify";
import _ from "lodash";

export default {
    name: "TransmissionFacet",

    components: {
        FacetTemplate,
        TransmissionIcon
    },

    computed: {
        availableTransmissions: get("vehicleSearch/facets@transmissions"),
        selectedTransmissions: sync("vehicleSearch/filters@transmissions"),
        /* Dont show "UNKNOWN" transmission facet. It will always be appended server side */
        filterableTransmissions() {
            return this.availableTransmissions.filter(
                transmission => transmission.name !== "UNKNOWN"
            );
        },
        isFiltered() {
            return !_.isEmpty(this.selectedTransmissions);
        }
    },

    methods: {
        clearSelectedTransmissions() {
            if (_.size(this.selectedTransmissions) > 0) {
                this.selectedTransmissions = [];
            }
        },
        toggleTransmission(transmission) {
            const transmissionIsAlreadySelected = this.isTransmissionSelected(
                transmission
            );

            if (transmissionIsAlreadySelected) {
                this.removeTransmissionFromFilters(transmission);
            } else {
                this.addTransmissionToFilters(transmission);
            }
        },
        addTransmissionToFilters(transmission) {
            this.selectedTransmissions = this.selectedTransmissions.concat(
                transmission.name
            );
        },
        removeTransmissionFromFilters(transmission) {
            this.selectedTransmissions = this.selectedTransmissions.filter(
                transmissionName => transmissionName !== transmission.name
            );
        },
        isTransmissionSelected(transmission) {
            return _.includes(this.selectedTransmissions, transmission.name);
        }
    }
};
</script>
