<template>
    <div v-if="showShopComLinkBack" class="shop-com-link-back-block">
        <div
            class="container-fluid h-100 d-flex justify-content-between align-items-center"
        >
            <div class="link-back-container">
                <a
                    class="link-back"
                    href="https://www.shop.com"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    &lt; Back to SHOP.COM
                </a>
            </div>
            <div class="d-none d-lg-inline  w-100 text-center">
                <span id="link-back-message">
                    You are no longer on SHOP.COM. SHOP.COM is a trusted partner
                    that makes auto buying services available to Carsaver
                    customers.
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import bannersViewedStorage from "@/lib/bannersViewedStorage";
import { dispatch, get } from "vuex-pathify";

export default {
    name: "ShopComLinkBackBanner",
    data() {
        return {
            isBannerEnabled: false,
            viewedBanners: bannersViewedStorage.get(),
            bannerName: "shopComBanner",
            enabledPaths: ["/", ""]
        };
    },

    computed: {
        campaignType: get("campaign/type"),
        bannerAlreadyViewed() {
            if (Array.isArray(this.viewedBanners)) {
                return this.viewedBanners.indexOf(this.bannerName) !== -1;
            }

            return false;
        },
        showShopComLinkBack() {
            return this.isCampaignEnabled && this.isBannerEnabled;
        },

        isCampaignEnabled() {
            const enabledCampaign = "shop";

            if (this.campaignType === enabledCampaign) {
                return true;
            }

            return false;
        }
    },

    methods: {
        isPathEnabled() {
            const path = window.location.pathname;

            return this.enabledPaths.indexOf(path) !== -1;
        },
        resetDynamicHeaderHeight() {
            dispatch("headerTransitionStore/setCutOffHeight");
        },
        disableBanner() {
            this.isBannerEnabled = false;
            setTimeout(this.resetDynamicHeaderHeight);
        },
        addToViewedList() {
            bannersViewedStorage.set(this.bannerName); // add to list of viewed banners
        }
    }
};
</script>

<style lang="scss">
$link-back-block-height: 40px;

.shop-com-link-back-block {
    height: $link-back-block-height;
    width: 100%;
    background-color: #f0f1f6;
    color: #444444;
    font-family: Helvetica, sans-serif;
    font-size: 14px;

    .link-back-container {
        min-width: 130px;
    }

    .link-back {
        font-size: 12px;
        letter-spacing: 0.04px;
        color: inherit !important;
    }

    .close-btn-wrapper {
        display: flex;
        align-items: center;
    }
    .close-btn {
        border: none;
        background: transparent;
        color: #444;
        font-size: 12px;

        &:focus {
            outline: none;
        }
    }
}
</style>
