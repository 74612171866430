<template>
    <facet-template
        :show-clear-link="isFiltered"
        :facet-name="'passengerCapacities'"
        @clear="clearSelectedPassengerCapacities"
    >
        <span slot="facetLabel">
            {{ $t("component.facets.seatCount") }}
        </span>

        <div slot="facetBody">
            <div class="container">
                <div class="row">
                    <div class="col-12 p-0">
                        <b-form-group class="m-0">
                            <b-form-checkbox-group
                                v-model="selectedPassengerCapacities"
                                plain
                                stacked
                            >
                                <b-form-checkbox
                                    v-for="option in availablePassengerCapacityOptions"
                                    :key="option.value"
                                    :value="option.value"
                                    class="mb-2"
                                >
                                    {{ option.text }}
                                </b-form-checkbox>
                            </b-form-checkbox-group>
                        </b-form-group>
                    </div>
                </div>
            </div>
        </div>
    </facet-template>
</template>

<script>
import FacetTemplate from "./FacetTemplate";
import { get, sync } from "vuex-pathify";
import _ from "lodash";

export default {
    name: "PassengerCapacityFacet",
    components: {
        FacetTemplate
    },
    computed: {
        selectedPassengerCapacities: sync(
            "vehicleSearch/filters@passengerCapacities"
        ),
        availablePassengerCapacities: get(
            "vehicleSearch/facets@passengerCapacities"
        ),
        availablePassengerCapacityOptions() {
            const options = _.map(
                this.availablePassengerCapacities,
                passengerCapacity => {
                    return {
                        text: `${passengerCapacity.capacity} Seats`,
                        value: passengerCapacity.capacity
                    };
                }
            );

            return options;
        },
        isFiltered() {
            return !_.isEmpty(this.selectedPassengerCapacities);
        }
    },
    methods: {
        clearSelectedPassengerCapacities() {
            if (this.selectedPassengerCapacities.length) {
                this.selectedPassengerCapacities = [];
            }
        }
    }
};
</script>
