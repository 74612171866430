export default function() {
    document.addEventListener("DOMContentLoaded", function() {
        const isChrome =
            /Chrome/.test(navigator.userAgent) &&
            /Google Inc/.test(navigator.vendor);

        if (window.location.hash && isChrome) {
            setTimeout(function() {
                const hash = window.location.hash;

                window.location.hash = "";
                window.location.hash = hash;
            }, 300);
        }
    });
}
