import "ionicons/css/ionicons.css";
import "jquery";
import "bootstrap/dist/js/bootstrap";
import "./smooth-scrolling";
import URL from "url-parse";
import _ from "lodash";
import numeral from "numeral";
import vueMoment from "vue-moment";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginVue from "@bugsnag/plugin-vue";
import IdleVue from "idle-vue";
import EventBus from "@/event-bus";
import BootstrapVue from "bootstrap-vue";
import VueGeoLocation from "vue-browser-geolocation";
import GeoLocation from "Components/GeoLocation";
import MainNavigation from "Modules/MainNavigation";
import AnimatedMainNavigation from "Components/AnimatedMainNavigation";
import HeaderTransitionWrapper from "Components/HeaderTransitionWrapper";
import SessionReset from "Modules/SessionReset";
import VueGtm from "vue-gtm";
import moveModalToBody from "@/mixins/moveModalToBody";
import CsFooter from "@/components/Footer";
import VueMq from "vue-mq";
import ClientUrlStorer from "Components/ClientUrlStorer";
import UniqueId from "vue-unique-id";
const VueAxe = import("vue-axe");
import { datadogRum } from "@datadog/browser-rum";

class CarSaverPlugin {
    constructor() {
        this.isWebpSupported = false;
        try {
            this.isWebpSupported =
                document
                    .createElement("canvas")
                    .toDataURL("image/webp")
                    .indexOf("data:image/webp") === 0;
        } catch (err) {
            console.info("This browser not support Webp");
        }

        this.environment = {
            PROD: "prod",
            BETA: "beta",
            STAGING: "beta",
            LOCAL: "local"
        };
    }

    install(Vue) {
        const environment =
            _.get(window, "appConfig.env[0]", this.environment.LOCAL) ||
            this.environment.LOCAL;
        const sessionReset = {
            idleTime: 300000 // 5 minutes
        };

        this.initDatadogRum(environment);

        Bugsnag.start({
            apiKey: "42f223030ba89a7acdaa81c70b1881ed",
            plugins: [new BugsnagPluginVue(Vue)],
            releaseStage: environment,
            enabledReleaseStages: [
                this.environment.PROD,
                this.environment.BETA
            ],
            onError: function(event) {
                // Make sure FullStory object exists.
                if (window.FS && window.FS.getCurrentSessionURL) {
                    event.addMetadata("fullstory", {
                        urlAtTime: window.FS.getCurrentSessionURL(true)
                    });
                }
            },
            user: _.get(window, "_USER", null)
        });

        // Excludes PROD environment
        if (environment !== this.environment.PROD) {
            VueAxe.then(axe => {
                Vue.use(axe.default, {
                    allowConsoleClears: false,
                    auto: true
                });
            });
        }

        Vue.use(IdleVue, {
            eventEmitter: EventBus,
            idleTime: sessionReset.idleTime
        });

        Vue.use(UniqueId);

        Vue.use(VueGeoLocation);

        Vue.use(VueMq, {
            breakpoints: {
                xs: 576,
                sm: 768,
                mg: 992,
                lg: 1200,
                xl: Infinity
            }
        });

        Vue.use(BootstrapVue);

        Vue.use(vueMoment);

        Vue.use(VueGtm, {
            enabled: environment === this.environment.PROD,
            debug: environment !== this.environment.PROD
        });

        Vue.directive("href-lang", {
            bind: function(el) {
                const baseHref = el.getAttribute("href");
                const lang = window._LANG;

                el.setAttribute("href", `/${lang}${baseHref}`);
            }
        });

        /**
         * Appends the current URL to the associated element's
         * href as query param 'returnUrl'. Accepts an optional
         * object which will append the provided key/val pairs
         * as query params ie:
         *
         * {
         *     modal : 'dealer-link'
         * }
         *
         * will result in the url being passed in as :
         *
         * returnUrl=currentUrlEncoded%3Fmodal%3Ddealer-link
         */
        Vue.directive("href-return-url", {
            bind: function(el, binding) {
                const baseHref = el.getAttribute("href");
                const url = new URL(baseHref, true);
                const returnUrl = new URL(window.location.href, true);

                const args = binding.value;
                _.forIn(args, (value, key) => {
                    returnUrl.query[key] = value;
                });

                url.query.returnUrl = returnUrl;
                el.setAttribute("href", `${url}`);
            }
        });

        Vue.directive("webp", (el, binding, vnode) => {
            if (vnode.tag !== "img") {
                return;
            }

            if (this.isWebpSupported) {
                try {
                    el.src = el.src.replace(/(\.jpg|\.png)/g, ".webp");
                } catch (e) {
                    console.error(e);
                }
            }
        });

        Vue.filter("capitalize", function(value) {
            if (!value) return "";

            if (value === "suv") {
                return value.toUpperCase();
            }
            value = value.toString();
            return value.charAt(0).toUpperCase() + value.slice(1);
        });

        Vue.filter("upperCase", function(value) {
            if (!value) return "";

            value = value.toString();
            return value.toUpperCase();
        });

        Vue.filter("phone", function(phone) {
            if (!_.isNil(phone)) {
                return phone
                    .replace(/[^0-9]/g, "")
                    .replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
            }

            return phone;
        });

        Vue.filter("numeral", function(val, format) {
            if (!_.isNumber(val)) {
                return val;
            }

            return numeral(val).format(format);
        });

        Vue.filter("emptyDash", function(val, path) {
            if (path !== undefined) {
                val = _.get(val, path);
            }

            if (_.isNil(val)) {
                return "- -";
            }

            return val;
        });

        Vue.filter("truncate", function(string, options) {
            return _.truncate(string, options);
        });

        Vue.filter("round", function(val, decimals) {
            if (!_.isNumber(val)) {
                return val;
            }

            if (!_.isNil(decimals) && _.isNumber(decimals)) {
                console.log("decimals", decimals);
                return Number(
                    Math.round(val + "e" + decimals) + "e-" + decimals
                );
            }

            return Math.round(val);
        });

        Vue.filter("abs", function(val) {
            if (!_.isNumber(val)) {
                return val;
            }

            return Math.abs(val);
        });

        Vue.mixin(moveModalToBody);

        Vue.component("CsFooter", CsFooter);
        Vue.component("GeoLocation", GeoLocation);
        Vue.component("SessionReset", SessionReset);
        Vue.component("AnimatedMainNavigation", AnimatedMainNavigation);
        Vue.component("MainNavigation", MainNavigation);
        Vue.component("HeaderTransitionWrapper", HeaderTransitionWrapper);
        Vue.component("ClientUrlStorer", ClientUrlStorer);
    }

    initDatadogRum(environment) {
        let env = environment;
        let baseConfig = {
            applicationId: "d5cd819a-d9b5-493c-a5cb-5408fd6b0607",
            clientToken: "pubde76c19eb4c08dc8fba3fdaaca196e70",
            site: "datadoghq.com",
            service: "carsaver---offers",
            env,
            // Specify a version number to identify the deployed version of your application in Datadog
            // version: '1.0.0',
            sampleRate: 0,
            premiumSampleRate: 100,
            trackInteractions: true,
            defaultPrivacyLevel: "mask-user-input"
        };
        let samplingRatings = {
            prod: {
                sampleRate: 0,
                premiumSampleRate: 0
            },
            beta: {
                sampleRate: 0,
                premiumSampleRate: 0
            },
            local: {
                sampleRate: 0,
                premiumSampleRate: 0
            }
        };
        let configs = samplingRatings[env]
            ? { ...baseConfig, ...samplingRatings[env] }
            : { ...baseConfig };

        datadogRum.init(configs);
        datadogRum.startSessionReplayRecording();
    }
}

export default new CarSaverPlugin();
