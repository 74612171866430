import Errors from "./errors";
import api from "../api";

export default class Form {
    constructor(data) {
        this.originalData = data;

        for (const field in data) {
            this[field] = data[field];
        }

        this.errors = new Errors();
    }

    data() {
        const data = {};

        for (const property in this.originalData) {
            data[property] = this[property];
        }

        return data;
    }

    reset() {
        for (const field in this.originalData) {
            this[field] = "";
        }

        this.errors.clear();
    }

    submit(url) {
        return new Promise((resolve, reject) => {
            api.post(url, this.data())
                .then(response => {
                    this.onSuccess(response);

                    resolve(response);
                })
                .catch(error => {
                    this.onFail(error.response.data.errors);

                    reject(error.response.data.errors);
                });
        });
    }

    onSuccess() {
        this.reset();
    }

    onFail(errors) {
        this.errors.record(errors);
    }
}
