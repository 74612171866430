<template>
    <facet-template
        :show-clear-link="isFiltered"
        :facet-name="'bodyStyles'"
        class="body-styles"
        @clear="clearSelectedBodyStyles"
    >
        <span slot="facetLabel">
            {{ $t("component.facets.bodyStyle") }}
        </span>

        <div slot="facetBody">
            <div class="d-flex flex-wrap">
                <div
                    v-for="bodyStyle in validBodyStyles"
                    :id="bodyStyle.id"
                    :key="bodyStyle.id"
                    class="text-center mb-2 body-style"
                >
                    <!-- selected style is based on applied filters in vuex -->
                    <div
                        class="body-style-wrapper mb-3"
                        @click="toggleBodyStyle(bodyStyle)"
                    >
                        <div class="body-style-icon">
                            <body-style-icon
                                :id="bodyStyle.id"
                                :selected="bodyStyleSelected(bodyStyle)"
                            />
                        </div>
                        <small>{{ bodyStyle.name }}</small>
                    </div>
                </div>
            </div>
        </div>
    </facet-template>
</template>

<script>
import FacetTemplate from "./FacetTemplate";
import BodyStyleIcon from "Components/BodyStyleIcon";
import { get, sync } from "vuex-pathify";
import _ from "lodash";
import mixin from "./mixin";

export default {
    name: "BodyStyleFacet",
    components: {
        BodyStyleIcon,
        FacetTemplate
    },
    mixins: [mixin],
    computed: {
        selectedBodyStyles: sync("vehicleSearch/filters@bodyStyles"),
        availableBodyStyles: get("vehicleSearch/facets@bodyStyles"),
        validBodyStyles() {
            return _.filter(this.availableBodyStyles, bodyStyle => {
                return bodyStyle != null;
            });
        },
        isFiltered() {
            return !_.isEmpty(this.selectedBodyStyles);
        }
    },
    methods: {
        clearSelectedBodyStyles() {
            if (this.selectedBodyStyles.length) {
                this.selectedBodyStyles = [];
            }
        },
        toggleBodyStyle(clickedBodyStyle) {
            const userIsAddingBodyStyleFilter =
                this.selectedBodyStyles.indexOf(clickedBodyStyle.id) === -1;

            if (userIsAddingBodyStyleFilter) {
                this.selectedBodyStyles = this.selectedBodyStyles.concat(
                    clickedBodyStyle.id
                );
            } else {
                this.selectedBodyStyles = this.selectedBodyStyles.filter(
                    bStyleId => bStyleId !== clickedBodyStyle.id
                );
            }
        },
        bodyStyleSelected(bodyStyle) {
            return this.selectedBodyStyles.indexOf(bodyStyle.id) > -1;
        }
    }
};
</script>
<style lang="scss">
.body-styles {
    .facet-body {
        margin-top: 26px !important;
        margin-bottom: 0 !important;
    }

    .body-style {
        width: 50%;

        @include media-breakpoint-down(sm) {
            width: 33%;
        }
    }

    .body-style-wrapper {
        cursor: pointer;

        .body-style-icon {
            height: 30px;
        }
    }

    i {
        font-size: 25px;
    }
    .icon-sedan,
    .icon-suv,
    .icon-convertible,
    .icon-truck,
    .icon-van,
    .icon-hatchback,
    .icon-wagon,
    .icon-coupe {
        color: $primary-blue !important;
    }

    @include media-breakpoint-down(md) {
        i {
            font-size: 20px;
            line-height: 30px;
            vertical-align: bottom;
        }
    }
}
</style>
