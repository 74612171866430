<!-- Template: CarSaver Modal Component -->
<template>
    <b-modal
        :id="id"
        ref="csmodal"
        :modal-class="modalClass"
        :size="size"
        :body-class="bodyClass"
        :footer-class="footerClass"
        :lazy="lazy"
        :hide-footer="hideFooter"
        :hide-header="hideHeader"
        :centered="centered"
        @hidden="onHidden"
        @shown="onShown"
    >
        <slot slot="modal-title" name="modal-title">
            You should be overriding this modal title.
        </slot>
        <div slot="modal-header-close">
            <i class="ion-ios-close-outline" aria-hidden="true"/>
        </div>

        <slot class="modal-body">You should be overriding this modal body.</slot>

        <slot slot="modal-footer" name="modal-footer">
            You should be overriding this modal footer, if you want to hide the
            footer use the class: (hide-footer="true")
        </slot>
    </b-modal>
</template>

<script>
export default {
    name: "CSModal",

    props: {
        id: {
            type: String,
            required: false,
            default: null
        },
        size: {
            type: String,
            required: false,
            default: null
        },
        centered: {
            type: Boolean,
            required: false,
            default: false
        },
        bodyClass: {
            type: String,
            required: false,
            default: null
        },
        footerClass: {
            type: String,
            required: false,
            default: null
        },
        lazy: {
            type: Boolean,
            default: true
        },
        hideFooter: {
            type: Boolean,
            required: false,
            default: false
        },
        hideHeader: {
            type: Boolean,
            required: false,
            default: false
        },
        modalClass: {
            type: String,
            required: false,
            default: null
        }
    },

    methods: {
        show() {
            this.$refs.csmodal.show();
        },
        hide() {
            this.$refs.csmodal.hide();
        },
        toggle() {
            if (this.$refs.csmodal.is_show) {
                this.hide();
            } else {
                this.show();
            }
        },
        onHidden(evt) {
            console.log('evt hidden: ', evt)
            this.$emit("hidden", evt);
        },
        onShown(evt) {
            this.$emit("shown", evt);
        }
    }
};
</script>
<style>
    .modal-body {
        padding: .25rem 1rem 1rem 1rem !important;
    }
</style>

