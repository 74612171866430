var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "image",
      staticClass: "progressive-vehicle-image",
      on: { click: _vm.click }
    },
    [
      !_vm.shouldImageRender
        ? _c("canvas", {
            ref: "canvas",
            staticClass: "canvas",
            attrs: { width: "1", height: "1" }
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "vehicle-image-wrapper inventory-desktop",
          style: _vm.wrapperStyle
        },
        [
          _c(
            "transition",
            {
              attrs: {
                "enter-class": "vehicle-image-enter",
                "enter-active-class": "vehicle-image-before-enter"
              }
            },
            [
              _c("div", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.shouldImageRender,
                    expression: "shouldImageRender"
                  }
                ],
                ref: "main",
                staticClass: "inventory-desktop-image",
                class: _vm.imageClassName,
                style: _vm.imageStyle,
                attrs: { alt: _vm.alt }
              })
            ]
          ),
          _vm._v(" "),
          !_vm.shouldImageRender
            ? _c(
                "div",
                { staticClass: "body-style d-flex align-items-center" },
                [
                  _c("body-style-icon", {
                    attrs: { id: _vm.bodyStyleOrDefault }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }