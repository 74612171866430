import _ from "lodash";
import moment from "moment-timezone";

export default {
    methods: {
        isExpired(location) {
            const isUserSet = _.get(location, "type") === "USER";
            if (isUserSet) {
                return false;
            }

            const createdTime = _.get(
                location,
                "created",
                moment().toISOString()
            );
            return moment(createdTime).isBefore(moment().subtract(3, "days"));
        }
    }
};
