import api from "../../api";
import _ from "lodash";

const state = {
    top: {
        biggestSavings: null,
        lowestPrice: null,
        lowestPayment: null,
        isLoading: false,
        fetched: false
    },

    bodyStyleDeals: {
        isLoading: false,
        fetched: false,
        deals: []
    }
};

const mutations = {
    SET_TOP: (state, payload) => {
        state.top = payload;
        state.top.isLoading = false;
        state.top.fetched = true;
    },

    LOAD_TOP_DEALS: state => {
        state.top.isLoading = true;
        state.top.fetched = false;
    },

    LOAD_DEALS: state => {
        state.bodyStyleDeals.isLoading = true;
        state.bodyStyleDeals.fetched = false;
    },

    SET_DEALS: (state, payload) => {
        state.bodyStyleDeals.deals = payload;
        state.bodyStyleDeals.isLoading = false;
        state.bodyStyleDeals.fetched = true;
    }
};

const actions = {
    loadTopDeals({ commit }, zipCode) {
        commit("LOAD_TOP_DEALS");
        api.get("/home/top-deals", { zipCode })
            .then(response => {
                commit(
                    "SET_TOP",
                    _.pick(response.data, [
                        "biggestSavings",
                        "lowestPayment",
                        "lowestPrice"
                    ])
                );
            })
            .catch(error => {
                console.error(error);
            });
    },

    loadDeals({ commit }, zipCode) {
        commit("LOAD_DEALS");
        api.get("/home/top-deals/body-styles", { zipCode })
            .then(response => {
                commit(
                    "SET_DEALS",
                    _.map(response.data, deal => {
                        return {
                            bodyStyle: _.get(deal, "bodyStyle"),
                            inventoryCount: _.get(deal, "inventoryCount"),
                            averageSavings: {
                                disclaimer: _.get(
                                    deal,
                                    "averageSavings.disclaimer"
                                ),
                                id: _.get(deal, "averageSavings.id"),
                                value: _.get(deal, "averageSavings.value"),
                                virtual: _.get(deal, "averageSavings.virtual")
                            },
                            biggestSavings: {
                                disclaimer: _.get(
                                    deal,
                                    "biggestSavings.disclaimer"
                                ),
                                id: _.get(deal, "biggestSavings.id"),
                                value: _.get(deal, "biggestSavings.value"),
                                virtual: _.get(deal, "biggestSavings.virtual")
                            },
                            lowestPrice: {
                                disclaimer: _.get(
                                    deal,
                                    "lowestPrice.disclaimer"
                                ),
                                id: _.get(deal, "lowestPrice.id"),
                                type: _.get(deal, "lowestPrice.type"),
                                value: _.get(deal, "lowestPrice.value"),
                                virtual: _.get(deal, "lowestPrice.virtual")
                            },
                            lowestPayment: {
                                disclaimer: _.get(
                                    deal,
                                    "lowestPayment.disclaimer"
                                ),
                                id: _.get(deal, "lowestPayment.id"),
                                type: _.get(deal, "lowestPayment.type"),
                                value: _.get(deal, "lowestPayment.value"),
                                virtual: _.get(deal, "lowestPayment.virtual")
                            }
                        };
                    })
                );
            })
            .catch(error => {
                console.error(error);
            });
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations
};
