<template>
    <footer id="main-footer">
        <div class="container-fluid">
            <div class="social">
                <ul>
                    <li>{{ $t("component.csFooter.about") }}</li>
                    <li v-if="showHowItWorks">
                        <a href="/how-it-works">
                            {{ $t("component.csFooter.howItWorks") }}
                        </a>
                    </li>
                    <li>
                        <a href="/contact">
                            {{ $t("component.csFooter.contact") }}
                        </a>
                    </li>
                    <li>
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://recruiting.paylocity.com/recruiting/jobs/List/1384/CarSaver-Management-LLC"
                        >
                            {{ $t("component.csFooter.careers") }}
                        </a>
                    </li>
                </ul>
                <ul v-if="showBuy">
                    <li>{{ $t("component.csFooter.services") }}</li>
                    <li>
                        <a href="/shop-by">
                            {{ $t("component.csFooter.shopNewCars") }}
                        </a>
                    </li>
                    <li>
                        <a href="/shop/used">
                            {{ $t("component.csFooter.shopUsedCars") }}
                        </a>
                    </li>
                    <li>
                        <a href="/shop-by/make" @click="updateSavedUrl">
                            {{ $t("component.csFooter.buildACar") }}
                        </a>
                    </li>
                    <li v-if="showFinance">
                        <a href="/finance">
                            {{ $t("component.csFooter.finance") }}
                        </a>
                    </li>
                    <li v-if="showInsure">
                        <a href="/insure">
                            {{ $t("component.csFooter.insure") }}
                        </a>
                    </li>
                    <li v-if="showProtect">
                        <a href="/warranty">
                            {{ $t("component.csFooter.lifetimeWarranty") }}
                        </a>
                    </li>
                </ul>
                <ul>
                    <li>{{ $t("component.csFooter.certifiedDealers") }}</li>
                    <li>
                        <a href="/dealer-overview">
                            {{ $t("component.csFooter.dealerOverview") }}
                        </a>
                    </li>
                    <li v-if="showPortal">
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://portal.carsaver.com"
                        >
                            {{ $t("component.csFooter.dealerPortal") }}
                        </a>
                    </li>
                    <li v-if="showBecomeCertified">
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="http://www.carsaverdealers.com"
                        >
                            {{ $t("component.csFooter.becomeCertified") }}
                        </a>
                    </li>
                </ul>
                <ul v-if="showProtect">
                    <li>{{ $t("component.csFooter.lifetimeWarranty") }}</li>
                    <li>
                        <a href="/warranty/faq">
                            {{ $t("component.csFooter.faq") }}
                        </a>
                    </li>
                    <li>
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="http://carsaver.naenwan.com/naenwan/onlineclaims"
                        >
                            {{ $t("component.csFooter.fileClaim") }}
                        </a>
                    </li>
                    <li>
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="http://carsaver.naenwan.com/naenwan/transfer"
                        >
                            {{ $t("component.csFooter.transfer") }}
                        </a>
                    </li>
                    <li>
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="http://carsaver.naenwan.com/OnlineCancellations"
                        >
                            {{ $t("component.csFooter.cancellation") }}
                        </a>
                    </li>
                </ul>
                <ul v-if="!isKiosk" class="icons">
                    <li>{{ $t("component.csFooter.follow") }}</li>
                    <li>
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.facebook.com/CarSaverUSA/"
                        >
                            <i
                                class="ion-social-facebook"
                                title="Facebook"
                                aria-hidden="true"
                            />
                        </a>
                    </li>
                    <li>
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://twitter.com/CarSaverUSA"
                        >
                            <i
                                class="ion-social-twitter"
                                title="Twitter"
                                aria-hidden="true"
                            />
                        </a>
                    </li>
                    <li>
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.instagram.com/carsaverusa/"
                        >
                            <i
                                class="ion-social-instagram-outline"
                                title="Instagram"
                                aria-hidden="true"
                            />
                        </a>
                    </li>
                    <li>
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.youtube.com/channel/UC8qFagdCSfNtl6yWFwNa_vw"
                        >
                            <i
                                class="ion-social-youtube"
                                title="YouTube"
                                aria-hidden="true"
                            />
                        </a>
                    </li>
                </ul>
            </div>
            <hr />
            <div class="policy">
                <ul class="copyright" :class="{ 'tc-spanish': isSpanish }">
                    <li class="item">
                        <span
                            >&copy; {{ currentYear }} CarSaver&trade;. All
                            rights reserved.</span
                        >
                    </li>
                    <li class="item">
                        <span>Vehicle photos &copy; Evox Images</span>
                    </li>
                    <li class="item">
                        <span
                            >&copy; 1986–{{ currentYear }} Chrome Data
                            Solutions, LP</span
                        >
                    </li>
                </ul>
                <ul class="tc" :class="{ 'tc-spanish': isSpanish }">
                    <li class="item">
                        <a
                            target="_blank"
                            href="/tos"
                            rel="noopener noreferrer"
                        >
                            {{ $t("component.csFooter.terms") }}
                        </a>
                    </li>
                    <li class="item">
                        <a
                            target="_blank"
                            href="/privacy"
                            rel="noopener noreferrer"
                        >
                            {{ $t("component.csFooter.privacy") }}
                        </a>
                    </li>
<!--                    <li class="item">-->
<!--                        <a-->
<!--                            target="_blank"-->
<!--                            href="/california-privacy-policy"-->
<!--                            rel="noopener noreferrer"-->
<!--                        >-->
<!--                            {{-->
<!--                                $t("component.csFooter.californiaPrivacyPolicy")-->
<!--                            }}-->
<!--                        </a>-->
<!--                    </li>-->
                    <li class="item">
                        <a
                            target="_blank"
                            href="/privacy#do-not-sell-my-info"
                            rel="noopener noreferrer"
                        >
                            {{ $t("component.csFooter.doNotSell") }}
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </footer>
</template>

<script>
import { get } from "vuex-pathify";
import moment from "moment";
import navigationHelpers from "@/mixins/navigationHelpers";
import listingsUrlStorer from "@/mixins/listingsUrlStorer";

export default {
    name: "CsFooter",

    mixins: [navigationHelpers, listingsUrlStorer],

    computed: {
        lang: get("translated/lang"),
        type: get("campaign/type"),
        isKiosk() {
            return this.type === "kiosk";
        },
        isSpanish() {
            return this.lang === "es";
        },
        currentYear() {
            return moment().format("YYYY");
        }
    },

    methods: {
        updateSavedUrl() {
            this.storeCurrentUrl("/pick-your-flow");
        }
    }
};
</script>

<style lang="scss">
#main-footer {
    background: linear-gradient(0deg, $very-light-gray, $light-gray);
    border-top: 1px solid hsla(190, 5%, 48%, 0.3);
    z-index: 0;
    padding-bottom: 30px;

    > hr {
        margin-top: 18px;
        margin-bottom: 18px;
        border: 0;
        border-top: 1px solid hsla(190, 5%, 48%, 0.3);
    }

    a {
        color: $medium-gray;
        opacity: 0.8;
        cursor: pointer;

        &:hover {
            text-decoration: none;
            opacity: 1;
        }
    }

    .social {
        color: $medium-gray;
        padding-top: 65px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;

        @include media-breakpoint-down(md) {
            padding-top: 40px;
            flex-wrap: wrap;
            overflow: hidden;
            flex-direction: row;
        }

        > ul {
            width: auto;
            padding: 0;
            vertical-align: top;
            list-style-type: none;
            font-size: 16px;
            color: $medium-gray;

            @include media-breakpoint-down(lg) {
                &.icons {
                    display: block;
                }
            }

            > li {
                margin-bottom: 10px;
            }

            > li:first-child {
                font-size: 14px;
                line-height: 18px;
                font-weight: 700;
                padding: 0;
                margin-right: 10px;
                margin-bottom: 16px;
                color: $primary-blue;
                text-transform: uppercase;
                letter-spacing: 1px;
                min-width: 146px;
                font-family: $helvetica-65-medium;
            }

            &.icons {
                min-width: 270px;

                li:not(:first-child) {
                    float: left;
                    margin-right: 14px;
                    margin-bottom: 0;

                    > a {
                        background-color: #fff;
                        border-radius: 50%;
                        display: inline-block;
                        height: 40px;
                        width: 40px;
                        font-size: 24px;
                        line-height: 40px;
                        text-align: center;
                        padding-top: 2px;

                        > i {
                            font-size: 24px;
                            color: $muted-dark-gray;
                        }
                    }
                }
            }
        }

        @include media-breakpoint-down(md) {
            ul {
                padding-right: 10px;
            }
        }

        @include media-breakpoint-down(xs) {
            ul {
                width: 50%;
            }
            ul.icons {
                width: 100%;
            }
        }
    }

    .policy {
        padding: 0 10px;

        @include media-breakpoint-up(lg) {
            padding-top: 20px;
        }

        .copyright {
            float: inherit;

            @include media-breakpoint-up(lg) {
                li {
                    &:nth-child(2):after {
                        opacity: 0.89;
                        content: "|";
                        margin-right: 10px;
                        margin-left: 10px;
                    }
                }
            }

            @include media-breakpoint-down(md) {
                li.item {
                    margin-bottom: 12px;
                }
            }
        }

        .tc {
            text-transform: uppercase;

            li.item {
                margin-bottom: 12px;
            }

            @include media-breakpoint-down(lg) {
                float: inherit;
            }

            a {
                color: $primary-blue;
            }
        }

        ul {
            list-style: none;
            text-align: center;
            margin: 0 auto;
            padding: 0;
            font-size: 14px;

            @include media-breakpoint-up(lg) {
                > li {
                    display: inline-block;
                    position: relative;

                    &:not(:last-child):after {
                        opacity: 0.89;
                        content: "|";
                        margin-right: 10px;
                        margin-left: 10px;
                    }
                }
            }
        }

        .tc-spanish {
            font-size: 13px;

            @include media-breakpoint-down(md) {
                font-size: 14px;
            }
        }
    }
}
</style>
