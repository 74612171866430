var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "d-none d-lg-flex flex-column justify-content-center align-items-center",
      attrs: { id: "autoAdvisorContent" }
    },
    [
      _c("span", { staticClass: "title" }, [
        _vm._v("\n        With You Every Step of The Way.\n    ")
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "mb-3" }, [
        _vm._v("\n        Buy. Finance. Insure. Save\n    ")
      ]),
      _vm._v(" "),
      _c("b-img", {
        staticClass: "mb-3",
        attrs: {
          src: require("../../MainNavigation/images/menu_personal_shopper.png"),
          width: "180",
          height: "180",
          alt: "Personal Shopper"
        }
      }),
      _vm._v(" "),
      _c("span", [_vm._v("CarSaver Personal Shopper")])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }