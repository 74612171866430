<template>
    <b-modal id="callMeThankYouModal" v-model="open" :hide-footer="true">
        <header slot="modal-header-close">
            <i aria-hidden="true" class="ion-ios-close-outline">
                &nbsp;
            </i>
        </header>
        <b-container fluid>
            <b-row>
                <b-col>
                    <div class="d-flex flex-column text-center">
                        <h1 class="title">
                            Thank You
                        </h1>
                        <span class="sub-title">
                            We look forward to speaking with you.
                        </span>

                        <p v-if="callTime" class="content-box p-3">
                            {{ callTime | moment("dddd[,] L [at] LT") }}
                        </p>

                        <div
                            class="d-flex justify-content-center align-items-center mb-4"
                        >
                            <b-button variant="orange" size="lg" @click="close">
                                Close Window
                            </b-button>
                        </div>
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </b-modal>
</template>

<script>
import { get, sync } from "vuex-pathify";

export default {
    name: "CallMeThankYouModal",
    computed: {
        open: sync("supplierPricing/callMeThankYouModal"),
        callTime: get("supplierPricing/callTime")
    },
    methods: {
        close() {
            this.open = false;
        }
    }
};
</script>
<style lang="scss">
#callMeThankYouModal {
    .title {
        font-size: 36px;
        color: $primary-blue;
    }
    .sub-title {
        font-family: $helvetica-55-Roman;
        font-size: 14px;
        color: $dark-gray;
        margin-top: 5px;
        margin-bottom: 15px;
    }
    .content-box {
        margin-bottom: 35px;
        background-color: $card-background;
        font-family: $helvetica-55-Roman;
        font-size: 24px;
        color: $dark-gray;
    }
    .btn-orange,
    .btn-outline-primary {
        font-size: 14px;
        width: 195px;
    }
    .modal-body {
        padding-top: 0;
        padding-bottom: 25px;
    }
}
</style>
