<template>
    <div
        ref="HeaderTransitionWrapper"
        class="header-transition-wrapper"
        :class="[isMobile ? 'mobile-transition' : 'desktop-transition']"
    >
        <slot />
    </div>
</template>
<script>
import _ from "lodash";
import { get, dispatch } from "vuex-pathify";

export default {
    name: "HeaderTransitionWrapper",
    props: {
        enableAnimation: {
            type: Boolean,
            default: true,
            required: false
        },
        topOffset: {
            type: Number,
            default: 0,
            required: false
        },
        stylePosition: {
            type: String,
            default: "static",
            required: false
        }
    },

    data() {
        return {
            isMobile: false,
            cancelable: {},
            addMobileTransition: false
        };
    },

    computed: {
        headerNode() {
            return _.get(this.$refs, "HeaderTransitionWrapper", null);
        },
        headerHeight() {
            return this.getHeight(this.headerNode);
        },
        cutOffHeight: get("headerTransitionStore/cutOffheight"),
        isHeaderHidden: get("headerTransitionStore/isHeaderHidden")
    },

    mounted() {
        this.init();
        window.addEventListener("resize", this.init);
    },
    methods: {
        checkIfMobile() {
            this.isMobile = window.matchMedia(
                "only screen and (max-width: 767px)"
            ).matches;
        },

        getHeight(el) {
            const height = _.get(el, "offsetHeight", null);

            if (height === null) {
                console.trace("Invalid html element el: ", el);
                return 0;
            }

            return height;
        },

        mobileOnScroll() {
            let prevScrollpos = window.pageYOffset;
            const el = this.headerNode;

            window.addEventListener(
                "scroll",
                _.throttle(() => {
                    const currentScrollPos = window.pageYOffset;

                    if (currentScrollPos <= 0) {
                        el.style.position = "static";
                        el.style.top = `-${this.headerHeight}px`;
                        dispatch(
                            "headerTransitionStore/setIsHeaderHidden",
                            false
                        );
                    } else if (
                        currentScrollPos >
                        prevScrollpos + this.headerHeight
                    ) {
                        // downward
                        el.style.position = "static";
                        el.style.top = `-${this.headerHeight}px`;
                        dispatch(
                            "headerTransitionStore/setIsHeaderHidden",
                            true
                        );
                    } else if (currentScrollPos < prevScrollpos) {
                        // upward
                        el.style.position = "fixed";
                        el.style.top = "0px";
                        dispatch(
                            "headerTransitionStore/setIsHeaderHidden",
                            false
                        );
                    }
                    prevScrollpos = currentScrollPos;
                }, 100)
            );
        },

        desktopOnScroll() {
            const el = this.headerNode;
            let previousScrollPos = window.pageYOffset;

            window.addEventListener(
                "scroll",
                _.throttle(() => {
                    const currentScrollPos = window.pageYOffset;

                    if (currentScrollPos > previousScrollPos) {
                        // downward direction
                        if (currentScrollPos < this.cutOffHeight) {
                            el.style.position = this.stylePosition;
                            el.style.top = "-90px";
                            el.style.opacity = "1";
                        } else {
                            el.style.position = "fixed";
                            el.style.top = `${this.topOffset}px`;
                            el.style.opacity = "0.97";
                        }
                    } else {
                        // upward direction
                        if (currentScrollPos === 0) {
                            el.style.position = this.stylePosition;
                            el.style.top = "-90px";
                            el.style.opacity = "1";
                        } else {
                            el.style.opacity = "0.97";
                            el.style.position = "fixed";
                            el.style.top = `${this.topOffset}px`;
                        }
                    }
                    previousScrollPos = currentScrollPos;
                }, 200)
            );
        },

        init() {
            this.checkIfMobile();

            if (_.isNil(this.headerNode) || !this.enableAnimation) {
                return true;
            } else if (this.isMobile) {
                this.mobileOnScroll();
            } else {
                this.desktopOnScroll();
            }

            return true;
        }
    }
};
</script>
<style lang="scss" scoped>
.header-transition-wrapper {
    position: static;
    width: 100%;
    z-index: 50;

    &.desktop-transition {
        transition: all 0.3s ease-out;
    }

    &.mobile-transition {
        transition: all 0.2s ease-out;
    }
}
</style>
