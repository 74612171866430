var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.show
    ? _c(
        "facet-template",
        {
          attrs: {
            "show-clear-link": _vm.isFiltered,
            "facet-name": "vehicleCategory"
          },
          on: { clear: _vm.clearSelectedCategory }
        },
        [
          _c("span", { attrs: { slot: "facetLabel" }, slot: "facetLabel" }, [
            _vm._v("\n        Category\n    ")
          ]),
          _vm._v(" "),
          _c("div", { attrs: { slot: "facetBody" }, slot: "facetBody" }, [
            _c("div", { staticClass: "container" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12 p-0 full-width-select" }, [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.selectedVehicleCategory,
                          expression: "selectedVehicleCategory"
                        }
                      ],
                      staticClass: "form-control-sm custom-select w-100",
                      on: {
                        change: function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.selectedVehicleCategory = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        }
                      }
                    },
                    [
                      _c("option", { domProps: { value: null } }, [
                        _vm._v(
                          "\n                            " +
                            _vm._s(_vm.$t("component.facets.any")) +
                            "\n                        "
                        )
                      ]),
                      _vm._v(" "),
                      _vm._l(_vm.availableVehicleCategories, function(
                        vehicleCategory
                      ) {
                        return _c(
                          "option",
                          {
                            key: vehicleCategory.id + "key",
                            domProps: { value: vehicleCategory.name }
                          },
                          [
                            _vm._v(
                              "\n                            " +
                                _vm._s(
                                  _vm._f("capitalize")(vehicleCategory.name)
                                ) +
                                "\n                        "
                            )
                          ]
                        )
                      })
                    ],
                    2
                  )
                ])
              ])
            ])
          ])
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }