var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: { id: "callMeThankYouModal", "hide-footer": true },
      model: {
        value: _vm.open,
        callback: function($$v) {
          _vm.open = $$v
        },
        expression: "open"
      }
    },
    [
      _c(
        "header",
        { attrs: { slot: "modal-header-close" }, slot: "modal-header-close" },
        [
          _c(
            "i",
            {
              staticClass: "ion-ios-close-outline",
              attrs: { "aria-hidden": "true" }
            },
            [_vm._v("\n             \n        ")]
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "b-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "b-row",
            [
              _c("b-col", [
                _c("div", { staticClass: "d-flex flex-column text-center" }, [
                  _c("h1", { staticClass: "title" }, [
                    _vm._v(
                      "\n                        Thank You\n                    "
                    )
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "sub-title" }, [
                    _vm._v(
                      "\n                        We look forward to speaking with you.\n                    "
                    )
                  ]),
                  _vm._v(" "),
                  _vm.callTime
                    ? _c("p", { staticClass: "content-box p-3" }, [
                        _vm._v(
                          "\n                        " +
                            _vm._s(
                              _vm._f("moment")(
                                _vm.callTime,
                                "dddd[,] L [at] LT"
                              )
                            ) +
                            "\n                    "
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex justify-content-center align-items-center mb-4"
                    },
                    [
                      _c(
                        "b-button",
                        {
                          attrs: { variant: "orange", size: "lg" },
                          on: { click: _vm.close }
                        },
                        [
                          _vm._v(
                            "\n                            Close Window\n                        "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ])
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }