import _ from "lodash";
import api from "../../api";
import { make } from "vuex-pathify";

const initialState = {
    callMeNowModal: false,
    scheduleCallModal: false,
    supplierPricingAvailableModal: false,
    callInQueueModal: false,
    callMeThankYouModal: false,
    hoursOfOperation: [],
    vehicle: null,
    callTime: null,
    callInQueue: false
};

const getters = {
    ...make.getters(initialState)
};

const mutations = {
    ...make.mutations(initialState)
};
const actions = {
    ...make.actions(initialState),

    fetchHoursOfOperations({ commit, rootState }) {
        const userId = _.get(rootState, "user.user.id");
        if (_.isNil(userId)) {
            return;
        }

        api.get("/call-queues", { userId })
            .then(response => {
                const futureCallTime = _.get(
                    response,
                    "data.futureCallTime",
                    null
                );
                const hoursOfOperation = _.get(
                    response,
                    "data.hoursOfOperation",
                    []
                );
                commit("SET_HOURS_OF_OPERATION", hoursOfOperation);

                if (futureCallTime) {
                    commit("SET_CALL_TIME", futureCallTime);
                    commit("SET_CALL_IN_QUEUE", true);
                } else {
                    commit("SET_CALL_TIME", null);
                    commit("SET_CALL_IN_QUEUE", false);
                }
            })
            .catch(error => {
                console.error(error);
            });
    },

    openSupplierPricingModal(
        { commit, state, rootState },
        { vehicle, splashImage }
    ) {
        const userId = _.get(rootState, "user.user.id");

        if (userId) {
            if (!_.isNil(vehicle)) {
                commit("SET_VEHICLE", {
                    ...vehicle,
                    splashImage
                });
            }

            if (state.callInQueue) {
                commit("SET_CALL_IN_QUEUE_MODAL", true);
                return;
            }

            api.get("/call-queues", { userId })
                .then(response => {
                    const futureCallTime = _.get(
                        response,
                        "data.futureCallTime",
                        null
                    );
                    const hoursOfOperation = _.get(
                        response,
                        "data.hoursOfOperation",
                        []
                    );
                    commit("SET_HOURS_OF_OPERATION", hoursOfOperation);

                    if (futureCallTime) {
                        commit("SET_CALL_TIME", futureCallTime);
                        commit("SET_CALL_IN_QUEUE", true);
                        commit("SET_CALL_IN_QUEUE_MODAL", true);
                    } else {
                        commit("SET_SUPPLIER_PRICING_AVAILABLE_MODAL", true);
                    }
                })
                .catch(error => {
                    console.error(error);
                });
        }
    },

    openCallNowModal({ commit, state, rootState }) {
        const userId = _.get(rootState, "user.user.id");

        if (state.callInQueue) {
            commit("SET_CALL_IN_QUEUE_MODAL", true);
            return;
        }

        api.get("/call-queues", { userId })
            .then(response => {
                const futureCallTime = _.get(
                    response,
                    "data.futureCallTime",
                    null
                );

                if (futureCallTime) {
                    commit("SET_CALL_TIME", futureCallTime);
                    commit("SET_CALL_IN_QUEUE", true);
                    commit("SET_CALL_IN_QUEUE_MODAL", true);
                } else {
                    commit("SET_CALL_ME_NOW_MODAL", true);
                }
            })
            .catch(error => {
                console.error(error);
            });
    },

    openScheduleCallModal({ commit, state, rootState }) {
        const userId = _.get(rootState, "user.user.id");

        if (state.callInQueue) {
            commit("SET_CALL_IN_QUEUE_MODAL", true);
            return;
        }

        api.get("/call-queues", { userId })
            .then(response => {
                const futureCallTime = _.get(
                    response,
                    "data.futureCallTime",
                    null
                );
                const hoursOfOperation = _.get(
                    response,
                    "data.hoursOfOperation",
                    []
                );

                commit("SET_HOURS_OF_OPERATION", hoursOfOperation);

                if (futureCallTime) {
                    commit("SET_CALL_TIME", futureCallTime);
                    commit("SET_CALL_IN_QUEUE", true);
                    commit("SET_CALL_IN_QUEUE_MODAL", true);
                } else {
                    commit("SET_SCHEDULE_CALL_MODAL", true);
                }
            })
            .catch(error => {
                console.error(error);
            });
    },

    addCallNowQueue({ commit, rootState }, { phoneNumber, certificateId }) {
        const userId = _.get(rootState, "user.user.id");

        api.post("/call-queues", {
            userId,
            certificateId,
            phoneNumber: phoneNumber.replace(/\D/g, "")
        })
            .then(response => {
                if (_.get(rootState, "vehicleDetails")) {
                    commit(
                        "vehicleDetails/SET_DEALER",
                        _.get(response, "data.dealer"),
                        { root: true }
                    );
                }
                if (_.get(rootState, "dealerLink")) {
                    commit("dealerLink/SET_ACTIVE", true, { root: true });
                }
                const callTime = _.get(response, "data.callQueue.callTime");

                commit("SET_CALL_IN_QUEUE", true);
                commit("SET_CALL_ME_NOW_MODAL", false);
                commit("SET_CALL_TIME", callTime);
                commit("SET_CALL_ME_THANK_YOU_MODAL", true);
            })
            .catch(error => {
                console.error(error);
            });
    },

    addScheduledCallQueue(
        { commit, rootState },
        { phoneNumber, message, certificateId, callTime }
    ) {
        const userId = _.get(rootState, "user.user.id");

        api.post("/call-queues", {
            userId,
            phoneNumber: phoneNumber.replace(/\D/g, ""),
            message,
            certificateId,
            callTime
        })
            .then(response => {
                if (_.get(rootState, "vehicleDetails")) {
                    commit(
                        "vehicleDetails/SET_DEALER",
                        _.get(response, "data.dealer"),
                        { root: true }
                    );
                }
                if (_.get(rootState, "dealerLink")) {
                    commit("dealerLink/SET_ACTIVE", true, { root: true });
                }

                commit("SET_CALL_IN_QUEUE", true);
                commit("SET_SCHEDULE_CALL_MODAL", false);
                commit("SET_CALL_TIME", callTime);
                commit("SET_CALL_ME_THANK_YOU_MODAL", true);
            })
            .catch(error => {
                console.error(error);
            });
    }
};

export default {
    namespaced: true,
    state: initialState,
    mutations,
    actions,
    getters
};
