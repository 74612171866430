<template>
    <div class="col-12 col-md-3 selected-filters">
        <h2 class="filter-title mb-0">
            Selected Filters
        </h2>

        <div
            class="d-flex flex-column justify-content-start justify-content-md-center align-items-md-center border-left"
        >
            <div class="filter-box d-flex flex-column">
                <div
                    v-if="hasFilters"
                    class="filter d-flex justify-content-start flex-wrap"
                >
                    <div
                        v-if="minPrice"
                        class="filter-pill d-flex align-items-center my-1"
                    >
                        <span class="mr-2">
                            Starting at ${{
                                (minPrice.value / 1000) | numeral("0,0")
                            }}k
                        </span>
                        <i
                            aria-hidden="true"
                            class="ion-close"
                            @click="resetMinPrice"
                        />
                    </div>

                    <div
                        v-if="maxPrice"
                        class="filter-pill d-flex align-items-center my-1"
                    >
                        <span class="mr-2">
                            Up to ${{
                                (maxPrice.value / 1000) | numeral("0,0")
                            }}k
                        </span>
                        <i
                            aria-hidden="true"
                            class="ion-close"
                            @click="resetMaxPrice"
                        />
                    </div>

                    <div
                        v-if="minPayment"
                        class="filter-pill d-flex align-items-center my-1"
                    >
                        <span class="mr-2">
                            Starting at
                            {{ minPayment.value | numeral("$0,0") }}/mo
                        </span>
                        <i
                            aria-hidden="true"
                            class="ion-close"
                            @click="resetMinPayment"
                        />
                    </div>

                    <div
                        v-if="maxPayment"
                        class="filter-pill d-flex align-items-center my-1"
                    >
                        <span class="mr-2">
                            Up to {{ maxPayment.value | numeral("$0,0") }}/mo
                        </span>
                        <i
                            aria-hidden="true"
                            class="ion-close"
                            @click="resetMaxPayment"
                        />
                    </div>

                    <div
                        v-if="paymentType"
                        class="filter-pill d-flex align-items-center my-1"
                    >
                        <span class="mr-2">
                            {{ paymentType.value | upperFirst }}
                        </span>
                        <i
                            aria-hidden="true"
                            class="ion-close"
                            @click="resetPaymentType"
                        />
                    </div>

                    <div
                        v-for="bodyStyle in bodyStyles"
                        :key="bodyStyle"
                        class="filter-pill d-flex align-items-center my-1"
                    >
                        <span class="mr-2">
                            {{ bodyStyle | capitalize }}
                        </span>
                        <i
                            aria-hidden="true"
                            class="ion-close"
                            @click="resetBodyStyle(bodyStyle)"
                        />
                    </div>
                </div>
            </div>

            <div class="d-flex justify-content-center btn-wrapper">
                <b-button variant="orange" @click="onNext">
                    Next
                </b-button>
            </div>
        </div>
    </div>
</template>

<script>
import _ from "lodash";
import { get, dispatch } from "vuex-pathify";

export default {
    filters: {
        upperFirst(value) {
            return _.upperFirst(_.lowerCase(value));
        }
    },
    props: {
        routeTo: {
            type: Object,
            required: true
        }
    },

    computed: {
        minPrice: get("shopBy/minPriceFilter"),
        maxPrice: get("shopBy/maxPriceFilter"),
        minPayment: get("shopBy/minPaymentFilter"),
        maxPayment: get("shopBy/maxPaymentFilter"),
        bodyStyles: get("shopBy/selectedBodyStyles"),
        paymentType: get("shopBy/paymentTypeFilter"),
        hasFilters: get("shopBy/hasFilters"),
        filterQuery: get("shopBy/filterQuery"),
        modelsUrl: function() {
            return "/shop/models" + this.filterQuery;
        }
    },

    methods: {
        resetPaymentType() {
            dispatch("shopBy/removeFilter", "paymentType");
        },
        resetMaxPrice() {
            dispatch("shopBy/removeFilter", "maxPrice");
        },
        resetMinPrice() {
            dispatch("shopBy/removeFilter", "minPrice");
        },
        resetMinPayment() {
            dispatch("shopBy/removeFilter", "minPayment");
        },
        resetMaxPayment() {
            dispatch("shopBy/removeFilter", "maxPayment");
        },
        resetBodyStyle(style) {
            dispatch("shopBy/removeFilter", {
                name: "bodyStyles",
                value: style
            });
        },
        onNext() {
            if (this.routeTo.modelsPage) {
                window.location = this.modelsUrl;
            } else if (this.routeTo.href) {
                window.location = this.routeTo.href;
            } else if (this.routeTo.to) {
                this.$router.push({ path: this.routeTo.to });
            } else {
                console.error(
                    "Error in SelectedFilters.vue ill form routeTo object",
                    this.routeTo
                );
            }
        }
    }
};
</script>
<style lang="scss">
.selected-filters {
    padding: 35px 0;

    .filter-title {
        font-size: px2rem(18);
        padding: 0 55px;
    }

    .filter-box {
        padding: 0;
        min-height: 200px;

        .filter {
            margin: 15px 0 50px 0;
            width: 200px;
            height: auto;

            .filter-pill {
                border-radius: 24px;
                height: 40px;
                border: 1px solid #d4d4d4;
                background-color: $very-light-gray;
                box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
                padding: 0 10px;
                color: $dark-gray;
                font-size: px2rem(16);

                i {
                    color: $primary-blue;
                    cursor: pointer;
                }
            }
        }
    }
    .btn-wrapper {
        button,
        a {
            width: 175px;
        }
    }

    @include media-breakpoint-down(sm) {
        padding: 0;

        .filter-title {
            font-size: px2rem(14) !important;
            padding: 0 15px !important;
        }

        .filter-box {
            padding: 0 15px 10px 15px !important;
            min-height: 220px !important;

            .filter {
                width: 100% !important;

                .filter-pill {
                    height: 30px !important;
                    font-size: px2rem(12) !important;
                    margin: 0 5px 5px 0 !important;
                }
            }
        }

        .btn-wrapper {
            position: fixed;
            bottom: 25px;
            width: 100%;
            z-index: 10;
        }
    }
}
</style>
