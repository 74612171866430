var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "facet-template",
    {
      attrs: {
        "show-clear-link": _vm.isFiltered,
        "facet-name": "interiorColors"
      },
      on: { clear: _vm.clearSelectedInteriorColors }
    },
    [
      _c("span", { attrs: { slot: "facetLabel" }, slot: "facetLabel" }, [
        _vm._v(
          "\n        " + _vm._s(_vm.$t("component.facets.intColor")) + "\n    "
        )
      ]),
      _vm._v(" "),
      _c("div", { attrs: { slot: "facetBody" }, slot: "facetBody" }, [
        _c("div", { staticClass: "container" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-12 p-0 ml-2 mb-0 mt-3" }, [
              _c(
                "div",
                { staticClass: "color-list d-flex flex-row flex-wrap" },
                _vm._l(_vm.normalizedInteriorColors, function(color) {
                  return _c("color-swatch", {
                    key: color.name,
                    attrs: {
                      color: color,
                      selected: _vm.isInteriorColorSelected(color)
                    },
                    nativeOn: {
                      click: function($event) {
                        return _vm.toggleInteriorColor(color)
                      }
                    }
                  })
                }),
                1
              )
            ])
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }