<template>
    <facet-template :show-clear-link="false" :facet-name="'distanceAndZip'">
        <template #facetLabel>
            Location
        </template>
        <template #facetBody>
            <div class="d-flex flex-md-wrap">
                <div class="d-flex align-items-center pb-md-2">
                    <span class="d-inline-flex font-italic pr-3">
                        within
                    </span>
                    <b-form-select
                        v-model="distance"
                        class="form-control-sm custom-select distance-select"
                    >
                        <option
                            v-for="option in options"
                            :key="option.value"
                            :value="option.value"
                            @click="selectDistance(option)"
                        >
                            {{ option.text }}
                        </option>
                    </b-form-select>
                </div>
                <div class="d-flex align-items-center mr-1">
                    <span class="d-inline-flex font-italic px-3 of">
                        of
                    </span>
                    <b-input
                        v-model="zipCode"
                        class="zip-code"
                        type="number"
                        :state="isZipValid && !hasServerErrors"
                    />
                </div>
            </div>
            <b-form-invalid-feedback
                v-if="!isZipValid"
                class="text-center"
                :state="isZipValid"
            >
                {{ validationMessage }}
            </b-form-invalid-feedback>
            <div v-if="hasServerErrors" class="server-error text-center">
                {{ locationServerErrors }}
            </div>
        </template>
    </facet-template>
</template>
<script>
import _ from "lodash";
import { get, sync, call } from "vuex-pathify";
import FacetTemplate from "Modules/UsedListings/components/Facets/components/FacetTemplate";

export default {
    name: "DistanceAndZipSelector",
    components: { FacetTemplate },
    data() {
        return {
            zipCode: "",
            options: [
                { value: 10, text: this.$t("component.distanceSelector.10") },
                { value: 25, text: this.$t("component.distanceSelector.25") },
                { value: 50, text: this.$t("component.distanceSelector.50") },
                { value: 100, text: this.$t("component.distanceSelector.100") },
                { value: 250, text: this.$t("component.distanceSelector.250") },
                { value: -1, text: this.$t("component.distanceSelector.any") }
            ]
        };
    },

    computed: {
        selectedZipCode: get("location/selectedLocation@zipCode"),
        distance: sync("vehicleSearch/filters@distance"),
        locationServerErrors: get("location/loader@errorMessages"),
        isZipValid() {
            return this.zipCodeIsValid(this.zipCode);
        },
        hasServerErrors() {
            return !_.isEmpty(this.locationServerErrors);
        },
        validationMessage() {
            return "Zip Code is required and must be exactly 5 digits.";
        }
    },

    watch: {
        zipCode(newZip, oldZip) {
            if (
                this.zipCodeIsValid(this.zipCode) &&
                !_.isNil(oldZip) &&
                oldZip !== ""
            ) {
                this.updateZipCode(this.zipCode).then(() =>
                    this.filtersChanged()
                );
            }
        },
        selectedZipCode(newZip) {
            if (this.zipCodeIsValid(newZip) && newZip !== this.zipCode) {
                this.zipCode = newZip;
            }
        }
    },

    created() {
        if (!_.isNil(this.selectedZipCode)) {
            this.zipCode = this.selectedZipCode;
        }
    },

    methods: {
        updateZipCode: call("location/updateZipCode"),
        filtersChanged: call("vehicleSearch/filtersChanged"),
        zipCodeIsValid(zipCode) {
            return !_.isEmpty(zipCode) && zipCode.length === 5;
        },
        selectDistance(option) {
            this.distance = option.value;
            this.filtersChanged();
        }
    }
};
</script>
<style lang="scss" scoped>
.distance-select {
    min-width: 140px;
    height: 34px;
    font-style: italic;
}
.of {
    @include media-breakpoint-up(md) {
        margin-right: 10px;
    }
}
.zip-code {
    max-width: 100px;
    color: $medium-gray;
}
.server-error {
    width: 100%;
    margin-top: 0.25rem;
    font-size: px2rem(11);
    color: #f44521;
}
/* hiding arrows in number input */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}
</style>
