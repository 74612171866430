<template>
    <div id="appointmentLoader">
        <content-loader
            :height="250"
            :width="800"
            :speed="2"
            primary-color="#f3f3f3"
            secondary-color="#ecebeb"
        >
            <rect x="470" y="20" rx="0" ry="0" width="320" height="200" />
            <rect x="10" y="20" rx="0" ry="0" width="360" height="20" />
            <rect x="10" y="50" rx="0" ry="0" width="200" height="20" />
            <rect x="230" y="50" rx="0" ry="0" width="220" height="20" />
            <rect x="10" y="80" rx="0" ry="0" width="200" height="20" />
            <rect x="10" y="110" rx="0" ry="0" width="440" height="110" />
        </content-loader>
    </div>
</template>
<script>
import { ContentLoader } from "vue-content-loader";
export default {
    name: "AppointmentLoader",
    components: { ContentLoader }
};
</script>
