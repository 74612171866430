<template>
    <label class="sort-selector px-2 mb-0">
        {{ $t("component.sortSelector.sortBy") }}:
        <b-form-select id="sort-select" v-model="sort" class="px-1">
            <option
                v-for="option in sortOptions"
                :key="option.value"
                :value="option.value"
            >
                {{ option.text }}
            </option>
        </b-form-select>
    </label>
</template>

<style lang="scss">
.sort-selector {
    font-family: $helvetica-55-Roman;
    border: 1px solid $light-gray;
    font-size: px2rem(12);
    border-radius: 4px;
    font-weight: bold;

    &:focus {
        outline: none;
    }
}

#sort-select {
    font-size: px2rem(12);
    line-height: px2rem(14);
    color: $muted-dark-gray;
    border: none;
    width: auto;
    min-width: 130px;

    &:focus {
        outline: none;
        box-shadow: none;
    }
}
</style>

<script>
import listingSort from "@/modules/UsedListings/mixins/listingSort";
import { call } from "vuex-pathify";

export default {
    name: "SortSelector",

    mixins: [listingSort],

    methods: {
        filtersChanged: call("vehicleSearch/filtersChanged"),
        selectSort(option) {
            this.sort = option.value;
            this.filtersChanged();
        }
    }
};
</script>
