var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "HeaderTransitionWrapper",
      staticClass: "header-transition-wrapper",
      class: [_vm.isMobile ? "mobile-transition" : "desktop-transition"]
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }