<template>
    <facet-template
        :show-clear-link="isFiltered"
        :facet-name="'year'"
        @clear="clearSelectedYears"
    >
        <span slot="facetLabel">
            {{ $t("component.facets.year") }}
        </span>

        <div slot="facetBody" class="d-flex justify-content-between">
            <div class="left-select">
                <label for="minYear">
                    starting at
                </label>
                <select
                    id="minYear"
                    v-model="selectedMinYear"
                    class="form-control-sm custom-select w-100"
                    name="minYear"
                >
                    <option :value="null">
                        {{ $t("component.facets.any") }}
                    </option>
                    <option
                        v-for="year in availableMinYears"
                        :key="year"
                        :value="year"
                    >
                        {{ year }}
                    </option>
                </select>
            </div>

            <div class="right-select">
                <label for="maxYear">
                    up to
                </label>
                <select
                    id="maxYear"
                    v-model="selectedMaxYear"
                    class="form-control-sm custom-select w-100"
                    name="maxYear"
                >
                    <option :value="null">
                        {{ $t("component.facets.any") }}
                    </option>
                    <option
                        v-for="year in availableMaxYears"
                        :key="year"
                        :value="year"
                    >
                        {{ year }}
                    </option>
                </select>
            </div>
        </div>
    </facet-template>
</template>

<script>
import FacetTemplate from "./FacetTemplate";
import { get, sync, dispatch } from "vuex-pathify";
import _ from "lodash";

export default {
    name: "YearFacet",
    components: {
        FacetTemplate
    },
    data() {
        return {
            latestYear() {}
        };
    },
    computed: {
        availableYears: get("vehicleSearch/facets@years"),
        selectedMinYear: sync("vehicleSearch/filters@minYear"),
        selectedMaxYear: sync("vehicleSearch/filters@maxYear"),
        normAvaliableYears() {
            const now = new Date();
            const dateOffset = 2;
            const latestYear = now.getFullYear() + dateOffset;

            return this.availableYears.filter(function(obj) {
                return obj.name <= latestYear;
            });
        },
        minimumAvailableYear() {
            let year = null;
            if (!_.isEmpty(this.normAvaliableYears)) {
                year = _.minBy(this.normAvaliableYears, "name").name;
            }
            return year;
        },
        maximumAvailableYear() {
            let year = null;
            if (!_.isEmpty(this.normAvaliableYears)) {
                year = _.maxBy(this.normAvaliableYears, "name").name;
            }
            return year;
        },
        availableMinYears() {
            const start = this.minimumAvailableYear;
            const end = this.selectedMaxYear
                ? Number(this.selectedMaxYear)
                : this.maximumAvailableYear;

            if (_.isNil(start) || start === "") {
                return null;
            }

            return _.reverse(_.range(start, end + 1, 1));
        },
        availableMaxYears() {
            const start = this.selectedMinYear
                ? this.selectedMinYear
                : this.minimumAvailableYear;
            const end = this.maximumAvailableYear;

            if (_.isNil(start) || start === "") {
                return null;
            }

            return _.reverse(_.range(start, end + 1, 1));
        },
        isFiltered() {
            return (
                !_.isNil(this.selectedMinYear) || !_.isNil(this.selectedMaxYear)
            );
        }
    },

    methods: {
        clearSelectedYears() {
            dispatch("vehicleSearch/clearYears");
        },
        // lodash ranges are not inclusive (upper bounds)
        rangeInclusive(start, end, step = 1) {
            if (start == null && end == null) {
                return [];
            }

            return _.range(start, end + step);
        }
    }
};
</script>
