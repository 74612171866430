<template>
    <div id="main-navigation-wrapper" ref="mainNavigationWrapper">
        <div v-if="showKioskHeader" class="kiosk-header">
            <main-navigation />
        </div>

        <header-transition-wrapper v-else>
            <main-navigation />
            <inventory-mobile-submenu v-if="showMobileInventorySubmenu" />
        </header-transition-wrapper>
    </div>
</template>
<script>
import { get, dispatch } from "vuex-pathify";
import mainNavigation from "Modules/MainNavigation";
import headerTransitionWrapper from "Components/HeaderTransitionWrapper";
import kioskUtils from "@/mixins/kioskUtils";
import _ from "lodash";
import InventoryMobileSubmenu from "Modules/UsedListings/components/InventoryMobileSubmenu/index";

export default {
    name: "AnimatedMainNavigation",

    components: {
        InventoryMobileSubmenu,
        mainNavigation,
        headerTransitionWrapper
    },

    mixins: [kioskUtils],

    props: {
        enableDisappearOnMobile: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    data() {
        return {
            showKioskHeader: false,
            isMobile: false,
            headerId: "#main-navigation-wrapper",
            headerRef: "mainNavigationWrapper"
        };
    },

    computed: {
        headerNode() {
            return this.$refs[this.headerRef];
        },
        headerOffset: get("headerTransitionStore/cutOffheight"),
        enabledMobileInventorySubmenu: get(
            "headerTransitionStore/enabledMobileInventorySubmenu"
        ),
        wrapperHeight() {
            return `${this.headerOffset}px`;
        },
        showMobileInventorySubmenu() {
            return this.enabledMobileInventorySubmenu === true && this.isMobile;
        }
    },

    watch: {
        headerOffset() {
            this.setWrapperHeight();
        }
    },

    mounted() {
        this.showKioskHeader = this.isKioskUrl();
        this.isMobile = window.innerWidth < 768;

        setTimeout(() => {
            this.setWrapperHeight();
        }, 200);

        window.addEventListener(
            "resize",
            _.throttle(() => {
                this.isMobile = window.innerWidth < 768;
                this.setWrapperHeight();
            }, 200)
        );
    },

    methods: {
        getWrapperHeight() {
            if (this.headerNode) {
                this.wrapperHeight = `${this.headerNode.offsetHeight}px`;
            }

            return "auto";
        },

        setWrapperHeight() {
            dispatch("headerTransitionStore/setCutOffHeight");
            this.headerNode.style.height = this.wrapperHeight;
        }
    }
};
</script>
<style lang="scss" scoped>
#main-navigation-wrapper {
    position: relative;
    height: auto;

    .kiosk-header {
        position: fixed;
        width: 100%;
        top: 40px;
        z-index: 70;
    }
}
</style>
