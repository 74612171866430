<template>
    <header id="main-navigation">
        <shop-com-link-back-banner />

        <!-- START: Walmart link back banner  -->
        <div v-if="showLinkBack" id="link-back-block">
            <div
                class="container-fluid h-100 d-flex justify-content-between align-items-center"
            >
                <div>
                    <a
                        id="link-back"
                        href="https://www.walmart.com"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        &lt; Back to Walmart.com
                    </a>
                </div>
                <div class="d-none d-lg-inline">
                    <span id="link-back-message">
                        You are no longer on Walmart.com. CarSaver is a trusted
                        partner that makes auto buying services available to
                        Walmart customers.
                    </span>
                </div>
                <div class="d-none d-lg-inline" />
            </div>
        </div>
        <!-- END: Walmart link back banner  -->

        <nav class="navbar navbar-dark cs-navbar">
            <div class="container-fluid">
                <b-link
                    class="navbar-brand mr-auto"
                    :title="campaignConfigs.logo.altText"
                    @click.prevent="goHome"
                >
                    <svg-fallback
                        class="logo"
                        :src="campaignConfigs.logo.svgSrc"
                    >
                        <img
                            :alt="campaignConfigs.logo.altText"
                            :src="campaignConfigs.logo.src"
                        />
                    </svg-fallback>
                </b-link>
                <autocomplete
                    v-if="showSearch"
                    class="mr-3"
                    source="/api/v2/search/suggest?value="
                    results-property="url"
                    results-display="text"
                    placeholder="Enter Make or Model"
                    :show-no-results="false"
                    @selected="searchResult"
                    @results="storeSearchResults"
                    @enter="queryResult"
                    @nothingSelected="queryResult"
                />
                <ul class="navbar-nav d-none d-lg-flex flex-row">
                    <li v-if="showBuy" class="nav-item dropdown">
                        <a
                            id="buyDropDownMenu"
                            class="nav-link dropdown-toggle"
                            href="#"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                        >
                            {{ $t("component.mainNav.buy") }}
                        </a>
                        <div
                            class="dropdown-menu"
                            aria-labelledby="buyDropDownMenu"
                        >
                            <a
                                class="dropdown-item"
                                href="/shop-by/payment?paymentType=LEASE"
                            >
                                Shop Leases
                            </a>
                            <a class="dropdown-item" href="/shop-by">
                                {{ $t("component.mainNav.new") }}
                            </a>
                            <a class="dropdown-item" href="/shop/used">
                                {{ $t("component.mainNav.used") }}
                            </a>
                            <a
                                class="dropdown-item"
                                href="/shop-by/make"
                                @click="updateSavedUrl"
                            >
                                {{ $t("component.mainNav.build") }}
                            </a>
                        </div>
                    </li>

                    <!-- Enable and Disabled in the application.yml file  -->
                    <li v-if="showSell" class="nav-item">
                        <a
                            class="nav-link"
                            href="/sell-your-car"
                        >
                            {{ $t("component.mainNav.sell") }}
                        </a>
                    </li>
                    <li v-if="showFinance" class="nav-item">
                        <a class="nav-link" href="/finance">
                            {{ $t("component.mainNav.finance") }}
                        </a>
                    </li>
                    <li v-if="showInsure" class="nav-item">
                        <a class="nav-link" href="/insure">
                            {{ $t("component.mainNav.insure") }}
                        </a>
                    </li>
                    <li v-if="showProtect" class="nav-item dropdown">
                        <a
                            id="protectDropDownMenu"
                            class="nav-link dropdown-toggle"
                            href="#"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                        >
                            {{ $t("component.mainNav.protect") }}
                        </a>
                        <div
                            class="dropdown-menu"
                            aria-labelledby="protectDropDownMenu"
                        >
                            <a class="dropdown-item" href="/warranty">
                                {{ $t("component.mainNav.warranty") }}
                            </a>
                            <a class="dropdown-item" href="/warranty/faq">
                                {{ $t("component.mainNav.faq") }}
                            </a>
                            <a
                                class="dropdown-item"
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://carsaver.naenwan.com/Account/Registration"
                            >
                                {{ $t("component.mainNav.register") }}
                            </a>
                        </div>
                    </li>
                    <li v-if="showHowItWorks" class="nav-item">
                        <a class="nav-link" href="/how-it-works">
                            {{ $t("component.mainNav.howItWorks") }}
                        </a>
                    </li>
                    <li class="nav-item dropdown">
                        <a
                            id="autoAdvisorDropDownMenu"
                            class="nav-link dropdown-toggle"
                            href="#"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                        >
                            {{ $t("component.mainNav.advisor") }}
                        </a>
                        <div
                            class="dropdown-menu dropdown-menu-right personal-shopper-content"
                            aria-labelledby="autoAdvisorDropDownMenu"
                        >
                            <h3>{{ $t("component.mainNav.advisor.with") }}</h3>
                            <p>{{ $t("component.mainNav.advisor.buy") }}</p>
                            <div class="image">
                                <b-img-lazy
                                    v-webp
                                    src="./images/menu_personal_shopper.png"
                                    width="180"
                                    height="180"
                                    alt="Personal Shopper"
                                />
                            </div>
                            <p class="title">
                                {{ $t("component.mainNav.advisor.carsaver") }}
                            </p>
                            <a :href="'tel:' + phoneNumber" class="phone">
                                <i
                                    class="ion-ios-telephone"
                                    aria-hidden="true"
                                />
                                {{ phoneNumberFormatted }}
                            </a>
                            <b-btn
                                variant="primary"
                                class="call-back"
                                size="lg"
                                block
                                @click="showCallbackModal()"
                            >
                                <i
                                    class="ion-calendar mr-2"
                                    aria-hidden="true"
                                />
                                {{ $t("component.mainNav.advisor.request") }}
                            </b-btn>
                        </div>
                    </li>
                    <li v-if="isSignedIn" class="nav-item dropdown">
                        <a
                            id="profileDropDownMenu"
                            class="nav-link dropdown-toggle"
                            href="#"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                        >
                            <b-img
                                v-if="imageUrl && enableUserImg"
                                :src="imageUrl"
                                width="24"
                                height="24"
                                rounded="circle"
                                :alt="firstName"
                                @error="imgErrorHandler"
                            />
                            <gravatar
                                v-else
                                default-img="mp"
                                :email="email"
                                :alt="firstName"
                                :size="24"
                                class="rounded-circle"
                            />
                            {{ firstName }}
                        </a>
                        <div
                            id="profileDropDownMenu-menu"
                            class="dropdown-menu"
                            aria-labelledby="profileDropDownMenu"
                        >
                            <a class="dropdown-item" href="/dashboard">
                                {{ $t("component.mainNav.garage") }}
                            </a>
                            <a class="dropdown-item" href="/profile">
                                {{ $t("component.mainNav.profile") }}
                            </a>
                            <template v-if="enableTranslations">
                                <a
                                    v-if="isEnglish"
                                    class="dropdown-item"
                                    :href="changeLocaleUrl"
                                >
                                    Español
                                </a>
                                <a
                                    v-else
                                    class="dropdown-item"
                                    :href="changeLocaleUrl"
                                >
                                    English
                                </a>
                            </template>
                            <a class="dropdown-item" href="/logout">
                                {{ $t("component.mainNav.logOut") }}
                            </a>
                        </div>
                    </li>
                    <li v-else class="nav-item">
                        <a class="nav-link" @click="signIn">
                            {{ $t("component.mainNav.signIn") }}
                        </a>
                    </li>
                    <template v-if="enableTranslations">
                        <li v-if="!isSignedIn && isEnglish" class="nav-item">
                            <a class="nav-link" :href="changeLocaleUrl">
                                Español
                            </a>
                        </li>
                        <li v-else-if="!isSignedIn" class="nav-item">
                            <a class="nav-link" :href="changeLocaleUrl">
                                English
                            </a>
                        </li>
                    </template>
                </ul>
                <button
                    v-b-modal.mobile-nav-modal
                    class="navbar-toggler d-lg-none collapsed"
                    aria-controls="mobile-nav-modal"
                    type="button"
                    aria-expanded="false"
                    aria-label="Toggle mobile navigation"
                >
                    <i aria-hidden="true" class="ion-navicon icon" />
                </button>
            </div>
        </nav>

        <b-modal id="mobile-nav-modal" title="Menu" hide-footer>
            <div slot="modal-header-close">
                <i aria-hidden="true" class="ion-ios-close-outline" />
            </div>

            <b-nav v-if="isSignedIn" vertical class="profile-dropdown">
                <b-nav-item
                    v-b-toggle.collapse-profile
                    link-classes="d-flex justify-content-between"
                >
                    <span>
                        <b-img
                            v-if="imageUrl"
                            :src="imageUrl"
                            width="24"
                            height="24"
                            rounded="circle"
                            :alt="firstName"
                        />
                        <gravatar
                            v-else
                            default-img="mp"
                            :email="email"
                            :alt="firstName"
                            :size="24"
                            class="rounded-circle"
                        />
                        {{ firstName }}
                    </span>
                    <i
                        aria-hidden="true"
                        :class="
                            profileCollapsed
                                ? 'ion-chevron-up'
                                : 'ion-chevron-down'
                        "
                    />
                </b-nav-item>
                <b-collapse id="collapse-profile" v-model="profileCollapsed">
                    <b-nav-item href="/dashboard">
                        {{ $t("component.mainNav.garage") }}
                    </b-nav-item>
                    <b-nav-item href="/profile">
                        {{ $t("component.mainNav.profile") }}
                    </b-nav-item>
                    <template v-if="enableTranslations">
                        <b-nav-item v-if="isEnglish" :href="changeLocaleUrl">
                            Español
                        </b-nav-item>
                        <b-nav-item v-else :href="changeLocaleUrl">
                            English
                        </b-nav-item>
                    </template>
                    <b-nav-item href="/logout">
                        {{ $t("component.mainNav.logOut") }}
                    </b-nav-item>
                </b-collapse>
            </b-nav>
            <div v-else class="text-center account-links">
                <b-link @click="signIn">
                    {{ $t("component.mainNav.signIn") }}
                </b-link>
                &nbsp;|&nbsp;
                <b-link @click="signUp">
                    Create an Account
                </b-link>
            </div>

            <b-nav vertical>
                <b-nav-item
                    v-if="showBuy"
                    v-b-toggle.collapse-buy
                    link-classes="d-flex justify-content-between"
                >
                    <span>{{ $t("component.mainNav.buy") }}</span>
                    <i
                        aria-hidden="true"
                        :class="
                            buyCollapsed ? 'ion-chevron-up' : 'ion-chevron-down'
                        "
                    />
                </b-nav-item>
                <b-collapse
                    v-if="showBuy"
                    id="collapse-buy"
                    v-model="buyCollapsed"
                >
                    <b-nav-item href="/shop-by/payment?paymentType=LEASE">
                        Shop Leases
                    </b-nav-item>
                    <b-nav-item href="/shop-by">
                        {{ $t("component.mainNav.new") }}
                    </b-nav-item>
                    <b-nav-item href="/shop/used">
                        {{ $t("component.mainNav.used") }}
                    </b-nav-item>
                    <b-nav-item href="/shop-by/make" @click="updateSavedUrl">
                        {{ $t("component.mainNav.build") }}
                    </b-nav-item>
                </b-collapse>
                <b-nav-item
                    v-if="showSell"
                    href="/sell-your-car"
                >
                    {{ $t("component.mainNav.sell") }}
                </b-nav-item>
                <b-nav-item v-if="showFinance" href="/finance">
                    {{ $t("component.mainNav.finance") }}
                </b-nav-item>
                <b-nav-item v-if="showInsure" href="/insure">
                    {{ $t("component.mainNav.insure") }}
                </b-nav-item>
                <b-nav-item
                    v-if="showProtect"
                    v-b-toggle.collapse-protect
                    link-classes="d-flex justify-content-between"
                >
                    <span>{{ $t("component.mainNav.protect") }}</span>
                    <i
                        aria-hidden="true"
                        :class="
                            protectCollapsed
                                ? 'ion-chevron-up'
                                : 'ion-chevron-down'
                        "
                    />
                </b-nav-item>
                <b-collapse
                    v-if="showProtect"
                    id="collapse-protect"
                    v-model="protectCollapsed"
                >
                    <b-nav-item href="/warranty">
                        {{ $t("component.mainNav.warranty") }}
                    </b-nav-item>
                    <b-nav-item href="/warranty/faq">
                        {{ $t("component.mainNav.faq") }}
                    </b-nav-item>
                    <b-nav-item
                        rel="noopener noreferrer"
                        target="_blank"
                        href="https://carsaver.naenwan.com/Account/Registration"
                    >
                        {{ $t("component.mainNav.register") }}
                    </b-nav-item>
                </b-collapse>
                <b-nav-item v-if="showHowItWorks" href="/how-it-works">
                    {{ $t("component.mainNav.howItWorks") }}
                </b-nav-item>
                <b-nav-item
                    v-b-toggle.collapse-auto-advisor
                    link-classes="d-flex justify-content-between"
                >
                    <span>Personal Shopper</span>
                    <i
                        aria-hidden="true"
                        :class="
                            autoAdvisorCollapsed
                                ? 'ion-chevron-up'
                                : 'ion-chevron-down'
                        "
                    />
                </b-nav-item>
                <b-collapse
                    id="collapse-auto-advisor"
                    v-model="autoAdvisorCollapsed"
                >
                    <div
                        class="d-flex justify-content-center align-items-center flex-column py-3"
                    >
                        <h4>{{ $t("component.mainNav.advisor.with") }}</h4>

                        <p>{{ $t("component.mainNav.advisor.buy") }}</p>

                        <div class="image">
                            <b-img-lazy
                                v-webp
                                src="./images/menu_personal_shopper.png"
                                width="180"
                                height="180"
                                alt="Personal Shopper"
                            />
                        </div>

                        <span class="title">
                            {{ $t("component.mainNav.advisor.carsaver") }}
                        </span>

                        <a :href="'tel:' + phoneNumber" class="phone mb-2">
                            <i class="ion-ios-telephone" aria-hidden="true" />
                            {{ phoneNumberFormatted }}
                        </a>
                        <b-btn
                            variant="primary"
                            class="call-back"
                            size="lg"
                            block
                            @click="showCallbackModal()"
                        >
                            <i class="ion-calendar mr-2" aria-hidden="true" />
                            {{ $t("component.mainNav.advisor.request") }}
                        </b-btn>
                    </div>
                </b-collapse>
                <template v-if="enableTranslations">
                    <b-nav-item
                        v-if="!isSignedIn && isEnglish"
                        :href="changeLocaleUrl"
                    >
                        Español
                    </b-nav-item>
                    <b-nav-item v-else-if="!isSignedIn" :href="changeLocaleUrl">
                        English
                    </b-nav-item>
                </template>
            </b-nav>
        </b-modal>
        <request-a-callback ref="callbackModal" />
    </header>
</template>
<script>
import _ from "lodash";
import api from "@/api";
import { get, sync } from "vuex-pathify";
import RequestACallback from "./components/RequestACallBack";
import URL from "url-parse";
import SvgFallback from "Components/SvgFallback";
import navigationHelpers from "@/mixins/navigationHelpers";
import signInMixin from "@/mixins/signInMixin";
import Gravatar from "vue-gravatar";
import Autocomplete from "vuejs-auto-complete";
import listingsUrlStorer from "@/mixins/listingsUrlStorer";
import ShopComLinkBackBanner from "Modules/MainNavigation/components/ShopComLinkBackBanner/index";

export default {
    name: "MainNavigation",

    components: {
        ShopComLinkBackBanner,
        SvgFallback,
        RequestACallback,
        Gravatar,
        Autocomplete
    },

    mixins: [navigationHelpers, signInMixin, listingsUrlStorer],

    props: {
        enableDisappearOnMobile: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    data() {
        return {
            searchResults: [],
            profileCollapsed: false,
            buyCollapsed: false,
            autoAdvisorCollapsed: false,
            protectCollapsed: false,
            top: 50,
            enableUserImg: true,
            enableTranslations: false
        };
    },

    computed: {
        isSignedIn: get("user/isSignedIn"),
        firstName: get("user/user@firstName"),
        imageUrl: get("user/user@imageUrl"),
        email: get("user/user@email"),
        signInOpen: sync("user/signInOpen"),
        lang: get("translated/lang"),
        phoneNumber: get("campaign/phoneNumber"),
        phoneNumberFormatted: get("campaign/phoneNumberFormatted"),
        campaignType: get("campaign/type"),
        campaignConfigs() {
            const defaultConfig = "carsaver";
            const configs = {
                carsaver: {
                    logo: {
                        svgSrc: require("./images/carsaver_logo.svg"),
                        altText: "CarSaver",
                        src: require("./images/carsaver_logo.png")
                    }
                },
                shop: {
                    logo: {
                        svgSrc: require("./images/carsaver_logo.svg"),
                        altText: "CarSaver",
                        src: require("./images/carsaver_logo.png")
                    }
                },
                walmart: {
                    logo: {
                        svgSrc: require("./images/carsaver_at_walmart_logo.svg"),
                        altText: "CarSaver at Walmart",
                        src: require("./images/carsaver_at_walmart_logo.png")
                    }
                },
                wavpp: {
                    logo: {
                        svgSrc: require("./images/carsaver_at_walmart_logo.svg"),
                        altText: "CarSaver at Walmart",
                        src: require("./images/carsaver_at_walmart_logo.png")
                    }
                },
                iheart: {
                    logo: {
                        svgSrc: require("./images/carsaver_logo.svg"),
                        altText: "CarSaver",
                        src: require("./images/carsaver_logo.png")
                    }
                }
            };

            return _.get(configs, this.campaignType, configs[defaultConfig]);
        },
        changeLocaleUrl() {
            const url = new URL(window.location.href, true);
            url.query.lang = this.lang === "en" ? "es" : "en";
            return `${url}`;
        },
        isEnglish() {
            return this.lang === "en";
        },

        showSearch() {
            const parsed = new URL(location.search, true);
            return parsed.query["enable-search"] === "true";
        },

        showLinkBack() {
            let show = false;

            switch (this.campaignType) {
                case "walmart":
                    show = true;
                    break;
                default:
                    show = false;
            }

            return show;
        }
    },

    methods: {
        imgErrorHandler() {
            this.enableUserImg = false;
        },
        storeSearchResults(results) {
            this.searchResults = _.get(results, "results");
        },
        queryResult(value) {
            api.get("/search/query", { value })
                .then(result => {
                    const url = _.get(result, "data.url");

                    if (_.isNil(url)) {
                        document.location = "/shop/models";
                        return;
                    }

                    document.location = url;
                })
                .catch(error => {
                    console.error(error);
                });
        },
        searchResult(result) {
            document.location = result.selectedObject.url;
        },
        signIn() {
            this.toSignInPage();
        },
        signUp() {
            this.toSignUpPage();
        },
        showCallbackModal() {
            this.$refs.callbackModal.show();
        },

        goHome() {
            document.location = "/";
        },

        updateSavedUrl() {
            this.storeCurrentUrl("/pick-your-flow");
        }
    }
};
</script>

<style lang="scss">
$link-back-block-height: 40px;
$nav-bar-height: 50px;
$offset: $link-back-block-height + $nav-bar-height;
$link-back-block-color: white;

#mobile-nav-modal {
    .modal-body {
        background-color: $very-light-gray;

        .account-links {
            font-size: 16px;
        }

        .profile-dropdown {
            background-color: #fff;

            .nav-item {
                height: 55px;
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
        }
    }

    .modal-header {
        height: 55px;
        box-shadow: rgba(24, 53, 88, 0.1) 1px 2px 3px 1px;
        z-index: 999;

        .close {
            padding: 10px 15px 10px 0;
            font-size: 2.2rem;
        }

        h5 {
            width: 100%;
            text-align: center;
            font-size: 18px;
        }

        .modal-title {
            align-self: center;
        }
    }

    .nav-item {
        font-size: 20px;
    }
    .nav-item:not(:last-child) {
        border-bottom: solid 1px #ccc;
    }

    .collapse .nav-item,
    .collapsing .nav-item {
        font-size: 18px;
        margin-left: 20px;
        border-bottom: none;

        a {
            color: $primary-dark-blue;
        }
    }
}

#profileDropDownMenu-menu {
    left: -50px;
}

#main-navigation {
    position: relative;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.5);

    .autocomplete {
        max-width: 200px;
    }

    .ion-android-car {
        font-size: 18px;
        line-height: 16px;
    }

    .navbar-toggler {
        color: #fff;
        border: none;
        font-size: 2rem;
        padding-right: 0;
        border-radius: 0;
        outline: none;

        &:not(.collapsed) {
            background-color: #fff;
            color: $dark-gray;
        }
    }

    .navbar {
        height: 50px;
    }

    #link-back-block {
        height: $link-back-block-height;
        width: 100%;
        background-color: #007dc6;
        color: $link-back-block-color;

        #link-back {
            color: $link-back-block-color;
            font-size: 12px;
            letter-spacing: 0.04px;
        }
    }

    .cs-navbar {
        background: $primary-dark-blue;
        padding-left: 0;
        padding-right: 0;

        .dropdown-menu {
            border-radius: 0;
            margin: 0;
            padding: 0;
            border: none;
            position: absolute !important;
            top: 43px;

            > a.dropdown-item {
                padding: 0.9rem 1rem;
                color: $medium-gray;
                border-bottom: 1px solid $light-medium-gray;
                border-left: 1px solid #e4e4e4;
                border-right: 1px solid #e4e4e4;
                font-size: 1.1rem;

                &:active,
                &:hover {
                    background-color: $very-light-gray;
                }

                &:focus {
                    outline: none;
                }
            }
        }

        .personal-shopper-content {
            padding: 1rem;
            text-align: center;
            left: -150px;
            box-shadow: 0 2px 20px 5px rgba(0, 0, 0, 0.2);
            border-radius: 0 0 2px 2px;
            min-width: 280px;

            @include media-breakpoint-down(md) {
                left: -165px;
            }

            @include media-breakpoint-down(sm) {
                left: -220px;
            }

            h3 {
                font-size: 16px;
                font-weight: bold;
            }

            p {
                font-size: 1rem;
            }

            .call-back {
                margin-top: 10px;
                font-family: $helvetica-55-Roman;
            }

            .phone {
                font-size: 1.2rem;
                font-weight: bold;
                color: $medium-gray;
                text-decoration: none;
            }

            .title {
                font-size: 1rem;
            }

            .image {
                margin: 1rem 0;
            }
        }

        .nav-item {
            .nav-link {
                min-height: 50px;
                padding: 14px 10px;
                margin: -7px 0px;
                color: #fff;
                font-family: $helvetica-55-Roman;

                @include media-breakpoint-up(xl) {
                    padding: 14px 15px;
                }

                &:hover {
                    background-color: rgba(0, 0, 0, 0.1);
                    cursor: pointer;
                }
            }

            &.show {
                .nav-link {
                    background-color: rgba(0, 0, 0, 0.1);
                }

                .dropdown-toggle:after {
                    left: 50%;
                    z-index: 10;
                    bottom: -21px;
                    color: #fff;
                    font-size: 24px;
                    content: "\f10d";
                    margin-left: -8px;
                    position: absolute;
                    font-family: Ionicons;
                }
            }
        }

        .dropdown-toggle:after {
            left: 50%;
            z-index: 0;
            bottom: -7px;
            content: "\f104";
            margin-left: -5px;
            position: absolute;
            font-family: Ionicons;
        }
    }

    .navbar-brand {
        .logo {
            svg {
                height: 27px;
            }
        }
    }

    .cs-mobile-navbar.navbar {
        position: absolute;
        width: 100%;
        background: #fff;
        padding: 0 1rem;
        z-index: 10;

        .nav-link {
            color: $primary-blue;
            font-weight: 700;
            border-bottom: 1px solid #ccc;
            font-size: 1.3rem;
            font-family: $helvetica-55-Roman;
        }
    }

    #mobile-nav {
        margin: 50px -15px 0 -15px;
        position: fixed;
        top: 0;
        width: 100%;
        background-color: #fff;
        z-index: 10;

        .nav-link {
            padding: 0.8rem 1.5rem;

            &:hover {
                background-color: $muted-gray;
                color: $primary-blue;
            }
        }
    }

    .mobile-nav-ext {
        margin-top: 90px !important;
    }
}
</style>
