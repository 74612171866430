var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("span", {
      domProps: {
        innerHTML: _vm._s(
          _vm.$t("disclaimer.warranty", { telephoneNumber: _vm.campaignNumber })
        )
      }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }