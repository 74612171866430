var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "layout",
    { attrs: { breadcrumbs: _vm.breadcrumbs } },
    [
      _c("template", { slot: "header" }, [
        _c("h1", { staticClass: "text-center" }, [
          _vm._v("Shop by " + _vm._s(_vm.pageTitle))
        ])
      ]),
      _vm._v(" "),
      _c(
        "b-row",
        [
          _c("div", { staticClass: "col-12 col-md-9" }, [
            _c(
              "div",
              {
                staticClass: "d-flex flex-column flex-sm-row",
                attrs: { id: "content" }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "d-flex flex-column",
                    attrs: { id: "bodystyle-selection" }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "d-flex", attrs: { id: "title" } },
                      [
                        _c("h2", [
                          _vm._v("What body style are you looking for?")
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "d-flex",
                        attrs: { id: "bodystyle-body" }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "d-flex flex-wrap mb-3",
                            attrs: { id: "body-styles" }
                          },
                          _vm._l(_vm.validBodyStyles, function(bodyStyle) {
                            return _c(
                              "div",
                              {
                                key: bodyStyle.id,
                                staticClass: "text-center body-style",
                                attrs: { id: bodyStyle.id }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "body-style-wrapper",
                                    on: {
                                      click: function($event) {
                                        return _vm.toggleBodyStyle(bodyStyle)
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "body-style-icon" },
                                      [
                                        _c("body-style-icon", {
                                          attrs: {
                                            id: bodyStyle.id,
                                            selected: _vm.isBodyStyleSelected(
                                              bodyStyle
                                            )
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "small",
                                      { staticClass: "text-uppercase" },
                                      [
                                        _vm._v(
                                          "\n                                        " +
                                            _vm._s(bodyStyle.name) +
                                            "\n                                    "
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ]
                            )
                          }),
                          0
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c("form", { attrs: { id: "multiselect-form" } }, [
                      _c("div", { staticClass: "form-check" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.multiselect,
                              expression: "multiselect"
                            }
                          ],
                          staticClass: "form-check-input",
                          attrs: { id: "multi-pick", type: "checkbox" },
                          domProps: {
                            checked: Array.isArray(_vm.multiselect)
                              ? _vm._i(_vm.multiselect, null) > -1
                              : _vm.multiselect
                          },
                          on: {
                            change: function($event) {
                              var $$a = _vm.multiselect,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    (_vm.multiselect = $$a.concat([$$v]))
                                } else {
                                  $$i > -1 &&
                                    (_vm.multiselect = $$a
                                      .slice(0, $$i)
                                      .concat($$a.slice($$i + 1)))
                                }
                              } else {
                                _vm.multiselect = $$c
                              }
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "label",
                          {
                            staticClass: "form-check-label",
                            attrs: { id: "multi-pick-text", for: "multi-pick" }
                          },
                          [
                            _vm._v(
                              "\n                                make multiple selections\n                            "
                            )
                          ]
                        )
                      ])
                    ])
                  ]
                )
              ]
            )
          ]),
          _vm._v(" "),
          _c("selected-filters", { attrs: { "route-to": _vm.next } })
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }