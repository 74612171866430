var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    [
      _c("div", { staticClass: "heading mb-3" }, [
        _c("span", { staticClass: "sub-form-title" }, [
          _vm._v(
            "Sign in to your CarSaver Profile to access this feature.\n        "
          )
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        [
          _c("sign-in-form", {
            attrs: { "show-section": false, returnUrl: _vm.redirectLink }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("social-signin"),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "to-signin d-flex flex-column align-items-center justify-content-center"
        },
        [_c("p", [_vm._v("Not a member yet? "), _c("sign-up-link")], 1)]
      ),
      _vm._v(" "),
      _c(
        "p",
        { staticClass: "disclaimer text-center" },
        [
          _vm._v(
            "\n        We dont post anything to your Facebook or Google account. By Signing in you agree to CarSavers \n        "
          ),
          _c("b-link", { attrs: { href: "/tos", target: "_blank" } }, [
            _vm._v(
              "\n            " +
                _vm._s(_vm.$t("component.signInForm.terms")) +
                "\n        "
            )
          ]),
          _vm._v("\n        & \n        "),
          _c("b-link", { attrs: { href: "/privacy", target: "_blank" } }, [
            _vm._v(
              "\n            " +
                _vm._s(_vm.$t("component.signInForm.privacy")) +
                " "
            )
          ]),
          _vm._v(".\n    ")
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }