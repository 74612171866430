import clientStorage from "./clientStorage";
import _ from "lodash";

const namespace = "bannersViewed";

const bannersViewedStorage = {
    set: name => {
        const value = name;
        let currentList = bannersViewedStorage.get();

        if (!Array.isArray(currentList)) {
            currentList = [];
        }

        if (_.isNil(value)) {
            console.trace("Error: Invalid banner name", name);
            return false;
        }

        clientStorage.setItem(namespace, [...currentList, value]);

        return { namespace: value };
    },

    get: () => {
        const value = clientStorage.getItem(namespace);

        if (_.isNil(value)) {
            return false;
        }

        return value;
    }
};

export { bannersViewedStorage as default };
