<template>
    <div
        id="inventory-mobile-nav-menu"
        class="d-flex align-items-center w-100 px-1 pt-3 mb-2 justify-content-between flex-wrap"
    >
        <div v-if="showViewOptions" class="d-flex view-options m-1 m-sm-0">
            <b-link
                v-if="isModelListings"
                class="view-option"
                :class="{ 'active-view': isModelListings }"
            >
                Models
            </b-link>
            <b-link
                v-else
                class="view-option"
                :class="{ 'active-view': isModelListings }"
                @click="showModels"
            >
                Models
            </b-link>
            <span class="px-1">
                |
            </span>
            <b-link
                v-if="isNewListings"
                class="view-option"
                :class="{ 'active-view': isNewListings }"
            >
                Listings
            </b-link>
            <b-link
                v-else
                class="view-option"
                :class="{ 'active-view': isNewListings }"
                @click="showListings"
            >
                Listings
            </b-link>
        </div>
        <div class="matches m-1 m-sm-0">
            <span>{{ total | numeral("0,0") }} matches</span>
        </div>
    </div>
</template>

<script>
import { get } from "vuex-pathify";
import _ from "lodash";

export default {
    name: "InventoryMobileNavmenu",
    computed: {
        isNewListings: get("vehicleSearch/mobileListingsView@isNew"),
        isModelListings: get("vehicleSearch/mobileListingsView@isModel"),
        pageMetadata: get("vehicleSearch/pageMetadata"),
        sortBy: get("vehicleSearch/pageable@sort"),
        filtersAsUrl: get("vehicleSearch/getFiltersAsUrl"),
        stockTypes: get("vehicleSearch/filters@stockTypes"),
        showViewOptions() {
            return this.isModelListings || this.isNewListings;
        },
        total() {
            return _.get(this.pageMetadata, "totalElements", 0);
        },
        isTypeUsed() {
            return this.stockTypes.indexOf("USED") !== -1;
        }
    },

    methods: {
        showModels() {
            if (this.sortBy.indexOf("distance") !== -1) {
                window.location.href = this.filtersAsUrl(
                    "/shop/models",
                    "savings,desc"
                );
            } else {
                window.location.href = this.filtersAsUrl("/shop/models");
            }
        },
        showListings() {
            window.location.href = this.filtersAsUrl("/shop/new");
        }
    }
};
</script>

<style lang="scss" scoped>
#inventory-mobile-nav-menu {
    .view-options {
        font-size: px2rem(12);
        line-height: px2rem(14);

        .view-option {
            color: $dark-gray;
            font-weight: normal;
        }

        .active-view {
            color: $primary-blue;
            text-decoration: none;
        }
    }

    .matches {
        font-size: px2rem(12);
        line-height: px2rem(14);
        color: $dark-gray;
    }
}
</style>
