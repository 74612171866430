<template>
    <c-s-modal
        ref="zipcodeModal"
        :hide-footer="true"
        :hide-header="true"
        class="modal"
    >
        <b-container fluid v-if="!isStateSycWhitelisted && submitted">
            <b-row class="text-center pb-2">
                <b-col></b-col>
                <b-col></b-col>
                <b-col class="text-right">
                    <div class="close">
                        <em
                            class="ion-ios-close-empty"
                            @click="closeModal"
                        ></em>
                    </div>
                </b-col>
            </b-row>
            <b-row class="text-center pb-5 mb-5">
                <b-col>
                    <div>
                        Sorry, this service is not available in your area yet!
                    </div>
                </b-col>
            </b-row>
            <b-row class="mt-5 pt-5 pb-3">
                <b-col></b-col>
                <b-col cols="8">
                    <b-button
                        variant="orange"
                        size="lg"
                        class="modal-btn"
                        block
                        @click="closeModal"
                        >Close
                    </b-button>
                </b-col>
                <b-col></b-col>
            </b-row>
        </b-container>
        <b-container fluid v-else>
            <b-row class="text-center">
                <b-col></b-col>
                <b-col cols="8" class="pb-0">
                    <div class="modal-title">
                        Location
                    </div>
                </b-col>
                <b-col>
                    <div class="close">
                        <em
                            class="ion-ios-close-empty"
                            @click="closeModal"
                        ></em>
                    </div>
                </b-col>
            </b-row>
            <b-row>
                <b-col></b-col>
                <b-col cols="4" class="pt-0 pb-4">
                    <hr class="horizontal-line" />
                </b-col>
                <b-col></b-col>
            </b-row>
            <b-row class="text-center pb-5">
                <b-col>
                    <div>
                        Please enter your zip code to continue
                    </div>
                </b-col>
            </b-row>
            <b-row class="pb-5">
                <b-col></b-col>
                <b-col class="text-right pr-1">
                    <label>Zip Code</label>
                </b-col>
                <b-col cols="3" class="text-left pl-1">
                    <b-form-input
                        v-model="zipCode"
                        id="zip-code"
                        class="zipcode"
                        maxlength="5"
                        :state="isValidZipCode"
                    />
                </b-col>
                <b-col></b-col>
            </b-row>
            <b-row class="pb-3">
                <b-col></b-col>
                <b-col cols="8">
                    <b-button
                        variant="orange"
                        size="lg"
                        class="modal-btn"
                        block
                        @click="saveZipCode"
                        >Continue
                    </b-button>
                </b-col>
                <b-col></b-col>
            </b-row>
        </b-container>
    </c-s-modal>
</template>

<script>
import CSModal from "Components/CSModal";
import { call } from "vuex-pathify";
import { get } from "vuex-pathify";
import _ from "lodash";
import navigationHelpers from "@/mixins/navigationHelpers";

export default {
    name: "ZipcodeModal",
    components: { CSModal },
    data() {
        return {
            zipCode: "",
            isValidZipCode: null,
            submitted: false
        };
    },
    computed: {
        isStateCarOffer: get("location/isStateCarOffer"),
        isStateSycWhitelisted: get("location/isStateSycWhitelisted"),
        quantumUrl: get("sellYourCar/urls@quantum"),
        carOfferUrl: get("sellYourCar/urls@carOffer")
    },
    methods: {
        updateZipCode: call("location/updateZipCode"),

        show() {
            this.$refs.zipcodeModal.show();
        },

        isValidZipCodePattern(zipCode) {
            return /(^\d{5}$)/.test(zipCode);
        },

        saveZipCode() {
            if (this.isValidZipCodePattern(this.zipCode)) {
                this.updateZipCode(this.zipCode)
                    .then(() => {
                        if (this.isStateSycWhitelisted) {
                            this.routeToSellYourCarWidget();
                        }
                    })
                    .catch(error => console.log("Zipcode modal error: ", error))
                    .finally(() => (this.submitted = true));
            } else {
                this.isValidZipCode = false;
            }
        },

        closeModal() {
            this.$refs.zipcodeModal.hide();
        },
        routeToSellYourCarWidget() {
            window.location = this.carOfferUrl;
        }
    }
};
</script>

<style lang="scss" scoped>
.horizontal-line {
    margin-top: 0;
    border: 1px solid $medium-yellow;
}

.modal-title {
    color: $primary-blue;
    margin: 0;
    font-size: 36px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.3;
    align-self: flex-end;
    font-weight: 500;
}

.close {
    opacity: 0.8;
    outline: none;
    cursor: pointer;

    em {
        font-size: 3.6rem;
        color: #444444;
    }

    &:hover {
        opacity: 1;
    }
}
</style>
