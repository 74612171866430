<template>
    <div v-if="showSessionResetOption" class="session-reset">
        <session-reset-button :ambassador-id="ambassadorId" />
    </div>
</template>

<script>
import SessionResetButton from "./components/SessionResetButton";
import { get } from "vuex-pathify";

export default {
    name: "SessionReset",

    components: {
        SessionResetButton
    },

    computed: {
        showSessionResetOption: function() {
            return !!this.walmartId;
        },

        walmartId: get("sessionReset/walmartId"),
        ambassadorId: get("sessionReset/ambassadorId")
    }
};
</script>
