var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "submenuContainer",
      staticClass:
        "d-flex d-md-none justify-content-between align-items-center px-3",
      class: { "transition--effect": _vm.enableTransition },
      attrs: { id: "inventory-mobile-submenu-container" }
    },
    [
      _c(
        "b-button",
        {
          staticClass: "filter-results",
          on: { click: _vm.toggleFiltersModal }
        },
        [
          _vm._v("\n        Filter Results\n        "),
          _c("i", {
            staticClass: "ion-arrow-down-b align-self-baseline pl-2",
            attrs: { "aria-hidden": "true" }
          })
        ]
      ),
      _vm._v(" "),
      _c("sort-selector-mobile")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }