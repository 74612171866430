/**
 * Default state of any state that needs to be loaded from an external resource
 *
 * @returns {{isLoading: boolean, isError: boolean, isCompete: boolean, errorMessages: null}}
 */
const defaultState = () => {
    return {
        isLoading: false,
        isError: false,
        isComplete: false,
        errorMessages: null
    };
};

/**
 * Help to set the loading state to allow a reload to happen (when no re-entrant is used for actions of ajax loading)
 * Mimics the same as default state, currently but create more semantically readable code.
 *
 * @returns {{isLoading: boolean, isError: boolean, isCompete: boolean, errorMessages: null}}
 */
const reload = () => {
    return {
        isLoading: false,
        isError: false,
        isComplete: false,
        errorMessages: null
    };
};

/**
 * Sets the loading state to indicate that a request has been started to load content from an external resource
 *
 * @returns {{isLoading: boolean, isError: boolean, isComplete: boolean, errorMessages: null}}
 */
const started = () => {
    return {
        isLoading: true,
        isError: false,
        isComplete: false,
        errorMessages: null
    };
};

/**
 * Sets the loading state to finished but with an error due to some unknown reason which will be passed via the errors param
 *
 * @param errors
 * @returns {{isLoading: boolean, isError: boolean, isComplete: boolean, errorMessages: *}}
 */
const error = errors => {
    return {
        isLoading: false,
        isError: true,
        isComplete: true,
        errorMessages: errors
    };
};

/**
 * Resource content has been successfully loaded without any errors
 *
 * @returns {{isLoading: boolean, isError: boolean, isComplete: boolean, errorMessages: null}}
 */
const successful = () => {
    return {
        isLoading: false,
        isError: false,
        isComplete: true,
        errorMessages: null
    };
};

export default {
    defaultState,
    started,
    error,
    successful,
    reload
};
