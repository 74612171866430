<template>
    <div v-if="!noneApplied" class="selected-facets p-3">
        <div class="container">
            <div class="row" @click="showFilters = !showFilters">
                <div class="col-12 p-0">
                    <div class="facet-header d-flex justify-content-between">
                        <b-link class="label">
                            <span class="font-weight-bold">
                                Filters Selected
                            </span>
                            <span class="total-filters">
                                ({{ totalFiltersApplied }})
                            </span>
                        </b-link>
                        <div>
                            <b-link @click="clearAllSelectedFilters">
                                <span class="clear font-italic">
                                    Clear All
                                </span>
                            </b-link>
                            <b-link class="collapse-icon">
                                <span
                                    :class="[
                                        showFilters
                                            ? 'ion-chevron-down'
                                            : 'ion-chevron-right'
                                    ]"
                                />
                            </b-link>
                        </div>
                    </div>
                </div>
            </div>
            <b-collapse :visible="showFilters">
                <div class="row">
                    <div class="col-12 p-0">
                        <div id="filterBox" class="filter-box d-flex flex-wrap">
                            <div
                                v-if="paymentTypeIsApplied"
                                class="filter-pill d-flex align-items-center"
                            >
                                <div
                                    class="d-flex justify-content-between filter-content "
                                >
                                    <span class="filter-text">
                                        {{ paymentType }}
                                    </span>
                                    <b-link @click="clearPaymentType">
                                        <i
                                            aria-hidden="true"
                                            class="ion ion-close"
                                        />
                                    </b-link>
                                </div>
                            </div>

                            <div
                                v-if="priceAreApplied"
                                class="filter-pill d-flex align-items-center"
                            >
                                <div
                                    class="d-flex justify-content-between filter-content "
                                >
                                    <span class="filter-text">
                                        {{
                                            appliedFilters.minPrice
                                                | nullToAny
                                                | numeral("$0,0")
                                        }}
                                        -
                                        {{
                                            appliedFilters.maxPrice
                                                | nullToAny
                                                | numeral("$0,0")
                                        }}
                                    </span>
                                    <b-link @click="clearPrices">
                                        <i
                                            aria-hidden="true"
                                            class="ion ion-close"
                                        />
                                    </b-link>
                                </div>
                            </div>

                            <div
                                v-if="
                                    stockTypesAreApplied && showStockTypeFilter
                                "
                            >
                                <div
                                    v-for="stockType in appliedStockTypeFilters"
                                    :key="stockType"
                                    class="filter-pill d-flex align-items-center"
                                >
                                    <div
                                        class="d-flex justify-content-between filter-content "
                                    >
                                        <span class="filter-text">
                                            {{
                                                stockType
                                                    | lowerCaseThenCapitalize
                                            }}
                                        </span>
                                        <b-link
                                            @click="clearStockType(stockType)"
                                        >
                                            <i
                                                aria-hidden="true"
                                                class="ion ion-close"
                                            />
                                        </b-link>
                                    </div>
                                </div>
                            </div>

                            <div
                                v-if="paymentsAreApplied"
                                class="filter-pill d-flex align-items-center"
                            >
                                <div
                                    class="d-flex justify-content-between filter-content "
                                >
                                    <span class="filter-text">
                                        {{
                                            appliedMinPayment
                                                | nullToAny
                                                | numeral("$0,0")
                                        }}
                                        -
                                        {{
                                            appliedMaxPayment
                                                | nullToAny
                                                | numeral("$0,0")
                                        }}{{
                                            !isNullOrNullString(
                                                appliedMaxPayment
                                            )
                                                ? "/mo"
                                                : ""
                                        }}
                                    </span>
                                    <b-link @click="clearMonthlyPayment">
                                        <i
                                            aria-hidden="true"
                                            class="ion ion-close"
                                        />
                                    </b-link>
                                </div>
                            </div>

                            <div
                                v-if="yearsAreApplied"
                                class="filter-pill d-flex align-items-center"
                            >
                                <div
                                    class="d-flex justify-content-between filter-content "
                                >
                                    <span
                                        v-if="appliedMinYear === appliedMaxYear"
                                        class="mr-1"
                                    >
                                        {{ appliedMinYear }}
                                    </span>

                                    <div v-else>
                                        <span>{{
                                            appliedMinYear | nullToAny
                                        }}</span>
                                        <span>-</span>
                                        <span class="filter-text">
                                            {{ appliedMaxYear | nullToAny }}
                                        </span>
                                    </div>

                                    <b-link @click="clearYears">
                                        <i
                                            aria-hidden="true"
                                            class="ion ion-close"
                                        />
                                    </b-link>
                                </div>
                            </div>

                            <div
                                v-if="milesAreApplied"
                                class="filter-pill d-flex align-items-center"
                            >
                                <div
                                    class="d-flex justify-content-between filter-content "
                                >
                                    <span class="filter-text">
                                        {{ appliedMilesDescription }}
                                    </span>
                                    <b-link @click="clearMiles">
                                        <i
                                            aria-hidden="true"
                                            class="ion ion-close"
                                        />
                                    </b-link>
                                </div>
                            </div>

                            <div
                                v-if="bodyStylesAreApplied"
                                class="d-inline-flex flex-wrap"
                            >
                                <div
                                    v-for="bodyStyle in appliedBodyStyleFilters"
                                    :key="bodyStyle"
                                    class="filter-pill d-flex align-items-center"
                                >
                                    <div
                                        class="d-flex justify-content-between filter-content "
                                    >
                                        <span class="filter-text">
                                            {{ bodyStyle | capitalize }}
                                        </span>
                                        <b-link
                                            @click="clearBodyStyle(bodyStyle)"
                                        >
                                            <i
                                                aria-hidden="true"
                                                class="ion ion-close"
                                            />
                                        </b-link>
                                    </div>
                                </div>
                            </div>

                            <div
                                v-if="makesAreApplied"
                                class="d-inline-flex flex-wrap"
                            >
                                <div
                                    v-for="make in appliedMakeFilters"
                                    :key="make"
                                    class="filter-pill d-flex align-items-center"
                                >
                                    <div
                                        class="d-flex justify-content-between filter-content "
                                    >
                                        <span class="filter-text">
                                            {{ make }}
                                        </span>
                                        <b-link @click="clearMake(make)">
                                            <i
                                                aria-hidden="true"
                                                class="ion ion-close"
                                            />
                                        </b-link>
                                    </div>
                                </div>
                            </div>

                            <div
                                v-if="modelsAreApplied"
                                class="d-inline-flex flex-wrap"
                            >
                                <div
                                    v-for="model in appliedModelFilters"
                                    :key="model"
                                    class="filter-pill d-flex align-items-center"
                                >
                                    <div
                                        class="d-flex justify-content-between filter-content "
                                    >
                                        <span class="filter-text">
                                            {{ model }}
                                        </span>
                                        <b-link @click="clearModel(model)">
                                            <i
                                                aria-hidden="true"
                                                class="ion ion-close"
                                            />
                                        </b-link>
                                    </div>
                                </div>
                            </div>

                            <div
                                v-if="trimsAreApplied"
                                class="d-inline-flex flex-wrap"
                            >
                                <div
                                    v-for="trim in appliedTrimFilters"
                                    :key="trim"
                                    class="filter-pill d-flex align-items-center"
                                >
                                    <div
                                        class="d-flex justify-content-between filter-content "
                                    >
                                        <span class="filter-text">
                                            {{ trim }}
                                        </span>
                                        <b-link @click="clearTrim(trim)">
                                            <i
                                                aria-hidden="true"
                                                class="ion ion-close"
                                            />
                                        </b-link>
                                    </div>
                                </div>
                            </div>

                            <div
                                v-if="exteriorColorAreApplied"
                                class="d-inline-flex flex-wrap"
                            >
                                <div
                                    v-for="exColor in appliedExteriorColorFilters"
                                    :key="exColor"
                                    class="filter-pill d-flex align-items-center"
                                >
                                    <div
                                        class="d-flex justify-content-between filter-content "
                                    >
                                        <span class="filter-text">
                                            {{ exColor }}
                                            <small class="font-italic">
                                                (Ext.)
                                            </small>
                                        </span>
                                        <b-link @click="clearExColors(exColor)">
                                            <i
                                                aria-hidden="true"
                                                class="ion ion-close"
                                            />
                                        </b-link>
                                    </div>
                                </div>
                            </div>

                            <div
                                v-if="interiorColorAreApplied"
                                class="d-inline-flex flex-wrap"
                            >
                                <div
                                    v-for="inColor in appliedInteriorColorFilters"
                                    :key="inColor"
                                    class="filter-pill d-flex align-items-center"
                                >
                                    <div
                                        class="d-flex justify-content-between filter-content "
                                    >
                                        <span class="filter-text">
                                            {{ inColor }}
                                            <small class="font-italic">
                                                (Int.)
                                            </small>
                                        </span>
                                        <b-link @click="clearInColors(inColor)">
                                            <i
                                                aria-hidden="true"
                                                class="ion ion-close"
                                            />
                                        </b-link>
                                    </div>
                                </div>
                            </div>

                            <div
                                v-if="transmissionsAreApplied"
                                class="d-inline-flex flex-wrap"
                            >
                                <div
                                    v-for="transmission in appliedTransmissionFilters"
                                    :key="transmission"
                                    class="filter-pill d-flex align-items-center"
                                >
                                    <div
                                        class="d-flex justify-content-between filter-content "
                                    >
                                        <span class="filter-text">
                                            {{
                                                transmission
                                                    | lowerCaseThenCapitalize
                                            }}
                                        </span>
                                        <b-link
                                            @click="
                                                clearTransmissions(transmission)
                                            "
                                        >
                                            <i
                                                aria-hidden="true"
                                                class="ion ion-close"
                                            />
                                        </b-link>
                                    </div>
                                </div>
                            </div>

                            <div
                                v-if="cylindersAreApplied"
                                class="d-inline-flex flex-wrap"
                            >
                                <div
                                    v-for="cylinder in appliedCylinderFilters"
                                    :key="cylinder"
                                    class="filter-pill d-flex align-items-center"
                                >
                                    <div
                                        class="d-flex justify-content-between filter-content "
                                    >
                                        <span class="filter-text">
                                            {{ cylinder }} Cylinders
                                        </span>
                                        <b-link
                                            @click="clearCylinders(cylinder)"
                                        >
                                            <i
                                                aria-hidden="true"
                                                class="ion ion-close"
                                            />
                                        </b-link>
                                    </div>
                                </div>
                            </div>

                            <div
                                v-if="drivetrainsAreApplied"
                                class="d-inline-flex flex-wrap"
                            >
                                <div
                                    v-for="drivetrain in appliedDrivetrainFilters"
                                    :key="drivetrain"
                                    class="filter-pill d-flex align-items-center"
                                >
                                    <div
                                        class="d-flex justify-content-between filter-content "
                                    >
                                        <span class="filter-text">
                                            {{ driveTrainLabel(drivetrain) }}
                                        </span>
                                        <b-link
                                            @click="
                                                clearDrivetrains(drivetrain)
                                            "
                                        >
                                            <i
                                                aria-hidden="true"
                                                class="ion ion-close"
                                            />
                                        </b-link>
                                    </div>
                                </div>
                            </div>

                            <div
                                v-if="fuelsAreApplied"
                                class="d-inline-flex flex-wrap"
                            >
                                <div
                                    v-for="fuel in appliedFuelFilters"
                                    :key="fuel"
                                    class="filter-pill d-flex align-items-center"
                                >
                                    <div
                                        class="d-flex justify-content-between filter-content "
                                    >
                                        <span class="filter-text">
                                            {{ fuel }}
                                        </span>
                                        <b-link @click="clearFuels(fuel)">
                                            <i
                                                aria-hidden="true"
                                                class="ion ion-close"
                                            />
                                        </b-link>
                                    </div>
                                </div>
                            </div>

                            <div
                                v-if="passengerCapacitiesAreApplied"
                                class="d-inline-flex flex-wrap"
                            >
                                <div
                                    v-for="passengerCapacity in appliedPassengerCapacityFilters"
                                    :key="passengerCapacity"
                                    class="filter-pill d-flex align-items-center"
                                >
                                    <div
                                        class="d-flex justify-content-between filter-content "
                                    >
                                        <span class="filter-text">
                                            {{ passengerCapacity }} Seats
                                        </span>
                                        <b-link
                                            @click="
                                                clearPassengerCapacities(
                                                    passengerCapacity
                                                )
                                            "
                                        >
                                            <i
                                                aria-hidden="true"
                                                class="ion ion-close"
                                            />
                                        </b-link>
                                    </div>
                                </div>
                            </div>

                            <div
                                v-if="specialProgramsAreApplied"
                                class="d-inline-flex flex-wrap w-100"
                            >
                                <div
                                    v-if="appliedCertifiedDealer"
                                    class="filter-pill d-flex align-items-center"
                                >
                                    <div
                                        class="d-flex justify-content-between filter-content "
                                    >
                                        <span class="filter-text">
                                            Certified Dealer
                                        </span>
                                        <b-link @click="clearCertifiedDealer">
                                            <i
                                                aria-hidden="true"
                                                class="ion ion-close"
                                            />
                                        </b-link>
                                    </div>
                                </div>

                                <div
                                    v-if="appliedHomeDelivery"
                                    class="filter-pill d-flex align-items-center"
                                >
                                    <div
                                        class="d-flex justify-content-between filter-content "
                                    >
                                        <span class="filter-text">
                                            Home Delivery
                                        </span>
                                        <b-link @click="clearHomeDelivery">
                                            <i
                                                aria-hidden="true"
                                                class="ion ion-close"
                                            />
                                        </b-link>
                                    </div>
                                </div>

                                <div
                                    v-if="appliedLocalDealer"
                                    class="filter-pill d-flex align-items-center"
                                >
                                    <div
                                        class="d-flex justify-content-between filter-content "
                                    >
                                        <span class="filter-text">
                                            Local Dealer
                                        </span>
                                        <b-link @click="clearLocalDealer">
                                            <i
                                                aria-hidden="true"
                                                class="ion ion-close"
                                            />
                                        </b-link>
                                    </div>
                                </div>

                                <div
                                    v-if="appliedSupplierPricing"
                                    class="filter-pill d-flex align-items-center"
                                >
                                    <div
                                        class="d-flex justify-content-between filter-content "
                                    >
                                        <span class="filter-text">
                                            Supplier Pricing
                                        </span>
                                        <b-link @click="clearSupplierPricing">
                                            <i
                                                aria-hidden="true"
                                                class="ion ion-close"
                                            />
                                        </b-link>
                                    </div>
                                </div>
                            </div>

                            <div
                                v-if="dealBadgesAreApplied"
                                class="d-inline-flex flex-wrap"
                            >
                                <div
                                    v-for="badgeId in appliedDealBadgesFilters"
                                    :key="badgeId"
                                    class="filter-pill d-flex align-items-center"
                                >
                                    <div
                                        class="d-flex justify-content-between filter-content "
                                    >
                                        <span class="filter-text">
                                            {{ badgeId | dealName }}
                                        </span>
                                        <b-link @click="clearBadges(badgeId)">
                                            <i
                                                aria-hidden="true"
                                                class="ion ion-close"
                                            />
                                        </b-link>
                                    </div>
                                </div>
                            </div>

                            <div
                                v-if="vehicleCategoryAreApplied"
                                class="d-inline-flex flex-wrap"
                            >
                                <div
                                    class="filter-pill d-flex align-items-center"
                                >
                                    <div
                                        class="d-flex justify-content-between filter-content "
                                    >
                                        <span class="filter-text">
                                            {{
                                                appliedVehicleCategoryFilters
                                                    | capitalize
                                            }}
                                        </span>
                                        <b-link @click="clearVehicleCategory">
                                            <i
                                                aria-hidden="true"
                                                class="ion ion-close"
                                            />
                                        </b-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </b-collapse>
        </div>
    </div>
</template>

<script>
import _ from "lodash";
import { sync, dispatch, get, call } from "vuex-pathify";
import numeral from "numeral";

export default {
    name: "SelectedFilters",

    filters: {
        nullToAny(input) {
            if (_.isNil(input) || input === "null") {
                return "Any";
            }
            return parseInt(input);
        },
        capitalize(value) {
            if (!value) return "";

            if (value === "suv") {
                return value.toUpperCase();
            }
            value = value.toString();
            return value.charAt(0).toUpperCase() + value.slice(1);
        },
        lowerCaseThenCapitalize(value) {
            if (!value) return "";
            return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
        },
        dealName(badgeId) {
            switch (badgeId) {
                case 0:
                    return "Great Deal";
                case 1:
                    return "Good Deal";
                case 2:
                    return "Fair Price";
                default:
                    return null;
            }
        }
    },

    props: {
        showStockTypeFilter: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            showFilters: false
        };
    },

    computed: {
        appliedFilters: sync("vehicleSearch/filters"),
        appliedBodyStyleFilters: sync("vehicleSearch/filters@bodyStyles"),
        appliedMakeFilters: sync("vehicleSearch/filters@makes"),
        appliedModelFilters: sync("vehicleSearch/filters@models"),
        appliedTrimFilters: sync("vehicleSearch/filters@trims"),
        appliedExteriorColorFilters: sync("vehicleSearch/filters@exteriorColors"),
        appliedInteriorColorFilters: sync("vehicleSearch/filters@interiorColors"),
        appliedTransmissionFilters: sync("vehicleSearch/filters@transmissions"),
        appliedCylinderFilters: sync("vehicleSearch/filters@cylinders"),
        appliedDrivetrainFilters: sync("vehicleSearch/filters@drivetrains"),
        appliedFuelFilters: sync("vehicleSearch/filters@fuels"),
        appliedMaximumMiles: sync("vehicleSearch/filters@maxMiles"),
        appliedMinimumMiles: sync("vehicleSearch/filters@minMiles"),
        appliedMinPayment: sync("vehicleSearch/filters@minPayment"),
        appliedMaxPayment: sync("vehicleSearch/filters@maxPayment"),
        appliedMinYear: get("vehicleSearch/filters@minYear"),
        appliedMaxYear: get("vehicleSearch/filters@maxYear"),
        appliedStockTypeFilters: sync("vehicleSearch/filters@stockTypes"),
        appliedPassengerCapacityFilters: sync("vehicleSearch/filters@passengerCapacities"),
        appliedCertifiedDealer: sync("vehicleSearch/filters@certifiedDealer"),
        appliedHomeDelivery: sync("vehicleSearch/filters@homeDelivery"),
        appliedLocalDealer: sync("vehicleSearch/filters@localDealer"),
        appliedSupplierPricing: sync("vehicleSearch/filters@supplierPricing"),
        appliedDealBadgesFilters: sync("vehicleSearch/filters@dealBadges"),
        appliedVehicleCategoryFilters: sync("vehicleSearch/filters@vehicleCategory"),
        paymentType: sync("vehicleSearch/filters@paymentType"),
        vehicleCategoryAreApplied() {
            return !_.isEmpty(this.appliedVehicleCategoryFilters);
        },
        dealBadgesAreApplied() {
            return !_.isEmpty(this.appliedDealBadgesFilters);
        },
        specialProgramsAreApplied() {
            return (
                this.appliedCertifiedDealer === true ||
                this.appliedSupplierPricing === true ||
                this.appliedHomeDelivery === true ||
                this.appliedLocalDealer === true
            );
        },
        paymentTypeIsApplied() {
            return this.paymentType !== "ALL";
        },
        priceAreApplied() {
            const minPriceApplied =
                !_.isNil(this.appliedFilters.minPrice) &&
                this.appliedFilters.minPrice !== "null";
            const maxPriceApplied =
                !_.isNil(this.appliedFilters.maxPrice) &&
                this.appliedFilters.maxPrice !== "null";

            return minPriceApplied || maxPriceApplied;
        },
        paymentsAreApplied() {
            const minPaymentApplied =
                !_.isNil(this.appliedMinPayment) &&
                this.appliedMinPayment !== "null";
            const maxPaymentApplied =
                !_.isNil(this.appliedMaxPayment) &&
                this.appliedMaxPayment !== "null";

            return minPaymentApplied || maxPaymentApplied;
        },
        yearsAreApplied() {
            const minYearApplied =
                !_.isNil(this.appliedFilters.minYear) &&
                this.appliedFilters.minYear !== "null";
            const maxYearApplied =
                !_.isNil(this.appliedFilters.maxYear) &&
                this.appliedFilters.maxYear !== "null";

            return minYearApplied || maxYearApplied;
        },
        milesAreApplied() {
            return (
                !_.isNil(this.appliedMinimumMiles) ||
                !_.isNil(this.appliedMaximumMiles)
            );
        },
        bodyStylesAreApplied() {
            return !_.isEmpty(this.appliedBodyStyleFilters);
        },
        stockTypesAreApplied() {
            return !_.isEmpty(this.appliedStockTypeFilters);
        },
        makesAreApplied() {
            return !_.isEmpty(this.appliedMakeFilters);
        },
        modelsAreApplied() {
            return !_.isEmpty(this.appliedModelFilters);
        },
        trimsAreApplied() {
            return !_.isEmpty(this.appliedTrimFilters);
        },
        exteriorColorAreApplied() {
            return !_.isEmpty(this.appliedExteriorColorFilters);
        },
        interiorColorAreApplied() {
            return !_.isEmpty(this.appliedInteriorColorFilters);
        },
        transmissionsAreApplied() {
            return !_.isEmpty(this.appliedTransmissionFilters);
        },
        cylindersAreApplied() {
            return !_.isEmpty(this.appliedCylinderFilters);
        },
        drivetrainsAreApplied() {
            return !_.isEmpty(this.appliedDrivetrainFilters);
        },
        fuelsAreApplied() {
            return !_.isEmpty(this.appliedFuelFilters);
        },
        passengerCapacitiesAreApplied() {
            return !_.isEmpty(this.appliedPassengerCapacityFilters);
        },
        appliedMilesDescription() {
            let milesDescription;

            if (
                _.isNil(this.appliedMinimumMiles) &&
                !_.isNil(this.appliedMaximumMiles)
            ) {
                milesDescription = `${numeral(this.appliedMaximumMiles).format(
                    "0,0"
                )} mi Or Less`;
            } else if (
                !_.isNil(this.appliedMinimumMiles) &&
                _.isNil(this.appliedMaximumMiles)
            ) {
                milesDescription = `${numeral(this.appliedMinimumMiles).format(
                    "0,0"
                )} mi Or More`;
            } else {
                milesDescription = `${numeral(this.appliedMinimumMiles).format(
                    "0,0"
                )} - ${numeral(this.appliedMaximumMiles).format("0,0")} mi`;
            }

            return milesDescription;
        },
        noneApplied() {
            return (
                !this.priceAreApplied &&
                !this.paymentsAreApplied &&
                !this.yearsAreApplied &&
                !this.milesAreApplied &&
                !this.bodyStylesAreApplied &&
                !this.makesAreApplied &&
                !this.modelsAreApplied &&
                !this.exteriorColorAreApplied &&
                !this.interiorColorAreApplied &&
                !this.transmissionsAreApplied &&
                !this.cylindersAreApplied &&
                !this.drivetrainsAreApplied &&
                !this.fuelsAreApplied &&
                !this.specialProgramsAreApplied &&
                !this.passengerCapacitiesAreApplied &&
                !this.paymentTypeIsApplied &&
                !this.dealBadgesAreApplied &&
                !this.vehicleCategoryAreApplied &&
                !this.trimsAreApplied
            );
        },
        totalFiltersApplied() {
            let total = 0;

            if (this.priceAreApplied) {
                total++;
            }

            if (this.paymentsAreApplied) {
                total++;
            }

            if (this.yearsAreApplied) {
                total++;
            }

            if (this.milesAreApplied) {
                total++;
            }

            if (this.bodyStylesAreApplied) {
                const totalBodyStyles = this.appliedBodyStyleFilters.length;
                total += totalBodyStyles;
            }

            if (this.makesAreApplied) {
                const totalMakes = this.appliedMakeFilters.length;
                total += totalMakes;
            }

            if (this.modelsAreApplied) {
                const totalModels = this.appliedModelFilters.length;
                total += totalModels;
            }

            if (this.exteriorColorAreApplied) {
                const totalExteriorColors = this.appliedExteriorColorFilters.length;
                total += totalExteriorColors;
            }

            if (this.interiorColorAreApplied) {
                const totalInteriorColors = this.appliedInteriorColorFilters.length;
                total += totalInteriorColors;
            }

            if (this.transmissionsAreApplied) {
                const totalTransmissions = this.appliedTransmissionFilters.length;
                total += totalTransmissions;
            }

            if (this.cylindersAreApplied) {
                const totalCylinders = this.appliedCylinderFilters.length;
                total += totalCylinders;
            }

            if (this.drivetrainsAreApplied) {
                const totalDriveTrains = this.appliedDrivetrainFilters.length;
                total += totalDriveTrains;
            }

            if (this.fuelsAreApplied) {
                const totalFuels = this.appliedFuelFilters.length;
                total += totalFuels;
            }

            if (this.specialProgramsAreApplied) {
                let totalSpecialPrograms = 0;

                if(this.appliedCertifiedDealer) {
                    totalSpecialPrograms++;
                }
                if(this.appliedHomeDelivery) {
                    totalSpecialPrograms++;
                }
                if(this.appliedSupplierPricing) {
                    totalSpecialPrograms++;
                }
                if(this.appliedLocalDealer) {
                    totalSpecialPrograms++;
                }

                total += totalSpecialPrograms;
            }

            if (this.passengerCapacitiesAreApplied) {
                const totalSeats = this.appliedPassengerCapacityFilters.length;
                total += totalSeats;
            }

            if (this.paymentTypeIsApplied) {
                total++;
            }

            if (this.dealBadgesAreApplied) {
                const totalDealBadges = this.appliedDealBadgesFilters.length;
                total += totalDealBadges;
            }

            if (this.vehicleCategoryAreApplied) {
                total++;
            }

            if (this.trimsAreApplied) {
                const totalTrims = this.appliedTrimFilters.length;
                total += totalTrims;
            }

            return total;
        }
    },

    methods: {
        clearVehicleCategory: call("vehicleSearch/clearVehicleCategory"),
        clearCertifiedDealer: call("vehicleSearch/clearCertifiedDealer"),
        clearHomeDelivery: call("vehicleSearch/clearHomeDelivery"),
        clearLocalDealer: call("vehicleSearch/clearLocalDealer"),
        clearSupplierPricing: call("vehicleSearch/clearSupplierPricing"),
        clearPaymentType: call("vehicleSearch/clearPaymentType"),
        clearMakeAction: call("vehicleSearch/clearMake"),
        clearModelAction: call("vehicleSearch/clearModel"),
        clearPrices() {
            dispatch("vehicleSearch/clearPrice");
        },
        clearYears() {
            dispatch("vehicleSearch/clearYears");
        },
        clearMiles() {
            dispatch("vehicleSearch/clearMiles");
        },
        clearMonthlyPayment() {
            dispatch("vehicleSearch/clearMonthlyPayment");
        },
        clearBodyStyle(bodyStyleToRemove) {
            if (!_.isEmpty(this.appliedBodyStyleFilters)) {
                this.appliedBodyStyleFilters = this.appliedBodyStyleFilters.filter(
                    bodyStyle => bodyStyle !== bodyStyleToRemove
                );
            }
        },
        clearStockType(stockTypeToRemove) {
            if (!_.isEmpty(this.appliedStockTypeFilters)) {
                this.appliedStockTypeFilters = this.appliedStockTypeFilters.filter(
                    stockType => stockType !== stockTypeToRemove
                );
            }
        },
        clearMake(makeToRemove) {
            if (!_.isEmpty(this.appliedMakeFilters)) {
                this.clearMakeAction(makeToRemove);
            }
        },
        clearModel(modelToRemove) {
            if (!_.isEmpty(this.appliedModelFilters)) {
                this.clearModelAction(modelToRemove);
            }
        },
        clearTrim(trimToRemove) {
            if (!_.isEmpty(this.appliedTrimFilters)) {
                this.appliedTrimFilters = this.appliedTrimFilters.filter(
                    trim => trim !== trimToRemove
                );
            }
        },
        clearExColors(exColorToRemove) {
            if (!_.isEmpty(this.appliedExteriorColorFilters)) {
                this.appliedExteriorColorFilters = this.appliedExteriorColorFilters.filter(
                    exColor => exColor !== exColorToRemove
                );
            }
        },
        clearInColors(inColorToRemove) {
            if (!_.isEmpty(this.appliedInteriorColorFilters)) {
                this.appliedInteriorColorFilters = this.appliedInteriorColorFilters.filter(
                    inColor => inColor !== inColorToRemove
                );
            }
        },
        clearTransmissions(transmissionToRemove) {
            if (!_.isEmpty(this.appliedTransmissionFilters)) {
                this.appliedTransmissionFilters = this.appliedTransmissionFilters.filter(
                    transmission => transmission !== transmissionToRemove
                );
            }
        },
        clearCylinders(cylinderToRemove) {
            if (!_.isEmpty(this.appliedCylinderFilters)) {
                this.appliedCylinderFilters = this.appliedCylinderFilters.filter(
                    cylinder => cylinder !== cylinderToRemove
                );
            }
        },
        clearDrivetrains(drivetrainToRemove) {
            if (!_.isEmpty(this.appliedDrivetrainFilters)) {
                this.appliedDrivetrainFilters = this.appliedDrivetrainFilters.filter(
                    drivetrain => drivetrain !== drivetrainToRemove
                );
            }
        },
        clearFuels(fuelToRemove) {
            if (!_.isEmpty(this.appliedFuelFilters)) {
                this.appliedFuelFilters = this.appliedFuelFilters.filter(
                    fuel => fuel !== fuelToRemove
                );
            }
        },
        clearPassengerCapacities(passengerCapacityToRemove) {
            if (!_.isEmpty(this.appliedPassengerCapacityFilters)) {
                this.appliedPassengerCapacityFilters = this.appliedPassengerCapacityFilters.filter(
                    passengerCapacity =>
                        passengerCapacity !== passengerCapacityToRemove
                );
            }
        },
        clearBadges(badgeIdToRemove) {
            if (!_.isEmpty(this.appliedDealBadgesFilters)) {
                this.appliedDealBadgesFilters = this.appliedDealBadgesFilters.filter(
                    badge => badge !== badgeIdToRemove
                );
            }
        },
        driveTrainLabel(driveTrainId) {
            switch (driveTrainId) {
                case "FOUR_WHEEL_DRIVE":
                    return "Four Wheel Drive";
                case "ALL_WHEEL_DRIVE":
                    return "All Wheel Drive";
                case "FRONT_WHEEL_DRIVE":
                    return "Front Wheel Drive";
                case "REAR_WHEEL_DRIVE":
                    return "Rear Wheel Drive";
                default:
                    return "Unknown Drive Type";
            }
        },
        isNullOrNullString(value) {
            return _.isNil(value) || value === "null";
        },
        clearAllSelectedFilters() {
            dispatch("vehicleSearch/clearAllFilters");
        }
    }
};
</script>
<style lang="scss" scoped>
.selected-facets {
    background-color: $card-background;
    color: $muted-dark-gray;
    margin-top: 5px;

    .facet-header {
        .label {
            color: $medium-gray;
            font-size: px2rem(16);

            &:hover {
                text-decoration: none;
            }
        }

        .clear {
            color: $primary-blue;
            font-size: px2rem(10);
            line-height: 15px;
        }

        .collapse-icon {
            color: #ababab;
            margin-left: 10px;
        }

        .total-filters {
            font-size: px2rem(14);
            line-height: px2rem(17);
            color: $dark-gray;
        }
    }

    .filter-box {
        margin-top: 5px;

        .filter-pill {
            background: $white;
            border: 1px solid #eaeaea;
            border-radius: 24px;
            box-shadow: none;
            color: $dark-gray;
            cursor: default;
            list-style: none;
            position: relative;
            font-size: px2rem(14);
            margin: 5px 5px 0 0;
            height: 30px;
            max-width: 100%;

            .filter-content {
                margin-left: 12px;
                margin-right: 12px;
                width: calc(100% - 20px);
            }

            .filter-text {
                margin-right: 10px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .ion-close {
                color: $primary-blue;
                font-weight: 500;
                font-size: px2rem(14);
            }
        }
    }
}
</style>
