<template>
    <div ref="wrapper" class="wrapper">
        <mobile-listings-footer @click="toggleFiltersModal" />
        <filters-modal
            :show-payment-type-facet="showPaymentType"
            :show-miles-facet="showMiles"
            :show-years-facet="showYears"
            :show-stock-type-facet="showStockType"
            :show-exterior-color-facet="showExteriorColor"
            :show-interior-color-facet="showInteriorColor"
        />
    </div>
</template>
<script>
import FiltersModal from "./components/FiltersModal";
import { get, sync, dispatch } from "vuex-pathify";
import MobileListingsFooter from "Modules/UsedListings/components/MobileListingsFooter/index";
import _ from "lodash";

export default {
    name: "MobileFilters",

    components: {
        MobileListingsFooter,
        FiltersModal
    },

    props: {
        // defaults found in vehicleSearch state
        showStockTypeFacet: {
            type: Boolean,
            default: false,
            required: false
        },
        showPaymentTypeFacet: {
            type: Boolean,
            default: true,
            required: false
        },
        showMilesFacet: {
            type: Boolean,
            default: true,
            required: false
        },
        showYearsFacet: {
            type: Boolean,
            default: true,
            required: false
        },
        showExteriorColorFacet: {
            type: Boolean,
            default: true,
            required: false
        },
        showInteriorColorFacet: {
            type: Boolean,
            default: true,
            required: false
        },
        isModelListings: {
            type: Boolean,
            required: false
        },
        isNewListings: {
            type: Boolean,
            required: false
        }
    },

    data() {
        return {
            elNode: {},
            elContainerNode: {},
            elHeight: 0,
            scroll: window.pageYOffset,
            headerNode: {},
            enableTransition: false
        };
    },

    computed: {
        filtersAsUrl: get("vehicleSearch/getFiltersAsUrl"),
        sortBy: get("vehicleSearch/pageable@sort"),
        show: sync("vehicleSearch/showMobileFilters"),
        showStockType: sync("vehicleSearch/mobileFiltersFacets@showStockType"),
        showPaymentType: sync(
            "vehicleSearch/mobileFiltersFacets@showPaymentType"
        ),
        showMiles: sync("vehicleSearch/mobileFiltersFacets@showMiles"),
        showYears: sync("vehicleSearch/mobileFiltersFacets@showYears"),
        showExteriorColor: sync(
            "vehicleSearch/mobileFiltersFacets@showExteriorColor"
        ),
        showInteriorColor: sync(
            "vehicleSearch/mobileFiltersFacets@showInteriorColor"
        ),
        isModel: sync("vehicleSearch/mobileListingsView@isModel"),
        isNew: sync("vehicleSearch/mobileListingsView@isNew")
    },

    watch: {
        isModelListings(newVal) {
            if (newVal === true) {
                dispatch("vehicleSearch/setToModelListings");
            }
        },
        isNewListings(newVal) {
            if (newVal === true) {
                dispatch("vehicleSearch/setToNewListings");
            }
        }
    },

    mounted() {
        this.init();
    },

    methods: {
        init() {
            this.showStockType = this.showStockTypeFacet;
            this.showPaymentType = this.showPaymentTypeFacet;
            this.showMiles = this.showMilesFacet;
            this.showYears = this.showYearsFacet;
            this.showExteriorColor = this.showExteriorColorFacet;
            this.showInteriorColor = this.showInteriorColorFacet;
            this.isNew = this.isNewListings;
            this.isModel = this.isModelListings;

            dispatch(
                "headerTransitionStore/enabledMobileInventorySubmenu",
                true
            );
        },
        showFiltersModal() {
            this.show = true;
        },
        hideFiltersModal() {
            this.show = false;
        },
        toggleFiltersModal() {
            this.show = !this.show;
        },
        showListings() {
            window.location.href = this.filtersAsUrl("/shop/new");
        },
        showModels() {
            if (
                !_.isNil(this.sortBy) &&
                this.sortBy.indexOf("distance") !== -1
            ) {
                window.location.href = this.filtersAsUrl(
                    "/shop/models",
                    "savings,desc"
                );
            } else {
                window.location.href = this.filtersAsUrl("/shop/models");
            }
        }
    }
};
</script>
