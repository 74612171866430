<template>
    <facet-template
        v-if="show"
        :show-clear-link="isFiltered"
        :facet-name="'vehicleCategory'"
        @clear="clearSelectedCategory"
    >
        <span slot="facetLabel">
            Category
        </span>
        <div slot="facetBody">
            <div class="container">
                <div class="row">
                    <div class="col-12 p-0 full-width-select">
                        <select
                            v-model="selectedVehicleCategory"
                            class="form-control-sm custom-select w-100"
                        >
                            <option :value="null">
                                {{ $t("component.facets.any") }}
                            </option>
                            <option
                                v-for="vehicleCategory in availableVehicleCategories"
                                :key="vehicleCategory.id + 'key'"
                                :value="vehicleCategory.name"
                            >
                                {{ vehicleCategory.name | capitalize }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
    </facet-template>
</template>

<script>
import FacetTemplate from "./FacetTemplate";
import { get, sync } from "vuex-pathify";
import _ from "lodash";

export default {
    name: "VehicleCategoryFacet",
    components: {
        FacetTemplate
    },
    filters: {
        capitalize: function(value) {
            if (!value) return "";
            value = value.toString();
            return value.charAt(0).toUpperCase() + value.slice(1);
        }
    },
    props: {
        show: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        availableVehicleCategories: get(
            "vehicleSearch/facets@vehicleCategories"
        ),
        selectedVehicleCategory: sync("vehicleSearch/filters@vehicleCategory"),
        isFiltered() {
            return !_.isNil(this.selectedVehicleCategory);
        }
    },
    methods: {
        clearSelectedCategory() {
            if (!_.isNil(this.selectedVehicleCategory)) {
                this.selectedVehicleCategory = null;
            }
        }
    }
};
</script>
