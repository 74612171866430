<template>
    <b-container>
        <div class="heading mb-3">
            <span
                class="sub-form-title">Sign in to your CarSaver Profile to access this feature.
            </span>
        </div>
        <div>
            <sign-in-form :show-section="false" :returnUrl="redirectLink"></sign-in-form>
        </div>
        <social-signin></social-signin>
        <div class="to-signin d-flex flex-column align-items-center justify-content-center">
            <p>Not a member yet?&nbsp;<sign-up-link></sign-up-link></p>
        </div>
        <p class="disclaimer text-center">
            We dont post anything to your Facebook or Google account. By Signing in you agree to CarSavers&nbsp;
            <b-link href="/tos" target="_blank">
                {{ $t("component.signInForm.terms") }}
            </b-link>
            &&nbsp;
            <b-link href="/privacy" target="_blank">
                {{ $t("component.signInForm.privacy") }} </b-link
            >.
        </p>
    </b-container>
</template>
<script>
import SignInForm from "Modules/Auth/SignIn/components/SignInForm";
import SocialSignin from "Modules/Auth/SignIn/components/SocialSignin";
import SignUpLink from "Modules/Auth/SignIn/components/SignUpLink";

export default {
    name: 'SignInModalForm',
    components: {
        SignInForm,
        SocialSignin,
        SignUpLink,
    },
    data() {
        return {
            redirectLink: window.location.href
        }
    }
}
</script>
<style lang="scss">
    .sub-form-title {
        font-family: $helvetica-75-bold;
        margin-bottom: 15px;
    }
</style>
