var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "filters-container" } },
    [
      _c(
        "b-modal",
        {
          ref: "modal",
          attrs: {
            id: "filtersModal",
            "body-class": "p-0",
            "footer-class": "rounded-0",
            "hide-header": _vm.hideHeader,
            "hide-footer": ""
          },
          on: { shown: _vm.onModalShown, hidden: _vm.onModalHidden },
          model: {
            value: _vm.modalVisible,
            callback: function($$v) {
              _vm.modalVisible = $$v
            },
            expression: "modalVisible"
          }
        },
        [
          _c("div", { attrs: { slot: "modal-title" }, slot: "modal-title" }, [
            _vm._v("\n            Filter Your Search\n        ")
          ]),
          _vm._v(" "),
          _c(
            "b-container",
            { staticClass: "p-0 h-100", attrs: { fluid: "" } },
            [
              _c("div", { ref: "title", staticClass: "fixed-top" }, [
                _c("div", { staticClass: "shop-title" }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.shopTitle) +
                      "\n                "
                  )
                ])
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  ref: "mobilefacetwrapper",
                  staticClass:
                    "d-flex flex-column h-100 w-100 mobile-facet-wrapper"
                },
                [
                  _c("facets", {
                    attrs: {
                      "is-loading": _vm.isLoading,
                      "show-payment-type-facet": _vm.showPaymentTypeFacet,
                      "show-miles-facet": _vm.showMilesFacet,
                      "show-years-facet": _vm.showYearsFacet,
                      "show-stock-type-filter": _vm.showStockTypeFilter,
                      "show-exterior-color-facet": _vm.showExteriorColorFacet,
                      "show-interior-color-facet": _vm.showInteriorColorFacet,
                      "show-sort-facet": _vm.showSortFacet
                    }
                  })
                ],
                1
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }