<template>
    <a :href="href">
        <slot>Sign Up</slot>
    </a>
</template>
<script>
export default {
    name: "SignUpLink",

    data() {
        return {
            baseUrl: "/sign-up",
            queries: window.location.search
        };
    },

    computed: {
        href() {
            return this.baseUrl + this.queries;
        }
    }
};
</script>
