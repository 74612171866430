<template>
    <div
        v-if="!isKiosk"
        v-cloak
        class="social-sign-up d-flex flex-column align-items-center mb-4"
    >
        <div class="or-separator d-flex justify-content-between mb-3">
            <div class="horizontal-line">
                <hr />
            </div>
            or
            <div class="horizontal-line">
                <hr />
            </div>
        </div>

        <div id="google-login-button" class="mb-3"></div>

        <div
            class="fb-login-button"
            data-size="medium"
            data-button-type="continue_with"
            data-auto-logout-link="false"
            data-use-continue-as="true"
            data-onlogin="onFacebookLogin"
            data-scope="email"
        ></div>
    </div>
</template>

<script>
import { get } from "vuex-pathify";

export default {
    name: "SocialSignin",
    computed: {
        campaignType: get("campaign/type"),
        isKiosk() {
            return this.campaignType === "kiosk";
        }
    },
    mounted() {
        this.loadGoogleButton();
        this.loadFacebookButton();
    },
    methods: {
        loadFacebookButton() {
            FB.XFBML.parse();
        },

        loadGoogleButton() {
            const el = document.getElementById("auth2_script_id");
            const gplatformScript = document.createElement("script");
            gplatformScript.setAttribute(
                "src",
                "https://apis.google.com/js/platform.js?onload=onGapiLoad"
            );
            gplatformScript.setAttribute("async", true);
            gplatformScript.setAttribute("defer", "defer");
            gplatformScript.setAttribute("id", "auth2_script_id");
            document.head.appendChild(gplatformScript);

            window.onGapiLoad = () => {
                window.gapi.signin2.render("google-login-button", {
                    scope: "profile email",
                    longtitle: true,
                    theme: "dark",
                    onsuccess: googleUser => {
                        const idToken = googleUser.getAuthResponse().id_token;

                        window.submitFORM("/sign-in/sso", {
                            provider: "google",
                            idToken
                        });
                    },
                    onfailure: error => {
                        console.error(error);
                    }
                });
            };
        }
    }
};
</script>
<style lang="scss">
.or-separator {
    font-weight: bold;
    margin: 0 auto;
    width: 190px;

    .horizontal-line {
        width: 40%;
    }
}
</style>
