var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "sort-by d-inline-flex align-items-center" },
    [
      _c("span", { staticClass: "sort-text pr-2" }, [_vm._v("Sort By:")]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "dropdown" },
        [
          _c(
            "b-link",
            {
              staticClass: "sort dropdown-toggle",
              attrs: {
                id: "dropdownMenuButton",
                "data-toggle": "dropdown",
                "aria-haspopup": "true",
                "aria-expanded": "false"
              }
            },
            [_vm._v("\n            " + _vm._s(_vm.sortDisplay) + "\n        ")]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dropdown-menu dropdown-menu-right px-2",
              attrs: { "aria-labelledby": "dropdownMenuButton" }
            },
            _vm._l(_vm.sortOptions, function(option) {
              return _c(
                "b-link",
                {
                  key: option.value,
                  staticClass: "dropdown-item",
                  on: {
                    click: function($event) {
                      return _vm.selectSort(option)
                    }
                  }
                },
                [
                  _vm.sortDisplay === option.text
                    ? _c("i", {
                        staticClass: "ion-checkmark position-absolute",
                        attrs: { "aria-hidden": "true" }
                      })
                    : _vm._e(),
                  _vm._v(
                    "\n                " +
                      _vm._s(option.text) +
                      "\n            "
                  )
                ]
              )
            }),
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }