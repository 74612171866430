<template>
    <cs-modal id="privacyProtectionModal" ref="modal" size="md">
        <div slot="modal-title">
            {{ $t("component.privacyProtectionModal.title") }}
        </div>
        <b-container class="py-sm-3 py-5">
            <div class="mb-2">
                <span class="font-weight-bold">
                    {{ $t("component.privacyProtectionModal.subTitle") }}
                </span>
            </div>
            <div class="d-flex flex-column ml-3">
                <div class="d-flex flex-column my-2">
                    <div class="d-flex flex-row">
                        <span class="font-weight-bold mr-2">
                            {{ $t("component.privacyProtectionModal.one") }}
                        </span>
                        <span class="font-weight-bold">
                            {{
                                $t(
                                    "component.privacyProtectionModal.listItemOne"
                                )
                            }}
                        </span>
                    </div>
                    <span class="ml-4">
                        {{
                            $t(
                                "component.privacyProtectionModal.subListItemOne"
                            )
                        }}
                    </span>
                </div>
                <div class="d-flex flex-column my-2">
                    <div class="d-flex flex-row">
                        <span class="font-weight-bold mr-2">
                            {{ $t("component.privacyProtectionModal.two") }}
                        </span>
                        <span class="font-weight-bold">
                            {{
                                $t(
                                    "component.privacyProtectionModal.listItemTwo"
                                )
                            }}
                        </span>
                    </div>
                    <span class="ml-4">
                        {{
                            $t(
                                "component.privacyProtectionModal.subListItemTwo"
                            )
                        }}
                    </span>
                </div>
                <div class="d-flex flex-column my-2">
                    <div class="d-flex flex-row">
                        <span class="font-weight-bold mr-2">
                            {{ $t("component.privacyProtectionModal.three") }}
                        </span>
                        <span class="font-weight-bold">
                            {{
                                $t(
                                    "component.privacyProtectionModal.listItemThree"
                                )
                            }}
                        </span>
                    </div>
                    <span class="ml-4">
                        {{
                            $t(
                                "component.privacyProtectionModal.subListItemThree"
                            )
                        }}
                    </span>
                </div>
            </div>
        </b-container>
        <div class="privacy-block d-flex flex-row justify-content-center">
            <b-link href="/tos" target="_blank" class="mx-1">
                {{ $t("component.signInRequiredModal.terms") }}
            </b-link>
            <span>|</span>
            <b-link href="/privacy" target="_blank" class="mx-1">
                {{ $t("component.signInRequiredModal.privacy") }}
            </b-link>
        </div>
        <div slot="modal-footer">
            <b-button variant="orange" @click="openDealerLinkRequiredModal()">
                {{ $t("component.privacyProtectionModal.back") }}
            </b-button>
        </div>
    </cs-modal>
</template>

<style lang="scss" scoped>
#privacyProtectionModal {
    .btn-orange {
        width: 180px;
    }

    .privacy-block {
        a {
            color: $primary-blue;
        }
    }
}
</style>

<script>
import { sync } from "vuex-pathify";
import CSModal from "../CSModal/index";
import EventBus from "../../event-bus";

export default {
    name: "PrivacyProtectionModal",

    components: {
        "cs-modal": CSModal
    },

    computed: {
        dealerLinkModalOpen: sync("dealerLink/isOpen")
    },

    created() {
        const component = this;
        EventBus.$on("privacy-protection-modal.open", () => {
            component.show();
        });
    },

    methods: {
        show() {
            this.$refs.modal.show();
        },
        close() {
            this.$refs.modal.hide();
        },
        openDealerLinkRequiredModal() {
            this.close();
            this.dealerLinkModalOpen = true;
        }
    }
};
</script>
