import URL from "url-parse";

export default {
    methods: {
        isSignInReturnUrlExcluded(url) {
            const excluded = ["/sign-in", "/sign-up"];

            let found = false;

            excluded.forEach(function(exclusion) {
                const searchIndex = url.search(exclusion);

                if (searchIndex !== -1) {
                    found = true;
                }
            });

            return found;
        },
        toSignInPage() {
            this.urlHandler("signIn");
        },
        toSignUpPage() {
            this.urlHandler("signUp");
        },
        // Option is either signin or signup
        urlHandler(option) {
            const currentLocation = window.location.href;
            const currentSearch = window.location.search;
            const currentUrl = new URL(currentLocation, true);
            const excluded = this.isSignInReturnUrlExcluded(currentLocation);
            const selected = option.toLowerCase();
            let url;

            switch (selected) {
                case "signin":
                    url = new URL("/sign-in", true);
                    break;
                case "signup":
                    url = new URL("/sign-up", true);
                    break;
                default:
                    url = new URL("/sign-in", true);
            }

            if (excluded) {
                document.location = url.toString() + currentSearch;
            } else {
                url.query.returnUrl = currentUrl;
                document.location = url.toString();
            }
        }
    }
};
