var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.isKiosk
    ? _c(
        "div",
        {
          staticClass:
            "social-sign-up d-flex flex-column align-items-center mb-4"
        },
        [
          _vm._m(0),
          _vm._v(" "),
          _c("div", {
            staticClass: "mb-3",
            attrs: { id: "google-login-button" }
          }),
          _vm._v(" "),
          _c("div", {
            staticClass: "fb-login-button",
            attrs: {
              "data-size": "medium",
              "data-button-type": "continue_with",
              "data-auto-logout-link": "false",
              "data-use-continue-as": "true",
              "data-onlogin": "onFacebookLogin",
              "data-scope": "email"
            }
          })
        ]
      )
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "or-separator d-flex justify-content-between mb-3" },
      [
        _c("div", { staticClass: "horizontal-line" }, [_c("hr")]),
        _vm._v("\n        or\n        "),
        _c("div", { staticClass: "horizontal-line" }, [_c("hr")])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }