<template>
    <div id="pick-price-drive" class="d-none d-md-block">
        <b-container fluid class="d-flex justify-content-around">
            <item
                :selected="selectedIndex === 0"
                :completed="selectedIndex > 0"
                :number="1"
                label="Pick It"
                description="Select Vehicle"
            />
            <item
                :selected="selectedIndex === 1"
                :completed="selectedIndex > 1"
                :number="2"
                label="Price It"
                :description="priceItDescription"
            />
            <item
                :selected="selectedIndex === 2"
                :completed="selectedIndex > 2"
                :number="3"
                label="Drive It"
                description="Schedule Test Drive"
            />
        </b-container>
    </div>
</template>

<script>
import Item from "Components/PickPriceDrive/Item";
import { get } from "vuex-pathify";

export default {
    name: "PickPriceDrive",
    components: { Item },
    props: {
        selectedIndex: {
            type: Number,
            required: false,
            default: 0
        }
    },
    computed: {
        campaignType: get("campaign/type"),
        priceItDescription() {
            if (this.campaignType === "wavpp") {
                return "Get Associate Price";
            }

            return "Get CarSaver Price";
        }
    }
};
</script>

<style lang="scss">
#pick-price-drive {
    background-color: $light-gray;
    height: 50px;
}
</style>
