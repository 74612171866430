import { make } from "vuex-pathify";
import api from "@/api";
import _ from "lodash";
import { selectedOrRequiredPredicate } from "./configure/filters";
import { CONSUMER_FRIENDLY_HEADER_ORDER } from "@/store/modules/configure/constants";

const handleResponse = (response, commit) => {
    const additionAffectedChromeOptions = _.map(
        _.get(response.data, "additionAffectedChromeOptionCodes"),
        code => {
            return _.find(_.get(response.data, "options"), {
                chromeOptionCode: code
            });
        }
    );
    commit(
        "SET_ADDITION_AFFECTED_CHROME_OPTIONS",
        additionAffectedChromeOptions
    );

    const deletionAffectedChromeOptions = _.map(
        _.get(response.data, "deletionAffectedChromeOptionCodes"),
        code => {
            return _.find(_.get(response.data, "options"), {
                chromeOptionCode: code
            });
        }
    );
    commit(
        "SET_DELETION_AFFECTED_CHROME_OPTIONS",
        deletionAffectedChromeOptions
    );

    if (
        _.size(additionAffectedChromeOptions) > 0 ||
        _.size(deletionAffectedChromeOptions) > 0
    ) {
        commit("SET_USER_CONFIRMATION_NEEDED", true);
    }

    let conflictResolvingChromeOptions = _.map(
        _.get(response.data, "conflictResolvingOptionCodes"),
        code => {
            return _.find(_.get(response.data, "options"), {
                chromeOptionCode: code
            });
        }
    );

    if (_.size(conflictResolvingChromeOptions) === 0) {
        const unsatisfiedChecklistItem = _.find(
            _.get(response.data, "checklistItems"),
            { satisfied: false }
        );
        conflictResolvingChromeOptions = _.map(
            _.get(unsatisfiedChecklistItem, "chromeOptionCodes"),
            code => {
                return _.find(_.get(response.data, "options"), {
                    chromeOptionCode: code
                });
            }
        );
        _.remove(conflictResolvingChromeOptions, {
            selectionState: "EXCLUDED"
        });
        if (_.size(conflictResolvingChromeOptions) > 0) {
            commit("SET_USER_CHOICE_NEEDED", true);
            commit("SET_USER_CONFIRMATION_NEEDED", false);
        }
    } else {
        commit("SET_USER_CHOICE_NEEDED", true);
    }
    commit(
        "SET_CONFLICT_RESOLVING_CHROME_OPTIONS",
        conflictResolvingChromeOptions
    );

    delete response.data.additionAffectedChromeOptionCodes;
    delete response.data.deletionAffectedChromeOptions;
    delete response.data.conflictResolvingChromeOptions;

    commit("SET_CONFIGURATION", response.data);
};

const initialState = {
    loading: true,
    firstLoad: true,
    vehicle: null,
    previousConfigurationState: null, // For Undo purposes
    certificateId: null,
    configuration: null,
    userConfirmationNeeded: false,
    userChoiceNeeded: false,
    additionAffectedChromeOptions: [],
    deletionAffectedChromeOptions: [],
    conflictResolvingChromeOptions: [],
    optionsTabIndex: 0
};

const storeGetters = {
    ...make.getters(initialState),
    originatingChromeOption: state => {
        if (
            _.isNil(_.get(state, "configuration.originatingChromeOptionCode"))
        ) {
            return null;
        }

        return _.find(_.get(state.configuration, "options"), {
            chromeOptionCode: state.configuration.originatingChromeOptionCode
        });
    },
    nextTabHeader: (state, getters) => {
        const headers = _.keys(getters.headerItems);
        return _.get(headers, `[${state.optionsTabIndex + 1}]`, null);
    },
    vehicleDetailsUrl: state => {
        if (_.isNil(state.vehicle)) {
            return null;
        }

        const configurationState = _.get(
            state.configuration,
            "configurationState"
        );
        if (_.isNil(configurationState)) {
            return `/build/${state.vehicle.styleId}`;
        }

        if (!_.isNil(state.certificateId)) {
            return `/build/${
                state.vehicle.styleId
            }?configurationState=${encodeURIComponent(
                configurationState
            )}&cid=${state.certificateId}`;
        }

        return `/build/${
            state.vehicle.styleId
        }?configurationState=${encodeURIComponent(configurationState)}`;
    },
    headerItems: (state, getters) => {
        const options = _.get(state.configuration, "options");
        _.remove(options, option => {
            return (
                _.get(option, "optionPackageContentOnly", false) === true &&
                _.get(option, "selectionState") === "UNSELECTED"
            );
        });

        const headerItems = {};

        _.each(CONSUMER_FRIENDLY_HEADER_ORDER, headerTemplate => {
            const includedOptions = _.filter(options, option => {
                return (
                    _.indexOf(
                        headerTemplate.includedIds,
                        _.get(option, "consumerFriendlyHeaderId")
                    ) !== -1
                );
            });
            _.remove(includedOptions, {
                headerId: getters.exteriorColorHeaderId
            });
            if (_.size(includedOptions) > 0) {
                headerItems[headerTemplate.label] = includedOptions;
            }
        });

        return headerItems;
    },
    exteriorColorHeaderId: state => {
        const options = _.get(state.configuration, "options");
        if (_.isNil(options)) {
            return [];
        }

        const exampleColorOption = _.find(options, option => {
            return !_.isNil(option.rgbValue);
        });

        return _.get(exampleColorOption, "headerId");
    },
    powertrainOptions: state => {
        const options = _.get(state.configuration, "options");
        if (_.isNil(options)) {
            return [];
        }

        _.remove(options, { headerId: 1156 }); // Remove Emissions
        _.remove(options, option => {
            return (
                _.get(option, "optionPackageContentOnly", false) === true &&
                _.get(option, "selectionState") === "UNSELECTED"
            );
        });

        return _.filter(options, { consumerFriendlyHeaderId: 3 });
    },
    colorOptions: (state, getters) => {
        // excludes colors in the selectState: EXCLUDED
        const options = _.get(state.configuration, "options");
        if (_.isNil(options)) {
            return [];
        }

        _.remove(options, { selectionState: "EXCLUDED" });

        return _.filter(options, { headerId: getters.exteriorColorHeaderId });
    },
    uniqColorOptions: (state, getters) => {
        const options = _.get(state.configuration, "options");
        let aName;

        if (_.isNil(options)) {
            return [];
        }

        const allColorOptions = _.filter(options, {
            headerId: getters.exteriorColorHeaderId
        });

        return _.uniqBy(allColorOptions, function(A) {
            aName = _.find(A.descriptions, { type: "PRIMARY_NAME" });

            return aName.description;
        });
    },
    exteriorColorCode: (state, getters) => {
        return _.chain(_.get(state.configuration, "options"))
            .filter({ headerId: getters.exteriorColorHeaderId })
            .filter(selectedOrRequiredPredicate)
            .map("oemOptionCode")
            .head()
            .value();
    },
    totalOptionsCost: state => {
        return _.chain(_.get(state.configuration, "options"))
            .filter(selectedOrRequiredPredicate)
            .map("msrp")
            .reduce((sum, msrp) => {
                return sum + msrp;
            }, 0)
            .value();
    },
    totalCost: (state, getters) => {
        return getters.totalOptionsCost + getters.baseMsrp;
    },
    baseMsrp: state => {
        return _.get(state.vehicle, "msrp", 0);
    },
    vehicleName: state => {
        if (_.isNil(state.vehicle)) {
            return null;
        }

        return `${state.vehicle.year} ${state.vehicle.make} ${state.vehicle.model}`;
    }
};

const mutations = {
    ...make.mutations(initialState)
};

const actions = {
    ...make.actions(initialState),

    getVehicleStyle({ commit, state }, styleId) {
        if (_.get(state, "vehicle.styleId") === _.toNumber(styleId)) {
            return;
        }

        api.get(`/vehicle-data/styles/${styleId}/vehicle`)
            .then(response => {
                commit("SET_VEHICLE", response.data);
            })
            .catch(error => {
                console.error(`Error fetching vehicle style ${styleId}`, error);
            });
    },

    getOptions({ commit, state }, styleId) {
        if (_.get(state, "vehicle.styleId") === _.toNumber(styleId)) {
            return;
        }
        commit("SET_LOADING", true);
        api.get(`/styles/${styleId}/configure/options`)
            .then(response => {
                handleResponse(response, commit);

                commit("SET_LOADING", false);
                commit("SET_FIRST_LOAD", false);
            })
            .catch(error => {
                console.error(
                    `Error fetching vehicle options for style ${styleId}`,
                    error
                );
                commit("SET_LOADING", false);
                commit("SET_FIRST_LOAD", false);
            });
    },

    setConfigurationState({ commit }, { configurationState, styleId }) {
        commit("SET_LOADING", true);
        api.get(`/styles/${styleId}/configure`, { configurationState })
            .then(response => {
                handleResponse(response, commit);

                commit("SET_LOADING", false);
                commit("SET_FIRST_LOAD", false);
            })
            .catch(error => {
                console.error(
                    `Error fetching vehicle options for configuration state ${configurationState}`,
                    error
                );
                commit("SET_LOADING", false);
                commit("SET_FIRST_LOAD", false);
            });
    },

    selectOption({ commit, state }, chromeCode) {
        commit("SET_LOADING", true);
        commit(
            "SET_PREVIOUS_CONFIGURATION_STATE",
            state.configuration.configurationState
        );
        api.get(
            `/styles/${state.vehicle.styleId}/configure/options/${chromeCode}`,
            { configurationState: state.configuration.configurationState }
        )
            .then(response => {
                handleResponse(response, commit);

                commit("SET_LOADING", false);
                commit("SET_FIRST_LOAD", false);
            })
            .catch(error => {
                commit("SET_LOADING", false);
                commit("SET_FIRST_LOAD", false);
                console.error(
                    `Error fetching vehicle options for style ${state.vehicle.styleId}`,
                    error
                );
            });
    },

    undo({ commit, state }) {
        commit("SET_LOADING", true);
        api.get(`/styles/${state.vehicle.styleId}/configure`, {
            configurationState: state.previousConfigurationState
        })
            .then(response => {
                handleResponse(response, commit);

                commit("SET_LOADING", false);
                commit("SET_FIRST_LOAD", false);
            })
            .catch(error => {
                commit("SET_LOADING", false);
                commit("SET_FIRST_LOAD", false);
                console.error(
                    `Error fetching vehicle options for style ${state.vehicle.styleId}`,
                    error
                );
            });
    }
};

export default {
    namespaced: true,
    state: initialState,
    mutations,
    actions,
    getters: storeGetters
};
