import _ from "lodash";

export default class Errors {
    constructor() {
        this.errors = {};
    }

    get(field) {
        if (this.errors[field]) {
            return (
                this.errors[field].message || this.errors[field].defaultMessage
            );
        }
    }

    has(field) {
        return _.has(this.errors, field);
    }

    any() {
        return Object.keys(this.errors).length > 0;
    }

    record(errors) {
        if (errors) {
            this.errors = _.mapKeys(errors, "field");
        }

        return this;
    }

    clear(field) {
        if (field) {
            delete this.errors[field];

            return;
        }

        this.errors = {};
    }
}
