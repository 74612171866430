<template>
    <facet-template
        :show-clear-link="isFiltered"
        :facet-name="'interiorColors'"
        @clear="clearSelectedInteriorColors"
    >
        <span slot="facetLabel">
            {{ $t("component.facets.intColor") }}
        </span>

        <div slot="facetBody">
            <div class="container">
                <div class="row">
                    <div class="col-12 p-0 ml-2 mb-0 mt-3">
                        <div class="color-list d-flex flex-row flex-wrap">
                            <color-swatch
                                v-for="color in normalizedInteriorColors"
                                :key="color.name"
                                :color="color"
                                :selected="isInteriorColorSelected(color)"
                                @click.native="toggleInteriorColor(color)"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </facet-template>
</template>

<script>
import FacetTemplate from "./FacetTemplate";
import ColorSwatch from "../../../../../components/ColorSwatch";
import { sync } from "vuex-pathify";
import _ from "lodash";

export default {
    name: "InteriorColorFacet",
    components: {
        FacetTemplate,
        ColorSwatch
    },
    computed: {
        availableInteriorColors: sync("vehicleSearch/facets@interiorColors"),
        selectedInteriorColors: sync("vehicleSearch/filters@interiorColors"),
        normalizedInteriorColors() {
            var array = [];
            var colorArray = this.availableInteriorColors.slice();
            var indexPos = colorArray.findIndex(
                value => value.name === "Other"
            );

            if (indexPos > -1) {
                array = colorArray.splice(indexPos, 1);
                colorArray.push(array.pop());
            }

            return colorArray;
        },
        isFiltered() {
            return !_.isEmpty(this.selectedInteriorColors);
        }
    },
    methods: {
        clearSelectedInteriorColors() {
            if (_.size(this.selectedInteriorColors) > 0) {
                this.selectedInteriorColors = [];
            }
        },
        toggleInteriorColor(color) {
            const colorIsAlreadySelected = this.isInteriorColorSelected(color);

            if (colorIsAlreadySelected) {
                this.removeInteriorColorFromFilters(color);
            } else {
                this.addInteriorColorToFilters(color);
            }
        },
        addInteriorColorToFilters(color) {
            this.selectedInteriorColors = this.selectedInteriorColors.concat(
                color.name
            );
        },
        removeInteriorColorFromFilters(color) {
            this.selectedInteriorColors = this.selectedInteriorColors.filter(
                colorName => colorName !== color.name
            );
        },
        isInteriorColorSelected(color) {
            return _.includes(this.selectedInteriorColors, color.name);
        }
    }
};
</script>
