import _ from "lodash";

const state = {
    cutOffheight: 0,
    isHeaderHidden: false,
    enabledMobileInventorySubmenu: false
};

const mutations = {
    SET_CUT_OFF_HEIGHT: state => {
        const elms = document.querySelectorAll(".header-transition-wrapper");
        const firstElm = elms.length ? elms[0] : null;
        let height = 0;

        if (firstElm) {
            height = firstElm.offsetTop + firstElm.offsetHeight;
        }

        state.cutOffheight = height;
    },

    SET_IS_HEADER_HIDDEN: (state, value) => {
        state.isHeaderHidden = value;
    },

    SET_ENABLED_MOBILE_INVENTORY_SUBMENU: (state, value) => {
        state.enabledMobileInventorySubmenu = value;
    }
};

const actions = {
    setCutOffHeight({ commit }) {
        commit("SET_CUT_OFF_HEIGHT");
    },
    setIsHeaderHidden({ commit }, value) {
        commit("SET_IS_HEADER_HIDDEN", value);
    },
    enabledMobileInventorySubmenu({ commit }, value) {
        if (_.isNil(value)) {
            commit("SET_ENABLED_MOBILE_INVENTORY_SUBMENU", true);
        }

        commit("SET_ENABLED_MOBILE_INVENTORY_SUBMENU", value);
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations
};
