var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.shouldDisplay
    ? _c("div", { staticClass: "container d-flex justify-content-center" }, [
        _c(
          "div",
          {
            staticClass: "next-steps-block",
            class: _vm.financingAvailable
              ? "width-with-finance"
              : "width-without-finance"
          },
          [
            _c("h1", { staticClass: "next-title text-center" }, [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.$t("component.nextStepsButtons.title")) +
                  "\n        "
              )
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "d-flex flex-wrap flex-sm-nowrap justify-content-around"
              },
              [
                _c(
                  "div",
                  { staticClass: "mx-2" },
                  [
                    _c(
                      "b-link",
                      {
                        staticClass: "btn btn-outline-primary square-btn",
                        attrs: { href: "/pick-your-flow" }
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.$t("component.nextStepsButtons.shop")) +
                            "\n                "
                        )
                      ]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.financingAvailable
                  ? _c(
                      "div",
                      { staticClass: "mx-2" },
                      [
                        _c(
                          "b-button",
                          {
                            attrs: { variant: "outline-primary square-btn" },
                            on: { click: _vm.openFinancing }
                          },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(
                                  _vm.$t("component.nextStepsButtons.finance")
                                ) +
                                "\n                "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "mx-2" },
                  [
                    _c(
                      "b-link",
                      {
                        staticClass: "btn btn-outline-primary square-btn",
                        on: { click: _vm.openInsurance }
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(
                              _vm.$t("component.nextStepsButtons.insurance")
                            ) +
                            "\n                "
                        )
                      ]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "mx-2" },
                  [
                    _c(
                      "b-button",
                      {
                        attrs: { variant: "outline-primary square-btn" },
                        on: { click: _vm.gotoTradeIn }
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.$t("component.nextStepsButtons.trade")) +
                            "\n                "
                        )
                      ]
                    )
                  ],
                  1
                )
              ]
            )
          ]
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }