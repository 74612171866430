var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "facet-template",
    {
      staticClass: "body-styles",
      attrs: { "show-clear-link": _vm.isFiltered, "facet-name": "bodyStyles" },
      on: { clear: _vm.clearSelectedBodyStyles }
    },
    [
      _c("span", { attrs: { slot: "facetLabel" }, slot: "facetLabel" }, [
        _vm._v(
          "\n        " + _vm._s(_vm.$t("component.facets.bodyStyle")) + "\n    "
        )
      ]),
      _vm._v(" "),
      _c("div", { attrs: { slot: "facetBody" }, slot: "facetBody" }, [
        _c(
          "div",
          { staticClass: "d-flex flex-wrap" },
          _vm._l(_vm.validBodyStyles, function(bodyStyle) {
            return _c(
              "div",
              {
                key: bodyStyle.id,
                staticClass: "text-center mb-2 body-style",
                attrs: { id: bodyStyle.id }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "body-style-wrapper mb-3",
                    on: {
                      click: function($event) {
                        return _vm.toggleBodyStyle(bodyStyle)
                      }
                    }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "body-style-icon" },
                      [
                        _c("body-style-icon", {
                          attrs: {
                            id: bodyStyle.id,
                            selected: _vm.bodyStyleSelected(bodyStyle)
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("small", [_vm._v(_vm._s(bodyStyle.name))])
                  ]
                )
              ]
            )
          }),
          0
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }