export default {
    methods: {
        isKioskUrl() {
            // test for kiosk urls ie. wm1082.carsaver.com
            const host = window.location.host;
            const href = window.location.href;
            const isLocalHost = host === "localhost:5000";
            const isStageHost = /staging/.test(host.toLowerCase());
            const hasSessionReset = href.includes("session_reset=");
            let isKioskUrl = /wm[^.]*(?=\.)/.test(host);

            if ((isLocalHost || isStageHost) && hasSessionReset) {
                isKioskUrl = true; // for testing
            }

            return isKioskUrl; // Prod version

            // return isStageHost || isKioskUrl; // Testing version for testing only
        }
    }
};
