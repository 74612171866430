<template>
    <div id="dealerStickyFooter" class="d-none d-md-flex">
        <div class="container">
            <div class="row">
                <div
                    class="d-flex flex-row justify-content-between content-block w-100"
                >
                    <div
                        class="d-flex flex-column justify-content-center align-items-start dealer-block"
                    >
                        <span class="dealer font-weight-bold">
                            {{ dealer.name }}
                        </span>
                        <span v-if="isDeliveryOnly" class="address">
                            Nationwide shipping to<br />
                            {{ user.city }}, {{ user.stateCode }}
                            {{ user.zipCode }}
                        </span>
                        <span v-else class="address">
                            {{ dealer.address1 }} <br />
                            {{ dealer.city }}, {{ dealer.state }}
                            {{ dealer.zipCode }}
                        </span>
                    </div>
                    <slot name="cta" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { get } from "vuex-pathify";
import _ from "lodash";

export default {
    name: "DealerStickyFooter",
    props: {
        dealer: {
            type: Object,
            required: true
        }
    },

    computed: {
        user: get("user/user"),

        isDeliveryOnly() {
            return _.get(this.dealer, "deliveryOnly", true);
        }
    }
};
</script>

<style lang="scss">
#dealerStickyFooter {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 80px;
    background-color: #fff;
    border: 1px solid #ddd;
    z-index: 20;

    .content-block {
        height: 70px;
        padding-right: 0;
        padding-left: 20px;

        .dealer-block {
            .address {
                font-size: 14px;
                font-weight: 400;
                font-stretch: normal;
                line-height: 1.07;
                letter-spacing: normal;
                color: #888;
            }
        }
    }
}
</style>
