var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "layout",
    { attrs: { breadcrumbs: _vm.breadcrumbs } },
    [
      _c("template", { slot: "header" }, [
        _c("h1", { staticClass: "text-center" }, [
          _vm._v("Shop by " + _vm._s(_vm.pageTitle))
        ])
      ]),
      _vm._v(" "),
      _c(
        "b-row",
        { attrs: { id: "shop-by-price" } },
        [
          _c("b-col", { attrs: { cols: "12", md: "9" } }, [
            _c("h2", { staticClass: "price-sub-title" }, [
              _vm._v(
                "\n                What is your price range?\n            "
              )
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "row d-none d-md-flex justify-content-center align-items-center h-50"
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "col-12 col-md d-flex justify-content-center justify-content-sm-end"
                  },
                  [_c("span", [_vm._v("$0")])]
                ),
                _vm._v(" "),
                _c(
                  "vue-slider-component",
                  _vm._b(
                    {
                      ref: "priceSlider",
                      staticClass: "col-12 col-md-8",
                      on: { callback: _vm.onDragEnd },
                      scopedSlots: _vm._u([
                        {
                          key: "tooltip",
                          fn: function({ index, value }) {
                            return [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "diy-tooltip d-flex flex-column justify-content-center align-items-center"
                                },
                                [
                                  index === 0
                                    ? _c("small", { staticClass: "mb-1" }, [
                                        _vm._v(
                                          "\n                                starting at\n                            "
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  index === 1
                                    ? _c("small", { staticClass: "mb-1" }, [
                                        _vm._v(
                                          "\n                                up to\n                            "
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  index === 0 || index === 1
                                    ? _c("div", [
                                        index === 0
                                          ? _c(
                                              "div",
                                              { staticClass: "price" },
                                              [
                                                _vm._v(
                                                  "\n                                    $" +
                                                    _vm._s(value) +
                                                    "k\n                                "
                                                )
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        index === 1
                                          ? _c(
                                              "div",
                                              { staticClass: "price" },
                                              [
                                                _vm._v(
                                                  "\n                                    $" +
                                                    _vm._s(value) +
                                                    "k\n                                "
                                                )
                                              ]
                                            )
                                          : _vm._e()
                                      ])
                                    : _c(
                                        "div",
                                        { staticClass: "merged-price" },
                                        [
                                          value[0] === value[1]
                                            ? _c("span", [
                                                _vm._v(
                                                  "\n                                    $" +
                                                    _vm._s(value[0]) +
                                                    "k\n                                "
                                                )
                                              ])
                                            : _c("span", [
                                                _vm._v(
                                                  "\n                                    $" +
                                                    _vm._s(value[0]) +
                                                    "k - $" +
                                                    _vm._s(value[1]) +
                                                    "k\n                                "
                                                )
                                              ])
                                        ]
                                      )
                                ]
                              )
                            ]
                          }
                        }
                      ]),
                      model: {
                        value: _vm.priceRange,
                        callback: function($$v) {
                          _vm.priceRange = $$v
                        },
                        expression: "priceRange"
                      }
                    },
                    "vue-slider-component",
                    _vm.desktopSliderOptions,
                    false
                  )
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "col-12 col-md d-flex justify-content-center justify-content-sm-start"
                  },
                  [_c("span", [_vm._v("$100k +")])]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "row d-flex d-md-none justify-content-center align-items-center"
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "col-12 col-md d-flex justify-content-center justify-content-sm-start m-2"
                  },
                  [_c("span", [_vm._v("$100k +")])]
                ),
                _vm._v(" "),
                _c(
                  "vue-slider-component",
                  _vm._b(
                    {
                      ref: "priceSlider",
                      on: { callback: _vm.onDragEnd },
                      scopedSlots: _vm._u([
                        {
                          key: "tooltip",
                          fn: function({ index, value }) {
                            return [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "diy-tooltip d-flex flex-column justify-content-center align-items-center"
                                },
                                [
                                  index === 0
                                    ? _c("small", { staticClass: "mb-1" }, [
                                        _vm._v(
                                          "\n                                starting at\n                            "
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  index === 1
                                    ? _c("small", { staticClass: "mb-1" }, [
                                        _vm._v(
                                          "\n                                up to\n                            "
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  index === 0 || index === 1
                                    ? _c("div", [
                                        index === 0
                                          ? _c(
                                              "div",
                                              { staticClass: "price" },
                                              [
                                                _vm._v(
                                                  "\n                                    $" +
                                                    _vm._s(value) +
                                                    "k\n                                "
                                                )
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        index === 1
                                          ? _c(
                                              "div",
                                              { staticClass: "price" },
                                              [
                                                _vm._v(
                                                  "\n                                    $" +
                                                    _vm._s(value) +
                                                    "k\n                                "
                                                )
                                              ]
                                            )
                                          : _vm._e()
                                      ])
                                    : _c(
                                        "div",
                                        { staticClass: "merged-price" },
                                        [
                                          value[0] === value[1]
                                            ? _c("span", [
                                                _vm._v(
                                                  "\n                                    $" +
                                                    _vm._s(value[0]) +
                                                    "k\n                                "
                                                )
                                              ])
                                            : _c("span", [
                                                _vm._v(
                                                  "\n                                    $" +
                                                    _vm._s(value[0]) +
                                                    "k - $" +
                                                    _vm._s(value[1]) +
                                                    "k\n                                "
                                                )
                                              ])
                                        ]
                                      )
                                ]
                              )
                            ]
                          }
                        }
                      ]),
                      model: {
                        value: _vm.priceRange,
                        callback: function($$v) {
                          _vm.priceRange = $$v
                        },
                        expression: "priceRange"
                      }
                    },
                    "vue-slider-component",
                    _vm.mobileSliderOptions,
                    false
                  )
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "col-12 col-md d-flex justify-content-center justify-content-sm-end m-2"
                  },
                  [_c("span", [_vm._v("$0")])]
                )
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("selected-filters", { attrs: { "route-to": _vm.next } })
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }