<template>
    <facet-template
        :show-clear-link="isFiltered"
        :facet-name="'dealBadges'"
        @clear="clearDealBadges"
    >
        <span slot="facetLabel">
            {{ $t("component.facets.dealBadges") }}
        </span>

        <div slot="facetBody">
            <div class="container">
                <div class="row">
                    <div class="col-12 p-0">
                        <b-form-group class="m-0">
                            <b-form-checkbox-group
                                id="badges"
                                v-model="selectedDealBadges"
                                stacked
                                plain
                                name="badges"
                                aria-label="Individual Badges"
                            >
                                <b-form-checkbox
                                    v-for="badge in availableDealBadgesOptions"
                                    :key="badge.id"
                                    :value="badge.id"
                                    class="mb-2"
                                >
                                    {{ badge.name }}
                                </b-form-checkbox>
                            </b-form-checkbox-group>
                        </b-form-group>
                    </div>
                </div>
            </div>
        </div>
    </facet-template>
</template>

<script>
import _ from "lodash";
import FacetTemplate from "./FacetTemplate";
import { get, sync } from "vuex-pathify";
import mixin from "./mixin";

export default {
    name: "DealBadgesFacet",
    components: {
        FacetTemplate
    },
    mixins: [mixin],
    computed: {
        availableDealBadges: get("vehicleSearch/facets@dealBadges"),
        selectedDealBadges: sync("vehicleSearch/filters@dealBadges"),
        availableDealBadgesOptions() {
            const badges = _.map(this.availableDealBadges, badge => {
                return {
                    count: badge.count,
                    id: badge.id,
                    name: badge.name
                };
            });

            return badges.reverse();
        },
        isFiltered() {
            return !_.isEmpty(this.selectedDealBadges);
        }
    },
    methods: {
        clearDealBadges() {
            if (_.size(this.selectedDealBadges) > 0) {
                this.selectedDealBadges = [];
            }
        }
    }
};
</script>
