<template>
    <div
        id="sign-in-content"
        class="d-flex flex-column align-items-center mb-3"
    >
        <b-form class="w-100" @submit="onSubmit">
            <b-form-group :label="$t('component.signInForm.email')">
                <b-form-input
                    id="email"
                    v-model.trim="form.email"
                    type="email"
                    :disabled="submitting"
                    required
                    placeholder="Enter your email"
                />
            </b-form-group>
            <b-form-group :label="$t('component.signInForm.password')">
                <b-form-input
                    id="password"
                    v-model.trim="form.password"
                    type="password"
                    :disabled="submitting"
                    required
                    placeholder="Enter Password"
                />
            </b-form-group>
            <p v-if="error" class="error-block text-center">
                The email address or password are not correct
            </p>

            <p class="text-right forgot-password font-italic">
                <b-link @click="showForgotPasswordModal()">
                    {{ $t("component.signInForm.forgot") }}
                </b-link>
            </p>
            <p class="disclaimer text-center" v-if="showSection">
                {{ $t("component.signInForm.disclaimer") }}
                <b-link href="/tos" target="_blank">
                    {{ $t("component.signInForm.terms") }}
                </b-link>
                and
                <b-link href="/privacy" target="_blank">
                    {{ $t("component.signInForm.privacy") }} </b-link
                >.
            </p>

            <b-button
                type="submit"
                variant="orange"
                :disabled="submitting"
                class="sign-in-btn mx-auto"
            >
                {{ $t("component.signInForm.signIn") }}
            </b-button>
        </b-form>

        <forgot-password ref="forgotPasswordModal" />
    </div>
</template>
<script>
import ForgotPassword from "Components/ForgotPassword";
import { get, dispatch } from "vuex-pathify";
import { trackGAEvent } from "@/lib/GoogleTagManager";
import mixin from "../mixin";

export default {
    name: "SignInForm",

    components: {
        "forgot-password": ForgotPassword
    },
    props: {
        showSection: {
            type: Boolean,
            default: true
        }
    },
    mixins: [mixin],

    data() {
        return {
            form: {
                email: "",
                password: ""
            }
        };
    },

    computed: {
        error: get("user/errorSigningIn"),
        submitting: get("user/signingIn")
    },

    methods: {
        onSubmit(evt) {
            evt.preventDefault();
            trackGAEvent(this, {
                category: "auth",
                action: "signin",
                label: "Sign In"
            });
            dispatch("user/signIn", {
                form: this.form,
                modalToOpen: this.modalToOpen,
                redirectUrl: this.redirectUrl
            });

            // Kiosk requires an explicit return of false - even with .prevent on the event.
            return false;
        },
        showForgotPasswordModal() {
            this.$refs.forgotPasswordModal.show();
        }
    }
};
</script>
<style lang="scss">
#sign-in-content {
    .error-block {
        color: $red;
        font-size: 0.9rem;
    }

    .forgot-password {
        font-size: 0.9rem;
        font-style: italic;
        margin-top: -10px;
    }

    .sign-in-btn {
        width: 60%;
    }
}
</style>
