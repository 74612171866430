<template>
    <!-- Modal Component -->
    <c-s-modal
        :id="'next-steps-modal'"
        ref="nextStepsModal"
        :hide-footer="true"
        class="next-steps-modal"
        ok-only
        ok-title="Close Window"
    >
        <div slot="modal-title">
            <span>{{ $t("component.nextStepsModal.title") }}</span>
        </div>
        <b-container>
            <b-row>
                <div class="w-100 pl-3 my-3">
                    <span class="body-title">
                        {{ $t("component.nextStepsModal.bodyTitle") }}
                    </span>
                </div>
            </b-row>
            <b-row>
                <next-steps-buttons />
            </b-row>
        </b-container>
    </c-s-modal>
</template>

<style lang="scss" scoped>
.next-steps-modal {
    .modal-body {
        margin-top: 40px;
        padding: 0 1rem 1rem 1rem;

        .body-title {
            color: $primary-blue;
            font-size: 24px;
            font-weight: bold;
            line-height: 30px;
        }

        .step-wrapper {
            .step-box {
                height: 145px;
                width: 125px;
                border: 1px solid #d4d4d4;
                background-color: #ffffff;
                box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
                font-size: 12px;
                line-height: 15px;
                text-align: center;
                padding: 10px 5px;

                > .circle {
                    border-radius: 50px;
                    color: $white;
                    background-color: $primary-blue;
                    height: 36px;
                    width: 36px;
                    font-size: 20px;
                    line-height: 26px;
                    font-weight: bold;
                    top: -15px;
                    left: -17px;
                }

                .print-icon {
                    margin-top: 15px;
                    margin-bottom: 10px;
                }

                .certified-dealer-icon {
                    margin-top: 5px;
                    margin-bottom: 5px;
                }

                .car-ribbon-icon {
                    margin-top: 5px;
                    margin-bottom: 15px;
                }

                .color-text {
                    color: $primary-blue;
                    font-weight: bold;
                }

                .text {
                    color: $dark-gray;
                }
            }
        }

        .mobile-steps-list {
            list-style-type: decimal;
            color: $dark-gray;

            > li {
                margin-bottom: 15px;
            }

            strong {
                color: $primary-blue;
            }
        }
    }

    .modal-footer {
        background-color: $light-gray;
        height: 65px;

        > .btn {
            margin: 0;
            width: 200px;
            border-radius: 2px;
            font-size: 16px;
            font-weight: bold;
            font-style: normal;
            line-height: 20px;
        }
    }
}
</style>

<script>
import CSModal from "../CSModal/index";
import { trackPageView } from "../../lib/GoogleTagManager";
import NextStepsButtons from "Components/NextStepsButtons/index";
export default {
    name: "NextStepsModal",
    components: { NextStepsButtons, CSModal },
    methods: {
        show: function() {
            trackPageView(this, "/joy-ride/thank-you");

            this.$refs.nextStepsModal.show();
        }
    }
};
</script>
