import _ from "lodash";

const state = {
    text: _.get(window, "_CS_I18N", null),
    lang: _.get(window, "_LANG", "en")
};

export default {
    namespaced: true,
    state
};
