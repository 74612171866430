import api from "@/api";
import { make } from "vuex-pathify";

const state = {
    dealer: null,
    distance: null,
    isOpen: false,
    isLoading: true,
    imageUrl: null,
    user: null,
    vehicle: null,
    certificateId: null,
    appointmentId: null
};

const mutations = {
    ...make.mutations(state)
};

const actions = {
    openScheduleModal({ commit }, { certificateId }) {
        commit("SET_CERTIFICATE_ID", certificateId);
        commit("SET_IS_LOADING", true);
        commit("SET_IS_OPEN", true);

        api.get(`/garage/${certificateId}/schedule-test-drive`)
            .then(response => {
                commit("SET_DEALER", response.data.dealer);
                commit("SET_DISTANCE", response.data.distance);
                commit("SET_IMAGE_URL", response.data.imageUrl);
                commit("SET_USER", response.data.user);
                commit("SET_VEHICLE", response.data.vehicle);
                commit("SET_IS_LOADING", false);
            })
            .catch(error => {
                console.error(error);
                commit("SET_IS_LOADING", false);
                commit("SET_IS_OPEN", false);
            });
    },

    openRescheduleModal({ commit }, { appointmentId }) {
        commit("SET_APPOINTMENT_ID", appointmentId);
        commit("SET_IS_LOADING", true);
        commit("SET_IS_OPEN", true);

        api.get(`/appointments/${appointmentId}/reschedule`)
            .then(response => {
                commit("SET_DEALER", response.data.dealer);
                commit("SET_DISTANCE", response.data.distance);
                commit("SET_IMAGE_URL", response.data.imageUrl);
                commit("SET_USER", response.data.user);
                commit("SET_VEHICLE", response.data.vehicle);
                commit("SET_IS_LOADING", false);
            })
            .catch(error => {
                console.error(error);
                commit("SET_IS_LOADING", false);
                commit("SET_IS_OPEN", false);
            });
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations
};
