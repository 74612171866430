import { make } from "vuex-pathify";
import api from "../../api";
import loader from "../../api/loader";
import isString from "lodash/isString";
import isNil from "lodash/isNil";
import get from "lodash/get";
import has from "lodash/has";
import URL from "url-parse";
import { dmas, sycDmaWhiteList } from "@/enum/sycDmas";

const LOCAL_STORAGE_KEY = "location-v1";

const initSelectedLocation = () => {
    const url = new URL(window.location.href, true);
    const zipCode = url.query.zipCode;
    // if a zipCode has been passed into the query we don't set the selectedLocation to start
    // it is up to the GeoLocation component to initialize based on it's rules. This is necessary
    // to prevent the search pages from searching with the session/client location which
    // may not be correct on page load
    if (!isNil(zipCode)) {
        return null;
    }

    if (isNil(clientLocation)) {
        return sessionLocation;
    } else {
        return clientLocation;
    }
};

const clientLocation = isNil(localStorage.getItem(LOCAL_STORAGE_KEY))
    ? null
    : JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));
const sessionLocation = get(window, "_CS_CONTEXT.selectedLocation", null);

const initialState = {
    sessionLocation,
    clientLocation,
    dmas,
    isSellYourCarEnabled: get(window, "appConfig.sellYourCarEnabled", false),
    selectedLocation: initSelectedLocation(),
    loader: loader.defaultState(),
    sycStateWhiteList: [
        "AL",
        "AR",
        "AZ",
        "CA",
        "CO",
        "CT",
        "DC",
        "DE",
        "FL",
        "GA",
        "IA",
        "IL",
        "IN",
        "LA",
        "MA",
        "MD",
        "MI",
        "MN",
        "MO",
        "NC",
        "NE",
        "NJ",
        "NM",
        "NV",
        "NY",
        "OH",
        "OK",
        "OR",
        "PA",
        "RI",
        "SC",
        "TN",
        "TX",
        "UT",
        "VA",
        "WA",
        "WI"
    ],
    urls: {
        carOffer: "https://caroffer.carsaver.com"
    },
    sycDmaWhiteList
};

const getters = {
    ...make.getters(initialState),
    selectedLocationDma: state => {
        return get(state.selectedLocation, "zipCodeDMA.dmaCode", null);
    },
    selectedLocationState: state => {
        return get(state.selectedLocation, "state", null);
    },

    clientLocationDma: state => {
        return get(state.clientLocation, "zipCodeDMA.dmaCode", null);
    },
    clientLocationState: state => {
        return get(state.clientLocation, "state", null);
    },

    activeDma: (state, getters) => {
        const dmaCode = getters.selectedLocationDma
            ? getters.selectedLocationDma
            : getters.clientLocationDma;

        return dmaCode;
    },
    activeState: (state, getters) => {
        const userState = getters.selectedLocationState
            ? getters.selectedLocationState
            : getters.clientLocationState;

        return userState;
    },

    isDmaSycWhitelisted: (state, getters) => {
        const whitelist = [...state.sycDmaWhiteList];
        const dmaCode = getters.activeDma;

        return whitelist.indexOf(dmaCode) !== -1;
    },
    isStateSycWhitelisted: (state, getters) => {
        const whitelist = [...state.sycStateWhiteList];
        let userState = getters.activeState;

        if (isString(userState)) {
            userState = userState.toUpperCase();
        }

        return whitelist.indexOf(userState) !== -1;
    }
};

const mutations = {
    ...make.mutations(initialState)
};

const actions = {
    ...make.actions(initialState),

    setLocation({ commit, state }, location) {
        commit("SET_SELECTED_LOCATION", location);
        commit("SET_CLIENT_LOCATION", location);
        commit("SET_SESSION_LOCATION", location);
        localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(location));

        if (
            get(state, "sessionLocation.zipCode") !== get(location, "zipCode")
        ) {
            api.post("/location", location).catch(error => {
                console.error(error);
            });
        }
    },

    loadLocation({ commit, state }) {
        if (!state.loader.isLoading) {
            commit("SET_LOADER", loader.started());

            return api
                .get(
                    "/location",
                    {},
                    {
                        ignore404: true
                    }
                )
                .then(response => {
                    commit("SET_SELECTED_LOCATION", response.data);
                    commit("SET_CLIENT_LOCATION", response.data);
                    commit("SET_SESSION_LOCATION", response.data);
                    localStorage.setItem(
                        LOCAL_STORAGE_KEY,
                        JSON.stringify(response.data)
                    );

                    commit("SET_LOADER", loader.successful());
                    return response.data;
                })
                .catch(error => {
                    const errorMessage =
                        get(error, "response.status") === 404
                            ? "Error finding location."
                            : error.message;
                    commit("SET_LOADER", loader.error(errorMessage));
                    return errorMessage;
                });
        }
    },

    loadLocationByCoordinates({ commit, state }, { lat, lng }) {
        if (!state.loader.isLoading) {
            commit("SET_LOADER", loader.started());

            api.get(
                "/location",
                { lat, lng },
                {
                    ignore404: true
                }
            )
                .then(response => {
                    commit("SET_SELECTED_LOCATION", response.data);
                    commit("SET_CLIENT_LOCATION", response.data);
                    commit("SET_SESSION_LOCATION", response.data);
                    localStorage.setItem(
                        LOCAL_STORAGE_KEY,
                        JSON.stringify(response.data)
                    );

                    commit("SET_LOADER", loader.successful());
                    return response.data;
                })
                .catch(error => {
                    const responseStatus = get(error, "response.status");
                    const errorMessage =
                        responseStatus === 404
                            ? "Error finding location."
                            : error.message;
                    commit("SET_LOADER", loader.error(errorMessage));
                    return errorMessage;
                });
        }
    },

    updateZipCode({ commit }, zipCode) {
        if (isNil(zipCode) || zipCode === "") {
            // Don't try and find a location from a blank zip code.
            return Promise.resolve();
        }

        commit("SET_LOADER", loader.started());

        return api
            .get(
                "/location",
                { zipCode },
                {
                    ignore404: true
                }
            )
            .then(response => {
                commit("SET_SELECTED_LOCATION", response.data);
                commit("SET_CLIENT_LOCATION", response.data);
                commit("SET_SESSION_LOCATION", response.data);
                localStorage.setItem(
                    LOCAL_STORAGE_KEY,
                    JSON.stringify(response.data)
                );

                // Update URL with new zipcode, if exists
                const url = new URL(window.location.href, true);
                const currentUrl = url
                    .toString()
                    .substr(url.toString().indexOf(url.pathname));
                if (
                    has(url.query, "zipcode") &&
                    url.query.zipcode !== zipCode
                ) {
                    url.query.zipcode = zipCode;

                    const newUrl = url
                        .toString()
                        .substr(url.toString().indexOf(url.pathname));

                    if (currentUrl !== newUrl) {
                        window.history.pushState(
                            url.query,
                            "Vehicle Search",
                            newUrl
                        );
                    }
                }

                commit("SET_LOADER", loader.successful());
            })
            .catch(error => {
                const errorMessage =
                    error.response.status === 404
                        ? "Zip code not found."
                        : error.message;
                commit("SET_LOADER", loader.error(errorMessage));
            });
    }
};

export default {
    namespaced: true,
    state: initialState,
    mutations,
    actions,
    getters
};
