export const trackEvent = (component, event, payload) => {
    component.$gtm.trackEvent({
        event,
        ...payload
    });
};

export const trackGAEvent = (component, payload) => {
    return trackEvent(component, "Event", payload);
};

export const trackPageView = (component, url, title) => {
    component.$gtm.trackEvent({
        event: "VirtualPageview",
        virtualPageURL: url,
        virtualPageTitle: title
    });
};
