<template>
    <div>
        <span v-html="$t('disclaimer.warranty', {telephoneNumber: campaignNumber})"></span>
    </div>
</template>

<script>
import { get } from 'vuex-pathify';

export default {
    name: "LifetimeWarrantyDisclaimer",
    computed: {
        campaignNumber: get('campaign/phoneNumberFormatted')
    }
}
</script>
