import { sync, dispatch, get } from "vuex-pathify";
import _ from "lodash";

export default {
    data() {
        return {
            modelOptions: [
                { value: "relevance", text: "Relevance" },
                {
                    value: "savings,desc",
                    text: this.$t("component.sortSelector.savingsHtL")
                },
                {
                    value: "price,desc",
                    text: this.$t("component.sortSelector.priceHtL")
                },
                {
                    value: "price,asc",
                    text: this.$t("component.sortSelector.priceLtH")
                },
                {
                    value: "quote.monthlyPayment,asc",
                    text: this.$t("component.sortSelector.paymentLtH")
                },
                {
                    value: "quote.monthlyPayment,desc",
                    text: this.$t("component.sortSelector.paymentHtL")
                }
            ],
            usedOptions: [
                { value: "relevance", text: "Relevance" },
                {
                    value: "distance,asc",
                    text: this.$t("component.sortSelector.distanceHtL")
                },
                {
                    value: "savings,desc",
                    text: this.$t("component.sortSelector.savingsHtL")
                },
                {
                    value: "price,desc",
                    text: this.$t("component.sortSelector.priceHtL")
                },
                {
                    value: "price,asc",
                    text: this.$t("component.sortSelector.priceLtH")
                },
                {
                    value: "miles,asc",
                    text: this.$t("component.sortSelector.miles.LtH")
                },
                {
                    value: "miles,desc",
                    text: this.$t("component.sortSelector.miles.HtL")
                },
                {
                    value: "year,asc",
                    text: this.$t("component.sortSelector.year.LtH")
                },
                {
                    value: "year,desc",
                    text: this.$t("component.sortSelector.year.HtL")
                },
                {
                    value: "quote.monthlyPayment,asc",
                    text: this.$t("component.sortSelector.paymentLtH")
                },
                {
                    value: "quote.monthlyPayment,desc",
                    text: this.$t("component.sortSelector.paymentHtL")
                }
            ],
            newOptions: [
                { value: "relevance", text: "Relevance" },
                {
                    value: "distance,asc",
                    text: this.$t("component.sortSelector.distanceHtL")
                },
                {
                    value: "savings,desc",
                    text: this.$t("component.sortSelector.savingsHtL")
                },
                {
                    value: "price,desc",
                    text: this.$t("component.sortSelector.priceHtL")
                },
                {
                    value: "price,asc",
                    text: this.$t("component.sortSelector.priceLtH")
                },
                {
                    value: "year,asc",
                    text: this.$t("component.sortSelector.year.LtH")
                },
                {
                    value: "year,desc",
                    text: this.$t("component.sortSelector.year.HtL")
                },
                {
                    value: "quote.monthlyPayment,asc",
                    text: this.$t("component.sortSelector.paymentLtH")
                },
                {
                    value: "quote.monthlyPayment,desc",
                    text: this.$t("component.sortSelector.paymentHtL")
                }
            ],
            dealerOptions: [
                { value: "relevance", text: "Relevance" },
                {
                    value: "savings,desc",
                    text: this.$t("component.sortSelector.savingsHtL")
                },
                {
                    value: "price,desc",
                    text: this.$t("component.sortSelector.priceHtL")
                },
                {
                    value: "price,asc",
                    text: this.$t("component.sortSelector.priceLtH")
                },
                {
                    value: "miles,asc",
                    text: this.$t("component.sortSelector.miles.LtH")
                },
                {
                    value: "miles,desc",
                    text: this.$t("component.sortSelector.miles.HtL")
                },
                {
                    value: "year,asc",
                    text: this.$t("component.sortSelector.year.LtH")
                },
                {
                    value: "year,desc",
                    text: this.$t("component.sortSelector.year.HtL")
                },
                {
                    value: "quote.monthlyPayment,asc",
                    text: this.$t("component.sortSelector.paymentLtH")
                },
                {
                    value: "quote.monthlyPayment,desc",
                    text: this.$t("component.sortSelector.paymentHtL")
                }
            ]
        };
    },

    props: {
        sortEntity: {
            type: String,
            default: "inventory"
        },
        isDealerInventorySearch: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        sort: sync("vehicleSearch/pageable@sort"),
        stockType: get("vehicleSearch/filters@stockTypes"),
        isModelListings() {
            return this.sortEntity === "models";
        },
        sortOptions() {
            if (this.isModelListings) {
                return this.modelOptions;
            }

            if (this.isDealerInventorySearch) {
                return this.dealerOptions;
            }

            return _.includes(this.stockType, "USED")
                ? this.usedOptions
                : this.newOptions;
        }
    },

    watch: {
        sort(newVal, oldVal) {
            if (!_.isNil(oldVal) && oldVal !== "") {
                dispatch("vehicleSearch/filtersChanged");
            }
        }
    },

    mounted: function() {
        if (_.isNil(this.sort) || this.sort === "") {
            this.sort = this.sortOptions[0].value;
        }
    }
};
