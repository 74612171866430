var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "mainNavigationWrapper", attrs: { id: "main-navigation-wrapper" } },
    [
      _vm.showKioskHeader
        ? _c("div", { staticClass: "kiosk-header" }, [_c("main-navigation")], 1)
        : _c(
            "header-transition-wrapper",
            [
              _c("main-navigation"),
              _vm._v(" "),
              _vm.showMobileInventorySubmenu
                ? _c("inventory-mobile-submenu")
                : _vm._e()
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }