var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showSessionResetOption
    ? _c(
        "div",
        { staticClass: "session-reset" },
        [
          _c("session-reset-button", {
            attrs: { "ambassador-id": _vm.ambassadorId }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }