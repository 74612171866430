import _ from "lodash";
import { get } from "vuex-pathify";
import moment from "moment-timezone";

const isCallCenterOpen = {
    computed: {
        hoursOfOperation: get("supplierPricing/hoursOfOperation"),

        isCallCenterOpen() {
            if (
                _.isNil(this.hoursOfOperation) ||
                this.hoursOfOperation.length < 7
            ) {
                return false;
            }

            const now = moment();

            const referenceIndex = [0, 1, 2, 3, 4, 5, 6];
            const dayOfWeekHours = this.hoursOfOperation[
                referenceIndex[now.day()]
            ];

            const startTime = moment(dayOfWeekHours.startTime, "HH:mm");
            const endTime = moment(dayOfWeekHours.endTime, "HH:mm");
            const start = moment.tz(
                now.format("L") + " " + startTime.format("LT"),
                "L LT",
                "US/Eastern"
            );
            const end = moment.tz(
                now.format("L") + " " + endTime.format("LT"),
                "L LT",
                "US/Eastern"
            );

            return now.isBetween(start, end);
        }
    }
};

export default isCallCenterOpen;
