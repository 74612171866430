<template>
    <div class="facet">
        <div
            class="facet-header d-flex justify-content-between mb-3"
            @click="collapseToggle"
        >
            <b-link class="label">
                <span class="font-weight-bold">
                    <slot name="facetLabel" />
                </span>
            </b-link>
            <div>
                <b-link v-if="showClearLink" @click="clearFilters">
                    <span class="clear font-italic">
                        Clear
                    </span>
                </b-link>
                <b-link v-if="showCollapsibleButton" class="collapse-icon">
                    <span
                        :class="[
                            collapsed ? 'ion-chevron-right' : 'ion-chevron-down'
                        ]"
                    />
                </b-link>
            </div>
        </div>
        <b-collapse
            :id="facetName"
            class="facet-body my-3"
            :visible="!collapsed"
        >
            <slot name="facetBody" />
        </b-collapse>
    </div>
</template>

<script>
import { get } from "vuex-pathify";
import FacetMixin from "./mixin";
import _ from "lodash";

export default {
    name: "FacetTemplate",
    mixins: [FacetMixin],
    props: {
        showClearLink: {
            type: Boolean,
            default: true
        },
        showCollapsibleButton: {
            type: Boolean,
            default: true,
            required: false
        },

        facetName: {
            type: String,
            default: "",
            required: false
        }
    },
    computed: {
        collapseds: get("vehicleSearch/collapsed"),
        collapsed() {
            return _.get(this.collapseds, this.facetName, false);
        },
        isCollapsible() {
            return this.facetName !== "";
        }
    },
    methods: {
        clearFilters() {
            this.$emit("clear");
        },
        collapseToggle() {
            if (_.get(this.collapsibles, this.facetName, false)) {
                this.$store.set(
                    "vehicleSearch/collapsed@" + this.facetName,
                    !this.collapsed
                );
            } else {
                console.error(
                    "Error: func collapseToggle, facetName not found",
                    this.facetName
                );
            }
        }
    }
};
</script>

<style lang="scss">
.facet {
    color: $muted-dark-gray;
    padding: 10px 15px 0px 15px;
    background-color: $card-background;
    margin-top: 5px;
    overflow: auto;

    .collapse-icon {
        color: #ababab;
        margin-left: 10px;
    }

    .facet-header {
        .label {
            color: $medium-gray;
            line-height: 18px;
            font-size: px2rem(16);

            &:hover {
                text-decoration: none;
            }
        }

        .clear {
            color: $primary-blue;
            font-size: px2rem(10);
            line-height: 15px;
        }
    }

    > div {
        margin-top: 5px;

        ul {
            list-style: none;
            padding-left: 0;
        }
    }
}
</style>
