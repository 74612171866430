var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "header",
    { attrs: { id: "main-navigation" } },
    [
      _c("shop-com-link-back-banner"),
      _vm._v(" "),
      _vm.showLinkBack
        ? _c("div", { attrs: { id: "link-back-block" } }, [_vm._m(0)])
        : _vm._e(),
      _vm._v(" "),
      _c("nav", { staticClass: "navbar navbar-dark cs-navbar" }, [
        _c(
          "div",
          { staticClass: "container-fluid" },
          [
            _c(
              "b-link",
              {
                staticClass: "navbar-brand mr-auto",
                attrs: { title: _vm.campaignConfigs.logo.altText },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.goHome.apply(null, arguments)
                  }
                }
              },
              [
                _c(
                  "svg-fallback",
                  {
                    staticClass: "logo",
                    attrs: { src: _vm.campaignConfigs.logo.svgSrc }
                  },
                  [
                    _c("img", {
                      attrs: {
                        alt: _vm.campaignConfigs.logo.altText,
                        src: _vm.campaignConfigs.logo.src
                      }
                    })
                  ]
                )
              ],
              1
            ),
            _vm._v(" "),
            _vm.showSearch
              ? _c("autocomplete", {
                  staticClass: "mr-3",
                  attrs: {
                    source: "/api/v2/search/suggest?value=",
                    "results-property": "url",
                    "results-display": "text",
                    placeholder: "Enter Make or Model",
                    "show-no-results": false
                  },
                  on: {
                    selected: _vm.searchResult,
                    results: _vm.storeSearchResults,
                    enter: _vm.queryResult,
                    nothingSelected: _vm.queryResult
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            _c(
              "ul",
              { staticClass: "navbar-nav d-none d-lg-flex flex-row" },
              [
                _vm.showBuy
                  ? _c("li", { staticClass: "nav-item dropdown" }, [
                      _c(
                        "a",
                        {
                          staticClass: "nav-link dropdown-toggle",
                          attrs: {
                            id: "buyDropDownMenu",
                            href: "#",
                            "data-toggle": "dropdown",
                            "aria-haspopup": "true",
                            "aria-expanded": "false"
                          }
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.$t("component.mainNav.buy")) +
                              "\n                    "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "dropdown-menu",
                          attrs: { "aria-labelledby": "buyDropDownMenu" }
                        },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "dropdown-item",
                              attrs: {
                                href: "/shop-by/payment?paymentType=LEASE"
                              }
                            },
                            [
                              _vm._v(
                                "\n                            Shop Leases\n                        "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              staticClass: "dropdown-item",
                              attrs: { href: "/shop-by" }
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(_vm.$t("component.mainNav.new")) +
                                  "\n                        "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              staticClass: "dropdown-item",
                              attrs: { href: "/shop/used" }
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(_vm.$t("component.mainNav.used")) +
                                  "\n                        "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              staticClass: "dropdown-item",
                              attrs: { href: "/shop-by/make" },
                              on: { click: _vm.updateSavedUrl }
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(_vm.$t("component.mainNav.build")) +
                                  "\n                        "
                              )
                            ]
                          )
                        ]
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.showSell
                  ? _c("li", { staticClass: "nav-item" }, [
                      _c(
                        "a",
                        {
                          staticClass: "nav-link",
                          attrs: { href: "/sell-your-car" }
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.$t("component.mainNav.sell")) +
                              "\n                    "
                          )
                        ]
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.showFinance
                  ? _c("li", { staticClass: "nav-item" }, [
                      _c(
                        "a",
                        {
                          staticClass: "nav-link",
                          attrs: { href: "/finance" }
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.$t("component.mainNav.finance")) +
                              "\n                    "
                          )
                        ]
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.showInsure
                  ? _c("li", { staticClass: "nav-item" }, [
                      _c(
                        "a",
                        { staticClass: "nav-link", attrs: { href: "/insure" } },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.$t("component.mainNav.insure")) +
                              "\n                    "
                          )
                        ]
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.showProtect
                  ? _c("li", { staticClass: "nav-item dropdown" }, [
                      _c(
                        "a",
                        {
                          staticClass: "nav-link dropdown-toggle",
                          attrs: {
                            id: "protectDropDownMenu",
                            href: "#",
                            "data-toggle": "dropdown",
                            "aria-haspopup": "true",
                            "aria-expanded": "false"
                          }
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.$t("component.mainNav.protect")) +
                              "\n                    "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "dropdown-menu",
                          attrs: { "aria-labelledby": "protectDropDownMenu" }
                        },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "dropdown-item",
                              attrs: { href: "/warranty" }
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(_vm.$t("component.mainNav.warranty")) +
                                  "\n                        "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              staticClass: "dropdown-item",
                              attrs: { href: "/warranty/faq" }
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(_vm.$t("component.mainNav.faq")) +
                                  "\n                        "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              staticClass: "dropdown-item",
                              attrs: {
                                target: "_blank",
                                rel: "noopener noreferrer",
                                href:
                                  "https://carsaver.naenwan.com/Account/Registration"
                              }
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(_vm.$t("component.mainNav.register")) +
                                  "\n                        "
                              )
                            ]
                          )
                        ]
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.showHowItWorks
                  ? _c("li", { staticClass: "nav-item" }, [
                      _c(
                        "a",
                        {
                          staticClass: "nav-link",
                          attrs: { href: "/how-it-works" }
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.$t("component.mainNav.howItWorks")) +
                              "\n                    "
                          )
                        ]
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("li", { staticClass: "nav-item dropdown" }, [
                  _c(
                    "a",
                    {
                      staticClass: "nav-link dropdown-toggle",
                      attrs: {
                        id: "autoAdvisorDropDownMenu",
                        href: "#",
                        "data-toggle": "dropdown",
                        "aria-haspopup": "true",
                        "aria-expanded": "false"
                      }
                    },
                    [
                      _vm._v(
                        "\n                        " +
                          _vm._s(_vm.$t("component.mainNav.advisor")) +
                          "\n                    "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "dropdown-menu dropdown-menu-right personal-shopper-content",
                      attrs: { "aria-labelledby": "autoAdvisorDropDownMenu" }
                    },
                    [
                      _c("h3", [
                        _vm._v(_vm._s(_vm.$t("component.mainNav.advisor.with")))
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v(_vm._s(_vm.$t("component.mainNav.advisor.buy")))
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "image" },
                        [
                          _c("b-img-lazy", {
                            directives: [{ name: "webp", rawName: "v-webp" }],
                            attrs: {
                              src: require("./images/menu_personal_shopper.png"),
                              width: "180",
                              height: "180",
                              alt: "Personal Shopper"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("p", { staticClass: "title" }, [
                        _vm._v(
                          "\n                            " +
                            _vm._s(
                              _vm.$t("component.mainNav.advisor.carsaver")
                            ) +
                            "\n                        "
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          staticClass: "phone",
                          attrs: { href: "tel:" + _vm.phoneNumber }
                        },
                        [
                          _c("i", {
                            staticClass: "ion-ios-telephone",
                            attrs: { "aria-hidden": "true" }
                          }),
                          _vm._v(
                            "\n                            " +
                              _vm._s(_vm.phoneNumberFormatted) +
                              "\n                        "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-btn",
                        {
                          staticClass: "call-back",
                          attrs: { variant: "primary", size: "lg", block: "" },
                          on: {
                            click: function($event) {
                              return _vm.showCallbackModal()
                            }
                          }
                        },
                        [
                          _c("i", {
                            staticClass: "ion-calendar mr-2",
                            attrs: { "aria-hidden": "true" }
                          }),
                          _vm._v(
                            "\n                            " +
                              _vm._s(
                                _vm.$t("component.mainNav.advisor.request")
                              ) +
                              "\n                        "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _vm.isSignedIn
                  ? _c("li", { staticClass: "nav-item dropdown" }, [
                      _c(
                        "a",
                        {
                          staticClass: "nav-link dropdown-toggle",
                          attrs: {
                            id: "profileDropDownMenu",
                            href: "#",
                            "data-toggle": "dropdown",
                            "aria-haspopup": "true",
                            "aria-expanded": "false"
                          }
                        },
                        [
                          _vm.imageUrl && _vm.enableUserImg
                            ? _c("b-img", {
                                attrs: {
                                  src: _vm.imageUrl,
                                  width: "24",
                                  height: "24",
                                  rounded: "circle",
                                  alt: _vm.firstName
                                },
                                on: { error: _vm.imgErrorHandler }
                              })
                            : _c("gravatar", {
                                staticClass: "rounded-circle",
                                attrs: {
                                  "default-img": "mp",
                                  email: _vm.email,
                                  alt: _vm.firstName,
                                  size: 24
                                }
                              }),
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.firstName) +
                              "\n                    "
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "dropdown-menu",
                          attrs: {
                            id: "profileDropDownMenu-menu",
                            "aria-labelledby": "profileDropDownMenu"
                          }
                        },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "dropdown-item",
                              attrs: { href: "/dashboard" }
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(_vm.$t("component.mainNav.garage")) +
                                  "\n                        "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              staticClass: "dropdown-item",
                              attrs: { href: "/profile" }
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(_vm.$t("component.mainNav.profile")) +
                                  "\n                        "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _vm.enableTranslations
                            ? [
                                _vm.isEnglish
                                  ? _c(
                                      "a",
                                      {
                                        staticClass: "dropdown-item",
                                        attrs: { href: _vm.changeLocaleUrl }
                                      },
                                      [
                                        _vm._v(
                                          "\n                                Español\n                            "
                                        )
                                      ]
                                    )
                                  : _c(
                                      "a",
                                      {
                                        staticClass: "dropdown-item",
                                        attrs: { href: _vm.changeLocaleUrl }
                                      },
                                      [
                                        _vm._v(
                                          "\n                                English\n                            "
                                        )
                                      ]
                                    )
                              ]
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              staticClass: "dropdown-item",
                              attrs: { href: "/logout" }
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(_vm.$t("component.mainNav.logOut")) +
                                  "\n                        "
                              )
                            ]
                          )
                        ],
                        2
                      )
                    ])
                  : _c("li", { staticClass: "nav-item" }, [
                      _c(
                        "a",
                        { staticClass: "nav-link", on: { click: _vm.signIn } },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.$t("component.mainNav.signIn")) +
                              "\n                    "
                          )
                        ]
                      )
                    ]),
                _vm._v(" "),
                _vm.enableTranslations
                  ? [
                      !_vm.isSignedIn && _vm.isEnglish
                        ? _c("li", { staticClass: "nav-item" }, [
                            _c(
                              "a",
                              {
                                staticClass: "nav-link",
                                attrs: { href: _vm.changeLocaleUrl }
                              },
                              [
                                _vm._v(
                                  "\n                            Español\n                        "
                                )
                              ]
                            )
                          ])
                        : !_vm.isSignedIn
                        ? _c("li", { staticClass: "nav-item" }, [
                            _c(
                              "a",
                              {
                                staticClass: "nav-link",
                                attrs: { href: _vm.changeLocaleUrl }
                              },
                              [
                                _vm._v(
                                  "\n                            English\n                        "
                                )
                              ]
                            )
                          ])
                        : _vm._e()
                    ]
                  : _vm._e()
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                directives: [
                  {
                    name: "b-modal",
                    rawName: "v-b-modal.mobile-nav-modal",
                    modifiers: { "mobile-nav-modal": true }
                  }
                ],
                staticClass: "navbar-toggler d-lg-none collapsed",
                attrs: {
                  "aria-controls": "mobile-nav-modal",
                  type: "button",
                  "aria-expanded": "false",
                  "aria-label": "Toggle mobile navigation"
                }
              },
              [
                _c("i", {
                  staticClass: "ion-navicon icon",
                  attrs: { "aria-hidden": "true" }
                })
              ]
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "b-modal",
        { attrs: { id: "mobile-nav-modal", title: "Menu", "hide-footer": "" } },
        [
          _c(
            "div",
            {
              attrs: { slot: "modal-header-close" },
              slot: "modal-header-close"
            },
            [
              _c("i", {
                staticClass: "ion-ios-close-outline",
                attrs: { "aria-hidden": "true" }
              })
            ]
          ),
          _vm._v(" "),
          _vm.isSignedIn
            ? _c(
                "b-nav",
                { staticClass: "profile-dropdown", attrs: { vertical: "" } },
                [
                  _c(
                    "b-nav-item",
                    {
                      directives: [
                        {
                          name: "b-toggle",
                          rawName: "v-b-toggle.collapse-profile",
                          modifiers: { "collapse-profile": true }
                        }
                      ],
                      attrs: {
                        "link-classes": "d-flex justify-content-between"
                      }
                    },
                    [
                      _c(
                        "span",
                        [
                          _vm.imageUrl
                            ? _c("b-img", {
                                attrs: {
                                  src: _vm.imageUrl,
                                  width: "24",
                                  height: "24",
                                  rounded: "circle",
                                  alt: _vm.firstName
                                }
                              })
                            : _c("gravatar", {
                                staticClass: "rounded-circle",
                                attrs: {
                                  "default-img": "mp",
                                  email: _vm.email,
                                  alt: _vm.firstName,
                                  size: 24
                                }
                              }),
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.firstName) +
                              "\n                "
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("i", {
                        class: _vm.profileCollapsed
                          ? "ion-chevron-up"
                          : "ion-chevron-down",
                        attrs: { "aria-hidden": "true" }
                      })
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-collapse",
                    {
                      attrs: { id: "collapse-profile" },
                      model: {
                        value: _vm.profileCollapsed,
                        callback: function($$v) {
                          _vm.profileCollapsed = $$v
                        },
                        expression: "profileCollapsed"
                      }
                    },
                    [
                      _c("b-nav-item", { attrs: { href: "/dashboard" } }, [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.$t("component.mainNav.garage")) +
                            "\n                "
                        )
                      ]),
                      _vm._v(" "),
                      _c("b-nav-item", { attrs: { href: "/profile" } }, [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.$t("component.mainNav.profile")) +
                            "\n                "
                        )
                      ]),
                      _vm._v(" "),
                      _vm.enableTranslations
                        ? [
                            _vm.isEnglish
                              ? _c(
                                  "b-nav-item",
                                  { attrs: { href: _vm.changeLocaleUrl } },
                                  [
                                    _vm._v(
                                      "\n                        Español\n                    "
                                    )
                                  ]
                                )
                              : _c(
                                  "b-nav-item",
                                  { attrs: { href: _vm.changeLocaleUrl } },
                                  [
                                    _vm._v(
                                      "\n                        English\n                    "
                                    )
                                  ]
                                )
                          ]
                        : _vm._e(),
                      _vm._v(" "),
                      _c("b-nav-item", { attrs: { href: "/logout" } }, [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.$t("component.mainNav.logOut")) +
                            "\n                "
                        )
                      ])
                    ],
                    2
                  )
                ],
                1
              )
            : _c(
                "div",
                { staticClass: "text-center account-links" },
                [
                  _c("b-link", { on: { click: _vm.signIn } }, [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.$t("component.mainNav.signIn")) +
                        "\n            "
                    )
                  ]),
                  _vm._v("\n             | \n            "),
                  _c("b-link", { on: { click: _vm.signUp } }, [
                    _vm._v("\n                Create an Account\n            ")
                  ])
                ],
                1
              ),
          _vm._v(" "),
          _c(
            "b-nav",
            { attrs: { vertical: "" } },
            [
              _vm.showBuy
                ? _c(
                    "b-nav-item",
                    {
                      directives: [
                        {
                          name: "b-toggle",
                          rawName: "v-b-toggle.collapse-buy",
                          modifiers: { "collapse-buy": true }
                        }
                      ],
                      attrs: {
                        "link-classes": "d-flex justify-content-between"
                      }
                    },
                    [
                      _c("span", [
                        _vm._v(_vm._s(_vm.$t("component.mainNav.buy")))
                      ]),
                      _vm._v(" "),
                      _c("i", {
                        class: _vm.buyCollapsed
                          ? "ion-chevron-up"
                          : "ion-chevron-down",
                        attrs: { "aria-hidden": "true" }
                      })
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.showBuy
                ? _c(
                    "b-collapse",
                    {
                      attrs: { id: "collapse-buy" },
                      model: {
                        value: _vm.buyCollapsed,
                        callback: function($$v) {
                          _vm.buyCollapsed = $$v
                        },
                        expression: "buyCollapsed"
                      }
                    },
                    [
                      _c(
                        "b-nav-item",
                        {
                          attrs: { href: "/shop-by/payment?paymentType=LEASE" }
                        },
                        [
                          _vm._v(
                            "\n                    Shop Leases\n                "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c("b-nav-item", { attrs: { href: "/shop-by" } }, [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.$t("component.mainNav.new")) +
                            "\n                "
                        )
                      ]),
                      _vm._v(" "),
                      _c("b-nav-item", { attrs: { href: "/shop/used" } }, [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.$t("component.mainNav.used")) +
                            "\n                "
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "b-nav-item",
                        {
                          attrs: { href: "/shop-by/make" },
                          on: { click: _vm.updateSavedUrl }
                        },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.$t("component.mainNav.build")) +
                              "\n                "
                          )
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.showSell
                ? _c("b-nav-item", { attrs: { href: "/sell-your-car" } }, [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.$t("component.mainNav.sell")) +
                        "\n            "
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.showFinance
                ? _c("b-nav-item", { attrs: { href: "/finance" } }, [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.$t("component.mainNav.finance")) +
                        "\n            "
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.showInsure
                ? _c("b-nav-item", { attrs: { href: "/insure" } }, [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.$t("component.mainNav.insure")) +
                        "\n            "
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.showProtect
                ? _c(
                    "b-nav-item",
                    {
                      directives: [
                        {
                          name: "b-toggle",
                          rawName: "v-b-toggle.collapse-protect",
                          modifiers: { "collapse-protect": true }
                        }
                      ],
                      attrs: {
                        "link-classes": "d-flex justify-content-between"
                      }
                    },
                    [
                      _c("span", [
                        _vm._v(_vm._s(_vm.$t("component.mainNav.protect")))
                      ]),
                      _vm._v(" "),
                      _c("i", {
                        class: _vm.protectCollapsed
                          ? "ion-chevron-up"
                          : "ion-chevron-down",
                        attrs: { "aria-hidden": "true" }
                      })
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.showProtect
                ? _c(
                    "b-collapse",
                    {
                      attrs: { id: "collapse-protect" },
                      model: {
                        value: _vm.protectCollapsed,
                        callback: function($$v) {
                          _vm.protectCollapsed = $$v
                        },
                        expression: "protectCollapsed"
                      }
                    },
                    [
                      _c("b-nav-item", { attrs: { href: "/warranty" } }, [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.$t("component.mainNav.warranty")) +
                            "\n                "
                        )
                      ]),
                      _vm._v(" "),
                      _c("b-nav-item", { attrs: { href: "/warranty/faq" } }, [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.$t("component.mainNav.faq")) +
                            "\n                "
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "b-nav-item",
                        {
                          attrs: {
                            rel: "noopener noreferrer",
                            target: "_blank",
                            href:
                              "https://carsaver.naenwan.com/Account/Registration"
                          }
                        },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.$t("component.mainNav.register")) +
                              "\n                "
                          )
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.showHowItWorks
                ? _c("b-nav-item", { attrs: { href: "/how-it-works" } }, [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.$t("component.mainNav.howItWorks")) +
                        "\n            "
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "b-nav-item",
                {
                  directives: [
                    {
                      name: "b-toggle",
                      rawName: "v-b-toggle.collapse-auto-advisor",
                      modifiers: { "collapse-auto-advisor": true }
                    }
                  ],
                  attrs: { "link-classes": "d-flex justify-content-between" }
                },
                [
                  _c("span", [_vm._v("Personal Shopper")]),
                  _vm._v(" "),
                  _c("i", {
                    class: _vm.autoAdvisorCollapsed
                      ? "ion-chevron-up"
                      : "ion-chevron-down",
                    attrs: { "aria-hidden": "true" }
                  })
                ]
              ),
              _vm._v(" "),
              _c(
                "b-collapse",
                {
                  attrs: { id: "collapse-auto-advisor" },
                  model: {
                    value: _vm.autoAdvisorCollapsed,
                    callback: function($$v) {
                      _vm.autoAdvisorCollapsed = $$v
                    },
                    expression: "autoAdvisorCollapsed"
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex justify-content-center align-items-center flex-column py-3"
                    },
                    [
                      _c("h4", [
                        _vm._v(_vm._s(_vm.$t("component.mainNav.advisor.with")))
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v(_vm._s(_vm.$t("component.mainNav.advisor.buy")))
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "image" },
                        [
                          _c("b-img-lazy", {
                            directives: [{ name: "webp", rawName: "v-webp" }],
                            attrs: {
                              src: require("./images/menu_personal_shopper.png"),
                              width: "180",
                              height: "180",
                              alt: "Personal Shopper"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("span", { staticClass: "title" }, [
                        _vm._v(
                          "\n                        " +
                            _vm._s(
                              _vm.$t("component.mainNav.advisor.carsaver")
                            ) +
                            "\n                    "
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          staticClass: "phone mb-2",
                          attrs: { href: "tel:" + _vm.phoneNumber }
                        },
                        [
                          _c("i", {
                            staticClass: "ion-ios-telephone",
                            attrs: { "aria-hidden": "true" }
                          }),
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.phoneNumberFormatted) +
                              "\n                    "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-btn",
                        {
                          staticClass: "call-back",
                          attrs: { variant: "primary", size: "lg", block: "" },
                          on: {
                            click: function($event) {
                              return _vm.showCallbackModal()
                            }
                          }
                        },
                        [
                          _c("i", {
                            staticClass: "ion-calendar mr-2",
                            attrs: { "aria-hidden": "true" }
                          }),
                          _vm._v(
                            "\n                        " +
                              _vm._s(
                                _vm.$t("component.mainNav.advisor.request")
                              ) +
                              "\n                    "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ]
              ),
              _vm._v(" "),
              _vm.enableTranslations
                ? [
                    !_vm.isSignedIn && _vm.isEnglish
                      ? _c(
                          "b-nav-item",
                          { attrs: { href: _vm.changeLocaleUrl } },
                          [
                            _vm._v(
                              "\n                    Español\n                "
                            )
                          ]
                        )
                      : !_vm.isSignedIn
                      ? _c(
                          "b-nav-item",
                          { attrs: { href: _vm.changeLocaleUrl } },
                          [
                            _vm._v(
                              "\n                    English\n                "
                            )
                          ]
                        )
                      : _vm._e()
                  ]
                : _vm._e()
            ],
            2
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("request-a-callback", { ref: "callbackModal" })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass:
          "container-fluid h-100 d-flex justify-content-between align-items-center"
      },
      [
        _c("div", [
          _c(
            "a",
            {
              attrs: {
                id: "link-back",
                href: "https://www.walmart.com",
                target: "_blank",
                rel: "noopener noreferrer"
              }
            },
            [
              _vm._v(
                "\n                    < Back to Walmart.com\n                "
              )
            ]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "d-none d-lg-inline" }, [
          _c("span", { attrs: { id: "link-back-message" } }, [
            _vm._v(
              "\n                    You are no longer on Walmart.com. CarSaver is a trusted\n                    partner that makes auto buying services available to\n                    Walmart customers.\n                "
            )
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "d-none d-lg-inline" })
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }