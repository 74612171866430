<template>
    <div class="sticky-footer">
        <div
            id="floatingFooter"
            data-aos="fade-up"
            class="position-fixed d-lg-none d-md-block w-100"
        >
            <div
                class="d-flex justify-content-center align-items-center w-100 footer-block"
            >
                <a :href="url.new" class="btn btn-primary mx-3 btn-xlg">
                    {{ $t("page.home.floatingFooter.new") }}
                </a>
                <a :href="url.used" class="btn btn-dark-blue mr-3 btn-xlg">
                    {{ $t("page.home.floatingFooter.used") }}
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import AOS from "aos";
import { get } from "vuex-pathify";

export default {
    name: "StickyFooter",

    computed: {
        lang: get("translated/lang"),
        url: function() {
            return {
                used: "/shop/used",
                new: "/shop-by"
            };
        }
    },

    mounted() {
        if (this.$mq !== "lg" && this.$mq !== "xl") {
            AOS.init();

            // For adjusting the Fresh Chat icon
            this.freshChatPlacementAdjuster();
        }
    },

    methods: {
        // For adjusting the Fresh Chat icon
        freshChatPlacementAdjuster() {
            let bodyElm = document.querySelector("body");

            document.addEventListener("aos:in", () => {
                if (!bodyElm) {
                    bodyElm = document.querySelector("body");
                }

                bodyElm.classList.add("freshchat_offset");
            });

            document.addEventListener("aos:out", () => {
                if (!bodyElm) {
                    bodyElm = document.querySelector("body");
                }
                bodyElm.classList.remove("freshchat_offset");
            });
        }
    }
};
</script>
<style lang="scss" scoped>
.sticky-footer {
    #floatingFooter {
        z-index: 70;
        bottom: 0;
        background: #fff;
        height: auto;

        @include media-breakpoint-down(sm) {
            .btn-primary,
            .btn-dark-blue {
                width: 100% !important;
            }
        }

        .footer-block {
            flex-flow: row nowrap;
            height: 70px;
            background-color: #f9f9f9;
            box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.5);

            .btn-primary,
            .btn-dark-blue {
                width: 225px;
                font-size: px2rem(16);
            }
        }
    }
}
</style>

// freshdesk icon adjustment when footer is present
<style lang="scss">
body {
    &.freshchat_offset {
        .freshchat_btn {
            transition-timing-function: ease-in;
            transition-duration: 0.3s;
            bottom: 75px !important;
        }
    }
    .freshchat_btn {
        transition-timing-function: ease-out;
        transition-duration: 0.3s;
    }
}
</style>
