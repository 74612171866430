import _ from "lodash";
import { make } from "vuex-pathify";
import api from "../../api";
import Errors from "../../api/errors";
import URL from "url-parse";

const initialState = {
    user: _.get(window, "_CS_USER", null),
    signingIn: false,
    errorSigningIn: false,
    signInOpen: false,
    updateEmailPhone: {
        editContactOpen: false,
        submitting: false,
        errors: null,
        hasServerError: false
    },
    createUserErrors: null
};

const getters = {
    ...make.getters(initialState),
    isSignedIn: state => {
        return _.get(state, "user", null) !== null;
    },

    // @deprecated - Please get phone from user.phoneNumber
    phone: state => {
        return _.get(state, "user.phoneNumber");
    }
};

const mutations = {
    ...make.mutations(initialState),

    SET_USER: (state, payload) => {
        state.user = {
            id: payload.id,
            firstName: payload.firstName,
            lastName: payload.lastName,
            email: payload.email,
            phoneNumber: payload.phoneNumber,
            zipCode: payload.zipCode,
            stateCode: _.get(payload, "stateCode"),
            city: _.get(payload, "city")
        };
    }
};

const actions = {
    ...make.actions(initialState),
    signIn({ commit }, payload) {
        commit("SET_SIGNING_IN", true);
        commit("SET_ERROR_SIGNING_IN", false);

        api.post("/user/login", {
            email: _.trim(payload.form.email),
            password: payload.form.password
        })
            .then(response => {
                const success = _.get(response, "data.success", false);
                const user = _.get(response, "data.user");
                if (!success || _.isNil(user)) {
                    commit("SET_ERROR_SIGNING_IN", true);
                    commit("SET_SIGNING_IN", false);
                    return;
                }

                const modalToOpen = _.get(payload, "modalToOpen");
                const baseUrl = _.isNil(_.get(payload, "redirectUrl"))
                    ? window.location.href
                    : payload.redirectUrl;
                const url = new URL(baseUrl, true);

                commit("SET_USER", user);
                commit("SET_SIGN_IN_OPEN", false);

                if (!_.isNil(modalToOpen)) {
                    url.query.modal = modalToOpen;
                }

                window.location = url.toString();
            })
            .catch(error => {
                console.error(error);
                commit("SET_ERROR_SIGNING_IN", true);
                commit("SET_SIGNING_IN", false);
            });
    },

    updateEmailPhone({ commit, state }, payload) {
        commit("SET_UPDATE_EMAIL_PHONE", {
            errors: null,
            hasServerError: false,
            submitting: true,
            editContactOpen: true
        });

        const newUser = {};
        Object.assign(newUser, state.user, _.omit(payload, "userId"));
        commit("SET_USER", newUser);

        api.post("/user/profile", {
            email: _.trim(payload.email),
            phoneNumber: _.trim(payload.phoneNumber),
            userId: payload.userId
        })
            .then(response => {
                commit("SET_USER", response.data);
                commit("SET_UPDATE_EMAIL_PHONE", {
                    editContactOpen: false,
                    errors: null,
                    submitting: false,
                    hasServerError: false
                });
            })
            .catch(error => {
                commit("SET_UPDATE_EMAIL_PHONE", {
                    editContactOpen: true,
                    submitting: false,
                    errors: new Errors().record(error.response.data.errors),
                    hasServerError: error.response.data.status === 500
                });
            });
    },

    resetProfileForm({ commit }) {
        commit("SET_UPDATE_EMAIL_PHONE", {
            errors: null,
            hasServerError: false,
            submitting: false,
            editContactOpen: false
        });
    },

    createUser({ commit }, payload) {
        api.post("/user/sign-up", payload)
            .then(response => {
                commit("SET_USER", response.data);
            })
            .catch(error => {
                console.error("createUser: ", error.response.data.errors);
                commit(
                    "SET_CREATE_USER_ERRORS",
                    new Errors().record(error.response.data.errors)
                );
            });
    }
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
};
