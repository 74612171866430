<template>
    <div>
        <div class="d-flex flex-column mb-5">
            <h3 class="form-title mb-0">
                {{ $t("component.purchaseTimeline.title") }}
            </h3>
            <div>{{ $t("component.purchaseTimeline.subTitle") }}</div>
        </div>

        <div class="text-center">
            <div
                class="d-flex flex-column justify-content-center align-items-center"
            >
                <div class="status-btn-wrapper">
                    <button
                        type="submit"
                        class="btn btn-outline-primary btn-block"
                        data-style="expand-left"
                        @click="setPurchaseTimeline(purchaseTimeline.THIS_WEEK)"
                    >
                        {{ $t("component.purchaseTimeline.valueOne") }}
                    </button>
                </div>
                <div class="status-btn-wrapper">
                    <button
                        type="submit"
                        class="btn btn-outline-primary btn-block"
                        data-style="expand-left"
                        @click="setPurchaseTimeline(purchaseTimeline.THIS_MONTH)"
                    >
                        {{ $t("component.purchaseTimeline.valueTwo") }}
                    </button>
                </div>
                <div class="status-btn-wrapper">
                    <button
                        type="submit"
                        class="btn btn-outline-primary btn-block"
                        data-style="expand-left"
                        @click="setPurchaseTimeline(purchaseTimeline.ONE_TO_THREE_MONTHS)"
                    >
                        {{ $t("component.purchaseTimeline.valueThree") }}
                    </button>
                </div>
                <div class="status-btn-wrapper">
                    <button
                        type="submit"
                        class="btn btn-outline-primary btn-block"
                        data-style="expand-left"
                            @click="setPurchaseTimeline(purchaseTimeline.THREE_TO_SIX_MONTHS)"
                    >
                        {{ $t("component.purchaseTimeline.valueFour") }}
                    </button>
                </div>
                <div class="status-btn-wrapper">
                    <b-link
                        class="font-italic"
                        @click="setPurchaseTimeline(purchaseTimeline.UNDECIDED)"
                    >
                        <small>{{
                            $t("component.purchaseTimeline.valueFive")
                        }}</small>
                    </b-link>
                </div>
            </div>
        </div>
        <input
            id="purchaseTimeFrameStartDate"
            type="hidden"
            name="purchaseTimeFrameStartDate"
            :value="startDate"
        />
        <input
            id="purchaseTimeFrameEndDate"
            type="hidden"
            name="purchaseTimeFrameEndDate"
            :value="endDate"
        />
    </div>
</template>

<script>
import purchaseTimelineMixin from "@/mixins/purchaseTimelineMixin";

export default {
    name: "PurchaseTimeline",
    mixins: [purchaseTimelineMixin]
};
</script>
