import store from "store";
import _ from "lodash";

const clientStorage = {
    setItem: (key, value) => {
        if (_.isNil(key) || _.isNil(value)) {
            console.trace("Error: Invalid key or and value", key, value);
            return false;
        }

        store.set(key, value);

        return { key: value };
    },

    removeItem: key => {
        if (typeof key !== "string") {
            console.trace("Error: Invalid or missing key", key);
            return false;
        }

        store.remove(key);

        return key;
    },

    getItem: key => {
        if (typeof key !== "string") {
            console.trace("Error: Invalid or missing key", key);
            return false;
        }

        return store.get(key);
    }
};

export { clientStorage as default };
