<template>
    <facet-template
        :show-clear-link="isFiltered"
        :facet-name="'models'"
        @clear="clearSelectedFilters"
    >
        <span slot="facetLabel">
            Trim
            <small class="font-italic font-weight-normal">
                ({{ selectedFilters.length }} selected)
            </small>
        </span>

        <div slot="facetBody">
            <div
                ref="modelListContainer"
                :class="[{ expanded: selectorExpanded }]"
                class="container item-list"
            >
                <div v-for="model in modelGroups" :key="model.name" class="row">
                    <div
                        class="col-12 make-divider d-flex align-items-center pl-3"
                    >
                        <span class="make-name">
                            {{ model.name }}
                        </span>
                    </div>
                    <div class="col-sm-12 p-0 mb-1">
                        <ul
                            v-for="trim in model.trims"
                            :key="trim.id"
                            class="model-for-makes my-2"
                        >
                            <li class="model-name">
                                <input
                                    :id="'model-selector-' + trim.name"
                                    v-model="selectedFilters"
                                    :value="trim.name"
                                    type="checkbox"
                                    class="mb-0"
                                />
                                <label
                                    :for="'model-selector-' + trim.name"
                                    class="mb-0 hotspot"
                                    style="cursor: pointer;"
                                >
                                    {{ trim.name }}
                                </label>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-12 p-2 text-center">
                <b-link v-if="!selectorExpanded" @click="toggleListContainer()">
                    Show More
                </b-link>
                <b-link v-if="selectorExpanded" @click="toggleListContainer()">
                    Show Less
                </b-link>
            </div>
        </div>
    </facet-template>
</template>

<script>
import _ from "lodash";
import FacetTemplate from "./FacetTemplate";
import { get, sync } from "vuex-pathify";

export default {
    name: "TrimFacet",

    components: {
        FacetTemplate
    },

    data() {
        return {
            selectorExpanded: false
        };
    },

    computed: {
        availableFacets: get("vehicleSearch/facets@trims"),
        selectedFilters: sync("vehicleSearch/filters@trims"),
        selectedModels: sync("vehicleSearch/filters@models"),
        modelMap() {
            return _.groupBy(this.availableFacets, "modelName");
        },
        modelMapKeys() {
            return Object.keys(this.modelMap);
        },
        modelGroups() {
            const modelsWithTrims = _.map(this.modelMapKeys, modelName => {
                return {
                    name: modelName,
                    trims: this.modelMap[modelName]
                };
            });

            return _.sortBy(modelsWithTrims, "name");
        },
        isFiltered() {
            return !_.isEmpty(this.selectedFilters);
        }
    },

    methods: {
        clearSelectedFilters() {
            if (this.selectedFilters.length) {
                this.selectedFilters = [];
            }
        },
        toggleListContainer() {
            this.selectorExpanded = !this.selectorExpanded;
        }
    }
};
</script>

<style lang="scss" scoped>
.item-list {
    max-height: 200px;
    overflow: scroll;
}
.item-list.expanded {
    max-height: 500px;
}
.make-divider {
    background-color: white;
    padding: 5px;
    border: 1px solid $light-medium-gray;
    color: $dark-gray;
    font-size: px2rem(14);
}

.model-for-makes {
    padding-left: 19px;
    padding-top: 5px;
}

.model-name {
    padding-bottom: 2px;
}

.hotspot {
    min-width: 80%;
}
</style>
