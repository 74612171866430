<template>
    <div
        id="inventory-mobile-submenu-container"
        ref="submenuContainer"
        class="d-flex d-md-none justify-content-between align-items-center px-3"
        :class="{ 'transition--effect': enableTransition }"
    >
        <b-button class="filter-results" @click="toggleFiltersModal">
            Filter Results
            <i
                aria-hidden="true"
                class="ion-arrow-down-b align-self-baseline pl-2"
            />
        </b-button>

        <sort-selector-mobile />
    </div>
</template>

<script>
import { call, sync } from "vuex-pathify";
import SortSelectorMobile from "Modules/UsedListings/components/MobileFilters/components/SortSelectorMobile";

export default {
    name: "InventoryMobileSubmenu",
    components: { SortSelectorMobile },
    data() {
        return {
            enableTransition: false
        };
    },
    computed: {
        showMobileFilters: sync("vehicleSearch/showMobileFilters")
    },
    mounted() {
        this.setCutOffHeight();
    },
    methods: {
        setCutOffHeight: call("headerTransitionStore/setCutOffHeight"),
        toggleFiltersModal() {
            this.showMobileFilters = !this.showMobileFilters;
        }
    }
};
</script>
<style lang="scss">
#inventory-mobile-submenu-container {
    width: 100%;
    height: 35px;
    background-color: $light-gray;
    color: $medium-gray;
    box-shadow: 0 1px 4px 0 #c1c1c1;
    position: static;
    z-index: 5;

    &.transition--effect {
        transition: all 0.3s ease-out;
    }

    .filter-results {
        width: 108px;
        height: 24px;
        border-radius: 13.5px;
        box-sizing: border-box;
        border: 1px solid $primary-blue;
        font-size: px2rem(12);
        letter-spacing: 0;
        line-height: px2rem(14);
        color: $primary-blue;
        background-color: $light-gray;
    }
}
</style>
