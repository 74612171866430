<template>
    <iframe
        title="CsIframe"
        :class="className"
        :src="src"
        :width="width"
        :height="height"
        frameborder="0"
    />
</template>

<script>
export default {
    name: "CsIframe",

    props: {
        className: {
            required: false,
            default: "",
            type: String
        },
        src: {
            required: true,
            type: String
        },
        width: {
            required: false,
            default: "",
            type: String
        },
        height: {
            required: false,
            default: "",
            type: String
        }
    }
};
</script>
