<template>
    <facet-template
        :show-clear-link="isFiltered"
        :facet-name="'fuels'"
        @clear="clearSelectedFuelTypes"
    >
        <span slot="facetLabel">
            {{ $t("component.facets.fuelType") }}
        </span>

        <div slot="facetBody">
            <div class="container">
                <div class="row">
                    <div class="col-12 p-0">
                        <b-form-group class="m-0">
                            <b-form-checkbox-group
                                v-model="selectedFuelTypes"
                                plain
                                stacked
                            >
                                <b-form-checkbox
                                    v-for="option in availableFuelTypeOptions"
                                    :key="option.value"
                                    :value="option.value"
                                    class="mb-2"
                                >
                                    {{ option.text }}
                                </b-form-checkbox>
                            </b-form-checkbox-group>
                        </b-form-group>
                    </div>
                </div>
            </div>
        </div>
    </facet-template>
</template>

<script>
import _ from "lodash";
import FacetTemplate from "./FacetTemplate";
import { get, sync } from "vuex-pathify";

export default {
    name: "FuelTypeFacet",
    components: {
        FacetTemplate
    },
    computed: {
        availableFuelTypes: get("vehicleSearch/facets@fuelTypes"),
        selectedFuelTypes: sync("vehicleSearch/filters@fuels"),
        availableFuelTypeOptions() {
            const options = _.map(this.availableFuelTypes, fuelType => {
                return {
                    text: fuelType.name,
                    value: fuelType.name
                };
            });

            return options;
        },
        isFiltered() {
            return !_.isEmpty(this.selectedFuelTypes);
        }
    },
    methods: {
        clearSelectedFuelTypes() {
            if (_.size(this.selectedFuelTypes) > 0) {
                this.selectedFuelTypes = [];
            }
        }
    }
};
</script>
