var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("next-steps-modal", { ref: "nextStepsModal" }),
      _vm._v(" "),
      _c(
        "c-s-modal",
        {
          ref: "formModal",
          attrs: {
            "modal-class": "schedule-test-drive-modal",
            size: "lg",
            lazy: ""
          },
          on: {
            ok: function($event) {
              $event.preventDefault()
              return _vm.onSubmit.apply(null, arguments)
            },
            hidden: _vm.onHidden,
            shown: _vm.onShow
          }
        },
        [
          _c("div", { attrs: { slot: "modal-title" }, slot: "modal-title" }, [
            _c("span", { staticClass: "d-none d-sm-inline" }, [
              _vm._v(_vm._s(_vm.scheduleOrReschedule) + " Test Drive")
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "d-sm-none" }, [_vm._v("Test Drive")])
          ]),
          _vm._v(" "),
          _vm.isLoading
            ? _c("appointment-loader")
            : _c(
                "b-container",
                [
                  _vm.hasError
                    ? _c(
                        "b-row",
                        [
                          _c("b-col", [
                            _c("h3", [_vm._v("Uh oh!")]),
                            _vm._v(" "),
                            _c("p", [
                              _vm._v(
                                "\n                        We've hit a bump in the road. Please try again.\n                    "
                              )
                            ])
                          ])
                        ],
                        1
                      )
                    : _c("b-row", [
                        _c("div", { staticClass: "col-md-8" }, [
                          _vm.vehicle
                            ? _c("span", [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(_vm.modalQuestion) +
                                    "\n                        "
                                ),
                                _c("strong", [
                                  _vm._v(
                                    _vm._s(_vm.vehicle.make) +
                                      " " +
                                      _vm._s(_vm.vehicle.model)
                                  )
                                ]),
                                _vm._v("?\n                    ")
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "form",
                            {
                              staticClass: "horizontal-form mt-2",
                              attrs: { id: "test-drive-form" },
                              on: {
                                submit: function($event) {
                                  $event.preventDefault()
                                  return _vm.onSubmit.apply(null, arguments)
                                },
                                keydown: function($event) {
                                  return _vm.form.errors.clear(
                                    $event.target.name
                                  )
                                }
                              }
                            },
                            [
                              _c("div", { staticClass: "inputs-wrapper" }, [
                                _c("div", { staticClass: "form-row" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "form-group col-12 col-md-6"
                                    },
                                    [
                                      _c("strong", [
                                        _c(
                                          "label",
                                          { staticClass: "day-label" },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "component.scheduleTestDrive.dayLabel"
                                                  )
                                                ) +
                                                  "\n                                            "
                                              )
                                            ])
                                          ]
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "input-group input-group-sm"
                                        },
                                        [
                                          _c("vue-bootstrap-datetime-picker", {
                                            attrs: {
                                              id: "datePicker",
                                              config: _vm.config
                                            },
                                            on: {
                                              "dp-change":
                                                _vm.changeAppointmentDate
                                            },
                                            model: {
                                              value: _vm.form.appointmentDate,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "appointmentDate",
                                                  $$v
                                                )
                                              },
                                              expression: "form.appointmentDate"
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "input-group-prepend"
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "input-group-text"
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass: "ion-calendar",
                                                    attrs: {
                                                      "aria-hidden": "true"
                                                    }
                                                  })
                                                ]
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _vm.form.errors.has("appointmentDate")
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "help-block with-errors text-danger"
                                            },
                                            [
                                              _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "has-error text-danger",
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      _vm.form.errors.get(
                                                        "appointmentDate"
                                                      )
                                                    )
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                            Error Text\n                                        "
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "form-group input-group-sm col-12 col-md-6"
                                    },
                                    [
                                      _c("strong", [
                                        _c(
                                          "label",
                                          {
                                            staticClass: "time-label",
                                            attrs: {
                                              for: "appointmentTimeSlots"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                                            " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "component.scheduleTestDrive.timeLabel"
                                                  )
                                                ) +
                                                "\n                                            "
                                            ),
                                            _c(
                                              "small",
                                              { staticClass: "font-italic" },
                                              [
                                                _vm._v(
                                                  "\n                                                " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "component.scheduleTestDrive.dayFirst"
                                                      )
                                                    ) +
                                                    "\n                                            "
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "select",
                                        {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.form.appointmentTime,
                                              expression: "form.appointmentTime"
                                            }
                                          ],
                                          staticClass: "form-control",
                                          attrs: { id: "appointmentTimeSlots" },
                                          on: {
                                            change: function($event) {
                                              var $$selectedVal = Array.prototype.filter
                                                .call(
                                                  $event.target.options,
                                                  function(o) {
                                                    return o.selected
                                                  }
                                                )
                                                .map(function(o) {
                                                  var val =
                                                    "_value" in o
                                                      ? o._value
                                                      : o.value
                                                  return val
                                                })
                                              _vm.$set(
                                                _vm.form,
                                                "appointmentTime",
                                                $event.target.multiple
                                                  ? $$selectedVal
                                                  : $$selectedVal[0]
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "option",
                                            {
                                              attrs: { disabled: "", value: "" }
                                            },
                                            [
                                              _vm._v(
                                                "\n                                            " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "component.scheduleTestDrive.selectOption"
                                                    )
                                                  ) +
                                                  "\n                                        "
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _vm._l(_vm.timeSlots, function(
                                            timeSlot,
                                            key
                                          ) {
                                            return _c(
                                              "option",
                                              {
                                                key: key,
                                                domProps: {
                                                  value: timeSlot.value
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                            " +
                                                    _vm._s(timeSlot.text) +
                                                    "\n                                        "
                                                )
                                              ]
                                            )
                                          })
                                        ],
                                        2
                                      ),
                                      _vm._v(" "),
                                      _vm.form.errors.has("appointmentTime")
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "help-block with-errors text-danger"
                                            },
                                            [
                                              _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "has-error text-danger",
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      _vm.form.errors.get(
                                                        "appointmentTime"
                                                      )
                                                    )
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                            Error Text\n                                        "
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ]
                                  )
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "form-group" }, [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "comment-box-label",
                                      attrs: { for: "question" }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                    " +
                                          _vm._s(
                                            _vm.$t(
                                              "component.scheduleTestDrive.questionOrComment"
                                            )
                                          ) +
                                          "\n                                "
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("textarea", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.form.question,
                                        expression: "form.question"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: { id: "question", rows: "4" },
                                    domProps: { value: _vm.form.question },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.form,
                                          "question",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  }),
                                  _vm._v(" "),
                                  _vm.form.errors.has("question")
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "help-block with-errors text-danger"
                                        },
                                        [
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "has-error text-danger",
                                              domProps: {
                                                textContent: _vm._s(
                                                  _vm.form.errors.get(
                                                    "question"
                                                  )
                                                )
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                                        Error Text\n                                    "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ]),
                                _vm._v(" "),
                                _vm.dealer
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "dealership-info d-flex flex-column d-sm-flex d-md-none d-lg-none d-xl-none mt-0 mb-3"
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "certified-dealer" },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "component.scheduleTestDrive.certifiedDealer"
                                                  )
                                                )
                                              )
                                            ])
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "dealership-name" },
                                          [
                                            _c("span", [
                                              _vm._v(_vm._s(_vm.dealer.name))
                                            ])
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          { staticClass: "distance" },
                                          [
                                            _vm._v(
                                              "\n                                    " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "component.scheduleTestDrive.distance"
                                                  )
                                                ) +
                                                "\n                                    " +
                                                _vm._s(
                                                  _vm._f("numeral")(
                                                    _vm.distance,
                                                    "0.0"
                                                  )
                                                ) +
                                                "\n                                    " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "component.scheduleTestDrive.miles"
                                                  )
                                                ) +
                                                "\n                                "
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.form.ambassadorPin,
                                      expression: "form.ambassadorPin"
                                    }
                                  ],
                                  attrs: {
                                    id: "ambassadorPin",
                                    type: "hidden",
                                    name: "ambassadorPin"
                                  },
                                  domProps: { value: _vm.form.ambassadorPin },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        _vm.form,
                                        "ambassadorPin",
                                        $event.target.value
                                      )
                                    }
                                  }
                                })
                              ])
                            ]
                          ),
                          _vm._v(" "),
                          _vm.user
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "d-none d-sm-none d-md-flex flex-column user-info"
                                },
                                [
                                  _c("div", { staticClass: "mb-2" }, [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "component.scheduleTestDrive.contactInfoCorrect"
                                          )
                                        )
                                      )
                                    ])
                                  ]),
                                  _vm._v(" "),
                                  _c("edit-contact-info")
                                ],
                                1
                              )
                            : _vm._e()
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "col-md-4 d-none d-sm-none d-md-flex flex-column"
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "vehicle-image flex-column" },
                              [
                                _c("b-img", {
                                  attrs: {
                                    src: _vm.imageUrl,
                                    alt: _vm.vehicleName,
                                    fluid: ""
                                  }
                                }),
                                _vm._v(" "),
                                _vm.vehicle
                                  ? _c(
                                      "div",
                                      { staticClass: "vehicle-title-box py-2" },
                                      [
                                        _c("h4", [
                                          _vm._v(_vm._s(_vm.vehicleName))
                                        ]),
                                        _vm._v(" "),
                                        _c("span", [
                                          _vm._v(_vm._s(_vm.vehicle.trim))
                                        ])
                                      ]
                                    )
                                  : _vm._e()
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _vm.dealer
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "dealership-info d-flex flex-column h-100 justify-content-end"
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "certified-dealer" },
                                      [
                                        _vm._v(
                                          "\n                            " +
                                            _vm._s(
                                              _vm.$t(
                                                "component.scheduleTestDrive.certifiedDealer"
                                              )
                                            ) +
                                            "\n                        "
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      { staticClass: "dealership-name" },
                                      [
                                        _vm._v(
                                          "\n                            " +
                                            _vm._s(_vm.dealer.name) +
                                            "\n                        "
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "distance" }, [
                                      _vm._v(
                                        "\n                            " +
                                          _vm._s(
                                            _vm.$t(
                                              "component.scheduleTestDrive.distance"
                                            )
                                          ) +
                                          "\n                            " +
                                          _vm._s(
                                            _vm._f("numeral")(
                                              _vm.distance,
                                              "0.0"
                                            )
                                          ) +
                                          "\n                            " +
                                          _vm._s(
                                            _vm.$t(
                                              "component.scheduleTestDrive.miles"
                                            )
                                          ) +
                                          "\n                        "
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "address" }, [
                                      _vm._v(
                                        "\n                            " +
                                          _vm._s(_vm.dealer.address1) +
                                          "\n                        "
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "address" }, [
                                      _vm._v(
                                        "\n                            " +
                                          _vm._s(_vm.dealer.city) +
                                          " " +
                                          _vm._s(_vm.dealer.state) +
                                          "\n                            " +
                                          _vm._s(_vm.dealer.zipCode) +
                                          "\n                        "
                                      )
                                    ])
                                  ]
                                )
                              : _vm._e()
                          ]
                        )
                      ]),
                  _vm._v(" "),
                  _c("b-row", [
                    _c("div", { staticClass: "col-12" }, [
                      _c("div", { staticClass: "terms-row mt-0 mt-lg-3" }, [
                        _c(
                          "div",
                          { staticClass: "terms-and-condition-block" },
                          [
                            _c(
                              "span",
                              { staticClass: "terms font-weight-bold" },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.$t(
                                        "component.scheduleTestDrive.termsBold"
                                      )
                                    ) +
                                    "\n                            "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c("span", { staticClass: "terms font-italic" }, [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm.$t("component.scheduleTestDrive.terms")
                                  ) +
                                  "\n                            "
                              )
                            ])
                          ]
                        )
                      ])
                    ])
                  ])
                ],
                1
              ),
          _vm._v(" "),
          _c(
            "template",
            { slot: "modal-footer" },
            [
              _c(
                "ladda-b-btn",
                {
                  attrs: {
                    loading: _vm.submitting,
                    disabled: _vm.isFormInvalid,
                    variant: "orange"
                  },
                  on: { click: _vm.onSubmit }
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.buttonTitle) +
                      "\n            "
                  )
                ]
              )
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }