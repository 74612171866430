var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "make-icon-container d-flex justify-content-start align-items-center mb-0 w-100",
      class: { selected: _vm.selected }
    },
    [
      _c("div", { staticClass: "d-flex align-items-center py-2 pl-3" }, [
        _c("img", {
          staticClass: "make-logo mr-3",
          attrs: { src: _vm.getLogoForMake, alt: "logo" }
        }),
        _vm._v(" "),
        _c("span", { staticClass: "make-name" }, [
          _vm._v("\n            " + _vm._s(_vm.make.name) + "\n        ")
        ])
      ]),
      _vm._v(" "),
      _vm.selected
        ? _c("i", {
            staticClass: "ion-checkmark icon",
            attrs: { "aria-hidden": "true" }
          })
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }