var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "cs-modal",
    { ref: "modal", attrs: { id: "privacyProtectionModal", size: "md" } },
    [
      _c("div", { attrs: { slot: "modal-title" }, slot: "modal-title" }, [
        _vm._v(
          "\n        " +
            _vm._s(_vm.$t("component.privacyProtectionModal.title")) +
            "\n    "
        )
      ]),
      _vm._v(" "),
      _c("b-container", { staticClass: "py-sm-3 py-5" }, [
        _c("div", { staticClass: "mb-2" }, [
          _c("span", { staticClass: "font-weight-bold" }, [
            _vm._v(
              "\n                " +
                _vm._s(_vm.$t("component.privacyProtectionModal.subTitle")) +
                "\n            "
            )
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "d-flex flex-column ml-3" }, [
          _c("div", { staticClass: "d-flex flex-column my-2" }, [
            _c("div", { staticClass: "d-flex flex-row" }, [
              _c("span", { staticClass: "font-weight-bold mr-2" }, [
                _vm._v(
                  "\n                        " +
                    _vm._s(_vm.$t("component.privacyProtectionModal.one")) +
                    "\n                    "
                )
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "font-weight-bold" }, [
                _vm._v(
                  "\n                        " +
                    _vm._s(
                      _vm.$t("component.privacyProtectionModal.listItemOne")
                    ) +
                    "\n                    "
                )
              ])
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "ml-4" }, [
              _vm._v(
                "\n                    " +
                  _vm._s(
                    _vm.$t("component.privacyProtectionModal.subListItemOne")
                  ) +
                  "\n                "
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "d-flex flex-column my-2" }, [
            _c("div", { staticClass: "d-flex flex-row" }, [
              _c("span", { staticClass: "font-weight-bold mr-2" }, [
                _vm._v(
                  "\n                        " +
                    _vm._s(_vm.$t("component.privacyProtectionModal.two")) +
                    "\n                    "
                )
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "font-weight-bold" }, [
                _vm._v(
                  "\n                        " +
                    _vm._s(
                      _vm.$t("component.privacyProtectionModal.listItemTwo")
                    ) +
                    "\n                    "
                )
              ])
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "ml-4" }, [
              _vm._v(
                "\n                    " +
                  _vm._s(
                    _vm.$t("component.privacyProtectionModal.subListItemTwo")
                  ) +
                  "\n                "
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "d-flex flex-column my-2" }, [
            _c("div", { staticClass: "d-flex flex-row" }, [
              _c("span", { staticClass: "font-weight-bold mr-2" }, [
                _vm._v(
                  "\n                        " +
                    _vm._s(_vm.$t("component.privacyProtectionModal.three")) +
                    "\n                    "
                )
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "font-weight-bold" }, [
                _vm._v(
                  "\n                        " +
                    _vm._s(
                      _vm.$t("component.privacyProtectionModal.listItemThree")
                    ) +
                    "\n                    "
                )
              ])
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "ml-4" }, [
              _vm._v(
                "\n                    " +
                  _vm._s(
                    _vm.$t("component.privacyProtectionModal.subListItemThree")
                  ) +
                  "\n                "
              )
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "privacy-block d-flex flex-row justify-content-center" },
        [
          _c(
            "b-link",
            { staticClass: "mx-1", attrs: { href: "/tos", target: "_blank" } },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.$t("component.signInRequiredModal.terms")) +
                  "\n        "
              )
            ]
          ),
          _vm._v(" "),
          _c("span", [_vm._v("|")]),
          _vm._v(" "),
          _c(
            "b-link",
            {
              staticClass: "mx-1",
              attrs: { href: "/privacy", target: "_blank" }
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.$t("component.signInRequiredModal.privacy")) +
                  "\n        "
              )
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { slot: "modal-footer" }, slot: "modal-footer" },
        [
          _c(
            "b-button",
            {
              attrs: { variant: "orange" },
              on: {
                click: function($event) {
                  return _vm.openDealerLinkRequiredModal()
                }
              }
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.$t("component.privacyProtectionModal.back")) +
                  "\n        "
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }