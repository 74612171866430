var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "facet" },
    [
      _c(
        "div",
        {
          staticClass: "facet-header d-flex justify-content-between mb-3",
          on: { click: _vm.collapseToggle }
        },
        [
          _c("b-link", { staticClass: "label" }, [
            _c(
              "span",
              { staticClass: "font-weight-bold" },
              [_vm._t("facetLabel")],
              2
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            [
              _vm.showClearLink
                ? _c("b-link", { on: { click: _vm.clearFilters } }, [
                    _c("span", { staticClass: "clear font-italic" }, [
                      _vm._v("\n                    Clear\n                ")
                    ])
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.showCollapsibleButton
                ? _c("b-link", { staticClass: "collapse-icon" }, [
                    _c("span", {
                      class: [
                        _vm.collapsed ? "ion-chevron-right" : "ion-chevron-down"
                      ]
                    })
                  ])
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-collapse",
        {
          staticClass: "facet-body my-3",
          attrs: { id: _vm.facetName, visible: !_vm.collapsed }
        },
        [_vm._t("facetBody")],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }