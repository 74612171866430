<template>
    <b-btn
        ref="ladda"
        :disabled="isDisabled"
        :variant="variant"
        :size="size"
        @click="click"
    >
        <span
            v-if="loading"
            class="spinner-border spinner-border-sm mr-2"
            role="status"
            aria-hidden="true"
        />
        <span v-if="loading">{{ loadingText }}</span>
        <span v-else><slot /></span>
    </b-btn>
</template>

<script>
export default {
    name: "LaddaBBtn",
    props: {
        // loading prop to change the status of this component.
        loading: {
            type: Boolean,
            required: true
        },
        loadingText: {
            type: String,
            required: false,
            default: "Loading..."
        },
        disabled: {
            type: Boolean,
            default: false
        },
        variant: {
            type: String,
            default: "primary"
        },
        size: {
            type: String,
            default: ""
        }
    },

    computed: {
        isDisabled() {
            return this.disabled || this.loading;
        }
    },

    methods: {
        click(event) {
            this.$emit("click", event);
        }
    }
};
</script>
