<template>
    <div class="client-url-storer" />
</template>
<script>
import listingsUrlStorer from "@/mixins/listingsUrlStorer";

export default {
    name: "ClientUrlStorer",

    mixins: [listingsUrlStorer],

    created() {
        this.storeCurrentUrl(); // stores locally to {'listings': {url: <value>}
    }
};
</script>
<style type="scss" scoped>
#client-url-storer {
    height: 0px;
    width: 0px;
}
</style>
