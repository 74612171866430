var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "full-height" },
    [
      _c("pick-price-drive", { attrs: { "selected-index": 0 } }),
      _vm._v(" "),
      _c(
        "b-container",
        { staticClass: "full-height", attrs: { fluid: "" } },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                [
                  _c("b-breadcrumb", {
                    staticClass: "mb-sm-0",
                    attrs: { items: _vm.breadcrumbs }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-row",
            { staticClass: "d-none d-sm-flex" },
            [_c("b-col", { staticClass: "header" }, [_vm._t("header")], 2)],
            1
          ),
          _vm._v(" "),
          _c("b-row", [_c("b-col", [_vm._t("default")], 2)], 1)
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }