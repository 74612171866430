<template>
    <div
        :class="{ selected: selected }"
        class="make-icon-container d-flex justify-content-start align-items-center mb-0 w-100"
    >
        <div class="d-flex align-items-center py-2 pl-3">
            <img :src="getLogoForMake" class="make-logo mr-3" alt="logo" />
            <span class="make-name">
                {{ make.name }}
            </span>
        </div>
        <i v-if="selected" class="ion-checkmark icon" aria-hidden="true" />
    </div>
</template>

<script>
export default {
    name: "MakeIcon",
    props: {
        make: {
            type: Object,
            required: true
        },
        selected: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    computed: {
        getLogoForMake() {
            // logo image path is
            const allSpacesAndDashes = /\s|-/g;
            const logoImageName = this.make.name
                .replace(allSpacesAndDashes, "_")
                .toLowerCase();
            return "/assets/images/make_logo/" + logoImageName + ".png";
        }
    }
};
</script>

<style lang="scss">
.make-icon-container {
    height: 41px;

    .make-name {
        user-select: none;
    }

    .make-logo {
        height: px2rem(25);
        width: px2rem(25);
    }
    .icon {
        font-size: px2rem(16);
        color: $check-mark-active;
        margin-left: 10px;
    }

    &.selected {
        color: $primary-blue;
    }
}

.selectedMakeBorder {
    border: 1px solid rgb(0, 125, 198);
    border-radius: 24px;
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.2) 0 0 5px 0;
}
</style>
