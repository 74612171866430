<template>
    <div
        v-if="showOverlay"
        id="location-loading-overlay"
        class="d-flex flex-column justify-content-center text-center"
    >
        <b-container>
            <h2>Calculating your location...</h2>
            <h5 v-if="nudge">
                To show vehicles in your area, please allow the location request
                or enter your zip code below:
            </h5>
            <b-form @submit.prevent="saveZipCode">
                <b-input-group
                    v-if="nudge"
                    prepend="Zip Code"
                    class="my-3 mx-auto zipcode-group"
                >
                    <b-form-input
                        v-model="zipCode"
                        class="zipcode"
                        placeholder="e.g. 33132"
                        maxlength="5"
                        :state="isValidZipCode"
                    />
                    <b-input-group-append>
                        <b-button variant="info" type="submit">
                            Update
                        </b-button>
                    </b-input-group-append>
                </b-input-group>
            </b-form>
            <p v-if="locationLoadingError" class="text-warning">
                We were unable to automatically determine your location, please
                enter your zip code above.
            </p>
            <loading-spinner v-else :size="50" color="#FFFFFF" />
        </b-container>
    </div>
</template>

<style lang="scss">
#location-loading-overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.7);
    color: #fff;
    z-index: 1000;

    .btn-info {
        background-color: $primary-blue;
        border-color: $primary-blue;
        color: #fff;
    }

    .half-circle-spinner {
        margin: 0 auto;
    }

    .zipcode-group {
        width: 250px;
    }
}
</style>

<script>
import { call, get } from "vuex-pathify";
import _ from "lodash";
import URL from "url-parse";
import LoadingSpinner from "Components/LoadingSpinner/index";
import geoLocationMixin from "@/mixins/geoLocationMixin";

export default {
    name: "GeoLocation",
    components: { LoadingSpinner },
    mixins: [geoLocationMixin],

    props: {
        required: {
            type: Boolean,
            default: true,
            required: false
        },
        overlayDisabled: {
            type: Boolean,
            default: false,
            required: false
        }
    },

    data() {
        return {
            zipCode: "",
            nudge: false,
            isValidZipCode: null
        };
    },

    computed: {
        clientLocation: get("location/clientLocation"),
        selectedLocation: get("location/selectedLocation"),
        locationLoadingError: get("location/loader@isError"),

        showOverlay() {
            return !this.hasLocation && this.required && !this.overlayDisabled;
        },

        hasLocation() {
            return this.selectedLocation !== null;
        }
    },

    watch: {
        locationLoadingError(newVal) {
            if (newVal === true) {
                this.nudge = true;
            }
        }
    },

    created() {
        const url = new URL(window.location.href, true);
        const zipParam = url.query.zipCode;
        if (!_.isNil(zipParam)) {
            this.zipCode = zipParam;
            this.saveZipCode();
            return;
        }

        if (!_.isNil(this.selectedLocation)) {
            return;
        }

        if (
            !_.isNil(this.clientLocation) &&
            !this.isExpired(this.clientLocation)
        ) {
            this.setLocation(this.clientLocation);
            return;
        }

        if (this.required) {
            this.getLocation();
        }
    },

    methods: {
        setLocation: call("location/setLocation"),
        loadLocation: call("location/loadLocation"),
        loadLocationByCoordinates: call("location/loadLocationByCoordinates"),
        updateZipCode: call("location/updateZipCode"),
        getLocation() {
            const nudgeTimeoutId = setTimeout(() => {
                this.nudge = true;
            }, 5000);

            const geoSuccess = coordinates => {
                this.nudge = false;

                const lat = _.get(coordinates, "lat");
                const lng = _.get(coordinates, "lng");

                if (_.isNil(lat) || _.isNil(lng)) {
                    this.loadLocation();
                }

                clearTimeout(nudgeTimeoutId);
                this.loadLocationByCoordinates({ lat, lng });
            };

            const geoError = error => {
                if (error.code === error.TIMEOUT) {
                    this.nudge = true;
                } else {
                    this.loadLocation();
                }
            };

            this.$getLocation()
                .then(geoSuccess)
                .catch(geoError);
        },

        isValidZipCodePattern(zipCode) {
            return /(^\d{5}$)/.test(zipCode);
        },

        saveZipCode() {
            if (this.isValidZipCodePattern(this.zipCode)) {
                this.updateZipCode(this.zipCode);
            } else {
                this.isValidZipCode = false;
            }

            // Kiosk requires an explicit return of false - even with .prevent on the event.
            return false;
        }
    }
};
</script>
