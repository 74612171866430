<template>
    <facet-template
        :show-clear-link="isFiltered"
        :facet-name="'models'"
        @clear="clearSelectedModels"
    >
        <span slot="facetLabel">
            Model
            <small class="font-italic font-weight-normal">
                ({{ selectedModels.length }} selected)
            </small>
        </span>

        <div slot="facetBody">
            <div
                ref="modelListContainer"
                :class="[{ expanded: modelSelectorExpanded }]"
                class="container model-list"
            >
                <div v-for="make in makeGroups" :key="make.name" class="row">
                    <div
                        class="col-12 make-divider d-flex align-items-center pl-3"
                    >
                        <img
                            :src="getLogoForMake(make)"
                            class="make-logo mr-1"
                            alt="logo"
                        />
                        <span class="make-name">
                            {{ make.name }}
                        </span>
                    </div>
                    <div class="col-sm-12 p-0 mb-1">
                        <ul
                            v-for="model in make.models"
                            :key="model.id"
                            class="model-for-makes my-2"
                        >
                            <li class="model-name">
                                <input
                                    :id="'model-selector-' + model.name"
                                    v-model="selectedModels"
                                    :value="model.name"
                                    type="checkbox"
                                    class="mb-0"
                                    @input="modelChange"
                                />
                                <label
                                    :for="'model-selector-' + model.name"
                                    class="mb-0 hotspot"
                                    style="cursor: pointer;"
                                >
                                    {{ model.name }}
                                </label>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div v-if="revealShowMoreToggle" class="col-12 p-2 text-center">
                <b-link
                    v-if="!modelSelectorExpanded"
                    @click="toggleModelListContainer()"
                >
                    Show More
                </b-link>
                <b-link v-else @click="toggleModelListContainer()">
                    Show Less
                </b-link>
            </div>
        </div>
    </facet-template>
</template>

<script>
import _ from "lodash";
import FacetTemplate from "./FacetTemplate";
import { get, sync, call } from "vuex-pathify";

export default {
    name: "ModelFacet",

    components: {
        FacetTemplate
    },

    data() {
        return {
            modelSelectorExpanded: false,
            revealShowMoreToggle: false
        };
    },

    computed: {
        availableModels: get("vehicleSearch/facets@models"),
        selectedModels: sync("vehicleSearch/filters@models"),
        selectedMakes: sync("vehicleSearch/filters@makes"),
        makeMap() {
            return _.groupBy(this.availableModels, "makeName");
        },
        makeMapKeys() {
            return Object.keys(this.makeMap);
        },
        makeGroups() {
            const makesWithModels = _.map(this.makeMapKeys, makeName => {
                return {
                    name: makeName,
                    models: this.makeMap[makeName]
                };
            });

            return _.sortBy(makesWithModels, "name");
        },
        isFiltered() {
            return !_.isEmpty(this.selectedModels);
        }
    },
    watch: {
        makeMapKeys: {
            handler: function() {
                // $refs aren't reactive so use nextTick to realize change
                this.$nextTick(() => {
                    const scrollHeight =
                        this.$refs.modelListContainer.scrollHeight - 12; // account for padding
                    const clientHeight = this.$refs.modelListContainer
                        .clientHeight;

                    // scrollHeight > clientHeight is indicative that not all is being shown
                    this.revealShowMoreToggle = scrollHeight > clientHeight;
                });
            },
            immediate: true // needed for initial page load
        }
    },
    methods: {
        clearModel: call("vehicleSearch/clearModel"),
        clearModels: call("vehicleSearch/clearModels"),
        modelChange(event) {
            if (!event.target.checked) {
                this.clearModel(event.target.value);
            }
        },
        clearSelectedModels() {
            if (this.selectedModels.length) {
                this.clearModels();
            }
        },
        toggleModelListContainer() {
            this.modelSelectorExpanded = !this.modelSelectorExpanded;
        },
        getLogoForMake(make) {
            // logo image path is
            const allSpacesAndDashes = /\s|-/g;
            const logoImageName = make.name
                .replace(allSpacesAndDashes, "_")
                .toLowerCase();

            return "/assets/images/make_logo/" + logoImageName + ".png";
        }
    }
};
</script>

<style lang="scss" scoped>
.model-list {
    max-height: 200px;
    overflow: scroll;
}
.model-list.expanded {
    max-height: 500px;
}
.make-logo {
    height: 20px;
    width: 20px;
}
.make-divider {
    background-color: white;
    padding: 5px;
    border: 1px solid $light-medium-gray;
    color: $dark-gray;
    font-size: px2rem(14);
}

.model-for-makes {
    padding-left: 19px;
    padding-top: 5px;
}

.model-name {
    padding-bottom: 2px;
}

.hotspot {
    min-width: 80%;
}
</style>
