<template>
    <div
        :class="{ selected: selected, completed: completed }"
        class="item d-flex justify-content-center"
    >
        <div class="d-flex flex-row align-items-center">
            <div class="number text-center">
                <i v-if="completed" class="ion-checkmark" aria-hidden="true" />
                {{ number }}
            </div>
            <div class="label ml-2">
                {{ label }}
                <small class="d-block">
                    {{ description }}
                </small>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        selected: {
            type: Boolean,
            required: false,
            default: false
        },
        completed: {
            type: Boolean,
            required: false,
            default: false
        },
        number: {
            type: Number,
            required: true
        },
        label: {
            type: String,
            required: true
        },
        description: {
            type: String,
            required: true
        }
    }
};
</script>

<style lang="scss" scoped>
.item {
    height: 50px;
    min-width: 200px;

    &.selected {
        border-bottom: 2px solid $primary-blue;

        .number {
            background-color: #fff;
            color: $primary-blue;
            font-weight: bold;
        }

        .label {
            font-style: normal;
            color: $primary-blue;
            font-weight: bold;

            > small {
                font-style: italic;
                color: $dark-gray;
            }
        }
    }

    &.completed {
        .number {
            background-color: #fff;
            color: $dark-gray;
        }

        .label {
            font-style: normal;
            color: $dark-gray;

            > small {
                font-style: italic;
                color: $dark-gray;
            }
        }
    }

    .number {
        background-color: $light-gray;
        border: solid 1px $muted-gray;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        position: relative;
        color: $muted-dark-gray;
        font-size: 12px;
        padding: 5px;

        > i {
            position: absolute;
            right: 0;
            top: -5px;
            color: $green;
        }
    }

    .label {
        line-height: 12px;
        font-style: italic;
        color: $muted-dark-gray;

        > small {
            font-family: $helvetica-46-light-italic;
            color: $muted-dark-gray;
            font-size: 10px;
        }
    }
}
</style>
