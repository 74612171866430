import _ from "lodash";

const convertValue = value => {
    if (value !== "") {
        if (value === "null") {
            return null;
        } else if (value === "true") {
            return true;
        } else if (value === "false") {
            return false;
        } else if (!isNaN(value)) {
            return _.toNumber(value);
        } else {
            return value;
        }
    }

    return value;
};

const queryToFilters = (filters, query) => {
    const newQuery = {};
    _.forEach(query, (value, queryItem) => {
        if (Object.prototype.hasOwnProperty.call(filters, queryItem)) {
            if (_.isArray(filters[queryItem])) {
                if (value !== "") {
                    newQuery[queryItem] = _.map(
                        _.split(value, ","),
                        convertValue
                    );
                } else {
                    newQuery[queryItem] = filters[queryItem];
                }
            } else {
                newQuery[queryItem] = convertValue(value);
            }
        }
    });

    return newQuery;
};

export { queryToFilters };
